import WidgetContainer from "../../components/widgets/WidgetContainer";
import NewSpyder from "../../components/blocks/NewSpyder";

const SpydeGestion = () => {
  return (
    <div className="configGrid">
      <section className="widget">
        <WidgetContainer
          type="spyde"
          icon="person-add-outline"
          title="Ajouter un Compte Spyde"
          order={2}
        >
          <NewSpyder />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default SpydeGestion;
