import { Outlet } from "react-router-dom";
import DashBoard from "../elements/DashBoard";

function ClientLayout() {
  return (
    <>
      <DashBoard />
      <Outlet />
      {/* <Help /> */}
    </>
  );
}

export default ClientLayout;
