import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../useAuth";

const RequireAuth = ({ allowedRules }) => {
  const { auth } = useAuth();
  const location = useLocation();

  return auth?.rules?.find((rule) => allowedRules?.includes(rule)) ? (
    <Outlet />
  ) : auth?.email ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/seconnecter" state={{ from: location }} replace />
  );
};

export default RequireAuth;
