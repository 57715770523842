import { useState, useEffect } from "react";
import CheckBox from "../elements/CheckBox";
import CreateSpydeAccount from "./CreateSpydeAccount";
import FindSpydeAccount from "./FindSpydeAccount";
import FormCreateSpyder from "./FormCreateSpyder";

const NewSpyder = () => {
  const [newAgency, setNewAgency] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const [selectedSpydeAccount, setSelectedSpydeAccount] = useState(null);
  const [selectedSpyder, setSelectedSpyder] = useState(null);
  const [emailSpyder, setEmailSpyder] = useState(null);

  const setDataSpydeAccount = (data) => {
    setSelectedSpydeAccount(data);
  };

  const setDataSpyder = (data, email) => {
    setSelectedSpyder(data);
    setEmailSpyder(email);
  };

  useEffect(() => {
    setNewAgency(false);
    setIsCreate(false);
  }, []);

  useEffect(() => {
    if (selectedSpydeAccount && selectedSpyder) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [selectedSpydeAccount, selectedSpyder]);

  return (
    <>
      {!isCreate ? (
        <div className="newClientForm">
          {!selectedSpydeAccount ? (
            <CheckBox
              order="002"
              text="Créer une nouvelle agence Spyde"
              afterDisable={() => setNewAgency(false)}
              afterSelect={() => setNewAgency(true)}
            />
          ) : (
            ""
          )}
          {newAgency ? (
            <CreateSpydeAccount setDataSpydeAccount={setDataSpydeAccount} />
          ) : (
            <FindSpydeAccount setDataSpydeAccount={setDataSpydeAccount} />
          )}
          {selectedSpydeAccount ? (
            <FormCreateSpyder
              selectedSpydeAccount={selectedSpydeAccount}
              setDataSpyder={setDataSpyder}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          <div className="responseContainer responseContainer__split">
            <div className="responseContainer__split--part">
              <h3 className="responseContainer__success">Agence :</h3>

              <div className="responseContainer__block">
                <p className="responseContainer__title">Nom de l'agence :</p>
                <p className="responseContainer__result">
                  {selectedSpydeAccount.agency}
                </p>
              </div>

              <div className="responseContainer__block">
                <p className="responseContainer__title">Responsable :</p>
                <p className="responseContainer__result">
                  {selectedSpydeAccount.respLastname +
                    " " +
                    selectedSpydeAccount.respFirstname}
                </p>
              </div>

              <div className="responseContainer__block">
                <p className="responseContainer__title">
                  Adresse de collecte :
                </p>
                <p className="responseContainer__result">
                  {selectedSpydeAccount.address}
                </p>
              </div>
            </div>
            <div className="responseContainer__split--part">
              <h3 className="responseContainer__success">
                Nouvel employé créé:
              </h3>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Prénom :</p>
                <p className="responseContainer__result">
                  {selectedSpyder.lastname}
                </p>
              </div>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Nom :</p>
                <p className="responseContainer__result">
                  {selectedSpyder.firstname}
                </p>
              </div>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Tel :</p>
                <p className="responseContainer__result">
                  {selectedSpyder.phone}
                </p>
              </div>

              <div className="responseContainer__block">
                <p className="responseContainer__title">Adresse e-mail :</p>
                <p className="responseContainer__result">{emailSpyder}</p>
              </div>
            </div>
          </div>

          {/* <div className="responseContainer responseContainer__split">
            <div className="responseContainer__split--part">
              <h3 className="responseContainer__success">Client :</h3>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Raison sociale :</p>
                <p className="responseContainer__result">
                  {selectedClient.clientName}
                </p>
              </div>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Nom du site :</p>
                <p className="responseContainer__result">
                  {selectedClient.agency}
                </p>
              </div>

              <div className="responseContainer__block">
                <p className="responseContainer__title">
                  Adresse de collecte :
                </p>
                <p className="responseContainer__result">
                  {selectedClient.address}
                </p>
              </div>
            </div>
            <div className="responseContainer__split--part">
              <h3 className="responseContainer__success">Contact :</h3>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Nom et prénom :</p>
                <p className="responseContainer__result">
                  {selectedContact.firstname + "  " + selectedContact.lastname}
                </p>
              </div>
              <div className="responseContainer__block">
                <p className="responseContainer__title">Tel :</p>
                <p className="responseContainer__result">
                  {selectedContact.phone}
                </p>
              </div>

              <div className="responseContainer__block">
                <p className="responseContainer__title">Adresse e-mail :</p>
                <p className="responseContainer__result">{emailContact}</p>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default NewSpyder;
