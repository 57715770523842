import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";

const NaviBurger = () => {
  const [toggleMenuNav, setToggleMenuNav] = useState(true);
  const [largeur, setLargeur] = useState(window.innerWidth);

  const navbar = document.getElementById("navbar");

  const toggleNav = () => {
    setToggleMenuNav(!toggleMenuNav);
    toggleMenuNav
      ? navbar.classList.remove("show-nav")
      : navbar.classList.add("show-nav");
  };

  //* Check window dimensions */
  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);

      if (window.innerWidth > 870) {
        setToggleMenuNav(false);
        navbar?.classList.remove("show-nav");
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, [toggleMenuNav, navbar]);

  //! NOTA : think to change largeur if you change breakpoints //

  return (
    <nav className="navbar" id="navbar" role="navigation" onClick={toggleNav}>
      {(toggleMenuNav || largeur > 870) && (
        <ul className="navbar__links">
          <li className="navbar__link navbar__link--0">
            <NavLink to="/" className="nav__a">
              Accueil
            </NavLink>
          </li>
          <li className="navbar__link navbar__link--1">
            <NavLink to="/services" className="nav__a">
              Nos Services
            </NavLink>
          </li>
          <li className="navbar__link navbar__link--2">
            <NavLink to="/actions" className="nav__a">
              Nos Actions
            </NavLink>
          </li>
          <li className="navbar__link navbar__link--3">
            <NavLink to="/blog" className="nav__a">
              Notre Blog
            </NavLink>
          </li>
          <li className="navbar__link navbar__link--4">
            <NavLink to="/contact" className="nav__a">
              A Propos
            </NavLink>
          </li>
        </ul>
      )}

      <button className="burger">
        <span className="bar"></span>
      </button>
    </nav>
  );
};

export default NaviBurger;
