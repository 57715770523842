import { phoneNumber } from "../../utils/phoneNumber";
import { useSelector } from "react-redux";

const MySpydeAgency = () => {
  const userData = useSelector((state) => state.user.user);

  return (
    <div className="dashfrom">
      <form className="dashform__form">
        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Agence :</label>

          <p className="responseContainer__result">
            {userData?.spydeContact["SpyderAccount.agency"]}
          </p>
        </div>
        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Responsable :</label>

          <p className="responseContainer__result">
            {userData?.spydeContact["SpyderAccount.respLastname"] +
              "  " +
              userData?.spydeContact["SpyderAccount.respFirstname"]}
          </p>
        </div>
        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Téléphone :</label>

          <p className="responseContainer__result">
            {phoneNumber(userData?.spydeContact["SpyderAccount.respPhone"])}
          </p>
        </div>

        <div className="dashform__form__block dashform__form__block--padding dashform__form__block--address  responseContainer__split--half">
          <label>Adresse du dépôt :</label>

          <p className="responseContainer__result">
            {userData?.spydeContact["SpyderAccount.address"]}
          </p>
        </div>
      </form>
    </div>
  );
};

export default MySpydeAgency;
