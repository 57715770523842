import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";

export const getClients = createAsyncThunk("Client/getClients", async () => {
  return await Axios.get(process.env.REACT_APP_API_URL + "/client")
    .then((res) => res.data)
    .catch((err) => console.log("Error getClients "));
});

export const addClient = createAsyncThunk("Client/addClient", async (data) => {
  return await Axios.put(
    process.env.REACT_APP_API_URL + "/client/add",
    data
  ).then((res) => res.data);
});

export const patchClient = createAsyncThunk(
  "Client/patchClient",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/client/modify/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

const clientSlice = createSlice({
  name: "Client",
  initialState: { client: null, status: null, loading: false, error: "" },

  reducers: {
    modifClient: (state, { payload }) => {
      state.client.data = state.client.data.map((client) => {
        if (client.id === payload.data.id) {
          return {
            ...client,
            ...payload.data,
          };
        } else return client;
      });
    },
    deleteClient: (state, action) => {},
  },

  extraReducers: {
    [getClients.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getClients.fulfilled]: (state, { payload }) => {
      state.client = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getClients.rejected]: (state, action) => {
      state.status = "failed";
      state.client = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [addClient.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addClient.fulfilled]: (state, { payload }) => {
      let newclient = { ...payload.data };
      state.client.data = [...state.client.data, newclient];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addClient.rejected]: (state, action) => {
      state.status = "failed";
      state.client = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchClient.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchClient.fulfilled]: (state, { payload }) => {
      console.log("PAYLOAD : ", payload);
      console.log("state : ", state.client);
      if (state.client) {
        state.client.data = state.client.data.map((client) => {
          if (client.id === payload.data.id) {
            //! TO CHANGE OR NEW:
            console.log("TO CHANGE :  TEST");
            return {
              ...client,
              ...payload.data,
            };
          } else return client;
        });
      } else {
        state.client = null;
      }

      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchClient.rejected]: (state, action) => {
      state.status = "failed";
      state.client = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { modifClient, deleteClient } = clientSlice.actions;

export default clientSlice;
