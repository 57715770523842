const IsCommercial = (auth) => {
  //check if rules Commercial ok :
  if (auth.auth.rules.includes(4002)) {
    return true;
  } else {
    return false;
  }
};

export default IsCommercial;
