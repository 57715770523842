import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const sendRdvRequest = createAsyncThunk(
  "Rdv/sendRdvRequest",
  async (data) => {
    return await Axios.put(
      process.env.REACT_APP_API_URL + "/send_rdv_request/add",
      data
    )
      .then(() =>
        toast.success(
          "Votre demande a été prise en compte. Spyde vous recontacte au plus vite !"
        )
      )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

const sendRdvSlice = createSlice({
  name: "Rdv",
  initialState: { rdv: null, status: null, loading: false, error: "" },

  reducers: {
    deleteRdv: (state, action) => {},
  },

  extraReducers: {
    [sendRdvRequest.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [sendRdvRequest.fulfilled]: (state, { payload }) => {
      state.rdv = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [sendRdvRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.rdv = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteRdv } = sendRdvSlice.actions;

export default sendRdvSlice;
