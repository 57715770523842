import { useState, useEffect, useRef } from "react";
import Axios from "../api/axios";
import Logo from "../components/elements/Logo";
import Svglogin from "../components/elements/Svglogin";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../services/useAuth";
import { useDispatch } from "react-redux";
import { setUser } from "../feature/user.slice";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../utils/regex";
import BtnOnOff from "../components/elements/BtnOnOff";

const Login = () => {
  const { setAuth, stayConnect, setStayConnect } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  //new :
  const userRef = useRef();

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const loadUserInfos = async () => {
    await Axios.get(process.env.REACT_APP_API_URL + "/me")
      .then((response) => {
        console.log(" LOAD USER INFOS");
      })
      .catch((err) => {
        console.log(" Error : LOAD USER INFOS ");
      });
  };
  //new: end

  const handleLogin = async (e) => {
    e.preventDefault();
    //! Modification after online :: 25/03 :
    //! add .trim()
    try {
      let dataConnexion = {
        email: email.trim(),
        password: password.trim(),
        stayConnect: stayConnect,
      };
      const response = await Axios.post(
        process.env.REACT_APP_API_URL + "/auth/log",
        dataConnexion
      );
      if (response) {
        const accessToken = response?.data?.responseToken.access_token;
        const rules = response?.data?.responseToken.rules;

        const refreshToken = response?.data?.refreshToken;
        localStorage.setItem("spy_tn", refreshToken);

        Axios.defaults.headers.common[
          "authorization"
        ] = `Bearer ${accessToken}`;

        loadUserInfos();

        setAuth({ email: email, accessToken: accessToken, rules: rules });
        dispatch(setUser(response?.data?.userData));
        setEmail("");
        setPassword("");

        if (rules.indexOf(1000) === 0) {
          navigate("../dashboard/home", { replace: true });
        } else if (rules.indexOf(2000) === 0) {
          navigate("../dashboard/spyde/home", { replace: true });
        } else {
          setAuth("");
          navigate("../seconnecter", { replace: true });
        }
      } else {
        setEmail("");
        setPassword("");
        toast.error(
          "Echec de la connexion, Merci de recommencer la tentative de connexion"
        );
      }
    } catch (err) {
      setUser("");
      setPassword("");
      let errMsg;

      if (!err?.response) {
      } else if (err.response.status === 409) {
        errMsg = err.response.data.message.split(" : ");
        toast.error(errMsg[1]);
      } else if (err.response.status === 400) {
        errMsg =
          "Pour vous connecter, saisissez votre identifiant et votre mot de passe";
        toast.error(errMsg);
      } else if (err.response.status === 404) {
        errMsg =
          "Aucune correspondance n'a été trouvée, vérifier votre identifiant et votre mot de passe";
        toast.error(errMsg);
      } else {
        errMsg =
          "Echec de la connexion, Merci de recommencer la tentative de connexion";
        toast.error(errMsg);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);
  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
  }, [password]);

  const toggleStayConnected = () => {
    setStayConnect((prev) => !prev);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("spy_sc", stayConnect);
  }, [stayConnect]);

  const goToRdvForm = () => {
    navigate("../", { replace: true });
  };

  return (
    <section className="login">
      <div className="login__containers">
        <div className="auth__form">
          <div className="auth__form__header">
            <Logo className="auth__form__logo stdLogo stdLogo--light" />
            <h1 className="auth__form__header--text">Authentifiez-vous</h1>
          </div>
          <div className="auth__form__main">
            <form onSubmit={handleLogin}>
              <label htmlFor="email">Votre identifiant :</label>

              <input
                type="text"
                id="email"
                ref={userRef}
                autocomplete="username"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              ></input>

              <label htmlFor="password">Votre mot de passe :</label>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                autocomplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              ></input>

              {validEmail && validPassword ? (
                <button type="submit" className="btnActif">
                  Se connecter
                </button>
              ) : (
                <button type="submit" disable="true" className="btnInactif">
                  Saisir les informations
                </button>
              )}

              <div className="stayConnected">
                <input
                  type="checkbox"
                  id="showpassword"
                  onChange={toggleShowPassword}
                  checked={showPassword}
                ></input>
                <label htmlFor="showpassword">Afficher le mot de passe</label>
              </div>
              <div className="stayConnected">
                <input
                  type="checkbox"
                  id="stayConnected"
                  onChange={toggleStayConnected}
                  checked={stayConnect}
                ></input>
                <label htmlFor="stayConnected">Restez connecté(e)</label>
              </div>
            </form>
          </div>
          <div className="auth__form__footer">
            <h2 className="auth__form__footer--text">Pas encore de compte ?</h2>
            <button type="submit" className="btnRdv" onClick={goToRdvForm}>
              Je prends rendez-vous
            </button>
          </div>
        </div>
        <div className="auth__picture">
          <h2 className="auth__picture__title">Bienvenue !</h2>
          <p className="auth__picture__text">
            Saisissez vos identifiants de connexion pour accéder à votre tableau
            de bord :
          </p>
          <Svglogin className="auth__picture__image" />
          <p className="auth__picture__explications">
            Cet espace est réservé à nos clients, si vous êtes intéressés par
            nos services, merci de prendre un rendez-vous
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;
