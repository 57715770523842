const ComboText = (props) => {
  return (
    <div
      className={"comboTtBtn comboTtBtn__text comboTtBtn--" + props.textColor}
    >
      <div>
        <h1 className="comboTtBtn__text--reduce font__bigTitle">
          {props.titleA}
        </h1>
        <h2 className="comboTtBtn__text--reduce font__bigTitle">
          {props.titleB}
          <br />
          {props.titleC}
        </h2>
      </div>
      <p className="font__simpleText">
        {props.paraA}
        <br />
        {props.paraB}
        <br />
        {props.paraC}
      </p>
    </div>
  );
};

export default ComboText;
