import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { AuthProvider } from "./services/contexts/AuthProvider";
import store from "./app/store";
import "./styles/index.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
//React.StrictMode retiré pour éviter le double appel au serveur
root.render(
  <>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <div id="app">
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </div>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </>
);
