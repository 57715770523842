import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
import Axios from "../../api/axios";

import { PASSWORD_REGEX } from "../../utils/regex";
import useAuth from "../../services/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearUser } from "../../feature/user.slice";
import CheckBox from "../elements/CheckBox";
import {
  infobulle_password,
  infobulle_confirmPassword,
  infobulle_newPassword,
} from "../../utils/infoBulleText";

const MyConnexion = () => {
  const { auth, setAuth, setStayConnect } = useAuth();

  const userData = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [isModify, setIsModify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validPassword, setValidPassword] = useState(false);
  const [validNewPassword, setValidNewPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const [countAuthFail, setCountAuthFail] = useState(0);

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
  }, [password]);
  useEffect(() => {
    setValidNewPassword(PASSWORD_REGEX.test(newPassword));
  }, [newPassword]);

  useEffect(() => {
    if (newPassword !== "" && confirmPassword === newPassword) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
  }, [confirmPassword, newPassword]);

  useEffect(() => {
    if (password === newPassword) {
      setValidConfirmPassword(false);
      setValidNewPassword(false);
    }
  }, [password, newPassword, confirmPassword]);

  const checkRules = () => {
    if (auth?.rules[0] === 1000) {
      return false;
    } else if (auth?.rules[0] === 2000) {
      return true;
    } else {
      return false;
    }
  };

  const handlePassword = async (e) => {
    e.preventDefault();

    try {
      const passwordData = {
        email: `${auth?.email}`,
        password: newPassword,
        isspyde: checkRules(),
        oldPassword: password,
      };

      let idToFind;
      if (checkRules()) {
        idToFind = userData.login_id;
      } else {
        idToFind = userData.contact.login_id;
      }

      await Axios.patch(
        process.env.REACT_APP_API_URL + "/login/modify/" + `${idToFind}`,
        passwordData
      ).then((res) => {
        setIsModify(false);
        toast.success("Mot de passe modifié avec succès");
      });
    } catch (err) {
      if (countAuthFail > 2) {
        setStayConnect(false);
        setAuth("");
        dispatch(clearUser());

        await Axios.post(process.env.REACT_APP_API_URL + "/logout");
      }
      if (err?.response) {
        if (err.response.status === 404) {
          const errMsg = err.response.data.message.split(" : ");
          setCountAuthFail(countAuthFail + 1);
          let tryResponse = 3 - countAuthFail;

          if (tryResponse === 0) {
            toast.error(
              errMsg[1] +
                ": Vous avez été déconnecté. Si vous ne vous souvenez plus de votre mot de passe, merci de contacter votre contact Spyde"
            );
          } else if (tryResponse === 1) {
            toast.error(errMsg[1] + " : Dernier essai avant redirection");
          } else if (tryResponse > 1) {
            toast.error(
              errMsg[1] +
                " : il vous reste " +
                tryResponse +
                " essais avant redirection"
            );
          }
        } else if (err.response.status === 400) {
          const errMsg =
            "Formulaire non conforme aux attentes : Vérifier vos mots de passe";
          toast.error(errMsg);
        }
      } else {
        toast.error("Erreur serveur");
      }
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleCancel = () => {
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setIsModify(false);
  };

  return !isModify ? (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Mon identifiant :</label>
            <p className="responseContainer__result">{auth?.email}</p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Mon mot de passe :</label>

            <p className="responseContainer__result">"********"</p>
          </div>
        </form>
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          <DashButton
            //type="submit"
            action="modify"
            text="Changer le mot de passe"
            onClick={() => setIsModify(true)}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Mon identifiant :</label>
            <p className="responseContainer__result">{auth?.email}</p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div className="info" data-tooltip={infobulle_password}>
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="password">Votre mot de passe actuel :</label>
            <input
              type={!showPassword ? "password" : "text"}
              id="password"
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              required
              value={password}
              placeholder="Saisir le mot de passe actuel ..."
            ></input>
            {validPassword && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(password.length < 1 && !validPassword) ||
            (password.length > 2 && validPassword) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div className="info" data-tooltip={infobulle_newPassword}>
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="newpassword">Votre nouveau mot de passe :</label>
            <input
              type={!showPassword ? "password" : "text"}
              id="newpassword"
              autoComplete="off"
              onChange={(e) => setNewPassword(e.target.value)}
              required
              value={newPassword}
              placeholder="Saisir un nouveau mot de passe ..."
            ></input>
            {validNewPassword && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(newPassword.length < 1 && !validNewPassword) ||
            (newPassword.length > 2 && validNewPassword) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div className="info" data-tooltip={infobulle_confirmPassword}>
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="newpasswordBis">Confirmer le mot de passe :</label>
            <input
              type={!showPassword ? "password" : "text"}
              id="newpasswordBis"
              autoComplete="off"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              value={confirmPassword}
              placeholder="Saisir un nouveau mot de passe ..."
            ></input>
            {validConfirmPassword && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(confirmPassword.length < 1 && !validConfirmPassword) ||
            (confirmPassword.length > 7 && validConfirmPassword) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
        </form>
      </div>

      <CheckBox
        order="001"
        text="Afficher les mots de passe "
        afterDisable={() => setShowPassword(false)}
        afterSelect={() => setShowPassword(true)}
      />

      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          {validPassword &&
          validNewPassword &&
          validConfirmPassword &&
          password &&
          newPassword &&
          confirmPassword ? (
            <DashButton
              //type="submit"
              action="action"
              text="Valider"
              onClick={(e) => handlePassword(e)}
            />
          ) : (
            <DashButton
              type="button"
              action="inactive"
              text="Saisir vos mots de passe"
            />
          )}
        </div>
        <div className="buttonContainer--small">
          <DashButton
            //type="submit"
            action="cancel"
            text="Annuler"
            onClick={() => handleCancel()}
          />
        </div>
      </div>
    </>
  );
};

export default MyConnexion;
