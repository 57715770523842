import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  addNewRack,
  deleteOneRack,
  getRacks,
  unpushInRack,
} from "../../../feature/rack.slice";
import DashButton from "../../elements/DashButton";
import ModalContent from "../ModalContent";
import ModalLayout from "../ModalLayout";
import DirectorAccess from "../../../services/contexts/DirectorAccess";
import useAuth from "../../../services/useAuth";
import CheckBox from "../../elements/CheckBox";
import { addNewRecycle, getRecycles } from "../../../feature/recycle.slice";

const ListRack = () => {
  const dispatch = useDispatch();
  const rackData = useSelector((state) => state.racks.racks);

  const [modal, setModal] = useState(false);
  const [modalRecycle, setModalRecycle] = useState(false);
  const [newRackNumber, setNewRackNumber] = useState("");
  const [dataRackAvailable, setDataRackAvailable] = useState(false);
  const [selectedRack, setSelectedRack] = useState(false);
  const [isDirecteur, setIsDirecteur] = useState(false);
  const [checkA, setCheckA] = useState(false);

  const auth = useAuth();

  const toggleModal = (e) => {
    modal && setNewRackNumber("");
    setModal(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  useEffect(() => {
    setIsDirecteur(DirectorAccess(auth));
  }, []);

  const toggleModalRecycle = (e) => {
    setCheckA(false);
    setModalRecycle(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  useEffect(() => {
    if (rackData?.data[0]) {
      setDataRackAvailable(true);
    } else {
      setDataRackAvailable(false);
    }
  }, [rackData]);

  const handleNewRack = async (e) => {
    e.preventDefault(e);

    try {
      let data = { rackNumber: newRackNumber, isAtSpyde: false };
      await dispatch(addNewRack(data));
      await setModal(false);
      await toast.success("Création du rack" + newRackNumber);
    } catch (err) {
      console.log("err handleNewRack");
    }
  };

  useEffect(() => {
    dispatch(getRacks());
  }, []);

  const handleDeleteRack = async (e, id, name) => {
    e.preventDefault();
    try {
      await dispatch(deleteOneRack(id));
      await setSelectedRack(false);
      await dispatch(getRacks());
      await toast.info("Delete Rack : " + name);
    } catch (err) {
      await toast.error(err);
    }
  };

  const generateRecycleData = async (e, data) => {
    e.preventDefault();
    try {
      // 1. recuperation des data dans racks :
      await dispatch(getRecycles());
      let dataForRecycle = {
        rackNumber: data.rackNumber,
        roadmap_id: data.roadmap_id,
        client_id: data.client_id,
        driver_id: data.spyder_id,
        dateOfCollecte: data.updatedAt,
        isFinish: false,
        billIsDone: false,
        isAtSpyde: data.isAtSpyde,
        comment_collecte: data.comment,
        typeOfCollecte: data.typeOfCollecte,
      };
      let dataForUnpush = {
        rackNumber: data.rackNumber,
        roadmap_id: null,
        client_id: null,
        spyder_id: null,
        wish_id: null,
        isAtSpyde: false,
        comment: null,
        typeOfCollecte: null,
      };

      let dataAndId = {
        data: dataForUnpush,
        id: data.id,
      };
      await dispatch(addNewRecycle(dataForRecycle));

      // vider le rack
      await dispatch(unpushInRack(dataAndId));

      //rechargement données? + fermeture popup + ? navigation
      await setModalRecycle(false);
      await setSelectedRack(false);
      await document.body.classList.remove("active-modal");

      toast.success("Bon de recyclage créé");
    } catch (err) {
      toast.error("err : GENERATE RECYCLE DATA");
    }
  };

  return (
    <>
      {!dataRackAvailable ? (
        <div className="racks">
          Données non disponibles ou aucun rack n'existe ...
        </div>
      ) : (
        <div className="racks">
          <div className="racks__grid">
            <div className="racks__grid--element">
              <h3>Racks vides :</h3>
              <div className="rackButtons">
                {rackData &&
                  rackData?.data
                    .filter(
                      (rack) => rack.client_id === null && !rack.isAtSpyde
                    )
                    .map((rack, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedRack.id === rack.id
                              ? "rackButtons__btn rackButtons__btn--selected"
                              : "rackButtons__btn rackButtons__btn--empty"
                          }
                          onClick={() => setSelectedRack(rack)}
                        >
                          <span>{rack.rackNumber}</span>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="racks__grid--element">
              <h3>Racks à venir :</h3>
              <div className="rackButtons">
                {rackData &&
                  rackData?.data
                    .filter(
                      (rack) => rack.client_id !== null && !rack.isAtSpyde
                    )
                    .map((rack, index) => {
                      return (
                        <p
                          className={
                            selectedRack.id === rack.id
                              ? "rackButtons__btn rackButtons__btn--selected"
                              : "rackButtons__btn rackButtons__btn--ontheroad"
                          }
                          key={index}
                          onClick={() => setSelectedRack(rack)}
                        >
                          {rack.rackNumber}
                        </p>
                      );
                    })}
              </div>
            </div>
            <div className="racks__grid--element">
              <h3>Racks à trier :</h3>
              <div className="rackButtons">
                {rackData &&
                  rackData?.data
                    .filter((rack) => rack.client_id !== null && rack.isAtSpyde)
                    .map((rack, index) => {
                      return (
                        <p
                          className={
                            selectedRack.id === rack.id
                              ? "rackButtons__btn rackButtons__btn--selected"
                              : "rackButtons__btn rackButtons__btn--recycle"
                          }
                          key={index}
                          onClick={() => setSelectedRack(rack)}
                        >
                          {rack.rackNumber}
                        </p>
                      );
                    })}
              </div>
            </div>
          </div>
          <div>
            <h3>Rack sélectionné :</h3>
            {selectedRack && (
              <>
                {!selectedRack.client_id && (
                  <div className="centerLign selectedRack">
                    <p className="selectedRack roadMapFont--titleValue roadMapFont--tittleValue--dyn">
                      {selectedRack.rackNumber + " - Rack vide"}
                    </p>
                  </div>
                )}
                {selectedRack.client_id && !selectedRack.isAtSpyde && (
                  <div className="centerLign selectedRack">
                    <p className="selectedRack roadMapFont--titleValue roadMapFont--tittleValue--dyn">
                      {selectedRack.rackNumber +
                        " - Rack chargé, sur la route du retour "}
                      <span className="selectedRack__info">
                        - Modification impossible
                      </span>
                    </p>
                  </div>
                )}
                {selectedRack.client_id && selectedRack.isAtSpyde && (
                  <div className="centerLign selectedRack">
                    <p className="selectedRack roadMapFont--titleValue roadMapFont--tittleValue--dyn mgbot--1">
                      {selectedRack.rackNumber + " - Rack à trier "}
                      <span className="selectedRack__info">
                        - Cliquez sur "Faire le tri" pour saisir les valeurs
                      </span>
                    </p>
                  </div>
                )}
                <div className="centerLign">
                  <div className="buttonsTable__buttonDim">
                    {selectedRack.client_id && selectedRack.isAtSpyde ? (
                      <>
                        <DashButton
                          action="action"
                          text="Vider ce rack et générer un bon de recyclage"
                          onClick={() => setModalRecycle(true)}
                        />
                        <ModalLayout
                          toggle={toggleModalRecycle}
                          modal={modalRecycle}
                          label="Création du bon de recyclage"
                          colorClass="blue"
                        >
                          <ModalContent
                            text={
                              "Vous êtes sur le point de créer un nouveau bon de recyclage à partir du rack : /g/ " +
                              selectedRack.rackNumber +
                              "/g/ " +
                              ":::Cela implique que : :::___1. Les clients seront prévenus du leur recyclage en cours :::___2. Ce rack sera vidé et à nouveau disponible pour remplissage"
                            }
                            moreText="Souhaitez-vous générer ce bon de recyclage ?"
                          >
                            <div className="dashform__form__block dashform__form__block--padding ">
                              <CheckBox
                                order="023"
                                text="Je confirme avoir pris connaissance des implications de la création d'un bon de recyclage"
                                afterDisable={() => setCheckA(false)}
                                afterSelect={() => setCheckA(true)}
                              />
                            </div>

                            <div className="buttonsContainer">
                              <div className="buttonContainer--small">
                                {checkA ? (
                                  <DashButton
                                    //disabled={disabled}
                                    action="action"
                                    text="Générer le bon de recyclage"
                                    onClick={(e) =>
                                      generateRecycleData(e, selectedRack)
                                    }
                                  />
                                ) : (
                                  <DashButton
                                    disabled={true}
                                    action="inactive"
                                    text="Veuillez confirmer"
                                  />
                                )}
                              </div>
                              <div className="buttonContainer--small">
                                <DashButton
                                  //disabled={disabled}
                                  action="cancel"
                                  text="Annuler"
                                  onClick={(e) => toggleModalRecycle(e)}
                                />
                              </div>
                            </div>
                          </ModalContent>
                        </ModalLayout>
                      </>
                    ) : (
                      <DashButton
                        //disabled={false}
                        //iconA="remove"
                        action="inactive"
                        text="Sélectionner un rack à trier"
                        //onClick={() => setModalRecycle(true)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {isDirecteur && (
        <>
          <div className="buttonsTable buttonContainer__direction flewWrap">
            <div className="buttonsTable__buttonDim">
              <DashButton
                //disabled={false}
                iconA="add"
                action="search"
                text="Ajouter un rack"
                onClick={() => setModal(true)}
              />

              <ModalLayout
                toggle={toggleModal}
                modal={modal}
                label="Création d'un nouveau rack"
                colorClass="yellow"
              >
                <ModalContent
                  text="Vous êtes sur le point d'ajouter un nouveau rack à ceux déjà existants. :::/g/Attention :/g/ La création d'un rack implique d'avoir un élément physique associé. "
                  moreText="Choisir un nom pour le futur rack : "
                >
                  <div className="dashform__form pad--1">
                    <input
                      id="rackNumber"
                      className="font__simpleText"
                      placeholder="Exemple : 001, 002, R1, etc ..."
                      onChange={(e) => setNewRackNumber(e.target.value)}
                    ></input>
                    <label htmlFor="rackNumber" className="pad--1"></label>
                  </div>

                  <div className="buttonsContainer">
                    <div className="buttonContainer--small">
                      {newRackNumber ? (
                        <DashButton
                          //disabled={disabled}
                          action="action"
                          text="Créer un rack"
                          onClick={(e) => handleNewRack(e)}
                        />
                      ) : (
                        <DashButton
                          disabled={true}
                          action="inactive"
                          text="Saisir un nom"
                        />
                      )}
                    </div>
                    <div className="buttonContainer--small">
                      <DashButton
                        //disabled={disabled}
                        action="cancel"
                        text="Annuler"
                        onClick={(e) => toggleModal(e)}
                      />
                    </div>
                  </div>
                </ModalContent>
              </ModalLayout>
            </div>
            {selectedRack && !selectedRack.client_id && isDirecteur ? (
              <div className="buttonsTable__buttonDim">
                <DashButton
                  //disabled={false}
                  iconA="remove"
                  action="delete"
                  text="Supprimer ce rack"
                  onClick={(e) =>
                    handleDeleteRack(
                      e,
                      selectedRack.id,
                      selectedRack.rackNumber
                    )
                  }
                />
              </div>
            ) : (
              <div className="buttonsTable__buttonDim">
                <DashButton
                  action="inactive"
                  text="Sélectionner un rack vide"
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default ListRack;
