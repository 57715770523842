import React, { useState, useRef, useEffect } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Logo from "../elements/Logo";

const MapShowAddress = (props) => {
  const [center, setCenter] = useState({
    latitude: 44.831388,
    longitude: -0.573456,
  });

  useEffect(() => {
    if (props.clientLat && props.clientLong) {
      setCenter({ latitude: props.clientLat, longitude: props.clientLong });
      setViewport({ latitude: props.clientLat, longitude: props.clientLong });
    }
  }, [center]);

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: center.latitude,
    longitude: center.longitude,
    zoom: 8,
  });
  const mapRef = useRef();

  const tokenMapbox = process.env.REACT_APP_TOKEN_MAPBOX;

  const onMove = (e) => {
    setViewport(e.target);
  };

  return (
    <>
      <ReactMapGL
        {...viewport}
        onMove={(e) => onMove(e)}
        mapStyle={
          "mapbox://styles/spyde33/cl5ovelxe006l15pc8jrjju4g?access_token=" +
          tokenMapbox
        }
        mapboxAccessToken={tokenMapbox}
        ref={mapRef}
      >
        {props.clientLat && props.clientLong ? (
          <Marker latitude={props.clientLat} longitude={props.clientLong}>
            <div>
              <Logo className="stdLogo stdLogo--map" />
            </div>
          </Marker>
        ) : (
          ""
        )}
      </ReactMapGL>
    </>
  );
};

export default MapShowAddress;
