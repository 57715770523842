import { useState, useEffect } from "react";

const CheckBox = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  const actionSelect = () => {
    setIsSelected(true);
    props.afterSelect();
  };

  const actionDisable = () => {
    setIsSelected(false);
    props.afterDisable();
  };

  useEffect(() => {
    props.default && setIsSelected(props.default);
  }, []);

  return (
    <div className="checkBox">
      {isSelected && (
        <>
          <input
            type="checkbox"
            id={props.order}
            className="checkBox_btn btnOnOff btnOnOff--selected"
            onClick={() => actionDisable()}
          ></input>
          <label
            htmlFor={props.order}
            className="checkBox__label checkBox__label--red"
          >
            {props.text}
          </label>
        </>
      )}
      {!isSelected && (
        <>
          <input
            type="checkbox"
            id={props.order}
            className="checkBox_btn btnOnOff"
            onClick={() => actionSelect()}
          ></input>
          <label htmlFor={props.order} className="checkBox__label">
            {props.text}
          </label>
        </>
      )}
    </div>
  );
};

export default CheckBox;
