import { useEffect, useState } from "react";
import WidgetContainer from "../../components/widgets/WidgetContainer";
import ListeWidgetRoadMap from "../../components/widgets/ListeWidgetRoadMap";
import "mapbox-gl/dist/mapbox-gl.css";
import ListRack from "../../components/layouts/administrator/ListRack";
//! vérifier si useEffect utile ici (2502__A)
//import { useDispatch } from "react-redux";
//import { getRoadMaps } from "../../feature/roadmap.slice";
//import { getWishes } from "../../feature/wish.slice";
import { ListeRecycles } from "../../components/widgets/ListeRecycles";
import { ListeRecyclesFinish } from "../../components/widgets/ListeRecyclesFinish";
import useAuth from "../../services/useAuth";
import TrieurAccess from "../../services/contexts/TrieurAccess";
import CollecteAccess from "../../services/contexts/CollecteAccess";

const SpydeLogistic = () => {
  //! vérifier si useEffect utile ici (2502__A)
  //const dispatch = useDispatch();

  const [isTrieur, setIsTrieur] = useState(false);
  const [isCollecteur, setIsCollecteur] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setIsTrieur(TrieurAccess(auth));
  }, []);
  useEffect(() => {
    setIsCollecteur(CollecteAccess(auth));
  }, []);

  //! vérifier si useEffect utile ici (2502__A)
  // useEffect(() => {
  //   dispatch(getRoadMaps());
  //   dispatch(getWishes());
  // }, []);

  return (
    <div className="configGrid">
      <section className="widget">
        {isCollecteur && (
          <ListeWidgetRoadMap
            order="1"
            containerName="Feuilles de routes"
            tableName="Liste des feuilles de routes"
            //data={arrayData}
          />
        )}
        {(isCollecteur || isTrieur) && (
          <WidgetContainer
            //key="1"
            icon="grid-outline"
            title="Gestion des racks"
            order={2}
            //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
          >
            <ListRack />
          </WidgetContainer>
        )}

        {isTrieur && (
          <WidgetContainer
            //key="1"
            icon="leaf-outline"
            title="Gestion des recyclages"
            order={3}
            //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
          >
            <ListeRecycles />
            <ListeRecyclesFinish />
          </WidgetContainer>
        )}
      </section>
    </div>
  );
};

export default SpydeLogistic;
