import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";
import { toast } from "react-toastify";

export const getWishes = createAsyncThunk("Wishes/getWishes", async () => {
  return await Axios.get(process.env.REACT_APP_API_URL + "/wish").then(
    (res) => res.data
  );
});

export const addNewWish = createAsyncThunk(
  "Wishes/addNewWish",
  async (data) => {
    return await Axios.put(process.env.REACT_APP_API_URL + "/wish/add", data)
      .then((res) => res.data)
      .then((res) => toast.success("Demande ajoutée"))
      .catch((err) => toast.error("Erreur lors de l'ajout"));
  }
);

export const getClientWish = createAsyncThunk(
  "Wishes/getClientWish",
  async (id) => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/wish/client/" + id
    ).then((res) => res.data);
  }
);

export const selectWish = createAsyncThunk(
  "Wishes/selectWish",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/wish/select/" + dataAndId.id,
      dataAndId.data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.error.message));
  }
);

export const unselectWish = createAsyncThunk(
  "Wishes/unselectWish",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/wish/unselect/" + dataAndId.id,
      dataAndId.data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.error.message));
  }
);

export const trashOneWish = createAsyncThunk(
  "Wishes/trashOneWish",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/wish/trash/" + id
    )
      .then((res) => res && { data: id })
      .catch((err) => console.log("err.response.data.error.message"));
  }
);

export const trashExpressWish = createAsyncThunk(
  "Wishes/trashExpressWish",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/wish/trash/express/" + id
    )
      .then((res) => res && { data: id })
      .catch((err) => console.log("err.response.data.error.message"));
  }
);

const wishSlice = createSlice({
  name: "Wishes",
  initialState: { wishes: null, status: null, loading: false, error: "" },

  reducers: {
    deleteWish: (state, action) => {},
  },

  extraReducers: {
    [getWishes.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getWishes.fulfilled]: (state, { payload }) => {
      state.wishes = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getWishes.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getClientWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getClientWish.fulfilled]: (state, { payload }) => {
      state.wishes = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getClientWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewWish.fulfilled]: (state, { payload }) => {
      let newWish = { ...payload.data };
      state.wishes.data = [...state.wishes.data, newWish];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [trashOneWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [trashOneWish.fulfilled]: (state, { payload }) => {
      state.wishes.data = state.wishes.data.filter(
        (wish) => wish.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [trashOneWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [trashExpressWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [trashExpressWish.fulfilled]: (state, { payload }) => {
      state.wishes.data = state.wishes.data.filter(
        (wish) => wish.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [trashExpressWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [selectWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [selectWish.fulfilled]: (state, { payload }) => {
      state.wishes.data = state.wishes.data.map((wish) => {
        if (wish.id === payload.data.id) {
          return {
            ...wish,
            ...payload.data,
          };
        } else return wish;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [selectWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [unselectWish.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [unselectWish.fulfilled]: (state, { payload }) => {
      state.wishes.data = state.wishes.data.map((wish) => {
        if (wish.id === payload.data.id) {
          return {
            ...wish,
            ...payload.data,
          };
        } else return wish;
      });

      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [unselectWish.rejected]: (state, action) => {
      state.status = "failed";
      state.wishes = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteWish } = wishSlice.actions;

export default wishSlice;
