import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendRdvRequest } from "../../feature/sendRdv.slice";
import {
  EMAIL_REGEX,
  ZIPCODE_REGEX,
  MESSAGE_REGEX,
  WEIGHT_REGEX,
  TEL_REGEX,
  NAME_RDV_REGEX,
} from "../../utils/regex";

const SectRdv = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const [validCompanySize, setValidCompanySize] = useState(false);
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [validZipCode, setValidZipCode] = useState(false);

  //? validation and error message :
  //*name
  const handleName = (e) => {
    setName(e.target.value);
    setValidName(NAME_RDV_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (validName) {
      setErrorMessage("");
    } else if (!validName) {
      if (name.length === 0) {
        setErrorMessage("");
      } else if (name.length <= 3 || name.length > 50) {
        setErrorMessage("Doit contenir entre 3 et 50 caractères");
      } else {
        setErrorMessage(
          "Certains caractères ne sont pas autorisés, merci de modifier votre nom"
        );
      }
    }
  }, [name]);

  //*message
  const handleMessage = (e) => {
    setMessage(e.target.value);
    setValidMessage(MESSAGE_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (validMessage) {
      setErrorMessage("");
    } else if (!validMessage) {
      if (message.length === 0) {
        setErrorMessage("");
      } else if (message.length <= 9 || message.length > 500) {
        setErrorMessage("Doit contenir entre 10 et 500 caractères");
      } else {
        setErrorMessage(
          "Certains caractères ne sont pas autorisés, merci de reformuler votre demande"
        );
      }
    }
  }, [message]);

  //* zipcode
  const handleZipCode = (e) => {
    setZipCode(e.target.value);
    setValidZipCode(ZIPCODE_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (
      validZipCode &&
      zipCode.length == 5 &&
      zipCode.slice(0, 1)[0] == 3 &&
      zipCode.slice(1, 2)[0] == 3
    ) {
      setErrorMessage("");
    } else if (validZipCode) {
      setErrorMessage(
        "Désolé, nous ne couvrons pas votre commune. Votre entreprise doit être en Gironde pour nous envoyer une demande via le site"
      );
    } else if (!validZipCode) {
      if (zipCode.length === 0) {
        setErrorMessage("");
      } else if (zipCode.length != 5) {
        setErrorMessage("Doit être un code postal valide");
      } else {
        setErrorMessage(
          "Certains caractères ne sont pas autorisés, merci de reformuler votre demande"
        );
      }
    }
  }, [zipCode]);

  //* email
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setValidEmail(EMAIL_REGEX.test(e.target.value));
  };
  useEffect(() => {
    if (validEmail) {
      setErrorMessage("");
    } else if (!validEmail) {
      if (email.length === 0) {
        setErrorMessage("");
      } else {
        setErrorMessage("Doit être une adresse mail valide");
      }
    }
  }, [email]);

  //*companyName
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
    setValidCompanyName(NAME_RDV_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (validCompanyName) {
      setErrorMessage("");
    } else if (!validCompanyName) {
      if (companyName.length === 0) {
        setErrorMessage("");
      } else if (companyName.length <= 3 || companyName.length > 50) {
        setErrorMessage("Doit contenir entre 3 et 50 caractères");
      } else {
        setErrorMessage(
          "Certains caractères ne sont pas autorisés, merci de modifier le nom de l'entreprise"
        );
      }
    }
  }, [companyName]);

  //* companySize

  const handleCompanySize = (e) => {
    setCompanySize(e.target.value);
    setValidCompanySize(WEIGHT_REGEX.test(e.target.value));
  };
  useEffect(() => {
    if (companySize == 0) {
      setErrorMessage("Minimum 1 personne");
    } else if (validCompanySize && companySize != 0) {
      setErrorMessage("");
    } else if (!validCompanySize) {
      if (companySize.length === 0 && companySize != 0) {
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Nécessite uniquement un nombre estimatif, pas de lettres ou autres caractères."
        );
      }
    }
  }, [companySize]);

  //* Phone
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setValidPhone(TEL_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (validPhone) {
      setErrorMessage("");
    } else if (!validPhone) {
      if (phone.length === 0) {
        setErrorMessage("");
      } else if (phone.length === 10) {
        setErrorMessage(
          "Certains caractères ne sont pas autorisés, merci de nous communiquer un numero de téléphone français valide."
        );
      } else {
        setErrorMessage(
          "Doit être un numero de téléphone français valide. Merci d'inscrire 10 chiffres uniquement."
        );
      }
    }
  }, [phone]);

  //? SEND REQUEST TO SERVER :
  const cancelForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCompanyName("");
    setCompanySize("");
    setZipCode("");

    setValidName(false);
    setValidEmail(false);
    setValidPhone(false);
    setValidMessage(false);
    setValidCompanySize(false);
    setValidCompanyName(false);
    setValidZipCode(false);
  };

  const sendRequest = async (e) => {
    e.preventDefault();
    try {
      let data = {
        name,
        email,
        phone,
        companyName,
        companySize,
        zipCode,
        message,
      };
      await dispatch(sendRdvRequest(data));
      await cancelForm();
    } catch (err) {
      console.log("err sendRequest");
    }
  };

  return (
    <section className="sectRdv" id="sectRdv">
      <div className="sectRdv__container">
        <div className="sectRdv__container__rightPart">
          <h2 className="font__blockPoint">Demande de rendez-vous</h2>
          <br />
          <p className="font__simpleText">
            Merci de remplir tous les champs ci-dessous avant de valider votre
            demande.
          </p>
          <form className="rdvForm">
            <div className="rdvForm__double">
              <div className="rdvForm__double--part">
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Votre nom et prénom"
                  onFocus={() => setErrorMessage("")}
                />
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Votre e-mail"
                  onFocus={() => setErrorMessage("")}
                />
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="text"
                  placeholder="Votre numéro de téléphone"
                  onFocus={() => setErrorMessage("")}
                />
              </div>
              <div className="rdvForm__double--part">
                <input
                  value={companyName}
                  onChange={handleCompanyName}
                  type="text"
                  placeholder="Votre entreprise"
                  onFocus={() => setErrorMessage("")}
                />
                <input
                  value={zipCode}
                  onChange={handleZipCode}
                  type="text"
                  placeholder="Son code postal"
                  onFocus={() => setErrorMessage("")}
                />
                <input
                  value={companySize}
                  onChange={handleCompanySize}
                  type="text"
                  placeholder="Nbre d'employés"
                  onFocus={() => setErrorMessage("")}
                />
              </div>
            </div>
            <textarea
              value={message}
              onChange={handleMessage}
              type="text"
              placeholder="Décrivez succinctement votre projet ..."
              onFocus={() => setErrorMessage("")}
            />
            {errorMessage && <p>{errorMessage}</p>}
            {name &&
            validName &&
            message &&
            validMessage &&
            zipCode &&
            validZipCode &&
            email &&
            validEmail &&
            companyName &&
            validCompanyName &&
            companySize &&
            validCompanySize &&
            phone &&
            validPhone ? (
              <button
                className="btn btn__callToAction"
                onClick={(e) => sendRequest(e)}
              >
                Envoyer ma demande
              </button>
            ) : (
              <button
                disabled
                className="btn btn__beforeAction"
                //onClick={(e) => sendRequest(e)}
              >
                Compléter le formulaire
              </button>
            )}
          </form>
        </div>
        <div className="sectRdv__container__leftPart">
          <h3 className="font__blockPoint">
            Un projet ? Un besoin ? <br />
            Nous venons vous voir pour en parler
          </h3>
          <br />
          <p className="font__simpleText">
            Décrivez-nous succinctement votre projet et donnez vos coordonnées,
            nous vous contacterons afin d'en discuter, et de convenir ensemble
            d'un rendez-vous.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectRdv;
