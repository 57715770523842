//rfce :

const NotFound = ()=> {
    return (
        <div>
            <h1>NotFound</h1>
            <p>Erreur 404</p> 
        </div>
    );
};

export default NotFound;