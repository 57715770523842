import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
import useInputHook from "../../services/useInputHook";
import MapShowAddress from "../widgets/MapShowAddress";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../api/axios";
import { toast } from "react-toastify";
import { selectLoading } from "../../feature/loading.slice";
import { toggleLoading } from "../../feature/loading.slice";
import LoaderM from "../../components/blocks/LoaderM";
//import LoaderS from "../../components/blocks/LoaderS.js";
import { CONTACT_NAME_REGEX, SITE_REGEX, TEL_REGEX } from "../../utils/regex";
import {
  setSpydeAccount,
  addSpydeAccount,
} from "../../feature/spyde_account.slice";
import useAuth from "../../services/useAuth";
import { useNavigate } from "react-router";

const CreateSpydeAccount = ({ setDataSpydeAccount }) => {
  const addressRecup = useInputHook("");

  const [respFirstname, setRespFirstname] = useState("");
  const [respLastname, setRespLastname] = useState("");
  const [respPhone, setRespPhone] = useState("");
  const [agency, setAgency] = useState("");
  const [address, setAddress] = useState("");
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLong, setSelectedLong] = useState("");

  const [validAgency, setValidAgency] = useState(false);
  const [validFirstname, setValidFirstname] = useState(false);
  const [validLastname, setValidLastname] = useState(false);
  const [validPhone, setValidPhone] = useState(false);

  const [IsSelected, setIsSelected] = useState(false);
  const [newData, setNewData] = useState(null);

  const dispatch = useDispatch();
  // const spydeAccountData = useSelector(
  //   (state) => state.spydeAccount.spydeAccount
  // );

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setValidAgency(SITE_REGEX.test(agency));
  }, [agency]);

  useEffect(() => {
    setValidFirstname(CONTACT_NAME_REGEX.test(respFirstname));
  }, [respFirstname]);

  useEffect(() => {
    setValidLastname(CONTACT_NAME_REGEX.test(respLastname));
  }, [respLastname]);

  useEffect(() => {
    setValidPhone(TEL_REGEX.test(respPhone));
  }, [respPhone]);

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "/spyde_accounts")
      .then((res) => {
        dispatch(setSpydeAccount(res.data));
      })
      .catch((err) => {
        setAuth("");
        navigate("/", {
          replace: true,
        });
      });
  }, []);

  const initialiseUseState = () => {
    setRespFirstname("");
    setRespLastname("");
    setRespPhone("");
    setAgency("");
    setAddress("");
    setSelectedLat("");
    setSelectedLong("");
    setValidAgency(false);
    setValidFirstname(false);
    setValidLastname(false);
    setValidPhone(false);
    setIsSelected(false);
  };

  const saveNewAgency = async (e) => {
    e.preventDefault();

    const data = {
      agency,
      respFirstname,
      respLastname,
      respPhone,
      address,
      lat: selectedLat,
      long: selectedLong,
    };

    dispatch(toggleLoading());

    await Axios.put(process.env.REACT_APP_API_URL + "/spyde_accounts/add", data)
      .then((res) => {
        dispatch(addSpydeAccount(res.data.data));
        setDataSpydeAccount(res.data.data);
        setNewData(res.data.data);
        setIsSelected(true);
        dispatch(toggleLoading());
      })
      .catch((err) => {
        dispatch(toggleLoading());
        initialiseUseState();
        if (err?.response) {
          const errMsg = err.response.data.message.split(" : ");
          toast.error(errMsg[1]);
        } else {
          toast.error("Erreur serveur");
        }
      });
  };

  const isLoading = useSelector(selectLoading);

  return isLoading && !IsSelected ? (
    <div className="loaderContainer loaderContainer--h100">
      <LoaderM color="red" text="Création de l'agence Spyde ..." lg="2500" />
    </div>
  ) : (
    <>
      {IsSelected ? (
        <div className="responseContainer">
          <h3 className="responseContainer__success">
            Nouvelle agence Spyde crée et sélectionnée !
          </h3>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Nom de l'agence :</p>
            <p className="responseContainer__result">{newData.agency}</p>
          </div>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Prénom du responsable :</p>
            <p className="responseContainer__result">{newData.respFirstname}</p>
          </div>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Nom du responsable :</p>
            <p className="responseContainer__result">{newData.respLastname}</p>
          </div>

          <div className="responseContainer__block">
            <p className="responseContainer__title">Adresse de l'agence :</p>
            <p className="responseContainer__result">{newData.address}</p>
          </div>
        </div>
      ) : (
        <>
          <h3 className="dashform__title dashform__title--spyde">
            Création d'une nouvelle agence Spyde
          </h3>

          <div className="formContainer">
            <div className="dashfrom">
              <form onSubmit={saveNewAgency} className="dashform__form">
                <div className="dashform__form__block dashform__form__block--padding">
                  <div
                    className="info"
                    data-tooltip="3 caractères minimum, est autorisé : lettres, tirets et underscore, max.50 caractères"
                  >
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>

                  <label htmlFor="agency">Nom de l'agence :</label>
                  <input
                    type="text"
                    id="agency"
                    autoComplete="off"
                    onChange={(e) => setAgency(e.target.value)}
                    required
                    placeholder="Saisir le nom de l'agence ..."
                  ></input>

                  {agency.length > 0 && validAgency && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}
                  {(agency.length < 1 && !validAgency) ||
                  (agency.length > 3 && validAgency) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>
                <div className="dashform__form__block dashform__form__block--padding">
                  <div
                    className="info"
                    data-tooltip="3 caractères minimum, est autorisé : lettres, tirets et underscore, max.50 caractères"
                  >
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>
                  <label htmlFor="lastname">Prénom du responsable :</label>
                  <input
                    id="lastname"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => setRespLastname(e.target.value)}
                    required
                    placeholder="Saisir le prénom du responsable ..."
                  ></input>
                  {respLastname.length > 0 && validLastname && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}

                  {(respLastname.length < 1 && !validLastname) ||
                  (respLastname.length > 2 && validLastname) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>
                <div className="dashform__form__block dashform__form__block--padding">
                  <div
                    className="info"
                    data-tooltip="3 caractères minimum, est autorisé : lettres, tirets et underscore, max.50 caractères"
                  >
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>
                  <label htmlFor="firstname">Nom du responsable :</label>
                  <input
                    id="firstname"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => setRespFirstname(e.target.value)}
                    required
                    placeholder="Saisir le nom du responsable ..."
                  ></input>
                  {respFirstname.length > 0 && validFirstname && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}

                  {(respFirstname.length < 1 && !validFirstname) ||
                  (respFirstname.length > 2 && validFirstname) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>

                <div className="dashform__form__block dashform__form__block--padding">
                  <div
                    className="info"
                    data-tooltip="Doit être un numéro français valide : 10 chiffres uniquement"
                  >
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>
                  <label htmlFor="tel">Téléphone :</label>
                  <input
                    id="tel"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => setRespPhone(e.target.value)}
                    required
                    placeholder="Saisir le numéro de téléphone ..."
                  ></input>
                  {respPhone.length > 0 && validPhone && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}

                  {(respPhone.length < 1 && !validPhone) ||
                  (respPhone.length > 2 && validPhone) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>

                <div className="dashform__form__block dashform__form__block--padding dashform__form__block--address ">
                  <label htmlFor="address">Adresse de l'agence :</label>

                  <input
                    id="address"
                    className="full"
                    type="text"
                    autoComplete="off"
                    required
                    placeholder="Saisir l'adresse du point de collecte"
                    {...addressRecup}
                    onClick={() => {
                      setAddress("");
                      setSelectedLat("");
                      setSelectedLong("");
                    }}
                  />
                  <p className="font__italic">
                    Merci de sélectionner l'adresse parmi celles qui vous seront
                    proposées
                  </p>
                  {addressRecup.suggestions?.length > 0 && (
                    <div className="resultsAddress">
                      {addressRecup.suggestions.map((suggestion, index) => {
                        return (
                          <p
                            key={index}
                            className="resultsAddress__result"
                            onClick={() => {
                              addressRecup.setValue(suggestion.place_name);
                              setAddress(suggestion.place_name);
                              addressRecup.setLong(suggestion.center[0]);
                              setSelectedLong(suggestion.center[0]);
                              addressRecup.setLat(suggestion.center[1]);
                              setSelectedLat(suggestion.center[1]);
                              addressRecup.setSuggestions([]);
                            }}
                          >
                            {suggestion.place_name}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="dashform__form--btn">
                  {agency.length > 3 &&
                  address &&
                  respFirstname &&
                  respLastname &&
                  respPhone &&
                  selectedLat &&
                  selectedLong &&
                  validFirstname &&
                  validLastname &&
                  validPhone &&
                  validAgency ? (
                    <DashButton
                      type="submit"
                      action="search"
                      text="Créer l'agence Spyde"
                      //onClick = ""
                    />
                  ) : (
                    <DashButton
                      type="button"
                      action="inactive"
                      text="Compléter le formulaire"
                      //onClick={""}
                    />
                  )}
                </div>
              </form>
            </div>

            <div className="mapContainer mapContainer--small">
              <MapShowAddress
                clientLat={selectedLat}
                clientLong={selectedLong}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateSpydeAccount;
