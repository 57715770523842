import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { getClients } from "../../feature/client.slice";
import { getSpyders } from "../../feature/spyder.slice";
import { getClients } from "../../feature/client.slice";
import LoaderM from "./LoaderM";
import InputForm from "../elements/InputForm";
import DashButton from "../elements/DashButton";
import TitleBlockForm from "./TitleBlockForm";
import { getAccessRules } from "../../feature/access_rules.slice";
import { toast } from "react-toastify";
import { patchClient } from "../../feature/client.slice";

const FormTransfertClient = ({ dataToShow }) => {
  const dispatch = useDispatch();
  const spyderData = useSelector((state) => state.spyder.spyder);
  const accessData = useSelector((state) => state.accessRules.access);
  const spyderLoading = useSelector((state) => state.spyder.loading);

  const [searchSpyder, setSearchSpyder] = useState("");
  const [selectedSpyder, setSelectedSpyder] = useState("");

  //getdata
  useEffect(() => {
    dispatch(getSpyders());
    dispatch(getAccessRules());
  }, [dispatch]);

  const changeSpyder = (e) => {
    setSearchSpyder(e.target.value);
  };

  let commercialSpyder = [];

  const changeCommercial = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("clientName", dataToShow.clientName);
    formData.append("agency", dataToShow.agency);
    formData.append("codeEts", dataToShow.codeEts);
    formData.append("address", dataToShow.address);
    formData.append("lat", dataToShow.lat);
    formData.append("long", dataToShow.long);

    formData.append("spyder_login_id", `${selectedSpyder.id}`);
    try {
      let dataAndId = { data: formData, id: dataToShow.id };
      await dispatch(patchClient(dataAndId));
      await dispatch(getClients());
      await toast.success(
        "Le client " +
          dataToShow.clientName +
          " vient d'être transféré à " +
          selectedSpyder.firstname +
          " " +
          selectedSpyder.lastname
      );
    } catch (err) {
      toast.error("Erreur lors de la mise à jour");
    }
  };

  return spyderLoading ? (
    <div className="loaderContainer loaderContainer--h100">
      <LoaderM
        color="red"
        text="Recherche des commerciaux Spyde ..."
        lg="2500"
      />
    </div>
  ) : (
    <div className="buttonContainer__direction">
      <form className="dashform__form">
        <TitleBlockForm
          isModify={false}
          isAuthorized={true}
          title="Transfert du client vers"
          //changeTitle="Transfert de compte Clients"
        />
        {!selectedSpyder && (
          <InputForm
            setData={changeSpyder}
            info="Rechercher un commercial Spyde en utilisant son nom et/ou son prénom"
            placeholder="Saisir son prénom ou son nom ..."
            //validation={validFirstname}
            //newValue={firstname}
            label="Rechercher un commercial :"
            htmlfor="commercial"
            defaultValue=""
            isModify={true}
            isAuthorized={true}
            dataToShow=""
            required={true}
          />
        )}
        {searchSpyder && !selectedSpyder ? (
          <div className="resultsAddress">
            {spyderData?.data

              .filter((spyder) =>
                (spyder.firstname + " " + spyder.lastname)
                  .toLowerCase()
                  .includes(searchSpyder.toLowerCase())
              )
              .slice(0, 5)
              .map((spyder, index) => {
                //verifiy if it's commercial or not :
                accessData?.data.map((access) => {
                  if (access.isContract && access.isClient) {
                    commercialSpyder.push(access.spyder_id);
                  }
                });
                // if commercial : show result
                if (commercialSpyder.includes(spyder.id)) {
                  return (
                    <p
                      key={index}
                      className="resultsAddress__result"
                      onClick={() => setSelectedSpyder(spyder)}
                    >
                      {spyder.firstname + " " + spyder.lastname}
                    </p>
                  );
                } else return null;
              })}
          </div>
        ) : (
          ""
        )}
        {selectedSpyder && (
          <div className="dashTwoParts">
            <div className="dashTwoParts__partTwo">
              <DashButton
                //type="submit"
                action="action"
                text="Valider le transfert"
                onClick={(e) => changeCommercial(e)}
              />
            </div>

            <div className="dashTwoParts__partOne">
              <p className="font__question">
                {selectedSpyder.firstname + "  " + selectedSpyder.lastname}
              </p>
            </div>

            <div className="dashTwoParts__partOne">
              <div className="imageBlock">
                <img
                  src={selectedSpyder.addressURL || "/img/spyde_avatar.png"}
                  className="imageBlock__img"
                  alt={selectedSpyder.firstname}
                ></img>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default FormTransfertClient;
