import { configureStore } from "@reduxjs/toolkit";
//import logger from "redux-logger";
import pageBlogSlice from "../feature/pageBlog.slice";
import clientSlice from "../feature/client.slice";
import contactSlice from "../feature/contact.slice";
import contractSlice from "../feature/contract.slice";
import loadingSlice from "../feature/loading.slice";
import spyderSlice from "../feature/spyder.slice";
import spydeAccountSlice from "../feature/spyde_account.slice";
import userSlice from "../feature/user.slice";
import accessRulesSlice from "../feature/access_rules.slice";
import wishSlice from "../feature/wish.slice";
import roadMapSlice from "../feature/roadmap.slice";
import rackSlice from "../feature/rack.slice";
import recycleSlice from "../feature/recycle.slice";
import sendRdvSlice from "../feature/sendRdv.slice";
import rdvSlice from "../feature/rdv.slice";
import imagesBlogSlice from "../feature/imagesBlog.slice";
import publishImagesBlogSlice from "../feature/publishImagesBlog.slice";
import publishPagesBlogSlice from "../feature/publishPageBlog.slice";

export const store = configureStore({
  reducer: {
    pagesBlog: pageBlogSlice.reducer,
    publishImagesBlog: publishImagesBlogSlice.reducer,
    publishPagesBlog: publishPagesBlogSlice.reducer,
    imagesBlog: imagesBlogSlice.reducer,
    client: clientSlice.reducer,
    contact: contactSlice.reducer,
    loading: loadingSlice.reducer,
    spyder: spyderSlice.reducer,
    spydeAccount: spydeAccountSlice.reducer,
    user: userSlice.reducer,
    accessRules: accessRulesSlice.reducer,
    contract: contractSlice.reducer,
    wishes: wishSlice.reducer,
    roadmaps: roadMapSlice.reducer,
    racks: rackSlice.reducer,
    recycles: recycleSlice.reducer,
    sendRdvSlice: sendRdvSlice.reducer,
    rdvs: rdvSlice.reducer,
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
