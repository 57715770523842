import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import CookieConsent from "react-cookie-consent";
import { Link, NavLink } from "react-router-dom";
import downloadURL from "../../download/presentation_Spyde.pdf";

const Footer = () => {
  const [showCookie, setShowCookie] = useState(true);

  useEffect(() => {
    let info = localStorage.getItem("spy_info");
    setShowCookie(info === "false" ? false : true);
  }, []);

  return (
    <footer>
      {showCookie && (
        <CookieConsent
          debug={true}
          buttonText="Ok, j'ai compris"
          style={{ background: "rgba(50, 50, 50, 0.9)" }}
          buttonStyle={{
            opacity: 1,
            background: "rgb(71, 138, 204)",
            color: "white",
            height: "40px",
            padding: "0 1rem",
            borderRadius: "1rem",
          }}
          onAccept={(e) => {
            localStorage.setItem("spy_info", e);
          }}
          expires={365}
        >
          <p>Information sur les cookies :</p>
          <br />
          <p className="font__italic preline">
            Chez Spyde, nous utilisons des cookies techniques uniquement pour la
            gestion de vos sessions utilisateurs. {"\n"}Conformément à la
            réglementation en vigueur, ces cookies ne nécessitent pas le
            consentement de l'utilisateur.{"\n"}Pour en savoir plus, merci de
            lire notre{" "}
            <Link to="/blog">
              <span className="linkPolitic">
                Politique sur la gestion des données.
              </span>
            </Link>
          </p>
        </CookieConsent>
      )}
      <div className="contact">
        <div className="contact__container">
          <div className="contact__container__message">
            <h2 className="font__blockPoint">A votre écoute</h2>
            <br />
            <p className="font__simpleText">
              Nous sommes toujours disponible pour parler ensemble de vos
              besoins, par le moyen qui vous correspond le mieux
            </p>
          </div>
          <div className="contact__container__online">
            <p className="font__simpleText">
              Retrouvez-nous aussi sur vos réseaux :
            </p>
            <div className="socialMedia">
              <a
                href="https://www.instagram.com/byspyde"
                className="socialMedia__link socialMedia__link--insta"
              >
                <FontAwesomeIcon
                  className="socialMedia__icon"
                  icon={faInstagram}
                />
              </a>
              <a
                href="https://www.twitter.com/SpydeFR"
                className="socialMedia__link socialMedia__link--twit"
              >
                <FontAwesomeIcon
                  className="socialMedia__icon"
                  icon={faTwitter}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/sasspyde"
                className="socialMedia__link socialMedia__link--linkedin"
              >
                <FontAwesomeIcon
                  className="socialMedia__icon"
                  icon={faLinkedin}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="contact__container">
          <div>
            <div className="contact__container__logo">
              <span className="font__blockPoint">Spyde</span>
            </div>
            <br />
            <p className="font__simpleText"> 10b, Rue Jean Mazaryck</p>
            <p className="font__simpleText"> 33700 MERIGNAC</p>
          </div>
          <br />
          <p className="font__simpleText">
            E-mail : service-commercial@spyde.fr
          </p>
          <br />
          <p className="font__simpleText">
            Tel : 06.52.78.28.84 ou 07.82.20.75.32
          </p>
          <br />

          <a
            className="downloadLink font__italic displayNoneOnSmallScreen"
            href={downloadURL}
            download="Spyde_Presentation_2023.pdf"
          >
            Télécharger notre présentation commerciale en cliquant ici
          </a>
          <br />
        </div>
      </div>
      <div className="endHeader">
        <NavLink to="/notrepolitique">
          <p className="font__italic">Politique de confidentialité</p>
        </NavLink>
        <NavLink to="/mentions">
          <p className="font__italic">Mentions légales</p>
        </NavLink>
      </div>
    </footer>
  );
};

export default Footer;
