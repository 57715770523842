import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateFormat from "../../utils/dateFormat";
import DashButton from "../elements/DashButton";
import ModalLayout from "../layouts/ModalLayout";
import plasticImage from "../../img/Plastic.svg";
import paperImage from "../../img/Paper.svg";
import aluImage from "../../img/Alu.svg";
import inkImage from "../../img/Ink.svg";
import DEEImage from "../../img/DEE.svg";
import { getSpydersForClient } from "../../feature/spyder.slice";
//import CertificatPdf from "../layouts/CertificatPdf";

//! : to unlock PDF certificat :
// import {
//   Document,
//   Page,
//   PDFDownloadLink,
//   PDFViewer,
//   Text,
//   View,
//   StyleSheet,
//   Image,
//   Font,
// } from "@react-pdf/renderer";
//! : to unlock PDF certificat :
import myImage from "../../img/bouteille_ocean.jpg";

import spydeLogoHeader from "../../img/pdf/logo-spyde-pdf.png";
import clientLogoHeader from "../../img/pdf/LogoEts.png";
import PlasticLogo from "../../img/pdf/Plastic.jpg";
import PaperLogo from "../../img/pdf/Paper.png";
import ElectronicLogo from "../../img/pdf/DEE.png";
import AluLogo from "../../img/pdf/Aluminium.png";
import InkLogo from "../../img/pdf/Ink.png";
import TreeLogo from "../../img/pdf/TreesImage.jpg";
import FooterLogo from "../../img/pdf/logo_spyde_white.png";

//font import for pdf :
import poppinsReg from "../../styles/settings/font_assets/Poppins-Regular.ttf";
import poppinsBold from "../../styles/settings/font_assets/Poppins-Bold.ttf";
import poppinsExtraBold from "../../styles/settings/font_assets/Poppins-ExtraBold.ttf";
import poppinsMedium from "../../styles/settings/font_assets/Poppins-Medium.ttf";

//Enregistrement des fonts;
//! : to unlock PDF certificat :
// Font.register({
//   family: "Poppins",
//   fonts: [
//     { src: poppinsReg },
//     { src: poppinsMedium, fontStyle: "medium" },
//     { src: poppinsBold, fontWeight: "bold" },
//     { src: poppinsExtraBold, fontWeight: "extrabold" },
//   ],
// });
//! : to unlock PDF certificat :

export const MyRecyclesData = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const spyderData = useSelector((state) => state.spyder.spyder);
  const [modal, setModal] = useState(false);
  const [selectedRecycle, setSelectedRecycle] = useState();

  useEffect(() => {
    dispatch(getSpydersForClient());
  }, []);

  const weightInKilo = (weightInGramme) => {
    return Math.round((weightInGramme / 1000) * 10) / 10;
  };
  const noteInStars = (note) => {
    if (note === 1) {
      return (
        <span className="starNote">
          <ion-icon icon="star" />
        </span>
      );
    }
    if (note === 2) {
      return (
        <span className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </span>
      );
    }
    if (note === 3) {
      return (
        <span className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </span>
      );
    }
    if (note === 4) {
      return (
        <span className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </span>
      );
    }
    if (note === 5) {
      return (
        <span className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </span>
      );
    }
  };

  const openModal = (e, recycle) => {
    e.preventDefault();
    setSelectedRecycle(recycle);
    setModal(true);
  };

  const toggleModal = (e) => {
    setModal(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  //! PDF CONFIG :
  //  const generateCertificatPdf = (e) => {
  //   e.preventDefault();
  //   console.log("Generate my certificat in pdf");
  // };

  const red = "#d02d2d";
  const gris = "#A9ABB0";
  const plasticColor = "#468BCC";
  const aluColor = "#C01122";
  const paperColor = "#40B189";
  const electronicColor = "#F5D740";
  const inkColor = "#432022";

  const styles = StyleSheet.create({
    logoHeader: {
      width: "auto",
      height: "100%",
      padding: 20,
    },
    logoClient: {
      width: "20%",
      height: "auto",
      padding: 20,
      objectFit: "cover",
      justifyContent: "center",
    },
    logoDechet: {
      width: 40,
      height: 40,
      padding: 2,
      objectFit: "cover",
      margin: "0 0 0 30",
      padding: "4",
    },
    logoThanks: {
      width: "auto",
      height: 120,
      padding: "20 40 0 0",
      objectFit: "cover",
      //margin: "0 0 10 30",
    },
    body: {
      flexGrow: 1,
      fontFamily: "Poppins",
      flexDirection: "column",
      justifyContent: "center",
    },
    rowHeader: {
      height: 70,
      flexGrow: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      //border: "2px solid blue",
    },
    rowTitle: {
      height: 46,
      flexGrow: 1,
      flexDirection: "row",
      //border: "2px solid red",
      margin: "0 0 20 0",
    },
    rowNumber: {
      height: 24,
      flexGrow: 1,
      flexDirection: "row",
    },
    rowNumberPart: {
      //height: 20,
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
      //paddingBottom: 20,
    },
    rowCenter: {
      //height: 20,
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
    },
    rowTotal: {
      height: 20,
      flexGrow: 1,
      flexDirection: "row",
      textAlign: "left",
      margin: "20 0 20 0",
    },
    rowSmall: {
      //height: 30,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      padding: "0 40",
      margin: "10 0 0 0",
    },
    rowSmallOne: {
      //height: 30,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      padding: "0 40",
      margin: "0 0 10 0",
    },
    rowTrees: {
      //height: 30,
      width: 597,
      //flexGrow: 1,
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
    },
    col: {
      width: "auto",
      //height: 50,
      flexGrow: 2,
      flexDirection: "column",
    },
    row: {
      //height: 30,
      flexGrow: 1,
      flexDirection: "row",
    },
    rowMorePadding: {
      flexGrow: 1,
      flexDirection: "row",
      margin: "37 0 0 0",
    },
    line: {
      width: 400,
      height: "auto",
      padding: "10 40 0 40",
      flexDirection: "row",
      justifyContent: "center",
    },
    footer: {
      height: 60,
      flexGrow: 1,
      flexDirection: "row",
      backgroundColor: red,

      justifyContent: "space-between",
      //border: "2px solid blue",
    },

    title: {
      width: "100%",
      fontSize: 27,
      color: red,
      textAlign: "center",
      textTransform: "uppercase",
      fontWeight: "extrabold",
    },
    label: {
      width: "auto",
      fontSize: 12,
      textAlign: "right",
      color: red,
      fontWeight: "medium",
      verticalAlign: "super",
      marginLeft: 40,
    },
    labelAddress: {
      width: "auto",
      fontSize: 12,
      textAlign: "center",
      color: red,
      fontWeight: "medium",
      verticalAlign: "super",
      margin: "10 0 0 0",
    },
    labelThanks: {
      width: "auto",
      fontSize: 12,
      textAlign: "center",
      color: red,
      fontWeight: "medium",
      verticalAlign: "super",
      margin: "0 40 20 40",
    },
    labelTotal: {
      width: "auto",
      fontSize: 14,
      textAlign: "center",
      color: red,
      fontWeight: "medium",
      verticalAlign: "super",
      marginLeft: 40,
    },
    text: {
      width: "auto",
      fontSize: 14,
      verticalAlign: "super",
      marginLeft: 10,
    },
    textGrey: {
      width: 100,
      fontSize: 10,
      color: gris,
      verticalAlign: "super",
      margin: "20 10 0 0",
      //marginLeft: 10,
    },
    textPlastic: {
      width: 180,
      fontSize: 16,
      color: plasticColor,
      verticalAlign: "super",
      margin: "15 0 0 10",
      //marginLeft: 10,
    },
    textPlasticWeight: {
      width: 80,
      fontSize: 22,
      color: plasticColor,
      textAlign: "right",
      margin: "3 10 0 0",
    },
    textAlu: {
      width: 180,
      fontSize: 16,
      color: aluColor,
      verticalAlign: "super",
      margin: "15 0 0 10",
      //marginLeft: 10,
    },
    textAluWeight: {
      width: 80,
      fontSize: 22,
      color: aluColor,
      textAlign: "right",
      margin: "3 10 0 0",
    },
    textPaper: {
      width: 180,
      fontSize: 16,
      color: paperColor,
      verticalAlign: "super",
      margin: "15 0 0 10",
      //marginLeft: 10,
    },
    textPaperWeight: {
      width: 80,
      fontSize: 22,
      color: paperColor,
      textAlign: "right",
      margin: "3 10 0 0",
    },
    textElectronic: {
      width: 180,
      fontSize: 16,
      color: electronicColor,
      verticalAlign: "super",
      margin: "15 0 0 10",
      //marginLeft: 10,
    },
    textElectronicWeight: {
      width: 80,
      fontSize: 22,
      color: electronicColor,
      textAlign: "right",
      margin: "3 10 0 0",
    },
    textInk: {
      width: 180,
      fontSize: 16,
      color: inkColor,
      verticalAlign: "super",
      margin: "15 0 0 10",
      //marginLeft: 10,
    },
    textInkWeight: {
      width: 80,
      fontSize: 22,
      color: inkColor,
      textAlign: "right",
      margin: "3 10 0 0",
    },
    textPlasticYear: {
      width: 40,
      fontSize: 18,
      textAlign: "right",
      verticalAlign: "super",
      margin: "15 25 0 0",
    },
    textSmall: {
      width: "auto",
      fontSize: 10,
      textAlign: "center",
      verticalAlign: "super",
    },
    textBold: {
      width: "auto",
      fontSize: 14,
      fontWeight: "bold",
      verticalAlign: "super",
      // marginRight: 40,
      // marginLeft: 10,
    },
    textBolder: {
      width: "auto",
      //fontSize: 12,
      fontWeight: "bold",
      verticalAlign: "super",
    },
    textNumber: {
      fontSize: 32,
      fontWeight: "bold",
      color: red,
    },
    textTrees: {
      fontSize: 16,
      color: gris,
      padding: "16 0 0 10",
    },
    punchLine: {
      color: "white",
      fontSize: 12,
      margin: "22 30 0 0",
    },
    dateStart: {
      width: "auto",
      fontSize: 12,
      textAlign: "left",
      fontWeight: "medium",
      verticalAlign: "super",
      marginLeft: 10,
    },
    rowEmpty: {
      height: 20,
      flexGrow: 1,
      flexDirection: "row",
    },
  });

  const [certifNumber, setCertifNumber] = useState("");
  const [certifDate, setCertifDate] = useState("");

  const [totalAlu, setTotalAlu] = useState(0);
  const [totalPaper, setTotalPaper] = useState(0);
  const [totalPlastic, setTotalPlastic] = useState(0);
  const [totalInk, setTotalInk] = useState(0);
  const [totalElectronic, setTotalElectronic] = useState(0);

  const [yearAlu, setYearAlu] = useState(0);
  const [yearPaper, setYearPaper] = useState(0);
  const [yearPlastic, setYearPlastic] = useState(0);
  const [yearInk, setYearInk] = useState(0);
  const [yearElectronic, setYearElectronic] = useState(0);

  const [totalTrees, setTotalTrees] = useState(0);
  const [totalO2, setTotalO2] = useState(0);

  const [startTime, setStartTime] = useState("");

  let timeStamp = Date.now().toString();
  let date1 = new Date(Date.now());
  let jourSemaine = date1.getDay();
  let jourMois = date1.getDate();
  let mois = date1.getMonth();
  let annee = date1.getFullYear();

  useEffect(() => {
    if (jourMois < 10) {
      jourMois = "0" + jourMois.toString();
    }
    if (mois < 10) {
      mois = "0" + (mois + 1).toString();
    }
    setCertifDate(jourMois + "/" + mois + "/" + annee);
    setCertifNumber(
      "CR" +
        timeStamp.slice(0, timeStamp.length - 4) +
        "_" +
        userData.contact.id +
        "_" +
        timeStamp.slice(timeStamp.length - 4, timeStamp.length)
    );
  }, []);

  //CheckTotalWeight :
  useEffect(() => {
    let cumulAlu = 0;
    let cumulPlastic = 0;
    let cumulPaper = 0;
    let cumulInk = 0;
    let cumulElectronic = 0;

    let i;

    for (i = 0; i < recyclesData.data.length; i++) {
      cumulAlu = cumulAlu + recyclesData.data[i].aluminium;
      cumulPlastic = cumulPlastic + recyclesData.data[i].plastic;
      cumulPaper = cumulPaper + recyclesData.data[i].paper;
      cumulInk = cumulInk + recyclesData.data[i].ink;
      cumulElectronic = cumulElectronic + recyclesData.data[i].electronic;
    }

    setTotalAlu(cumulAlu / 1000);
    setTotalPaper(cumulPaper / 1000);
    setTotalPlastic(cumulPlastic / 1000);
    setTotalInk(cumulInk / 1000);
    setTotalElectronic(cumulElectronic / 1000);
  }, [recyclesData]);

  //CheckTotalWeight THIS YEAR :
  useEffect(() => {
    let yearAlu = 0;
    let yearPlastic = 0;
    let yearPaper = 0;
    let yearInk = 0;
    let yearElectronic = 0;

    let i;

    const yearRecycles = recyclesData.data.filter((recycle) => {
      if (`${recycle.updatedAt.slice(0, 4)}` === `${annee}`) {
        return recycle;
      }
    });

    for (i = 0; i < yearRecycles.length; i++) {
      yearAlu = yearAlu + yearRecycles[i].aluminium;
      yearPlastic = yearPlastic + yearRecycles[i].plastic;
      yearPaper = yearPaper + yearRecycles[i].paper;
      yearInk = yearInk + yearRecycles[i].ink;
      yearElectronic = yearElectronic + yearRecycles[i].electronic;
    }

    setYearAlu(yearAlu / 1000);
    setYearPaper(yearPaper / 1000);
    setYearPlastic(yearPlastic / 1000);
    setYearInk(yearInk / 1000);
    setYearElectronic(yearElectronic / 1000);
  }, [recyclesData]);

  //CheckTotalTrees

  const numberTreeByTo = 3;
  const numberO2ByTree = 15;

  useEffect(() => {
    const totalWaste =
      totalPlastic + totalPaper + totalInk + totalElectronic + totalAlu;

    const numberOfTrees = Math.floor(totalWaste / 1000);

    setTotalTrees(numberOfTrees * numberTreeByTo);
    setTotalO2(numberOfTrees * numberTreeByTo * numberO2ByTree);
  }, [totalPlastic, totalPaper, totalInk, totalElectronic, totalAlu]);

  //Date first collecte :
  useEffect(() => {
    let recyclesDate = recyclesData.data.map((recycle) => {
      return recycle.updatedAt;
    });
    let OrderByDate = recyclesDate.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    let firstCollectDate = dateFormat(OrderByDate[0]);
    setStartTime(firstCollectDate);
  }, [recyclesData]);

  return (
    <div className="recycle">
      {/* //! : to unlock PDF certificat : 
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          {certifNumber && certifDate ? (
            <PDFDownloadLink
              document={
                <Document>
                  <Page size="A4">
                    <View style={styles.body}>
                      <View style={styles.rowHeader}>
                        <Image
                          style={styles.logoHeader}
                          src={spydeLogoHeader}
                        />
                        <Image
                          style={styles.logoHeader}
                          src={
                            userData.contact["Client.addressURL"]
                              ? `${userData.contact["Client.addressURL"]}`
                              : spydeLogoHeader
                          }
                        />
                      </View>
                      <View style={styles.rowTitle}>
                        <Text style={styles.title}>
                          CERTIFICAT DE RECYCLAGE
                        </Text>
                      </View>
                      <View style={styles.rowNumber}>
                        <View style={styles.rowNumberPart}>
                          <Text style={styles.label}>
                            Numéro du certificat :
                          </Text>
                          <Text style={styles.text}>{certifNumber}</Text>
                        </View>
                        <View style={styles.rowNumberPart}>
                          <Text style={styles.addMarginLeft}></Text>
                        </View>
                        <View style={styles.rowNumberPart}>
                          <Text style={styles.label}>Edité le :</Text>
                          <Text style={styles.text}>{certifDate}</Text>
                        </View>
                      </View>
                      <View style={styles.rowCenter}>
                        <Text style={styles.labelAddress}>
                          Adresse de collecte concernée :
                        </Text>
                      </View>
                      <View style={styles.rowCenter}>
                        <Text style={styles.textBold}>
                          {userData.contact["Client.clientName"]
                            ? userData.contact["Client.clientName"]
                            : "Erreur de chargement"}
                        </Text>
                      </View>
                      <View style={styles.rowCenter}>
                        <Text style={styles.text}>
                          {userData.contact["Client.address"]
                            ? userData.contact["Client.address"]
                            : "Erreur de chargement"}
                        </Text>
                      </View>
                      <View style={styles.rowTotal}>
                        <Text style={styles.label}>
                          Total des déchets recyclés depuis le :
                        </Text>
                        <Text style={styles.dateStart}>{startTime}</Text>
                      </View>
                      <View style={styles.rowSmallOne}>
                        <Text style={styles.textSmall}>
                          L’entreprise{" "}
                          <Text style={styles.textBolder}> SPYDE </Text> atteste
                          que la société{" "}
                          <Text style={styles.textBolder}>
                            {userData.contact["Client.clientName"]
                              ? userData.contact["Client.clientName"]
                              : "Erreur de chargement"}{" "}
                          </Text>
                          lui a confié
                        </Text>
                        <Text style={styles.textSmall}>
                          l’ensemble des déchêts mentionnés, depuis le début de
                          leur collaboration
                        </Text>
                      </View>
                      {totalPlastic > 0 && (
                        <View style={styles.row}>
                          <Image style={styles.logoDechet} src={PlasticLogo} />
                          <Text style={styles.textPlastic}>Plastique</Text>
                          <Text style={styles.textPlasticWeight}>
                            {totalPlastic < 1000
                              ? Math.floor(totalPlastic * 10) / 10
                              : Math.floor(
                                  Math.floor(totalPlastic * 10) / 1000
                                ) / 10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {totalPlastic < 1000
                              ? "Kilogrammes dont"
                              : totalPlastic < 2000
                              ? "Tonne dont"
                              : "Tonnes dont"}
                          </Text>
                          <Text style={styles.textPlasticYear}>
                            {yearPlastic < 1000
                              ? Math.floor(yearPlastic * 10) / 10
                              : Math.floor(
                                  Math.floor(yearPlastic * 10) / 1000
                                ) / 10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {yearPlastic < 1000
                              ? "kg en " + annee
                              : "T. en " + annee}
                          </Text>
                        </View>
                      )}
                      {totalAlu > 0 && (
                        <View style={styles.row}>
                          <Image style={styles.logoDechet} src={AluLogo} />
                          <Text style={styles.textAlu}>Aluminium</Text>
                          <Text style={styles.textAluWeight}>
                            {totalAlu < 1000
                              ? Math.floor(totalAlu * 10) / 10
                              : Math.floor(Math.floor(totalAlu * 10) / 1000) /
                                10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {totalAlu < 1000
                              ? "Kilogrammes dont"
                              : totalAlu < 2000
                              ? "Tonne dont"
                              : "Tonnes dont"}
                          </Text>
                          <Text style={styles.textPlasticYear}>
                            {yearAlu < 1000
                              ? Math.floor(yearAlu * 10) / 10
                              : Math.floor(Math.floor(yearAlu * 10) / 1000) /
                                10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {" "}
                            {yearAlu < 1000
                              ? "kg en " + annee
                              : "T. en " + annee}
                          </Text>
                        </View>
                      )}
                      {totalPaper > 0 && (
                        <View style={styles.row}>
                          <Image style={styles.logoDechet} src={PaperLogo} />
                          <Text style={styles.textPaper}>Papier et Carton</Text>
                          <Text style={styles.textPaperWeight}>
                            {totalPaper < 1000
                              ? Math.floor(totalPaper * 10) / 10
                              : Math.floor(Math.floor(totalPaper * 10) / 1000) /
                                10}{" "}
                          </Text>
                          <Text style={styles.textGrey}>
                            {totalPaper < 1000
                              ? "Kilogrammes dont"
                              : totalPaper < 2000
                              ? "Tonne dont"
                              : "Tonnes dont"}
                          </Text>
                          <Text style={styles.textPlasticYear}>
                            {yearPaper < 1000
                              ? Math.floor(yearPaper * 10) / 10
                              : Math.floor(Math.floor(yearPaper * 10) / 1000) /
                                10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {yearPaper < 1000
                              ? "kg en " + annee
                              : "T. en " + annee}
                          </Text>
                        </View>
                      )}
                      {totalElectronic > 0 && (
                        <View style={styles.row}>
                          <Image
                            style={styles.logoDechet}
                            src={ElectronicLogo}
                          />
                          <Text style={styles.textElectronic}>
                            Electronique
                          </Text>
                          <Text style={styles.textElectronicWeight}>
                            {totalElectronic < 1000
                              ? Math.floor(totalElectronic * 10) / 10
                              : Math.floor(
                                  Math.floor(totalElectronic * 10) / 1000
                                ) / 10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {totalElectronic < 1000
                              ? "Kilogrammes dont"
                              : totalElectronic < 2000
                              ? "Tonne dont"
                              : "Tonnes dont"}
                          </Text>
                          <Text style={styles.textPlasticYear}>
                            {yearElectronic < 1000
                              ? Math.floor(yearElectronic * 10) / 10
                              : Math.floor(
                                  Math.floor(yearElectronic * 10) / 1000
                                ) / 10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {" "}
                            {yearElectronic < 1000
                              ? "kg en " + annee
                              : "T. en " + annee}
                          </Text>
                        </View>
                      )}
                      {totalInk > 0 && (
                        <View style={styles.row}>
                          <Image style={styles.logoDechet} src={InkLogo} />
                          <Text style={styles.textInk}>Cartouche d'encre</Text>
                          <Text style={styles.textInkWeight}>
                            {totalInk < 1000
                              ? Math.floor(totalInk * 10) / 10
                              : Math.floor(Math.floor(totalInk * 10) / 1000) /
                                10}
                          </Text>
                          <Text style={styles.textGrey}>
                            {totalInk < 1000
                              ? "Kilogrammes dont"
                              : totalInk < 2000
                              ? "Tonne dont"
                              : "Tonnes dont"}
                          </Text>
                          <Text style={styles.textPlasticYear}>
                            {yearInk < 1000
                              ? Math.floor(yearInk * 10) / 10
                              : Math.floor(Math.floor(yearInk * 10) / 1000) /
                                10}
                          </Text>

                          <Text style={styles.textGrey}>
                            {yearInk < 1000
                              ? "kg en " + annee
                              : "T. en " + annee}
                          </Text>
                        </View>
                      )}
                      <View style={styles.rowSmall}>
                        <Text style={styles.textSmall}>
                          La collecte de ces déchets a été réalisée par l’agence
                          SPYDE
                        </Text>
                        <Text style={styles.textSmall}>
                          située au{" "}
                          <Text style={styles.textBolder}>
                            {userData.spydeContact["SpyderAccount.address"]
                              ? userData.spydeContact["SpyderAccount.address"]
                              : "Erreur de chargement"}
                          </Text>
                        </Text>
                      </View>
                      {totalAlu === 0 && <View style={styles.rowEmpty}></View>}
                      {totalPaper === 0 && (
                        <View style={styles.rowEmpty}></View>
                      )}
                      {totalPlastic === 0 && (
                        <View style={styles.rowEmpty}></View>
                      )}
                      {totalInk === 0 && <View style={styles.rowEmpty}></View>}
                      {totalElectronic === 0 && (
                        <View style={styles.rowEmpty}></View>
                      )}
                      {totalTrees === 0 ? (
                        <View style={styles.rowMorePadding}>
                          <Text style={styles.label}>
                            Chez Spyde, pour chaque tonne de déchêts recyclés,
                            c'est {numberTreeByTo} arbres plantés !
                          </Text>
                        </View>
                      ) : (
                        <View style={styles.rowMorePadding}>
                          <Text style={styles.label}>
                            Grâce à votre action responsable de l’environnement
                            et à nos associations partenaires,
                          </Text>
                        </View>
                      )}
                      {totalTrees === 0 ? (
                        <View style={styles.rowTrees}>
                          <View style={styles.col}>
                            <View style={styles.line}>
                              <Text style={styles.textNumber}>
                                {1000 -
                                  Math.floor(
                                    totalAlu +
                                      totalElectronic +
                                      totalInk +
                                      totalPaper +
                                      totalPlastic
                                  )}
                              </Text>
                              <Text style={styles.textTrees}>Kilogrammes</Text>
                            </View>

                            <View style={styles.line}>
                              <Text style={styles.textSmall}>
                                de déchêts à recycler, et nous planterons vos
                                premiers arbres. Un arbre planté représente plus
                                de
                                <Text style={styles.textBolder}>
                                  {" " + numberO2ByTree + " kg "}
                                </Text>
                                d'oxygène libérée par an.
                              </Text>
                            </View>
                            <View style={styles.line}>
                              <Text style={styles.labelThanks}>
                                Vous y êtes presque ;)
                              </Text>
                            </View>
                          </View>

                          <Image style={styles.logoThanks} src={TreeLogo} />
                        </View>
                      ) : (
                        <View style={styles.rowTrees}>
                          <View style={styles.col}>
                            <View style={styles.line}>
                              <Text style={styles.textNumber}>
                                {totalTrees}
                              </Text>
                              <Text style={styles.textTrees}>Arbres</Text>
                            </View>

                            <View style={styles.line}>
                              <Text style={styles.textSmall}>
                                ont été plantés en votre nom, soit une
                                production d’oxygéne annuelle de plus de{" "}
                                <Text style={styles.textBolder}>
                                  {totalO2 + " kg"}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.line}>
                              <Text style={styles.labelThanks}>
                                Merci pour votre contribution ;)
                              </Text>
                            </View>
                          </View>

                          <Image style={styles.logoThanks} src={TreeLogo} />
                        </View>
                      )}
                      <View style={styles.footer}>
                        <Image style={styles.logoHeader} src={FooterLogo} />
                        <Text style={styles.punchLine}>
                          Plus qu’un prestataire, un partenaire écologique
                        </Text>
                      </View>
                    </View>
                  </Page>
                </Document>
              }
              fileName={"Spyde_" + certifNumber + ".pdf"}
            >
              {({ loading }) =>
                loading ? (
                  <DashButton
                    disabled={true}
                    action="inactive"
                    text="Chargement du certificat ..."
                  />
                ) : (
                  <DashButton
                    action="action"
                    text="Télécharger mon certificat"
                  />
                )
              }
            </PDFDownloadLink>
          ) : (
            <DashButton
              disabled={true}
              action="inactive"
              text="Erreur lors de l'édition"
            />
          )}
        </div>
      </div> */}
      {/* certifNumber && certifDate ? (
        <PDFViewer width="600px" height="800px">
          <Document>
            <Page size="A4">
              <View style={styles.body}>
                <View style={styles.rowHeader}>
                  <Image style={styles.logoHeader} src={spydeLogoHeader} />
                  <Image
                    style={styles.logoHeader}
                    src={
                      userData.contact["Client.addressURL"]
                        ? `${userData.contact["Client.addressURL"]}`
                        : spydeLogoHeader
                    }
                  />
                </View>
                <View style={styles.rowTitle}>
                  <Text style={styles.title}>CERTIFICAT DE RECYCLAGE</Text>
                </View>
                <View style={styles.rowNumber}>
                  <View style={styles.rowNumberPart}>
                    <Text style={styles.label}>Numéro du certificat :</Text>
                    <Text style={styles.text}>{certifNumber}</Text>
                  </View>
                  <View style={styles.rowNumberPart}>
                    <Text style={styles.addMarginLeft}></Text>
                  </View>
                  <View style={styles.rowNumberPart}>
                    <Text style={styles.label}>Edité le :</Text>
                    <Text style={styles.text}>{certifDate}</Text>
                  </View>
                </View>
                <View style={styles.rowCenter}>
                  <Text style={styles.labelAddress}>
                    Adresse de collecte concernée :
                  </Text>
                </View>
                <View style={styles.rowCenter}>
                  <Text style={styles.textBold}>
                    {userData.contact["Client.clientName"]
                      ? userData.contact["Client.clientName"]
                      : "Erreur de chargement"}
                  </Text>
                </View>
                <View style={styles.rowCenter}>
                  <Text style={styles.text}>
                    {userData.contact["Client.address"]
                      ? userData.contact["Client.address"]
                      : "Erreur de chargement"}
                  </Text>
                </View>
                <View style={styles.rowTotal}>
                  <Text style={styles.label}>
                    Total des déchets recyclés depuis le :
                  </Text>
                  <Text style={styles.dateStart}>{startTime}</Text>
                </View>
                <View style={styles.rowSmallOne}>
                  <Text style={styles.textSmall}>
                    L’entreprise <Text style={styles.textBolder}> SPYDE </Text>{" "}
                    atteste que la société{" "}
                    <Text style={styles.textBolder}>
                      {userData.contact["Client.clientName"]
                        ? userData.contact["Client.clientName"]
                        : "Erreur de chargement"}{" "}
                    </Text>
                    lui a confié
                  </Text>
                  <Text style={styles.textSmall}>
                    l’ensemble des déchêts mentionnés, depuis le début de leur
                    collaboration
                  </Text>
                </View>
                {totalPlastic > 0 && (
                  <View style={styles.row}>
                    <Image style={styles.logoDechet} src={PlasticLogo} />
                    <Text style={styles.textPlastic}>Plastique</Text>
                    <Text style={styles.textPlasticWeight}>
                      {totalPlastic < 1000
                        ? Math.floor(totalPlastic * 10) / 10
                        : Math.floor(Math.floor(totalPlastic * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {totalPlastic < 1000
                        ? "Kilogrammes dont"
                        : totalPlastic < 2000
                        ? "Tonne dont"
                        : "Tonnes dont"}
                    </Text>
                    <Text style={styles.textPlasticYear}>
                      {yearPlastic < 1000
                        ? Math.floor(yearPlastic * 10) / 10
                        : Math.floor(Math.floor(yearPlastic * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {yearPlastic < 1000 ? "kg en " + annee : "T. en " + annee}
                    </Text>
                  </View>
                )}
                {totalAlu > 0 && (
                  <View style={styles.row}>
                    <Image style={styles.logoDechet} src={AluLogo} />
                    <Text style={styles.textAlu}>Aluminium</Text>
                    <Text style={styles.textAluWeight}>
                      {totalAlu < 1000
                        ? Math.floor(totalAlu * 10) / 10
                        : Math.floor(Math.floor(totalAlu * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {totalAlu < 1000
                        ? "Kilogrammes dont"
                        : totalAlu < 2000
                        ? "Tonne dont"
                        : "Tonnes dont"}
                    </Text>
                    <Text style={styles.textPlasticYear}>
                      {yearAlu < 1000
                        ? Math.floor(yearAlu * 10) / 10
                        : Math.floor(Math.floor(yearAlu * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {" "}
                      {yearAlu < 1000 ? "kg en " + annee : "T. en " + annee}
                    </Text>
                  </View>
                )}
                {totalPaper > 0 && (
                  <View style={styles.row}>
                    <Image style={styles.logoDechet} src={PaperLogo} />
                    <Text style={styles.textPaper}>Papier et Carton</Text>
                    <Text style={styles.textPaperWeight}>
                      {totalPaper < 1000
                        ? Math.floor(totalPaper * 10) / 10
                        : Math.floor(Math.floor(totalPaper * 10) / 1000) /
                          10}{" "}
                    </Text>
                    <Text style={styles.textGrey}>
                      {totalPaper < 1000
                        ? "Kilogrammes dont"
                        : totalPaper < 2000
                        ? "Tonne dont"
                        : "Tonnes dont"}
                    </Text>
                    <Text style={styles.textPlasticYear}>
                      {yearPaper < 1000
                        ? Math.floor(yearPaper * 10) / 10
                        : Math.floor(Math.floor(yearPaper * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {yearPaper < 1000 ? "kg en " + annee : "T. en " + annee}
                    </Text>
                  </View>
                )}
                {totalElectronic > 0 && (
                  <View style={styles.row}>
                    <Image style={styles.logoDechet} src={ElectronicLogo} />
                    <Text style={styles.textElectronic}>Electronique</Text>
                    <Text style={styles.textElectronicWeight}>
                      {totalElectronic < 1000
                        ? Math.floor(totalElectronic * 10) / 10
                        : Math.floor(Math.floor(totalElectronic * 10) / 1000) /
                          10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {totalElectronic < 1000
                        ? "Kilogrammes dont"
                        : totalElectronic < 2000
                        ? "Tonne dont"
                        : "Tonnes dont"}
                    </Text>
                    <Text style={styles.textPlasticYear}>
                      {yearElectronic < 1000
                        ? Math.floor(yearElectronic * 10) / 10
                        : Math.floor(Math.floor(yearElectronic * 10) / 1000) /
                          10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {" "}
                      {yearElectronic < 1000
                        ? "kg en " + annee
                        : "T. en " + annee}
                    </Text>
                  </View>
                )}
                {totalInk > 0 && (
                  <View style={styles.row}>
                    <Image style={styles.logoDechet} src={InkLogo} />
                    <Text style={styles.textInk}>Cartouche d'encre</Text>
                    <Text style={styles.textInkWeight}>
                      {totalInk < 1000
                        ? Math.floor(totalInk * 10) / 10
                        : Math.floor(Math.floor(totalInk * 10) / 1000) / 10}
                    </Text>
                    <Text style={styles.textGrey}>
                      {totalInk < 1000
                        ? "Kilogrammes dont"
                        : totalInk < 2000
                        ? "Tonne dont"
                        : "Tonnes dont"}
                    </Text>
                    <Text style={styles.textPlasticYear}>
                      {yearInk < 1000
                        ? Math.floor(yearInk * 10) / 10
                        : Math.floor(Math.floor(yearInk * 10) / 1000) / 10}
                    </Text>

                    <Text style={styles.textGrey}>
                      {yearInk < 1000 ? "kg en " + annee : "T. en " + annee}
                    </Text>
                  </View>
                )}
                <View style={styles.rowSmall}>
                  <Text style={styles.textSmall}>
                    La collecte de ces déchets a été réalisée par l’agence SPYDE
                  </Text>
                  <Text style={styles.textSmall}>
                    située au{" "}
                    <Text style={styles.textBolder}>
                      {userData.spydeContact["SpyderAccount.address"]
                        ? userData.spydeContact["SpyderAccount.address"]
                        : "Erreur de chargement"}
                    </Text>
                  </Text>
                </View>
                {totalAlu === 0 && <View style={styles.rowEmpty}></View>}
                {totalPaper === 0 && <View style={styles.rowEmpty}></View>}
                {totalPlastic === 0 && <View style={styles.rowEmpty}></View>}
                {totalInk === 0 && <View style={styles.rowEmpty}></View>}
                {totalElectronic === 0 && <View style={styles.rowEmpty}></View>}
                {totalTrees === 0 ? (
                  <View style={styles.rowMorePadding}>
                    <Text style={styles.label}>
                      Chez Spyde, pour chaque tonne de déchêts recyclés, c'est{" "}
                      {numberTreeByTo} arbres plantés !
                    </Text>
                  </View>
                ) : (
                  <View style={styles.rowMorePadding}>
                    <Text style={styles.label}>
                      Grâce à votre action responsable de l’environnement et à
                      nos associations partenaires,
                    </Text>
                  </View>
                )}
                {totalTrees === 0 ? (
                  <View style={styles.rowTrees}>
                    <View style={styles.col}>
                      <View style={styles.line}>
                        <Text style={styles.textNumber}>
                          {1000 -
                            Math.floor(
                              totalAlu +
                                totalElectronic +
                                totalInk +
                                totalPaper +
                                totalPlastic
                            )}
                        </Text>
                        <Text style={styles.textTrees}>Kilogrammes</Text>
                      </View>

                      <View style={styles.line}>
                        <Text style={styles.textSmall}>
                          de déchêts à recycler, et nous planterons vos premiers
                          arbres. Un arbre planté représente plus de
                          <Text style={styles.textBolder}>
                            {" " + numberO2ByTree + " kg "}
                          </Text>
                          d'oxygène libérée par an.
                        </Text>
                      </View>
                      <View style={styles.line}>
                        <Text style={styles.labelThanks}>
                          Vous y êtes presque ;)
                        </Text>
                      </View>
                    </View>

                    <Image style={styles.logoThanks} src={TreeLogo} />
                  </View>
                ) : (
                  <View style={styles.rowTrees}>
                    <View style={styles.col}>
                      <View style={styles.line}>
                        <Text style={styles.textNumber}>{totalTrees}</Text>
                        <Text style={styles.textTrees}>Arbres</Text>
                      </View>

                      <View style={styles.line}>
                        <Text style={styles.textSmall}>
                          ont été plantés en votre nom, soit une production
                          d’oxygéne annuelle de plus de{" "}
                          <Text style={styles.textBolder}>
                            {totalO2 + " kg"}
                          </Text>
                        </Text>
                      </View>
                      <View style={styles.line}>
                        <Text style={styles.labelThanks}>
                          Merci pour votre contribution ;)
                        </Text>
                      </View>
                    </View>

                    <Image style={styles.logoThanks} src={TreeLogo} />
                  </View>
                )}
                <View style={styles.footer}>
                  <Image style={styles.logoHeader} src={FooterLogo} />
                  <Text style={styles.punchLine}>
                    Plus qu’un prestataire, un partenaire écologique
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        "Erreur"
      )  
      //! : to unlock PDF certificat : 
      */}
      <div className="recycle__container">
        <div className="recycle__container__table">
          <table className="tableRecycle">
            <thead className="tableRecycle__lines tableRecycle__line--header">
              <tr className="tableRecycle__columns tableRecycle__columns--header">
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  N°
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Date
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Type
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Total
                </th>

                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--plastic">
                  P
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--paper ">
                  P
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--alu ">
                  A
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--ink ">
                  E
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--electronic ">
                  D
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Note
                </th>

                <th className="widgetFont__table--header">Voir le bon</th>
              </tr>
            </thead>
            <tbody className="tableRecycle__lines">
              {recyclesData &&
                recyclesData.data.map((recycle, index) => {
                  return (
                    <tr key={index} className="tableRecycle__columns">
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {"BR" + recycle.id}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {dateFormat(recycle.dateOfCollecte)}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {recycle.typeOfCollecte}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(
                          recycle.electronic +
                            recycle.ink +
                            recycle.aluminium +
                            recycle.paper +
                            recycle.plastic
                        ) + " Kg"}
                      </th>

                      <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(recycle.plastic)}
                      </th>
                      <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(recycle.paper)}
                      </th>
                      <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(recycle.aluminium)}
                      </th>
                      <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(recycle.ink)}
                      </th>
                      <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                        {weightInKilo(recycle.electronic)}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body  ">
                        <p className="displayNoneOnSmallScreen">
                          {noteInStars(recycle.note)}
                        </p>
                        <p className="displayNoneOnPhoneScreen">
                          {recycle.note}
                        </p>
                      </th>

                      <th className="tableRecycle__lines__btnBox">
                        <div>
                          <DashButton
                            type="button"
                            action="search"
                            text="Détails"
                            onClick={(e) => openModal(e, recycle)}
                          />
                        </div>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {modal && (
        <ModalLayout
          toggle={toggleModal}
          modal={modal}
          label="Détail du recyclage"
          colorClass="yellow"
        >
          <div className="detailGrid">
            <div className="detailGrid__element detailGrid__element--half">
              <h3>N° de bon :</h3>
              <p>BR {selectedRecycle.id}</p>
            </div>
            <div className="detailGrid__element detailGrid__element--half detailGrid__element--right">
              <h3>Date de la collecte :</h3>
              <p>{dateFormat(selectedRecycle.dateOfCollecte)} </p>
            </div>
            <div className="detailGrid__element detailGrid__element--full detailGrid__element--align">
              <h3>Client:</h3>
              <p>
                {selectedRecycle["Client"]?.clientName +
                  " - " +
                  selectedRecycle["Client"]?.agency}{" "}
              </p>
              {selectedRecycle["Client"]?.addressURL && (
                <div className="detailClient">
                  <img
                    src={selectedRecycle["Client"]?.addressURL}
                    alt={
                      "logo du client selectionné" +
                      selectedRecycle["Client"]?.clientName
                    }
                  />
                </div>
              )}
            </div>

            <div className="detailGrid__element  detailGrid__element--align detailGrid__element--half">
              <h3>Note :</h3>
              {noteInStars(selectedRecycle.note)}
            </div>
            <div className="detailGrid__element detailGrid__element--align detailGrid__element--half">
              <h3>Feuille de route :</h3>
              <p>FR {selectedRecycle.roadmap_id}</p>
            </div>

            <div className="detailGrid__element detailGrid__element--full">
              <h3>Poids par produit</h3>
            </div>
            <div className="detailGrid__element detailGrid__element--align detailGrid__element--full weightbox">
              {selectedRecycle.plastic +
                selectedRecycle.ink +
                selectedRecycle.electronic +
                selectedRecycle.paper +
                selectedRecycle.aluminium ===
                0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--alert">
                    Pesée nulle - Aucun enregistrement de poids
                  </p>
                </div>
              )}
              {selectedRecycle.plastic !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--plastic">
                    {weightInKilo(selectedRecycle.plastic) + " "}
                    <span>kg</span>
                  </p>
                  <img
                    src={plasticImage}
                    alt="pictogramme bleu pour le plastique"
                  />
                </div>
              )}
              {selectedRecycle.paper !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--paper">
                    {weightInKilo(selectedRecycle.paper) + " "}
                    <span>kg</span>
                  </p>
                  <img
                    src={paperImage}
                    alt={"pictogramme vert pour le papier"}
                  />
                </div>
              )}
              {selectedRecycle.aluminium !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--aluminium">
                    {weightInKilo(selectedRecycle.aluminium) + " "}
                    <span>kg</span>
                  </p>
                  <img
                    src={aluImage}
                    alt="pictogramme rouge pour l'aluminium"
                  />
                </div>
              )}
              {selectedRecycle.ink !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--ink">
                    {weightInKilo(selectedRecycle.ink) + " "}
                    <span>kg</span>
                  </p>
                  <img
                    src={inkImage}
                    alt="pictogramme marron pour les cartouches d'encres"
                  />
                </div>
              )}
              {selectedRecycle.electronic !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--electronic">
                    {weightInKilo(selectedRecycle.electronic) + " "}
                    <span>kg</span>
                  </p>
                  <img
                    src={DEEImage}
                    alt="pictogramme jaune pour le matériel informatique et electronique"
                  />
                </div>
              )}
            </div>

            <div className="detailGrid__element detailGrid__element--align detailGrid__element--half">
              <h3>Collecte :</h3>
              <p>{selectedRecycle.typeOfCollecte}</p>
            </div>
            <div className="detailGrid__element  detailGrid__element--align detailGrid__element--half">
              <h3>Poids total :</h3>
              <p>
                {(selectedRecycle.plastic +
                  selectedRecycle.ink +
                  selectedRecycle.electronic +
                  selectedRecycle.paper +
                  selectedRecycle.aluminium) /
                  1000 +
                  " kg"}
              </p>
            </div>

            {selectedRecycle.driver_id === selectedRecycle.peseur_id &&
              selectedRecycle.trieur_id === selectedRecycle.peseur_id && (
                //si tous identiques affiche 1 seul
                <>
                  <div className="detailGrid__element detailGrid__element--full">
                    <h3>Collecteur / Peseur / Recycleur :</h3>
                  </div>
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--full">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "portrait de " +
                                    spyder.firstname +
                                    "  " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                </>
              )}

            {selectedRecycle.driver_id !== selectedRecycle.peseur_id &&
              selectedRecycle.trieur_id === selectedRecycle.peseur_id && (
                <>
                  <div className="detailGrid__element detailGrid__element--half">
                    <h3>Collecteur :</h3>
                  </div>
                  <div className="detailGrid__element detailGrid__element--half">
                    <h3>Peseur / Recycleur :</h3>
                  </div>
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--half">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "portrait de " +
                                    spyder.firstname +
                                    "  " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.peseur_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element detailGrid__element--nopadding detailGrid__element--half"
                          >
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "portrait de " +
                                  spyder.firstname +
                                  "  " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                </>
              )}

            {selectedRecycle.driver_id === selectedRecycle.peseur_id &&
              selectedRecycle.trieur_id !== selectedRecycle.peseur_id && (
                <>
                  <div className="detailGrid__element detailGrid__element--half">
                    <h3>Collecteur / Peseur :</h3>
                  </div>
                  <div className="detailGrid__element detailGrid__element--half">
                    <h3>Recycleur :</h3>
                  </div>
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element detailGrid__element--center detailGrid__element--half"
                          >
                            <div className="detailSpyder--center">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "portrait de " +
                                    spyder.firstname +
                                    "  " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.trieur_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element  detailGrid__element--nopadding detailGrid__element--half"
                          >
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "portrait de " +
                                  spyder.firstname +
                                  "  " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                </>
              )}

            {selectedRecycle.driver_id !== selectedRecycle.peseur_id &&
              selectedRecycle.trieur_id !== selectedRecycle.peseur_id && (
                <>
                  <div className="detailGrid__element detailGrid__element--tierce">
                    <h3>Collecteur :</h3>
                  </div>
                  <div className="detailGrid__element detailGrid__element--tierce">
                    <h3>Peseur :</h3>
                  </div>
                  <div className="detailGrid__element detailGrid__element--tierce">
                    <h3>Recycleur :</h3>
                  </div>
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element detailGrid__element--nopadding detailGrid__element--tierce"
                          >
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "portrait de " +
                                  spyder.firstname +
                                  "  " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.peseur_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element detailGrid__element--nopadding detailGrid__element--tierce"
                          >
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "portrait de " +
                                  spyder.firstname +
                                  "  " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                  {spyderData &&
                    spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.trieur_id
                      )
                      .map((spyder, index) => {
                        return (
                          <div
                            key={index}
                            className="detailGrid__element  detailGrid__element--nopadding detailGrid__element--tierce"
                          >
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "portrait de " +
                                  spyder.firstname +
                                  "  " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                </>
              )}

            <div className="detailGrid__element detailGrid__element--full">
              <h3>Commercial Spyde :</h3>
              {spyderData &&
                spyderData.data
                  .filter(
                    (spyder) =>
                      spyder.id === selectedRecycle["Client"].spyder_login_id
                  )
                  .map((spyder, index) => {
                    return (
                      <div
                        key={index}
                        className="detailGrid__element detailGrid__element--nopadding detailGrid__element--full"
                      >
                        <div className="detailCommercial">
                          <img
                            src={
                              spyder.addressURL
                                ? spyder.addressURL
                                : "/img/spyde_avatar.png"
                            }
                            alt={
                              "portrait de " +
                              spyder.firstname +
                              "  " +
                              spyder.lastname
                            }
                          />
                          <div className="detailCommercial__text">
                            <p>{spyder.firstname}</p>
                            <p>{spyder.lastname}</p>
                            {/* <p>{"Tel : " + phoneNumber(spyder.phone)}</p> */}
                            <p className="detailGrid__element--span">
                              {"Agence Spyde de " +
                                spyder["SpyderAccount"].agency}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>

          <div className="buttonsContainer">
            <div className="buttonContainer--small">
              <DashButton
                //disabled={disabled}
                action="cancel"
                text="Fermer"
                onClick={(e) => toggleModal(e)}
              />
            </div>
          </div>
        </ModalLayout>
      )}
    </div>
  );
};
