import { NavLink } from "react-router-dom";

//! Find icon for icons on ionicons.io */

const DashLi = (props) => {
  return (
    <li className="naviDash__list">
      <NavLink to={"/dashboard/" + props.goTo} className="naviDash__list--link">
        <span className="naviDash__list--icon">
          <ion-icon icon={props.icon}></ion-icon>
        </span>
        <span className="naviDash__list--title">{props.name}</span>
      </NavLink>
    </li>
  );
};

export default DashLi;
