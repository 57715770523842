import emailJS from "@emailjs/browser";
import { toast } from "react-toastify";

const sendMailForNewAccount = (data, message, email, password) => {
  const templateId = process.env.REACT_APP_EMAILJS_NEWACCOUNT_TEMPLATE;
  const serviceId = process.env.REACT_APP_EMAILJS_NEWACCOUNT_SERVICE;

  const templateMail = {
    firstname: data.firstname,
    lastname: data.lastname,
    email: email,
    message: message,
    password: password,
  };

  emailJS.init(process.env.REACT_APP_EMAILJS_PUBLICKEY);

  emailJS
    .send(serviceId, templateId, templateMail)
    .then((res) => {
      return toast.info("E-mail envoyé au contact");
    })
    .catch((err) => {
      return toast.error("Erreur lors de l'envoi du mail");
    });
};

export default sendMailForNewAccount;
