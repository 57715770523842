import SvgArrow from "../elements/SvgArrow";
import dateFormat from "../../utils/dateFormat";
import { NavLink } from "react-router-dom";
import isEmpty from "../../utils/isEmpty";

const SmallBlockBlog = ({ images, data }) => {
  return (
    <div className="articleBlog">
      {!isEmpty(images) && (
        <div className="articleBlog__img">
          <img
            src={images[0].addressURL ? images[0].addressURL : ""}
            alt={images[0].description ? images[0].description : ""}
          />
        </div>
      )}
      <div className="articleBlog__text">
        <span className="font__date font__date--red">
          Edité le {dateFormat(data.updatedAt)}
        </span>
        <h3 className="font__question">{data.pageName}</h3>
        <p className="font__simpleText">{data.intro}</p>
        <NavLink to={"/blog/article/" + data.id}>
          <button className="font__btn btn btn__simple btn__simple--red">
            Lire l'article
            <SvgArrow className="btn__arrow--red" />
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default SmallBlockBlog;
