import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getRecycles = createAsyncThunk(
  "Recycles/getRecycles",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/recycle").then(
      (res) => res.data
    );
  }
);

export const addNewRecycle = createAsyncThunk(
  "Recycles/addNewRecycle",
  async (data) => {
    return await Axios.put(process.env.REACT_APP_API_URL + "/recycle/add", data)
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const getClientRecycles = createAsyncThunk(
  "Recycles/getClientRecycles",
  async (id) => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/recycle/client/" + id
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const patchOneRecycle = createAsyncThunk(
  "Recycles/patchOneRecycle",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/recycle/modify/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const deleteOneRecycle = createAsyncThunk(
  "Recycles/deleteOneRecycle",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/recycle/delete/" + id
    ).then((res) => res && { data: id });
  }
);

//*mise à la poubelle : softDelete
export const trashOneRecycle = createAsyncThunk(
  "Recycles/trashOneRecycle",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/recycle/trash/" + id
    ).then((res) => res && { data: id });
  }
);

const recycleSlice = createSlice({
  name: "Recycles",
  initialState: { recycles: null, status: null, loading: false, error: "" },

  reducers: {
    deleteRecycle: (state, action) => {},
  },

  extraReducers: {
    [getRecycles.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getRecycles.fulfilled]: (state, { payload }) => {
      state.recycles = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getRecycles.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getClientRecycles.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getClientRecycles.fulfilled]: (state, { payload }) => {
      state.recycles = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getClientRecycles.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewRecycle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewRecycle.fulfilled]: (state, { payload }) => {
      let newRecycle = { ...payload.data };
      state.recycles.data = [...state.recycles.data, newRecycle];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewRecycle.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [deleteOneRecycle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteOneRecycle.fulfilled]: (state, { payload }) => {
      state.recycles.data = state.recycles.data.filter(
        (recycle) => recycle.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteOneRecycle.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [trashOneRecycle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [trashOneRecycle.fulfilled]: (state, { payload }) => {
      state.recycles.data = state.recycles.data.filter(
        (recycle) => recycle.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [trashOneRecycle.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchOneRecycle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchOneRecycle.fulfilled]: (state, { payload }) => {
      state.recycles.data = state.recycles.data.map((recycles) => {
        if (recycles.id === payload.data.id) {
          return {
            ...recycles,
            ...payload.data,
          };
        } else return recycles;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchOneRecycle.rejected]: (state, action) => {
      state.status = "failed";
      state.recycles = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteRecycle } = recycleSlice.actions;

export default recycleSlice;
