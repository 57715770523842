import WidgetContainer from "../../components/widgets/WidgetContainer";
import MySpydeAgency from "../../components/blocks/MySpydeAgency";
import MySpydeContact from "../../components/blocks/MySpydeContact";
import MyContract from "../../components/blocks/MyContract";

const ClientContract = () => {
  return (
    <div className="configGrid">
      <section className="widget widget--half">
        <WidgetContainer icon="options-outline" title="Mon contrat" order={3}>
          <MyContract />
        </WidgetContainer>

        <WidgetContainer
          icon="person-outline"
          title="Mon contact Spyde"
          order={2}
        >
          <MySpydeContact />
        </WidgetContainer>
        <WidgetContainer
          //key="1"
          icon="business-outline"
          title="Mon agence Spyde"
          order={1}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MySpydeAgency />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default ClientContract;
