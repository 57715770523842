import { useState, useEffect } from "react";
import CheckBox from "../elements/CheckBox";
import CreateClient from "./CreateClient";
import FindClient from "./FindClient";
import FormCreateContact from "./FormCreateContact";

const NewClient = () => {
  const [notNew, setNotNew] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [emailContact, setEmailContact] = useState(null);

  const setDataClient = (data) => {
    setSelectedClient(data);
  };

  const setDataContact = (data, email) => {
    setSelectedContact(data);
    setEmailContact(email);
  };

  useEffect(() => {
    setNotNew(false);
    setIsCreate(false);
  }, []);

  useEffect(() => {
    if (selectedClient && selectedContact) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [selectedClient, selectedContact]);

  return (
    <>
      {!isCreate ? (
        <div className="newClientForm">
          {!selectedClient ? (
            <CheckBox
              order="001"
              text="L'entreprise existe déjà"
              afterDisable={() => setNotNew(false)}
              afterSelect={() => setNotNew(true)}
            />
          ) : (
            ""
          )}
          {notNew ? (
            <FindClient setDataClient={setDataClient} />
          ) : (
            <CreateClient setDataClient={setDataClient} />
          )}
          {selectedClient ? (
            <FormCreateContact
              selectedClient={selectedClient}
              setDataContact={setDataContact}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="responseContainer responseContainer__split">
          <div className="responseContainer__split--part pad--1">
            <h3 className="responseContainer__success">
              Enregistrement réussi !
            </h3>
          </div>

          {/* <div className="responseContainer__split--part">
            <h3 className="responseContainer__success">Client :</h3>
            <div className="responseContainer__block">
              <p className="responseContainer__title">Raison sociale :</p>
              <p className="responseContainer__result">
                {selectedClient.clientName}
              </p>
            </div>
            <div className="responseContainer__block">
              <p className="responseContainer__title">Nom du site :</p>
              <p className="responseContainer__result">
                {selectedClient.agency}
              </p>
            </div>

            <div className="responseContainer__block">
              <p className="responseContainer__title">Adresse de collecte :</p>
              <p className="responseContainer__result">
                {selectedClient.address}
              </p>
            </div>
          </div>
          <div className="responseContainer__split--part">
            <h3 className="responseContainer__success">Contact :</h3>
            <div className="responseContainer__block">
              <p className="responseContainer__title">Nom et prénom :</p>
              <p className="responseContainer__result">
                {selectedContact.firstname + "  " + selectedContact.lastname}
              </p>
            </div>
            <div className="responseContainer__block">
              <p className="responseContainer__title">Tel :</p>
              <p className="responseContainer__result">
                {selectedContact.phone}
              </p>
            </div>

            <div className="responseContainer__block">
              <p className="responseContainer__title">Adresse e-mail :</p>
              <p className="responseContainer__result">{emailContact}</p>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default NewClient;
