import { useState, useEffect } from "react";
import { phoneNumber } from "../../utils/phoneNumber";
import { useSelector, useDispatch } from "react-redux";
import { getMyContract } from "../../feature/contract.slice";
import CounterBalance from "../widgets/CounterBalance";
import LoaderS from "../blocks/LoaderS";
import dateFormat from "../../utils/dateFormat";

const MyContract = () => {
  const userData = useSelector((state) => state.user.user);
  const contractData = useSelector((state) => state.contract.contract);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyContract(userData.contact.id));
  }, []);

  return contractData?.data ? (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Nom :</label>

            <p className="responseContainer__result">
              {contractData.data.contractName}
            </p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Numéro :</label>

            <p className="responseContainer__result">
              {contractData.data.contractNumber}
            </p>
          </div>

          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Début du contrat :</label>
            <p className="responseContainer__result">
              {dateFormat(contractData.data.startDate)}
            </p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Durée du contrat :</label>
            <p className="responseContainer__result">
              {contractData.data.duration + " mois"}
            </p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Fréquences des collectes :</label>
            <p className="responseContainer__result">
              {contractData.data.frequency > 1
                ? contractData.data.frequency + " jours"
                : contractData.data.frequency + " jour"}
            </p>
          </div>
        </form>
      </div>
      <div className="buttonsContainer">
        <p className="font__italic font__italic--red">
          Pour toutes demandes de modification de ce contrat, veuillez contacter
          votre commercial Spyde.
        </p>
      </div>
    </>
  ) : (
    <div className="loaderPosition">
      <LoaderS color="blue" />
    </div>
  );
};

export default MyContract;
