import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContacts } from "../../feature/contact.slice";
import { selectLoading } from "../../feature/loading.slice";
import FormClient from "./FormClient";
import FormContacts from "./FormContacts";
import FormMyCommercial from "./FormMyCommercial";

const DetailClient = ({ clientSelected }) => {
  const dispatch = useDispatch();
  let idClient = clientSelected?.id.split("Lign")[1] || "";
  const clientData = useSelector((state) => state.client.client);
  const contactData = useSelector((state) => state.contact.contact);

  const [dataToShow, setDataToShow] = useState("");
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      if (idClient === "") {
        setDataToShow("");
      } else {
        clientData &&
          clientData.data.map((client) => {
            if (client.id == idClient) {
              setDataToShow(client);
            }
          });
      }
    }
  }, [clientData.data, idClient, isLoading]);

  return (
    <div className="allclients__details">
      <FormClient dataToShow={dataToShow} />
      {dataToShow?.Spyder ? (
        <FormMyCommercial dataToShow={dataToShow} />
      ) : (
        <div>Inscription non finalisée !!!</div>
      )}
      {contactData?.data ? (
        contactData.data.map((contact, index) => {
          if (contact.client_id === dataToShow.id)
            return (
              <React.Fragment key={index}>
                <FormContacts dataToShow={dataToShow} contact={contact} />
              </React.Fragment>
            );
        })
      ) : (
        <div>Aucun contact dans la base de données ... </div>
      )}
    </div>
  );
};

export default DetailClient;
