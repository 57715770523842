import ComboTtBtn from "../blocks/ComboTtBtn";
import SvgH1 from "../elements/SvgH1";

const SectHomeA = () => {
  return (
    <section className="sectHomeA">
      <ComboTtBtn
        titleA="Collecter,"
        titleB="Recycler, Valoriser,"
        titleC="C'est notre métier !"
        paraA="Spyde est une société de collecte et de valorisation des déchets."
        paraC="Un engagement écologique fort et des actions concrètes, pour un monde plus propre."
        paraB=""
        textColor="light"
        btnA="Prendre rendez-vous"
        goToA="/#sectRdv"
        // btnB="Faire une estimation"
        //btnColorB="light"
      />
      <div className="sectHomeA__picture">
        <SvgH1 className="sectHomeA__picture--dim" />
      </div>
    </section>
  );
};
export default SectHomeA;
