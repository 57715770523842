import ComboTtBtn from "../components/blocks/ComboTtBtn";
import contactTeam from "../img/contactTeam.svg";
import studies from "../img/studies.svg";
import nature from "../img/nature.svg";
import map from "../img/map4.svg";
import bg_spyde from "../img/bg_Spyde_contact.svg";

const Contact = () => {
  return (
    <>
      <section className="aPropos">
        <h1 className="aPropos__title">À Propos</h1>
        <div className="aPropos__content">
          <ComboTtBtn
            titleA="Qui est Spyde ?"
            titleB=""
            titleC=""
            paraA="Spyde est une entreprise spécialisée dans la gestion des déchets pour les entreprises en région Nouvelle-Aquitaine. Nous proposons des solutions simples et efficaces pour la collecte, le tri et le recyclage des déchets, afin de faciliter la vie des entreprises qui souhaitent améliorer leur impact environnemental."
            paraB=""
            paraC="Avec Spyde, trier en entreprise n'a jamais été aussi simple ! Nos services comprennent la collecte des déchets, le tri et le recyclage, ainsi que la formation et l'accompagnement des entreprises dans leur démarche RSE."
            textColor="dark"
            //btnA="Prendre rendez-vous"
            //goToA="/#sectRdv"
            // btnB="Faire une estimation"
            //btnColorB="light"
          />
          <div className="aPropos__picture">
            <img src={studies} alt="illustration de l'équipe" />
          </div>
        </div>
      </section>
      <section className="nous">
        <h2 className="nous__title">Qui sommes-nous ?</h2>
        <div className="nous__content">
          <div className="nous__picture">
            <img src={contactTeam} alt="illustration de l'équipe" />
          </div>
          <ComboTtBtn
            titleA="L'équipe Spyde"
            titleB=""
            titleC=""
            paraA="Spyde, a été fondée par deux amis, Yanis et Damien, animés par une même passion pour l'environnement et l'entrepreneuriat. Nous sommes spécialisés dans la gestion des déchets pour les entreprises en région Nouvelle-Aquitaine, et nous avons à cœur de proposer des solutions simples et efficaces pour le tri et le recyclage des déchets."
            paraB=""
            paraC="Nous croyons que chaque entreprise peut contribuer à un monde plus propre et plus durable, et c'est ce qui nous motive chaque jour. Nous avons également développé une plateforme innovante qui permet à nos clients de suivre en temps réel la quantité de déchets collectés chez eux par catégorie, ainsi que la destination de leurs déchets."
            textColor="dark"
            //btnA="Prendre rendez-vous"
            //goToA="/#sectRdv"
            // btnB="Faire une estimation"
            //btnColorB="light"
          />
        </div>
        <div className="nous__content">
          <ComboTtBtn
            titleA="L'esprit de Spyde"
            titleB=""
            titleC=""
            paraA="L'esprit Spyde, c'est avant tout celui de l'engagement et de la simplicité. Nous sommes une entreprise qui se veut proche de ses clients, à l'écoute de leurs besoins et de leurs préoccupations environnementales. Nous mettons notre expertise à leur disposition pour leur proposer des solutions adaptées et efficaces, tout en leur offrant une expérience de tri en entreprise simple et agréable. Nous sommes également animés par une forte volonté de transparence et de traçabilité."
            paraB=""
            paraC="En somme, chez Spyde, nous sommes engagés pour l'environnement, simples dans notre approche, proches de nos clients et transparents dans notre gestion des déchets."
            textColor="dark"
            //btnA="Prendre rendez-vous"
            //goToA="/#sectRdv"
            // btnB="Faire une estimation"
            //btnColorB="light"
          />
          <div className="nous__picture">
            <img src={nature} alt="illustration de l'équipe" />
          </div>
        </div>
      </section>
      <section className="aPropos">
        <h2 className="aPropos__title">Nous contacter</h2>
        <div className="aPropos__content aPropos__content--shadow">
          <div className="aPropos__picture">
            <img src={map} alt="illustration de l'équipe" />
          </div>
          <div className="aPropos__pictureBis">
            <img src={bg_spyde} alt="illustration de l'équipe" />
            <div className="aPropos__pictureBis__textContainer">
              <div>
                <h3 className="subLign font__question">Service Commercial</h3>
                <p className="font__italic pad--1">
                  Un de nos commerciaux se fera un plaisir de vous accueillir
                  par téléphone :
                </p>
                <p className="font__paragraphe textCenter">
                  06.52.78.28.84 ou 07.82.20.75.32
                </p>
              </div>
              <div>
                <h3 className="subLign font__question">
                  Courrier Electronique
                </h3>
                <p className="font__italic pad--1">
                  Vous pouvez nous adresser un mail, en précisant vos besoins à
                  l’adresse mail suivante :
                </p>
                <p className="font__paragraphe textCenter">
                  service-commercial@spyde.fr
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
