import { useState } from "react";

const BlockFAQ =(props)=>{

    const [open, setOpen]= useState(false);

    return (
        <div className={'blockFaq blockFaq--' + props.line}>
            <div className="blockFaq__id">
                <span className="font__blockPoint">{props.number}</span>
            </div>
            <div className="blockFaq__QR" onClick={()=>setOpen(!open)}>
                <h3 className="font__question">{props.question}</h3>
                <p className={open ? 'font__simpleText' : 'font__simpleText noAnswer' }>{props.response}</p>
            </div>
            <div className={open ? 'blockFaq__cross blockFaq__cross--rotate' : 'blockFaq__cross' } onClick={()=>setOpen(!open)}></div>
        </div>
    )
}

export default BlockFAQ;