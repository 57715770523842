import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../services/useAuth";
import DashButton from "../elements/DashButton";
import InputForm from "../elements/InputForm";
import ImageForm from "./ImageForm";
import { patchClient } from "../../feature/client.slice";
//import { selectLoading, toggleLoading } from "../../feature/loading.slice";
import dateFormat from "../../utils/dateFormat";
import { toast } from "react-toastify";
import valueOrReplace from "../../utils/valueOrReplace";
import IsItMyClient from "../../services/contexts/IsItMyClient";

import Compressor from "compressorjs";

import {
  infobulle_agency,
  infobulle_clientname,
  infobulle_zipCode,
  infobulle_paragraphe,
  infobulle_address,
  infobulle_address_bill,
  infobulle_siret,
} from "../../utils/infoBulleText";

import {
  CLIENT_REGEX,
  ZIPCODE_REGEX,
  MESSAGE_REGEX,
  SIRET_REGEX,
  SITE_REGEX,
} from "../../utils/regex";

import TitleBlockForm from "./TitleBlockForm";
import InputAddress from "../elements/InputAddress";
//import slowCode from "../../services/helpers/slowCode";
import DirectorAccess from "../../services/contexts/DirectorAccess";

const FormClient = ({ dataToShow }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  const [isAuthorizedForModification, setIsAuthorizedForModification] =
    useState(false);
  const [isDirecteur, setIsDirecteur] = useState(false);
  const [isModify, setIsModify] = useState(false);

  const [validClientname, setValidClientname] = useState(false);
  const [clientname, setClientname] = useState("");

  const [validAgency, setValidAgency] = useState(false);
  const [agency, setAgency] = useState("");

  const [validSiret, setValidSiret] = useState(false);
  const [siret, setSiret] = useState("");

  const [validZipcode, setValidZipcode] = useState(false);
  const [zipcode, setZipcode] = useState("");

  const [validInfoForSpyde, setValidInfoForSpyde] = useState(false);
  const [infoForSpyde, setInfoForSpyde] = useState("");

  const [validAddress, setValidAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [addressBill, setAddressBill] = useState("");

  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLong, setSelectedLong] = useState("");

  // for image : To MODIFY
  const [file, setFile] = useState();
  const [pictureUrl, setPictureUrl] = useState(null);

  const makeCompressor = (file, options) => {
    return new Compressor(file, options);
  };

  const handlePicture = (e) => {
    makeCompressor(e.target.files[0], {
      maxWidth: 200,
      quality: 0.8,
      success(imgBlob) {
        setPictureUrl(URL.createObjectURL(imgBlob));
        setFile(imgBlob);
      },
      error(err) {
        console.log("err :  handlePicture");
      },
    });
  };

  //send form :
  const handleFormData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    //required :
    formData.append(
      "clientName",
      valueOrReplace(clientname, dataToShow.clientName)
    );
    formData.append("agency", valueOrReplace(agency, dataToShow.agency));
    formData.append("codeEts", valueOrReplace(zipcode, dataToShow.codeEts));
    formData.append("address", valueOrReplace(address, dataToShow.address));
    formData.append("lat", valueOrReplace(selectedLat, dataToShow.lat));
    formData.append("long", valueOrReplace(selectedLong, dataToShow.long));
    formData.append("spyder_login_id", `${dataToShow.spyder_login_id}`);

    //not required :
    siret && formData.append("siretNumber", `${siret}`);
    infoForSpyde && formData.append("spydeMessage", `${infoForSpyde}`);
    addressBill && formData.append("addressFact", `${addressBill}`);

    if (file) {
      formData.append("file", file);
      if (userData["SpyderAccount.addressURL"]) {
        formData.append(
          "imageToDelete",
          `${userData["SpyderAccount.addressURL"]}`
        );
      }
    }
    try {
      let dataAndId = { data: formData, id: dataToShow.id };
      dispatch(patchClient(dataAndId));
      cancelModify();
      setIsModify(false);
    } catch (err) {
      toast.error("Erreur lors de la mise à jour");
      cancelModify();
    }
  };

  // Add for modification from children
  const changeAddress = (e) => {
    setAddress(e);
  };

  const changeAddressBill = (e) => {
    setAddressBill(e);
  };

  const changePosition = (lat, long) => {
    setSelectedLong(long);
    setSelectedLat(lat);
  };

  const changeZipcode = (e) => {
    setZipcode(e.target.value);
  };

  const changeClientname = (e) => {
    setClientname(e.target.value);
  };

  const changeAgency = (e) => {
    setAgency(e.target.value);
  };

  const changeSiret = (e) => {
    setSiret(e.target.value);
  };

  const changeInfoForSpyde = (e) => {
    setInfoForSpyde(e.target.value);
  };

  const cancelModify = () => {
    setIsModify(false);
    setFile("");
    setIsModify(false);
    setValidClientname(false);
    setClientname("");
    setValidAgency(false);
    setAgency("");
    setValidSiret(false);
    setSiret("");
    setValidZipcode(false);
    setZipcode("");
    setValidInfoForSpyde(false);
    setInfoForSpyde("");
    setValidAddress(false);
    setAddress("");
    setAddressBill("");
    setSelectedLat("");
    setSelectedLong("");
  };

  // Validation Data :
  useEffect(() => {
    if (selectedLat && selectedLong) {
      setValidAddress(true);
    } else {
      setValidAddress(false);
    }
  }, [address]);

  useEffect(() => {
    setValidZipcode(ZIPCODE_REGEX.test(zipcode));
  }, [zipcode]);

  useEffect(() => {
    setValidClientname(CLIENT_REGEX.test(clientname));
  }, [clientname]);

  useEffect(() => {
    setValidAgency(SITE_REGEX.test(agency));
  }, [agency]);

  useEffect(() => {
    setValidSiret(SIRET_REGEX.test(siret));
  }, [siret]);

  useEffect(() => {
    setValidInfoForSpyde(MESSAGE_REGEX.test(infoForSpyde));
  }, [infoForSpyde]);

  //check for authorization :
  useEffect(() => {
    //reset details :
    cancelModify();
    //check authorization :
    setIsAuthorizedForModification(
      IsItMyClient(auth, dataToShow, userData) || DirectorAccess(auth)
    );
    //check if rules Directeur ok :
    setIsDirecteur(DirectorAccess(auth));
  }, [dataToShow]);

  return dataToShow ? (
    <div className="detail">
      <form className="dashform__form">
        <div className="dashform--row">
          <TitleBlockForm
            isModify={isModify}
            isAuthorized={isAuthorizedForModification}
            title="Détail de l'entreprise"
            changeTitle="Modification de l'entreprise"
          />
          <ImageForm
            logo={true}
            htmlfor={"Logo_" + dataToShow.clientName.split(" ").join("")}
            accept=".jpg, .jpeg, .png"
            alt={"Logo de l'entreprise " + dataToShow.clientName}
            inputName="file"
            setData={handlePicture}
            defaultValue={dataToShow.addressURL}
            newValue={pictureUrl}
            isModify={isModify}
            defaultImage="/img/LogoEts.png"
            isAuthorized={isAuthorizedForModification}
            textOverlay="Cliquer pour modifier"
          />
        </div>
        <InputForm
          label="Raison sociale :"
          htmlfor="clientname"
          setData={changeClientname}
          info={infobulle_clientname}
          defaultValue={dataToShow.clientName}
          placeholder="Changer le nom du client ..."
          validation={validClientname}
          newValue={clientname}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.clientName}
          required={true}
        />
        <InputForm
          label="Agence / site :"
          htmlfor="agency"
          setData={changeAgency}
          info={infobulle_agency}
          defaultValue={dataToShow.agency}
          placeholder="Changer le nom du client ..."
          validation={validAgency}
          newValue={agency}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.agency}
          required={true}
        />
        <InputAddress
          label="Adresse de collecte :"
          htmlfor="address"
          setAddress={changeAddress}
          setPosition={changePosition}
          info={infobulle_address}
          defaultValue={dataToShow.address}
          placeholder="Sélectionner le lieu de collecte ..."
          validation={validAddress}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.address}
          required={false}
        />
        <InputAddress
          label="Adresse de facturation :"
          htmlfor="addressFact"
          setAddress={changeAddressBill}
          //setPosition={changePosition}
          info={infobulle_address_bill}
          defaultValue={dataToShow.addressFact}
          placeholder="Sélectionner le lieu de facturation ..."
          //validation={validAgency}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.addressFact}
          required={false}
        />
        <InputForm
          label="N° de siret :"
          htmlfor="siret"
          setData={changeSiret}
          info={infobulle_siret}
          defaultValue={dataToShow.siretNumber}
          placeholder="Changer le nom du client ..."
          validation={validSiret}
          newValue={siret}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.siretNumber}
          required={false}
        />
        <InputForm
          label="Code entreprise :"
          labelBis="Code postal :"
          htmlfor="zipcode"
          setData={changeZipcode}
          info={infobulle_zipCode}
          defaultValue={dataToShow.codeEts}
          placeholder="Changer le code postal ..."
          validation={validZipcode}
          newValue={zipcode}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.codeEts}
          required={true}
        />
        <InputForm
          label="Informations pour Spyde :"
          type="textarea"
          htmlfor="infoForSpyde"
          setData={changeInfoForSpyde}
          info={infobulle_paragraphe}
          defaultValue={dataToShow.spydeMessage}
          placeholder="Laisser un mémo, avec des informations pour les équipes. Exemple: Taille de l'entreprise, Accès réglementé, etc ..."
          validation={validInfoForSpyde}
          newValue={infoForSpyde}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dataToShow.spydeMessage}
          required={false}
        />

        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Création du compte :</label>
          <p className="responseContainer__result">
            {dateFormat(dataToShow.createdAt)}
          </p>
        </div>
      </form>
      <div className="buttonsContainer">
        {(isAuthorizedForModification || isDirecteur) && (
          <>
            {isModify ? (
              <>
                <div className="buttonContainer--small">
                  {file ||
                  (validClientname &&
                    clientname &&
                    clientname !== dataToShow.clientName) ||
                  (validAgency && agency && agency !== dataToShow.agency) ||
                  (validSiret && siret && siret !== dataToShow.siretNumber) ||
                  (validZipcode && zipcode && zipcode !== dataToShow.codeEts) ||
                  (validInfoForSpyde &&
                    infoForSpyde &&
                    infoForSpyde !== dataToShow.spydeMessage) ||
                  (validAddress && address && address !== dataToShow.address) ||
                  (addressBill && addressBill !== dataToShow.addressFact) ? (
                    <DashButton
                      action="action"
                      text="Valider"
                      onClick={(e) => handleFormData(e)}
                    />
                  ) : (
                    <DashButton
                      action="inactive"
                      text="Modifier le formulaire"
                    />
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    action="cancel"
                    text="Annuler"
                    onClick={() => cancelModify()}
                  />
                </div>
              </>
            ) : (
              <div className="buttonContainer--small">
                <DashButton
                  action="modify"
                  text="Modifier"
                  onClick={() => setIsModify(true)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="detail">"Pas de data à afficher"</div>
  );
};

export default FormClient;
