import { useEffect } from "react";
import SectBlogLarge from "../components/layouts/SectBlogLarge";
import SectBlogInfinite from "../components/layouts/SectBlogInfinite";
import SvgBlog from "../components/elements/SvgBlog";
import { useDispatch } from "react-redux";
import { getPublishPagesBlog } from "../feature/publishPageBlog.slice";
import { getPublishImagesBlog } from "../feature/publishImagesBlog.slice";

const Blog = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(getPublishPagesBlog());
    dispatch(getPublishImagesBlog());
  }, []);

  return (
    <div className="blog">
      <section className="blog__title">
        <div className="blog__picture">
          <SvgBlog />
        </div>
      </section>
      <div className="blog__text">
        <h1 className="font__bigTitle">Toute l'Actualité de Spyde</h1>
      </div>
      <SectBlogLarge />
      <SectBlogInfinite />
    </div>
  );
};

export default Blog;
