import React from "react";
import dateFormat from "../../utils/dateFormat";
import { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import ImageForm from "../blocks/ImageForm";
import Compressor from "compressorjs";
import DashButton from "../elements/DashButton";
import isEmpty from "../../utils/isEmpty";
import {
  deleteOnePage,
  getPagesBlog,
  patchArticle,
  togglePublishPage,
} from "../../feature/pageBlog.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewImageToBlog,
  deleteOneImage,
  getImagesBlog,
} from "../../feature/imagesBlog.slice";
import { toast } from "react-toastify";
import DirectorAccess from "../../services/contexts/DirectorAccess";
import useAuth from "../../services/useAuth";

export const BlogEditArticle = ({ pageData, clearSelectedPage }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const imagesBlogData = useSelector((state) => state.imagesBlog.imagesBlog);
  const [title, setTitle] = useState(pageData.pageName);
  const [intro, setIntro] = useState(pageData.intro);
  const [title_A, setTitle_A] = useState(pageData.title_A);
  const [title_B, setTitle_B] = useState(pageData.title_B);
  const [title_C, setTitle_C] = useState(pageData.title_C);
  const [paragrapheA, setParagrapheA] = useState(pageData.paragraph_A);
  const [paragrapheB, setParagrapheB] = useState(pageData.paragraph_B);
  const [paragrapheC, setParagrapheC] = useState(pageData.paragraph_C);
  const [link_name_A, setLink_name_A] = useState(pageData.link_name_A);
  const [link_name_B, setLink_name_B] = useState(pageData.link_name_B);
  const [link_name_C, setLink_name_C] = useState(pageData.link_name_C);
  const [link_url_A, setLink_url_A] = useState(pageData.link_url_A);
  const [link_url_B, setLink_url_B] = useState(pageData.link_url_B);
  const [link_url_C, setLink_url_C] = useState(pageData.link_url_C);
  const [fileA, setFileA] = useState();
  const [pictureUrlA, setPictureUrlA] = useState(null);
  // const [fileB, setFileB] = useState();
  // const [pictureUrlB, setPictureUrlB] = useState(null);
  const [validModification, setValidModification] = useState(false);
  const [validPublish, setValidPublish] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [detailPicture, setDetailPicture] = useState("");
  const [descriptionPicture, setDescriptionPicture] = useState("");
  const [isDirecteur, setIsDirecteur] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    setIsDirecteur(DirectorAccess(auth));
  }, []);

  useEffect(() => {
    dispatch(getImagesBlog());
  }, [dispatch]);

  useEffect(() => {
    setShowPublish(false);
    if (pageData.isPublished) {
      setShowPublish(true);
    } else {
      setShowPublish(false);
    }
  }, [pageData]);

  const changeTitle = (e) => {
    setTitle(e.target.value);
  };
  const changeIntro = (e) => {
    setIntro(e.target.value);
  };
  const changeTitle_A = (e) => {
    setTitle_A(e.target.value);
  };
  const changeParagrapheA = (e) => {
    setParagrapheA(e.target.value);
  };
  const changeTitle_B = (e) => {
    setTitle_B(e.target.value);
  };
  const changeParagrapheB = (e) => {
    setParagrapheB(e.target.value);
  };
  const changeTitle_C = (e) => {
    setTitle_C(e.target.value);
  };
  const changeParagrapheC = (e) => {
    setParagrapheC(e.target.value);
  };
  const changeLinkNameA = (e) => {
    setLink_name_A(e.target.value);
  };
  const changeLinkNameB = (e) => {
    setLink_name_B(e.target.value);
  };
  const changeLinkNameC = (e) => {
    setLink_name_C(e.target.value);
  };
  const changeLinkUrlA = (e) => {
    setLink_url_A(e.target.value);
  };
  const changeLinkUrlB = (e) => {
    setLink_url_B(e.target.value);
  };
  const changeLinkUrlC = (e) => {
    setLink_url_C(e.target.value);
  };
  const changeDetail = (e) => {
    setDetailPicture(e.target.value);
  };
  const changeDescription = (e) => {
    setDescriptionPicture(e.target.value);
  };

  const makeCompressor = (file, options) => {
    return new Compressor(file, options);
  };

  const handlePictureA = (e) => {
    makeCompressor(e.target.files[0], {
      maxWidth: 1000,
      quality: 0.9,
      success(imgBlob) {
        setPictureUrlA(URL.createObjectURL(imgBlob));
        setFileA(imgBlob);
      },
      error(err) {
        console.log("err : MySpydeInfos");
      },
    });
  };

  useEffect(() => {
    setTitle(pageData.pageName);
    setIntro(pageData.intro);
    setTitle_A(pageData.title_A);
    setParagrapheA(pageData.paragraph_A);
    setTitle_B(pageData.title_B);
    setParagrapheB(pageData.paragraph_B);
    setTitle_C(pageData.title_C);
    setParagrapheC(pageData.paragraph_C);
    setLink_name_A(pageData.link_name_A);
    setLink_name_B(pageData.link_name_B);
    setLink_name_C(pageData.link_name_C);
    setLink_url_A(pageData.link_url_A);
  }, [pageData]);

  useEffect(() => {
    if (!isEmpty(title) && !isEmpty(intro)) {
      setValidModification(true);
    } else {
      setValidModification(false);
    }
  }, [title, intro]);

  useEffect(() => {
    if (!isEmpty(pageData.pageName) && !isEmpty(pageData.intro)) {
      setValidPublish(true);
    } else {
      setValidPublish(false);
    }
  }, [pageData]);

  const saveArticle = async (e) => {
    e.preventDefault();
    try {
      const data = {
        pageName: title,
        intro,
        title_A,
        title_B,
        title_C,
        paragraph_A: paragrapheA,
        paragraph_B: paragrapheB,
        paragraph_C: paragrapheC,
        link_name_A,
        link_name_B,
        link_name_C,
        link_url_A,
        link_url_B,
        link_url_C,
        spyder_id: userData.id,
        isPublished: false,
      };

      const dataAndId = {
        data: data,
        id: pageData.id,
      };

      await dispatch(patchArticle(dataAndId));
      await dispatch(getPagesBlog());
    } catch (err) {
      console.log("error : patchArticle");
    }
    //
  };

  const deleteArticle = async (e, id) => {
    e.preventDefault();
    await dispatch(deleteOnePage(id));
  };

  const togglePublishThisArticle = async (e, id) => {
    e.preventDefault();
    try {
      const data = {
        pageName: pageData.pageName,
        intro: pageData.intro,
        title_A: pageData.title_A,
        title_B: pageData.title_B,
        title_C: pageData.title_C,
        paragraph_A: pageData.paragraph_A,
        paragraph_B: pageData.paragraph_B,
        paragraph_C: pageData.paragraph_C,
        link_name_A: pageData.link_name_A,
        link_name_B: pageData.link_name_B,
        link_name_C: pageData.link_name_C,
        link_url_A: pageData.link_url_A,
        link_url_B: pageData.link_url_B,
        link_url_C: pageData.link_url_C,
        spyder_id: pageData.spyder_id,
        isPublished: !pageData.isPublished,
      };

      const dataAndId = {
        data: data,
        id: id,
      };
      await dispatch(togglePublishPage(dataAndId));
      await dispatch(getPagesBlog());
      await clearSelectedPage();
    } catch (err) {
      console.log("error : publishPage");
    }
    //
  };

  const savePicture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    //required :
    formData.append("pageBlog_id", pageData.id);

    //not required :
    detailPicture && formData.append("detail", `${detailPicture}`);
    descriptionPicture &&
      formData.append("description", `${descriptionPicture}`);

    if (fileA) {
      formData.append("file", fileA);
    }
    try {
      let data = formData;
      await dispatch(addNewImageToBlog(data));
      await dispatch(getImagesBlog());
      setDescriptionPicture("");
      setDetailPicture("");
      setFileA();
      setPictureUrlA(null);
    } catch (err) {
      toast.error("Erreur lors de l'enregistrement");
    }
  };

  const deletePicture = async (e, id) => {
    e.preventDefault();
    try {
      await dispatch(deleteOneImage(id));
      await dispatch(getImagesBlog());
    } catch (err) {
      console.log("error deletePicture");
    }
  };

  return (
    <>
      {isDirecteur && (
        <div className="directorControl">
          <h2 className="widgetFont--container">Publication de l'article</h2>

          <div className="blockMessage blockMessage__message">
            {showPublish ? (
              <div className="buttonContainer--center">
                <DashButton
                  //disabled={true}
                  action="cancel"
                  text="Arrêter la diffusion"
                  onClick={(e) => togglePublishThisArticle(e, pageData.id)}
                />
              </div>
            ) : (
              <>
                <div className="buttonContainer--small">
                  {validPublish ? (
                    <DashButton
                      //disabled={true}
                      action="modify"
                      text="Mettre en Ligne"
                      onClick={(e) => togglePublishThisArticle(e, pageData.id)}
                    />
                  ) : (
                    <p className="textCenter font__italic">
                      Minimum requis Titre et Introduction
                    </p>
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    //disabled={true}
                    action="delete"
                    text="Supprimer l'acticle"
                    onClick={(e) => deleteArticle(e, pageData.id)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <h2 className="widgetFont__table--body">Modification de l'article</h2>

      <div className="blockMessage blockMessage__message">
        <div className="blogGrid">
          <div className="blogGrid__element">
            <span className="font__sublineInfo">Numéro de la page :</span>
            <p>{pageData.id && pageData.id}</p>
          </div>
          <div className="blogGrid__element">
            <span className="font__sublineInfo">Statut :</span>
            <p>
              {showPublish ? (
                <span className="inLine">En Ligne</span>
              ) : (
                <span className="offLine">Hors Ligne</span>
              )}
            </p>
          </div>
          <div className="blogGrid__element">
            <span className="font__sublineInfo">Date de création :</span>
            <p>{pageData.createdAt && dateFormat(pageData.createdAt)}</p>
          </div>
          <div className="blogGrid__element">
            <span className="font__sublineInfo">Dernière modification :</span>
            <p>{pageData.updatedAt && dateFormat(pageData.updatedAt)}</p>
          </div>
        </div>
      </div>

      <div className="blogArticle">
        <div className="blogArticle__line">
          <label htmlFor="title" className="font__sublineInfo">
            Titre :
          </label>
          <input id="title" type="text" value={title} onChange={changeTitle} />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="intro" className="font__sublineInfo">
            Introduction :
          </label>
          <TextareaAutosize
            id="intro"
            type="text"
            value={intro ? intro : ""}
            onChange={changeIntro}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="title_A" className="font__sublineInfo">
            Chapitre A :
          </label>
          <input
            id="title_A"
            type="text"
            value={title_A ? title_A : ""}
            onChange={changeTitle_A}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="paragrapheA" className="font__sublineInfo">
            Paragraphe A :
          </label>
          <TextareaAutosize
            id="paragrapheA"
            type="text"
            value={paragrapheA ? paragrapheA : ""}
            onChange={changeParagrapheA}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="title_B" className="font__sublineInfo">
            Chapitre B :
          </label>
          <input
            id="title_B"
            type="text"
            value={title_B ? title_B : ""}
            onChange={changeTitle_B}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="paragrapheB" className="font__sublineInfo">
            Paragraphe B :
          </label>
          <TextareaAutosize
            id="paragrapheB"
            type="text"
            value={paragrapheB ? paragrapheB : ""}
            onChange={changeParagrapheB}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="title_C" className="font__sublineInfo">
            Chapitre C :
          </label>
          <input
            id="title_C"
            type="text"
            value={title_C ? title_C : ""}
            onChange={changeTitle_C}
          />
        </div>
        <div className="blogArticle__line">
          <label htmlFor="paragrapheC" className="font__sublineInfo">
            Paragraphe C :
          </label>
          <TextareaAutosize
            id="paragrapheC"
            type="text"
            value={paragrapheC ? paragrapheC : ""}
            onChange={changeParagrapheC}
          />
        </div>
        <div className="blogArticle__line">
          <div className="blogArticle__link__name">
            <label htmlFor="link_name_A" className="font__sublineInfo">
              Nom du Lien A :
            </label>
            <input
              id="link_name_A"
              type="text"
              value={link_name_A ? link_name_A : ""}
              onChange={changeLinkNameA}
            />
          </div>
          <div className="blogArticle__link__url">
            <label htmlFor="url_A" className="font__sublineInfo">
              Adresse du Lien A :
            </label>
            <input
              id="url_A"
              type="text"
              value={link_url_A ? link_url_A : ""}
              onChange={changeLinkUrlA}
            />
          </div>
        </div>

        <div className="blogArticle__line">
          <div className="blogArticle__link__name">
            <label htmlFor="link_name_B" className="font__sublineInfo">
              Nom du Lien B :
            </label>
            <input
              id="link_name_B"
              type="text"
              value={link_name_B ? link_name_B : ""}
              onChange={changeLinkNameB}
            />
          </div>
          <div className="blogArticle__link__url">
            <label htmlFor="url_B" className="font__sublineInfo">
              Adresse du Lien B :
            </label>
            <input
              id="url_B"
              type="text"
              value={link_url_B ? link_url_B : ""}
              onChange={changeLinkUrlB}
            />
          </div>
        </div>
        <div className="blogArticle__line">
          <div className="blogArticle__link__name">
            <label htmlFor="link_C" className="font__sublineInfo">
              Nom du Lien C :
            </label>
            <input
              id="link_C"
              type="text"
              value={link_name_C ? link_name_C : ""}
              onChange={changeLinkNameC}
            />
          </div>
          <div className="blogArticle__link__url">
            <label htmlFor="url_C" className="font__sublineInfo">
              Adresse du Lien C :
            </label>
            <input
              id="url_C"
              type="text"
              value={link_url_C ? link_url_C : ""}
              onChange={changeLinkUrlC}
            />
          </div>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          {validModification && !pageData.isPublished ? (
            <DashButton
              //disabled={true}
              action="action"
              text="Enregistrer les modifications"
              onClick={(e) => saveArticle(e)}
            />
          ) : pageData.isPublished ? (
            <DashButton
              disabled={true}
              action="inactive"
              text="Modification impossible"
              //onClick={(e) => generateNewPassword(e)}
            />
          ) : (
            <DashButton
              disabled={true}
              action="inactive"
              text="Compléter l'article"
              //onClick={(e) => generateNewPassword(e)}
            />
          )}
        </div>
        <div className="buttonContainer--small">
          <DashButton
            //disabled={disabled}
            action="cancel"
            text="Annuler"
            //onClick={(e) => toggleModal(e)}
          />
        </div>
      </div>
      <div className="blockMessage blockMessage__message">
        <h2 className="widgetFont__table--body">
          Ajouter des images à cet article
        </h2>
      </div>
      <div className="blogGrid blogGrid--selectPicture">
        {!pageData.isPublished && (
          <div className="blogGrid__selectPicture">
            <ImageForm
              logo={true}
              htmlfor="blog_picture_first"
              accept=".jpg, .jpeg, .png"
              alt="Sélectioner une image"
              inputName="file"
              setData={handlePictureA}
              //! modif picture :::
              defaultValue="/img/picture_default.png"
              newValue={pictureUrlA}
              isModify={true}
              defaultImage="/img/picture_default.png"
              isAuthorized={true}
              textOverlay="Sélectioner une image"
            />
            <div className="blogArticle__line">
              <label htmlFor="detail" className="font__sublineInfo">
                Légende :
              </label>
              <input
                id="detail"
                type="text"
                value={detailPicture}
                onChange={changeDetail}
              />
            </div>
            <p className="font__sublineInfo">Option, max. 100 caractères</p>
            <div className="blogArticle__line">
              <label htmlFor="description" className="font__sublineInfo">
                Description :
              </label>
              <input
                id="description"
                type="text"
                value={descriptionPicture}
                onChange={changeDescription}
              />
            </div>
            <p className="font__sublineInfo">Option, max. 100 caractères</p>

            <div className="blogGrid__btn">
              {pictureUrlA ? (
                <DashButton
                  //disabled={true}
                  action="action"
                  text="Ajouter"
                  onClick={(e) => savePicture(e)}
                />
              ) : (
                <DashButton
                  disabled={true}
                  action="inactive"
                  text="Choisir une image"
                  //onClick={(e) => saveArticle(e)}
                />
              )}
            </div>
          </div>
        )}
        {imagesBlogData?.data
          .filter((image) => image.pageBlog_id === pageData.id)
          .map((image, index) => {
            return (
              <div
                key={index}
                className={
                  index === 0
                    ? "blogGrid__elementPicture blogGrid__elementPicture--first"
                    : index === 1
                    ? "blogGrid__elementPicture blogGrid__elementPicture--second"
                    : "blogGrid__elementPicture blogGrid__elementPicture--last"
                }
              >
                <img src={image.addressURL} alt="" />
                <div className="blogGrid__elementPicture__overlay">
                  <p>Légende :</p>
                  <p>{image.detail}</p>
                  <br />
                  <p>Description :</p>
                  <p>{image.description}</p>
                  {!pageData.isPublished && (
                    <div
                      className="blogGrid__elementPicture__overlay--close"
                      onClick={(e) => deletePicture(e, image.id)}
                    >
                      <ion-icon name="trash-outline"></ion-icon>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
