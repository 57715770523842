import { useEffect } from "react";
import ComboTtBtn from "../components/blocks/ComboTtBtn";
//import FlipCard from "../components/blocks/FlipCard";
import Point from "../components/elements/Point";
import LogoServices from "../img/LogoServices.svg";
import Trash_1 from "../img/trash_1.png";
import Trash_2 from "../img/trash_2.png";
import Trash_3 from "../img/trash_3.png";
import Trash_4 from "../img/trash_4.png";
import Trash_5 from "../img/trash_5.png";
import Trash_6 from "../img/trash_6.png";
import Trash_7 from "../img/trash_7.png";
import Trash_8 from "../img/trash_8.png";
import Trash_9 from "../img/trash_9.png";
import Trash_10 from "../img/trash_10.png";
import Trash_11 from "../img/trash_11.png";
import Trash_12 from "../img/trash_12.png";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="services">
      <section className="serviceTitle">
        <div className="serviceTitle__picture">
          <img
            className="serviceTitle__picture--dim"
            src={LogoServices}
            alt="Illustration des sercvices"
          />
        </div>
        <ComboTtBtn
          titleA="Nos Services"
          titleB=""
          titleC="Découvrez ce que Spyde peut faire pour vous !"
          paraA=""
          paraC=""
          paraB=""
          textColor="Dark"
          colorA="red"
        />
      </section>
      <section className="services__partOne">
        <div className="services__grid">
          <div className="services__grid__element">
            <div className="services__point">
              <Point number="1" />
              <h2 className="font__blockPoint">La Collecte</h2>
            </div>
            <p className="lineHeightx2 font__simpleText">
              Nous mettons à disposition des contenants adaptés et résistants
              pour la collecte de vos déchets en fonction de vos besoins et des
              différentes matières. <br />
              <br />
              Notre planification de collecte est personnalisée pour s'adapter à
              votre production de déchets et réduire les coûts de transport et
              les émissions de carbone. <br />
              <br />
              En cas de pic de production de déchets, notre système "Collecte
              Express" permet une intervention rapide en seulement deux clics
              sous 24 à 48 heures.
              <br />
              <br />
              Nous assurons également un suivi rigoureux de vos déchets grâce à
              la fourniture de bons de pesée et d'enlèvement disponible dans
              votre espace personnel.
            </p>
          </div>
          <div className="services__grid__element">
            <div className="services__point">
              <Point number="2" />
              <h2 className="font__blockPoint">Le Recyclage</h2>
            </div>
            <p className="lineHeightx2 font__simpleText">
              Après avoir collecté vos déchets et les avoir transportés jusqu'à
              notre centre de tri, notre équipe dédiée procède à un tri
              complémentaire à la main.
              <br />
              <br />
              Bien que nous effectuions déjà un tri grâce à vos contenants mise
              en place sur votre site, le tri manuel rigoureux que nous
              réalisons permet de mieux séparer les différents produits à
              recycler, ce qui garantit un recyclage optimisé.
              <br />
              <br />
              Nous accordons également une grande importance à l'impact
              environnemental de notre processus de valorisation. C'est pourquoi
              nos filières de valorisation sont locales, ce qui permet de
              réduire les émissions de carbone liées au transport des déchets.
              <br />
              <br />
              Nous travaillons en étroite collaboration avec des partenaires
              locaux pour assurer un recyclage de qualité et contribuer à la
              protection de l'environnement de notre région.
              <br />
              <br />
              Nous stockons ensuite les déchets triés dans des bennes de grand
              volume en attendant de les acheminer vers nos filières de
              valorisation.
            </p>
          </div>
          <div className="services__grid__element">
            <div className="services__point">
              <Point number="3" />
              <h2 className="font__blockPoint">La Valorisation</h2>
            </div>
            <p className="lineHeightx2 font__simpleText">
              La valorisation des déchets est une action importante pour
              l'environnement et l'économie. En effet, non seulement cela permet
              de préserver les ressources naturelles en donnant une seconde vie
              aux matières premières, mais cela permet également de réduire les
              coûts liés à l'enfouissement des déchets en évitant les redevances
              toujours plus élevées imposées par les autorités publiques.
              <br />
              <br />
              Chez Spyde, nous sommes convaincus que la valorisation des déchets
              est une priorité pour toutes les entreprises soucieuses de leur
              impact environnemental et de leur responsabilité sociétale.
              <br />
              <br />
              En outre, la valorisation des déchets offre des avantages sociaux
              et économiques significatifs. La filière du recyclage offre de
              nombreuses opportunités d'emploi et soutient les métiers locaux
              qui ne peuvent être délocalisés. En optant pour une solution de
              gestion des déchets respectueuse de l'environnement, vous
              contribuez ainsi à l'économie locale et à la création d'emplois
              durables.
            </p>
          </div>
          <div className="services__grid__element">
            <div className="services__point">
              <Point number="4" />
              <h2 className="font__blockPoint">Le Cash Back</h2>
            </div>
            <p className="lineHeightx2 font__simpleText">
              Il est important de noter que la gestion efficace des déchets peut
              non seulement aider à préserver l'environnement, mais peut
              également avoir des avantages économiques significatifs.
              <br />
              <br />
              Chez Spyde, nous sommes engagés à travailler en partenariat avec
              nos clients pour créer des solutions durables et rentables pour la
              gestion de leurs déchets.
              <br />
              <br />
              Nous proposons un programme de reprise et de recyclage des déchets
              qui permet à nos clients de recevoir une compensation financière
              en échange de leurs déchets recyclables. Cette compensation est
              calculée en fonction du volume et du type de déchets collectés, et
              peut être utilisée pour aider à couvrir les coûts de gestion des
              déchets.
            </p>
          </div>
          <div className="services__grid__element">
            <div className="services__point">
              <Point number="5" />
              <h2 className="font__blockPoint">La Formation</h2>
            </div>
            <p className="lineHeightx2 font__simpleText">
              En 2022, les chiffres ont révélé que seulement 0,1 % des 986 240
              000 heures de formation effectuées par les Français ont été
              consacrées à l'environnement ou aux métiers associés.
              <br />
              <br />
              Chez Spyde, nous sommes fermement convaincus que pour réussir un
              tri efficace et une valorisation réussie de vos déchets, il est
              crucial que les salariés soient formés et adoptent les bonnes
              pratiques. <br />
              <br />
              Ainsi, nous avons mis en place un système de tri clair et optimal
              pour faciliter l'identification des différentes matières.
              <br />
              <br />
              Nous proposons également une formation courte mais complète sur
              site, dispensée par un de nos formateurs, pour sensibiliser les
              salariés aux enjeux du tri et du recyclage.
            </p>
          </div>
        </div>
      </section>

      <section className="services__partTwo">
        <div className="services__partTwo--title">
          <h2 className="font__blockPoint">Nos Produits</h2>
          <br />
          <p className="font__question">
            Découvrez des produits adaptés à tous vos besoins du quotidien
          </p>
        </div>
        <div className="productContainer">
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_1}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">Pour le bureau ou la salle de pause.</p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_2}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Les stands sont adaptés pour vos halls d’entrée ou vos salles
                  de réunion.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_3}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  A l’esthétique soignée mais résistants pour le bureau ou la
                  salle de pause.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_4}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Corbeille en matière recyclée et recyclable, parfaite pour
                  votre bureau ou salle de pause.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_5}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Stand de 4 bacs en matière souple mais résistante, s’adapte
                  parfaitement à votre salle de pause ou pour un grand bureau.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_6}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Design, les stands sont incontournables pour vos halls
                  d’entrée ou vos salles de réunion.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_7}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Les stands sont idéaux dans vos halls d’entrée ou vos salles
                  de réunion et s’adaptent à tout environnement.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_8}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Style et qualité pour se contenant s’adaptant bien à vos
                  bureau et salle de pause.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_9}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Pour stocker de petits volumes de déchets à l’exterieur.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_10}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Pour stocker de gros volumes de déchets à l’extérieur.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_11}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Rolls conteneurs pour stocker d3E, cartons et même tout autres
                  types de déchets en intérieur.
                </p>
              </div>
            </div>
          </div>
          <div className="productContainer__product">
            <div className="imageProduct">
              <img
                className="imageProduct__img "
                src={Trash_12}
                alt="Une de nos produits"
              />
              <div className="imageProduct__overlay">
                <p className="pad--1">
                  Petite poubelle de bureau pour papier et petits cartons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;

{
  /* <div id="block1" className="controlCard">
          <FlipCard
            number="1"
            imgVariable="collect"
            title="Collecte"
            backTitle="47%"
            knowIt="Seulement des déchets collectés en entreprise sont triés ! Il est vraiment temps de changer ça. "
          />
        </div> */
}
