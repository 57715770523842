import React from "react";

const InputMaterial = ({
  label,
  htmlfor,
  defaultValue,
  image,
  color,
  onChange,
}) => {
  return (
    <div className={"inputMaterial materialColor--" + color}>
      <div className="inputMaterial__image">
        <img src={image} alt=""></img>
      </div>
      <div className="inputMaterial__container">
        <div className="inputMaterial__container__input">
          <input
            type="number"
            name={htmlfor}
            id={htmlfor}
            autoComplete="off"
            //ref={ref}
            onChange={(e) => onChange(e)}
            required={true}
            defaultValue={defaultValue}
            placeholder="Si pas de collecte = 0 g"
          ></input>
          <span>g</span>
        </div>
        <label htmlFor={htmlfor} className="inputMaterial__container__label">
          <p>{label}</p>
        </label>
      </div>
    </div>
  );
};

export default InputMaterial;

// !props.isModify || !props.isAuthorized ? (
//     <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
//       <label>{props.label}</label>
//       <p className="responseContainer__result">{props.dataToShow}</p>
//     </div>
//   ) : (
//     <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
//       <div className="info" data-tooltip={props.info}>
//         <ion-icon icon="information-circle-outline"></ion-icon>
//       </div>
//       <label htmlFor={props.htmlfor}>
//         {props.labelBis ? props.labelBis : props.label}
//       </label>
//       {props.type && props.type === "textarea" ? (
//         <textarea
//           className="preline"
//           type="textarea"
//           id={props.htmlfor}
//           autoComplete="off"
//           onChange={(e) => props.setData(e)}
//           required={props.required}
//           defaultValue={props.defaultValue}
//           placeholder={props.placeholder}
//         ></textarea>
//       ) : (
//         <input
//           type={props.type}
//           id={props.htmlfor}
//           autoComplete="off"
//           onChange={(e) => props.setData(e)}
//           required={props.required}
//           defaultValue={props.defaultValue}
//           placeholder={props.placeholder}
//         ></input>
//       )}

//       {props.validation ? (
//         <div className="check check__valid">
//           <ion-icon icon="checkmark-circle-outline"></ion-icon>
//         </div>
//       ) : props.newValue ? (
//         <div className="check check__invalid">
//           <ion-icon icon="backspace-outline"></ion-icon>
//         </div>
//       ) : (
//         ""
//       )}
//     </div>
//   );
