const PageDash = (props) => {
  return (
    <div className="page">
      <div className="page__title">
        <h1 className="font__dashTitle">{props.title}</h1>
      </div>
      <div className="page__contain">{props.page}</div>
    </div>
  );
};

export default PageDash;
