const Logo =(props)=> {
  return (
    <svg className={props.className}xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 500 500">
		<g>
			<path fillRule="evenodd" clipRule="evenodd" d="M35.748,248.091c0-29.929-0.291-59.871,0.094-89.797
				c0.302-23.651,10.862-42.693,30.931-54.599c52.323-31.049,105.036-61.459,158.072-91.273c19.468-10.952,40.918-11.028,60.392-0.081
				c53.341,29.999,106.357,60.574,158.947,91.855c19.542,11.62,29.921,30.505,30.079,53.444c0.403,60.212,0.371,120.434,0.021,180.656
				c-0.134,23.411-10.746,42.372-30.658,54.193c-52.318,31.051-105.015,61.469-158.022,91.318c-19.669,11.067-41.211,11.067-60.866,0
				c-53.313-30.02-106.369-60.535-158.924-91.875c-19.476-11.606-29.63-30.513-29.958-53.51
				C35.424,308.317,35.748,278.201,35.748,248.091z M458.729,247.967c0-29.591,0.36-59.185-0.134-88.766
				c-0.298-18.218-8.396-33.053-24.218-42.327C382.75,86.657,330.988,56.69,279.027,27.06c-15.506-8.84-32.172-8.907-47.738-0.022
				C179.343,56.69,127.554,86.62,75.927,116.824c-16.021,9.365-24.28,24.327-24.379,42.754c-0.328,59.004-0.306,118.014-0.018,177.014
				c0.089,18.719,8.606,33.743,24.72,43.18c51.461,30.118,103.116,59.924,154.936,89.442c15.719,8.949,32.479,8.859,48.221-0.143
				c51.32-29.331,102.456-58.992,153.571-88.699c16.998-9.876,25.484-25.168,25.682-44.677
				C458.936,306.458,458.729,277.216,458.729,247.967z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M252.734,412.67c-8.493-0.331-16.879-4.61-24.988-9.273
				c-44.461-25.589-133.375-77.173-135.807-78.589c-21.669-11.845-15.135-26.011,0.261-33.972
				c2.632-1.354,27.321-15.787,40.945-23.729c3.812-2.224,7.495-2.371,11.303-0.148c5.684,3.315,11.439,6.531,17.076,9.93
				c4.283,2.579,4.274,4.362-0.143,6.963c-12.109,7.109-24.312,14.062-36.447,21.115c-4.777,2.776-4.798,4.212-0.044,6.951
				c41.129,23.742,82.274,47.441,123.414,71.173c3.812,2.208,7.564,2.189,11.332,0c45.151-26.118,90.304-52.255,135.437-78.415
				c4.583-2.651,4.583-4.067,0.064-6.695c-41.062-23.885-82.134-47.716-123.16-71.651c-4.375-2.562-5.361-3.508-10.026-1
				c-1.176,0.65-8.18,4.717-8.355,4.825c-5.915,3.416-5.915,3.416-9.983-5.296c-4.332-9.63-10.812-23.733-15.067-33.398
				c-3.175-7.144-3.175-7.144,4.474-6.372c24.546,2.461,67.67,8.914,72.244,9.469c3.077,0.377,3.528,0.854,3.741,2.271
				c0.216,1.669-2.602,2.845-2.987,3.059c-0.662,0.376-6.431,3.714-7.282,4.207c-3.606,2.08-3.372,4.287-0.134,6.147
				c17.812,10.374,35.679,20.697,53.527,31.002c20.11,11.604,40.282,23.124,60.372,34.782c10.021,5.814,19.771,14.286,19.636,26.261
				c-0.134,11.749-9.919,19.938-19.813,25.616c-44.641,25.607-89.289,51.223-133.967,76.768
				C270.821,408.976,262.983,412.742,252.734,412.67z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M257.986,83.566c8.484,0.332,16.881,4.619,24.962,9.279
				c44.47,25.596,133.392,77.173,135.813,78.584c21.68,11.844,15.143,26.021-0.25,33.982c-2.638,1.354-27.321,15.786-40.946,23.725
				c-3.812,2.223-7.498,2.372-11.301,0.146c-5.688-3.319-11.436-6.534-17.088-9.928c-4.278-2.58-4.278-4.368,0.146-6.96
				c12.117-7.108,24.315-14.064,36.451-21.119c4.771-2.774,4.807-4.204,0.044-6.951c-41.124-23.737-82.276-47.444-123.401-71.185
				c-3.81-2.199-7.576-2.176-11.345,0.004c-45.146,26.132-90.309,52.26-135.443,78.423c-4.575,2.65-4.564,4.074-0.053,6.702
				c41.043,23.879,82.123,47.712,123.148,71.647c4.391,2.56,5.365,3.505,10.037,1c1.165-0.651,8.158-4.719,8.364-4.83
				c5.902-3.413,5.902-3.413,9.973,5.305c4.334,9.623,10.811,23.733,15.07,33.394c3.166,7.148,3.166,7.148-4.477,6.368
				c-24.543-2.46-67.682-8.92-72.24-9.468c-3.085-0.382-3.542-0.852-3.739-2.264c-0.238-1.673,2.584-2.849,2.981-3.064
				c0.656-0.376,6.417-3.715,7.276-4.201c3.614-2.08,3.376-4.288,0.153-6.148c-17.836-10.382-35.691-20.702-53.552-31.008
				c-20.119-11.601-40.282-23.117-60.361-34.774c-10.019-5.821-19.772-14.292-19.642-26.268c0.143-11.748,9.929-19.939,19.812-25.61
				c44.654-25.612,89.286-51.238,133.977-76.771C239.904,87.271,247.729,83.513,257.986,83.566z"/>
		</g>
	</svg>
  )
}

export default Logo;