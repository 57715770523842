import React, { useState, useRef, useEffect } from "react";
import ReactMapGL, {
  FullscreenControl,
  Source,
  Layer,
  Marker,
} from "react-map-gl";
import useSupercluster from "use-supercluster";
import getCenter from "geolib/es/getCenter";
import "mapbox-gl/dist/mapbox-gl.css";

import MapPopupDemande from "../blocks/MapPopupDemande";
import MapPopupSelected from "../blocks/MapPopupSelected";
//import mapDataTest from "../../dataBeforeServer/mapDataTest.json";
import MapSelection from "./MapSelection";
// import newMap from "../../dataBeforeServer/newMap.json";
import formatLog from "../../utils/formatLog";
import Logo from "../elements/Logo";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getWishes } from "../../feature/wish.slice";
import dayjs from "dayjs";
import dateFormat from "../../utils/dateFormat";
import { getContacts } from "../../feature/contact.slice";

const MapBoxType = ({ data, dataSelected, isPublished, listOfSelected }) => {
  const [responseMap, setResponseMap] = useState(data);
  const [selectedData, setSelectedData] = useState(null);
  const [removeData, setRemoveData] = useState(null);
  const [newMap, setNewMap] = useState([]);
  const [activeBigScreen, setActiveBigScreen] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const userData = useSelector((state) => state.user.user);
  const wishesData = useSelector((state) => state.wishes.wishes);

  const dispatch = useDispatch();

  //? Transform the results objet into the {latitude: xxx, longitude:xxxx} object //
  useEffect(() => {
    setResponseMap(data);
  }, [data]);

  useEffect(() => {
    let getCoordinates = responseMap.map((result) => ({
      longitude: result.long,
      latitude: result.lat,
    }));
    setCoordinates(getCoordinates);
  }, [responseMap]);

  useEffect(() => {
    dataSelected(newMap);
  }, [newMap]);

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getWishes());
  }, [dispatch]);
  //? Transform the results objet into the {latitude: xxx, longitude:xxxx} object //

  let center = getCenter(coordinates) || {
    latitude: 44.836151,
    longitude: -0.580816,
  };

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: center.latitude,
    longitude: (center.longitude * 100 + 5) / 100,
    zoom: 10,
    cursor: "pointer",
  });
  const mapRef = useRef();

  const tokenMapbox = process.env.REACT_APP_TOKEN_MAPBOX;

  //? ----------------------------- For cluster : ----------------------------- //
  const points = responseMap.map((point) => ({
    type: "Feature",
    properties: {
      cluster: false,
      pointId: point.id,
      status: point.status,
      name: point.name,
      site: point.site,
      contact: point.contact,
      demande: point.demande,
      is_in_time: point.is_in_time,
      popup: point.popup,
      address: point.address,
      position: point.position,
      date: point.date,
    },
    geometry: {
      type: "Point",
      coordinates: [point.long, point.lat],
    },
  }));

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const [zoomMap, setZoomMap] = useState(11);

  const { clusters } = useSupercluster({
    points,
    zoom: zoomMap,
    bounds,
    options: { radius: 20, maxZoom: 20 },
  });

  //? For cluster :End ----------------------------- //

  //? Données Etapes sélectionnées : -------------------------------//

  // recuperation de l'Id dans l'URL :
  let { uid } = useParams();

  const chargeData = async () => {
    try {
      let selectedChargeData = await wishesData.data
        .filter((wish) => wish.roadmap_id == uid)
        .sort((a, b) => a.position - b.position)
        .map((wish) => {
          const verifyStatus = (isExpress, wish) => {
            let date1 = dayjs(new Date());
            let date2 = dayjs(wish.dateOfIntervention);

            let diffDays = date2.diff(date1, "days");

            if (isExpress) {
              return "express";
            } else {
              if (diffDays > 10) {
                return "disable";
              } else if (diffDays > 7) {
                return "wait";
              } else if (diffDays > 5) {
                return "ok";
              } else if (diffDays > 3) {
                return "good";
              } else if (diffDays > 1) {
                return "warning";
              } else if (diffDays >= 0) {
                return "alert";
              } else if (diffDays < 0) {
                return "out";
              } else return "err";
            }
          };

          let checkStatus = verifyStatus(wish.isExpress, wish);

          let checkIsInTime;

          let popupInfo;

          //popupInfo (separe /popup/[spydeMessage[1]] in client description)
          if (wish["Client"].spydeMessage) {
            popupInfo = wish["Client"].spydeMessage.split("/popup/")[1];
          }

          if (!popupInfo) {
            popupInfo = "Pas de contraintes";
            checkIsInTime = "good";
          } else checkIsInTime = "alert";

          let interventionDate = dateFormat(`${wish.dateOfIntervention}`);

          let newWishToAdd = {
            id: wish.id,
            name: wish["Client"].clientName,
            site: wish["Client"].agency,
            contact: "no found",
            lat: wish["Client"].lat,
            long: wish["Client"].long,
            demande: "DE" + wish.id,
            popup: popupInfo,
            status: checkStatus,
            is_in_time: checkIsInTime,
            date: interventionDate,
            address: wish["Client"].address,
            position: wish.position,
          };

          return newWishToAdd;
        });

      await setNewMap(selectedChargeData);
    } catch (err) {
      toast.error("Erreur lors du chargement des données");
    }
  };
  useEffect(() => {
    chargeData();
  }, []);

  const addToSelectedData = (e) => {
    let newToAdd = [
      {
        id: e.properties.pointId,
        position: newMap.length + 1,
        name: e.properties.name,
        site: e.properties.site,
        contact: e.properties.contact,
        lat: e.geometry.coordinates[1],
        long: e.geometry.coordinates[0],
        demande: e.properties.demande,
        status: e.properties.status,
        is_in_time: e.properties.is_in_time,
        popup: e.properties.popup,
        address: e.properties.address,
        date: e.properties.date,
      },
    ];

    setSelectedData(newToAdd);
  };

  const removeToSelectedData = (e) => {
    setRemoveData(e);
  };

  useEffect(() => {
    //* Remove data in selectedMap
    if (removeData) {
      let oldSelectedData = newMap;
      let dataToRemove = removeData;
      formatLog("dataToRemove", dataToRemove);
      formatLog("newMap", newMap);
      const newPositionData = () => {
        newMap.map((value, index) => {
          return (value.position = index + 1);
        });
      };
      newPositionData();

      const newSelectedData = oldSelectedData.filter((value) => {
        return value.id !== dataToRemove.id;
      });
      setNewMap(newSelectedData);
    }

    //*Add data to originalMap

    if (removeData) {
      let originalMap = responseMap;
      let addData = removeData;

      const newOriginal = [...originalMap, addData];
      setResponseMap(newOriginal);
    }
  }, [removeData]);

  const onMove = (e) => {
    setViewport(e.target);
    setZoomMap(e.viewState.zoom);
  };
  useEffect(() => {
    setSelectedData(null);
  }, []);

  useEffect(() => {
    //* Add data in selectedMap
    if (selectedData) {
      let defaultMap = newMap;
      let newData = selectedData;

      const newTable = [...defaultMap, ...newData];
      setNewMap(newTable);
    }

    //* Remove data in originalMap
    if (selectedData) {
      let oldResponseMap = responseMap;
      let dataToDelete = selectedData;

      const newResponseMap = oldResponseMap.filter((value) => {
        return value.id !== dataToDelete[0].id;
      });
      setResponseMap(newResponseMap);
    }
  }, [selectedData]);

  const [newSelectedLine, setnewSelectedLine] = useState({
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [
        [-0.48369693756104, 44.83381888486939],
        [-0.48348236083984, 44.83317489144141],
      ],
    },
  });

  useEffect(() => {
    if (window.outerWidth < 500 || window.outerHeight < 500) {
      setActiveBigScreen(false);
    } else {
      setActiveBigScreen(true);
    }
  }, []);

  useEffect(() => {
    let routeGeojson;
    if (newMap) {
      routeGeojson = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: newMap.map((point) => {
            return [point.long, point.lat];
          }),
        },
      };
      setnewSelectedLine(routeGeojson);
    }
  }, [newMap]);

  return (
    <>
      <ReactMapGL
        {...viewport}
        onMove={(e) => onMove(e)}
        mapStyle={
          "mapbox://styles/spyde33/cl5ovelxe006l15pc8jrjju4g?access_token=" +
          tokenMapbox
        }
        mapboxAccessToken={tokenMapbox}
        ref={mapRef}
      >
        <React.Fragment key="SpydePosition">
          <Marker
            latitude={userData["SpyderAccount.lat"]}
            longitude={userData["SpyderAccount.long"]}
          >
            <div>
              <Logo className="stdLogo stdLogo--map" />
            </div>
          </Marker>
        </React.Fragment>

        <Source id="route" type="geojson" data={newSelectedLine}>
          <Layer
            id="route"
            type="line"
            source="route"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": "rgb(32, 151, 202)",
              "line-width": 1,
            }}
          />
        </Source>

        <FullscreenControl />
        {isPublished ? (
          ""
        ) : (
          <MapPopupDemande
            clusters={clusters}
            addToSelectedData={addToSelectedData}
            mapRef={mapRef}
            listOfSelected={listOfSelected}
          />
        )}

        {newMap.map((result, index) => (
          <React.Fragment key={"NewMap" + index}>
            <MapPopupSelected
              removeToSelectedData={removeToSelectedData}
              result={result}
              newMap={newMap}
              isPublished={isPublished}
              listOfSelected={listOfSelected}
            />
          </React.Fragment>
        ))}

        {activeBigScreen && <MapSelection newMap={newMap} />}
      </ReactMapGL>
      {!activeBigScreen && <MapSelection newMap={newMap} />}
    </>
  );
};

export default MapBoxType;
