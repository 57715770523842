import BlockFAQ from "../blocks/BlockFAQ";

const SectHomeFAQ = () => {
  return (
    <section className="sectHomeFaq">
      <div className="sectHomeFaq__title">
        <h2 className="font__blockPoint">Questions fréquentes</h2>
        <a href="/" className="font__simpleText">
          Besoin de plus d'informations ? <br />
          Contactez-nous en cliquant ici.
        </a>
      </div>
      <div className="sectHomeFaq__questions">
        <BlockFAQ
          number="01"
          question="Quels types de déchets pouvez-vous collecter et valoriser ?"
          response="Nous pouvons collecter et valoriser une grande variété de déchets, tels que les déchets recyclables (papier, carton, plastique, métal, verre), les déchets organiques, le D3E, les mégots et les cartouches d’encre."
          line="line"
        />
        <BlockFAQ
          number="02"
          question="Quels sont les avantages de la valorisation des déchets pour mon entreprise ?"
          response="La valorisation des déchets peut aider votre entreprise à économiser de l'argent en réduisant les coûts d'enfouissement. Elle peut également contribuer à améliorer votre impact environnemental et à renforcer votre image de marque en tant qu'entreprise responsable. Enfin, elle peut également aider à créer des emplois locaux dans la filière du recyclage."
          line="line"
        />
        <BlockFAQ
          number="03"
          question="Qu'est-ce que le décret 5 flux ?"
          response="Le décret 5 flux, également appelé la loi des 5 flux, est une réglementation française mise en place en 2016 qui impose aux entreprises de trier leurs déchets en cinq flux distincts : papier, plastique, verre, métal et déchets organiques. 
          Cette mesure vise à promouvoir des pratiques de tri plus responsables dans les entreprises et à réduire leur impact environnemental en limitant la quantité de déchets envoyés en décharge ou incinérés. En triant les déchets en cinq flux, les entreprises peuvent améliorer leur taux de valorisation des déchets et ainsi contribuer à la préservation des ressources naturelles et à la réduction des émissions de gaz à effet de serre. Les entreprises qui ne se conforment pas au décret 5 flux s'exposent à des sanctions financières pouvant aller jusqu'à 75 000 €."
          line="line"
        />
        <BlockFAQ
          number="04"
          question="Comment fonctionne votre système de collecte ?"
          response="Nous proposons des contenants adaptés à vos besoins et aux différents types de déchets à collecter. Nous organisons ensuite des tournées de collecte adaptées à votre production de déchets afin de minimiser les coûts de transport et les émissions de carbone. Nous pouvons également intervenir rapidement en cas de besoin grâce à notre système « Collecte Express »."
          line="line"
        />
        <BlockFAQ
          number="05"
          question="Comment puis-je organiser des formations pour sensibiliser mes employés à la gestion des déchets ?"
          response="Nous proposons des formations adaptées à vos besoins et à vos contraintes de temps. Nos experts peuvent intervenir directement sur votre site pour dispenser des formations pratiques et théoriques sur la gestion des déchets. Nous sommes également en mesure de vous fournir des supports de formation tels que des documents pédagogiques ou des vidéos pour accompagner vos employés."
        />
      </div>
    </section>
  );
};
export default SectHomeFAQ;
