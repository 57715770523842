import Logo from "../elements/Logo";

const BlockLogo =()=>{
    return (
        <div className="blockLogo">
            <div className="blockLogo_firstPart">
                <Logo className="stdLogo stdLogo--light"/>
                <span className="stdLogo__name">Spyde</span>
            </div>
            <div className="blockLogo_secondPart">
                <span className="stdLogo__slogan">Vers un monde plus propre</span>
            </div>
        </div>
    )
}

export default BlockLogo;