//* scss dans _combo.scss *//
import SvgArrow from "../elements/SvgArrow";

const BlockHoverImage = (props) => {
  return (
    <div className="image">
      <img
        className={"image--" + props.size}
        src={props.image}
        alt={props.alt}
      ></img>
      <div className="image__text">
        <div className="image__text--container">
          <h3>{props.text}</h3>

          <button className="btn btn__actions btn__simple btn__simple--yellow">
            <p>{props.button}</p>
            <SvgArrow className={"btn__arrow--yellow"} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlockHoverImage;
