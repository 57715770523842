import React from "react";
import SmallBlockBlog from "../blocks/SmallBlockBlog";
import { useSelector } from "react-redux";

const SectBlogInfinite = () => {
  const publicImagesData = useSelector(
    (state) => state.publishImagesBlog.imagesBlog
  );
  const publicPagesData = useSelector(
    (state) => state.publishPagesBlog.pagesBlog
  );
  return (
    <section className="sectBlogInfinite">
      <h2 className="font__btn">Nos Autres Articles</h2>
      <div className="sectBlogInfinite__grid">
        {publicPagesData?.data.slice(1).map((page, index) => {
          let imagesForPage = publicImagesData?.data.filter(
            (image) => image.pageBlog_id === page.id
          );

          return (
            <React.Fragment key={index}>
              <SmallBlockBlog images={imagesForPage} data={page} />
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default SectBlogInfinite;
