import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";

export const getAccessRules = createAsyncThunk(
  "AccessRules/getAccessRules",
  async () => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/access_rules"
    ).then((res) => res.data);
  }
);

const accessRulesSlice = createSlice({
  name: "AccessRules",
  initialState: { access: null },

  reducers: {
    setAccessRules: (state, { payload }) => {
      state.access = payload;
    },
  },
  extraReducers: {
    [getAccessRules.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getAccessRules.fulfilled]: (state, { payload }) => {
      state.access = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getAccessRules.rejected]: (state, action) => {
      state.status = "failed";
      state.access = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setAccessRules } = accessRulesSlice.actions;

export default accessRulesSlice;
