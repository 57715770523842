export const infobulle_password =
  "Doit comporter entre 8 à 30 caractères, à minima une majuscule, une minuscule, un chiffre et un caractère spécial";

export const infobulle_clientname =
  "Doit commencer par 4 lettres, puis est autorisé : lettres, chiffres, tirets et underscore, max.100 caractères";

export const infobulle_agency =
  "3 caractères minimum, est autorisé : lettres, chiffres, tirets et underscore, max.50 caractères";

export const infobulle_zipCode = "5 chiffres uniquement";

export const infobulle_name =
  "Est autorisé : lettres, espace, tirets et underscore, max.50 caractères";

export const infobulle_phone =
  "Doit être un numéro français valide : 10 chiffres uniquement";

export const infobulle_paragraphe =
  "Doit comporter entre 10 et 500 caractères max., autorise la plus part des caractères";

export const infobulle_confirmEmail = "Doit correspondre à l'e-mail";

export const infobulle_email =
  "Doit être un email valide, le mot de passe automatique sera envoyé à cette adresse";

export const infobulle_newPassword =
  "Doit être différent de l'ancien, et comporter entre 8 à 30 caractères, à minima une majuscule, une minuscule, un chiffre et un caractère spécial";

export const infobulle_confirmPassword =
  "Doit correspondre à votre nouveau mot de passe";

export const infobulle_address =
  "Doit correspondre à l'adresse où les collectes seront effectuées";

export const infobulle_address_bill =
  "Doit correspondre à l'adresse où les factures seront envoyées";

export const infobulle_siret =
  "Doit correspondre au numéro de siret de l'entreprise : les 9 chiffres du numéro SIREN + les 5 chiffres du NIC";

export const infobulle_frequency =
  "Indiquer le nombre de jour d'écart entre les collectes";

export const infobulle_contract_time =
  "Indiquer le nombre de mois de validité du contrat";

export const infobulle_contract_number = "Indiquer le numero du document";

export default {
  infobulle_agency,
  infobulle_phone,
  infobulle_clientname,
  infobulle_password,
  infobulle_zipCode,
  infobulle_confirmEmail,
  infobulle_newPassword,
  infobulle_confirmPassword,
  infobulle_email,
  infobulle_paragraphe,
  infobulle_name,
  infobulle_address,
  infobulle_address_bill,
  infobulle_frequency,
  infobulle_contract_time,
  infobulle_contract_number,
};
