import React, { useEffect, useState } from "react";
import SvgMap from "../elements/SvgMap";
import MapBlock from "../blocks/MapBlock";

const MapSelection = ({ newMap }) => {
  const mapInstructions = [
    "Cliquez sur les points pour voir les demandes en attente ",
    "ATTENTION : Toute demande, enregistrée ici, ne sera plus disponible pour les autres feuilles de routes.",
  ];

  const [instructions, setInstructions] = useState(mapInstructions[0]);

  useEffect(() => {
    newMap &&
      newMap.map((selection, index) => {
        selection.position = index + 1;
        index >= 0 && setInstructions(mapInstructions[1]);
        index === 0 && setInstructions(mapInstructions[0]);
        return null;
      });
  }, [newMap]);

  return (
    <div className="mapSelection">
      <div className="mapSelection__blocks">
        <p className="mapSelection__blocks--instruct">{instructions}</p>
        {newMap.map((selection, index) => (
          <React.Fragment key={"mapSelection" + index}>
            <MapBlock selection={selection} />
          </React.Fragment>
        ))}
      </div>

      <div className="mapSelection__footer">
        <div className="mapSelection__footer--bg">
          <SvgMap />
        </div>
      </div>
    </div>
  );
};

export default MapSelection;
