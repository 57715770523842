import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetContainer from "../../components/widgets/WidgetContainer";
import { FollowingRecycle } from "../../components/widgets/FollowingRecycle";
import { getClientRecycles } from "../../feature/recycle.slice";
import { getMyContract } from "../../feature/contract.slice";
import { getClientWish } from "../../feature/wish.slice";
import { getClientRacks } from "../../feature/rack.slice";
import { MyRecyclesData } from "../../components/widgets/MyRecyclesData";
import { ExpressRecycle } from "../../components/widgets/ExpressRecycle";

const ClientCollect = () => {
  return (
    <div className="configGrid">
      <section className="widget">
        <FollowingRecycle />
        <ExpressRecycle />
        <WidgetContainer
          icon="calendar-outline"
          title="Mes bons de recyclage"
          order={1}
        >
          <MyRecyclesData />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default ClientCollect;
