import React from "react";
import { useState, useEffect } from "react";
import OneClient from "./OneClient";
import { useSelector } from "react-redux";
import useAuth from "../../services/useAuth";
import DetailClient from "./DetailClient";
import LoaderM from "../blocks/LoaderM";
//import valueOrReplace from "../../utils/valueOrReplace";
import IsItMyClient from "../../services/contexts/IsItMyClient";
import { useDispatch } from "react-redux";
import { getContacts } from "../../feature/contact.slice";
//import { toast } from "react-toastify";

import BtnOnOff from "../elements/BtnOnOff";
import { getContracts } from "../../feature/contract.slice";
import { getWishes } from "../../feature/wish.slice";
import { getRecycles } from "../../feature/recycle.slice";
//import isEmpty from "../../utils/isEmpty";

const AllClients = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.client.client);
  const clientStatus = useSelector((state) => state.client.status);
  const userData = useSelector((state) => state.user.user);
  const clientLoading = useSelector((state) => state.client.loading);
  //const contactData = useSelector((state) => state.contact.contact);

  const [clientSelected, setClientSelected] = useState("");
  const [searchCode, setSearchCode] = useState("");
  // const [contact, setContact] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [onlyMine, setOnlyMine] = useState(false);
  //for filter :
  //show different filter
  const [openSearch, setOpenSearch] = useState(false);
  const [openBonus, setOpenBonus] = useState(false);
  //const [openWatch, setOpenWatch] = useState(false);
  //set Radios button
  // const [selectedRadio, setSelectedRadio] = useState("");

  // const buttonradios = [
  //   "Prochaine Collecte",
  //   "Fin de contrat",
  //   "Création du compte",
  // ];

  //set Checks button

  const [showCollecte, setShowCollecte] = useState(false);
  const [showCodeEts, setShowCodeEts] = useState(false);
  //const [showEndContrat, setShowEndContrat] = useState(false);
  const [showContrat, setShowContrat] = useState(false);
  //const [showBonus, setShowBonus] = useState(false);

  const searchByClient = (evt) => {
    setClientSelected(evt);
    //setSearchCode("");
    //setContact("");
  };

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getContracts());
    dispatch(getWishes());
    dispatch(getRecycles());
  }, [dispatch]);

  const searchByZipCode = (evt) => {
    setSearchCode(evt);
  };

  const selectRow = (e) => {
    selectedRow?.classList.remove("oneLignInfos--selected");
    const newRow = e.target.offsetParent;
    setSelectedRow(null);
    if (newRow.id) {
      setSelectedRow(newRow);
      newRow?.classList.toggle("oneLignInfos--selected");
    } else {
      setSelectedRow(null);
    }
  };

  useEffect(() => {
    if (selectedRow === null) {
      setShowDetails(false);
    } else if (selectedRow) {
      setShowDetails(true);
    }
  }, [selectedRow]);

  const isMineClient = (client) => {
    if (onlyMine) {
      IsItMyClient(auth, client, userData);
      if (auth.auth.rules.includes(4002)) {
        if (client.Spyder.id === userData.id) {
          return true;
        } else {
          return false;
        }
      } else return false;
    } else return true;
  };

  const locateClient = (client) => {
    let testCode = [client.codeEts.toString()];
    let validCode = searchCode;
    let result = testCode[0].includes(validCode);

    if (validCode) {
      if (result) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <div className="allclients">
      <div className="allclients allclients__filters dashform__form dashform__form--full">
        <div className="filter" onClick={() => setOpenSearch(!openSearch)}>
          <span className="filter__icon">
            <ion-icon icon="options-outline" />
          </span>
          <p>Réglages</p>
        </div>
        {openSearch && (
          <div className="allclients__containFilter">
            <div className="allclients__blockFilter allclients__blockFilter--fullOnSmallDevice">
              <label className="font__italic">Rechercher un client :</label>
              <input
                type="text"
                id="clientName"
                autoComplete="off"
                onChange={(e) => searchByClient(e.target.value)}
                defaultValue={clientSelected}
                required
                placeholder="Saisir le nom de l'entreprise recherchée ..."
              ></input>
            </div>
            <div className="allclients__blockFilter allclients__blockFilter--fullOnSmallDevice">
              <label className="font__italic">
                Rechercher par code postal :
              </label>
              <input
                type="text"
                id="zipCode"
                autoComplete="off"
                onChange={(e) => searchByZipCode(e.target.value)}
                defaultValue={searchCode}
                required
                placeholder="Saisir un code postal ..."
              ></input>
            </div>
            {/* <div className="allclients__blockFilter allclients__blockFilter--fullOnSmallDevice">
              <label>Contact :</label>
              <input
                type="text"
                id="contactName"
                autoComplete="off"
                onChange={(e) => searchByContact(e.target.value)}
                defaultValue={contact}
                required
                placeholder="Saisir un code postal ..."
              ></input>
            </div> */}
          </div>
        )}
        {/* 
        <div className="filter" onClick={() => setOpenOrder(!openOrder)}>
          <span className="filter__icon">
            <ion-icon icon="list-outline" />
          </span>
          <p>Trier par</p>
        </div>

        {selectedRadio && (
          <button
            className="filter__cancel"
            onClick={() => setSelectedRadio("")}
          >
            Annuler la recherche
          </button>
        )}
        {openOrder && (
          <div className="filter__radios">
            {buttonradios.map((action) => {
              return (
                <div className="filter__radios__lign">
                  <label htmlFor={action}>{action}</label>
                  <input
                    type="radio"
                    id={action}
                    name="filter"
                    checked={action === selectedRadio}
                    onChange={(e) => setSelectedRadio(e.target.id)}
                  />
                </div>
              );
            })}
          </div>
        )} */}

        {openSearch && (
          <div className="filter__checkboxs">
            <BtnOnOff
              name="Contrat"
              afterDisable={() => setShowContrat(false)}
              afterSelect={() => setShowContrat(true)}
            />
            {/* <CheckBox
              order="100"
              text="Contrat"
              afterDisable={() => {
                setShowContrat(false);
              }}
              afterSelect={() => {
                setShowContrat(true);
              }}
            /> */}
            <BtnOnOff
              name="Collecte"
              afterDisable={() => setShowCollecte(false)}
              afterSelect={() => setShowCollecte(true)}
            />
            <BtnOnOff
              name="Code postal"
              afterDisable={() => setShowCodeEts(false)}
              afterSelect={() => setShowCodeEts(true)}
            />
            <BtnOnOff
              name="Bonus"
              afterDisable={() => setOpenBonus(false)}
              afterSelect={() => setOpenBonus(true)}
            />

            {auth.auth.rules.includes(4002) && (
              <BtnOnOff
                name="Mes clients"
                afterDisable={() => setOnlyMine(false)}
                afterSelect={() => setOnlyMine(true)}
              />
            )}

            {/* <CheckBox
              order="104"
              text="Bonus"
              afterDisable={() => {
                setShowBonus(false);
              }}
              afterSelect={() => {
                setShowBonus(true);
              }}
            /> */}
          </div>
        )}
      </div>

      {!clientLoading ? (
        <div className="allclients allclients__container">
          <div className="allclients__results">
            {clientData &&
              clientData?.data
                .filter((client) =>
                  client.clientName
                    .toLowerCase()
                    .includes(clientSelected.toLowerCase())
                )
                .filter((client) => locateClient(client))

                .filter((client) => isMineClient(client))
                .map((client, index) => {
                  return (
                    <div
                      key={index}
                      className="allclients__results__lign"
                      onClick={(e) => selectRow(e)}
                    >
                      <OneClient
                        client={client}
                        showContrat={showContrat}
                        showCollecte={showCollecte}
                        showCodeEts={showCodeEts}
                        showBonus={openBonus}
                      />
                    </div>
                  );
                })}
          </div>
          {selectedRow && showDetails ? (
            <DetailClient clientSelected={selectedRow} />
          ) : (
            <div className="allclients__details">
              <div className="detail">
                <p className="font__italic ">
                  Séléctionner un client pour voir les détails
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="allclients allclients__container">
          <div className="allclients__results">
            <LoaderM
              color="red"
              text="Récupération des données en cours"
              lg="2500"
            />
          </div>
        </div>
      )}
      {clientStatus === "failed" && (
        <div className="allclients allclients__container">
          <div className="allclients__results">
            <p>
              Erreur lors du chargement des données ... Veuillez actualiser la
              page. Si le problème persiste, contactez votre administrateur
              réseau
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllClients;
