import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import CounterBalance from "../components/widgets/CounterBalance.js";
import LoaderM from "../components/blocks/LoaderM.js";
import MapBoxType from "../components/widgets/MapBoxType";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  deleteOneRoadMap,
  getRoadMaps,
  patchOneRoadMap,
  trashOneRoadMap,
} from "../feature/roadmap.slice";
import {
  addNewWish,
  getWishes,
  selectWish,
  trashExpressWish,
  trashOneWish,
} from "../feature/wish.slice";
import { useParams, useNavigate } from "react-router-dom";
import dateFormat from "../utils/dateFormat.js";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getContacts } from "../feature/contact.slice.js";
import DashButton from "../components/elements/DashButton.js";
import ModalLayout from "../components/layouts/ModalLayout.js";
import ModalContent from "../components/layouts/ModalContent.js";
import CheckBox from "../components/elements/CheckBox.js";
import PublishedCollectBlock from "../components/blocks/PublishedCollectBlock.js";
import {
  backToSpyde,
  getRacks,
  pushInRack,
  unpushInRack,
} from "../feature/rack.slice.js";
import { getContracts } from "../feature/contract.slice.js";
import isEmpty from "../utils/isEmpty.js";
import slowCode from "../services/helpers/slowCode.js";

const RoadMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roadmapData = useSelector((state) => state.roadmaps.roadmaps);
  const wishesData = useSelector((state) => state.wishes.wishes);
  const contactData = useSelector((state) => state.contact.contact);
  const userData = useSelector((state) => state.user.user);
  const rackData = useSelector((state) => state.racks.racks);
  const contractData = useSelector((state) => state.contract.contract);

  const [roadmapSelected, setRoadmapSelected] = useState(null);
  const [wishesForMap, setWishesForMap] = useState(null);
  const [wishesSelected, setWishesSelected] = useState(null);
  const [wishForCollecte, setWishForCollecte] = useState(null);
  const [wishesAreReady, setWishesAreReady] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkA, setCheckA] = useState(false);
  const [modalCollecte, setModalCollecte] = useState(false);
  const [rackComment, setRackComment] = useState("");
  const [selectedRacks, setSelectedRacks] = useState([]);
  const [rackToUnpush, setRackToUnpush] = useState(null);
  const [validationToPublish, setValidationToPublish] = useState(true); //true = publisk ok
  const [modalCloseRoadmap, setModalCloseRoadmap] = useState(false);

  //* recuperation de l'Id dans l'URL :
  let { uid } = useParams();
  const roadmapId = uid;

  useEffect(() => {
    dispatch(getRoadMaps());
    dispatch(getWishes());
    dispatch(getContacts());
    dispatch(getContracts());
    dispatch(getRacks());
  }, []);

  const cancelRoadmapState = () => {
    setRoadmapSelected(null);
    setWishesForMap(null);
    setWishesSelected(null);
    setWishForCollecte(null);
    setIsPublished(false);
    setIsFull(false);
    setModal(false);
    setCheckA(false);
    setModalCollecte(false);
    setRackComment("");
    setSelectedRacks([]);
    setRackToUnpush(null);
    setValidationToPublish(true); //true = publish ok
    setModalCloseRoadmap(false);
  };

  const stopButtonPublication = (e) => {
    setValidationToPublish(e);
  };

  //* récupération du contact :
  let wishAllsContacts;
  const getWishContact = (wish) => {
    wishAllsContacts = contactData?.data
      .filter(
        (contact) =>
          contact["Client"].lat === wish.lat &&
          contact["Client"].long === wish.long &&
          contact["Client"].clientName === wish.name
      )
      .map((contact) => {
        return contact;
      });
  };

  //* récupération de la roadmap selectionnée :
  useEffect(() => {
    let roadmapToAdd = roadmapData?.data.filter((roadmap) => {
      return roadmap.id == roadmapId;
    });

    setRoadmapSelected(roadmapToAdd);

    if (roadmapToAdd && roadmapToAdd[0]?.driver_id) {
      setIsPublished(true);
    } else {
      setIsPublished(false);
    }
  }, [roadmapData]);

  const validIfWishesAreFull = (wishes, racks) => {
    //je regarde dans les racks :
    let count = 0;
    wishes &&
      wishes.map((wish) => {
        let chargedWish = racks?.data.filter(
          (rack) => rack.wish_id === wish.id
        );
        //Compte +1 si un ou plusieurs racks sont présents dans la demande :
        if (chargedWish && chargedWish?.length > 0) {
          count = count + 1;
        }
      });

    //si le compteur = nombre de demandes :
    if (count === wishes?.length) {
      return setIsFull(true);
    } else {
      return setIsFull(false);
    }
  };

  useEffect(() => {
    validIfWishesAreFull(wishesSelected, rackData);
  }, [wishesSelected, rackData]);

  const changeSelectedData = (data) => {
    setWishesSelected(data);
  };

  const [expressWish, setExpressWish] = useState([]);
  const [arrayWithNoDouble, setArrayWithNoDouble] = useState([]);
  const [contractWish, setContractWish] = useState([]);

  const getExpressWish = async () => {
    //1. Demandes Express en attente :
    let arrayOfExpressWish = await wishesData?.data
      .filter((wish) => wish.roadmap_id === null)
      .filter(
        (
          (set) => (f) =>
            f.isExpress &&
            !set.has(f.client_id) &&
            set.add(f.client_id && f.isExpress)
        )(new Set())
      );

    //? J'ai toutes les demandes Express :

    //2. ISOLER LES CLIENTS AYANT PLUS d'une demande :
    await setExpressWish(arrayOfExpressWish);
  };

  const getContractWish = async () => {
    let contractWishFilter = await wishesData?.data.filter(
      (wish) => wish.roadmap_id === null && !wish.isExpress
    );

    //? J'ai toutes les demandes aux contrats :

    await setContractWish(contractWishFilter);
  };

  useEffect(() => {
    getExpressWish();
    getContractWish();
  }, [wishesData]);

  //* Supprimer du Contrat les demandes ayant le même client que dans Express :

  const getArrayWithNoDouble = async (arrayExpress, arrayContract) => {
    let idToDelete = await arrayExpress.map((wish) => {
      return wish.client_id;
    });
    let contractWishLess = await arrayContract.filter((wish) => {
      if (idToDelete.includes(wish.client_id)) {
        return;
      } else {
        return wish;
      }
    });

    let finalArray = [...contractWishLess, ...arrayExpress];
    setArrayWithNoDouble(finalArray);
  };

  useEffect(() => {
    if (!isEmpty(expressWish) && !isEmpty(contractWish)) {
      getArrayWithNoDouble(expressWish, contractWish);
    } else if (wishesData) {
      setArrayWithNoDouble(wishesData.data);
    }
  }, [expressWish, contractWish, wishesData]);

  //*Transformation de la data pour affichage map :
  const verifyStatus = (isExpress, wish) => {
    const date1 = dayjs(new Date());
    const date2 = dayjs(wish.dateOfIntervention);

    let diffDays = date2.diff(date1, "days");

    if (isExpress) {
      return "express";
    } else {
      if (diffDays > 10) {
        return "disable";
      } else if (diffDays > 7) {
        return "wait";
      } else if (diffDays > 5) {
        return "ok";
      } else if (diffDays > 3) {
        return "good";
      } else if (diffDays > 1) {
        return "warning";
      } else if (diffDays >= 0) {
        return "alert";
      } else if (diffDays < 0) {
        return "out";
      } else return "err";
    }
  };

  const transformDataForMap = async (array) => {
    let allWishesForMap = await array
      .filter((wish) => wish.roadmap_id === null)
      //filter for wish disponible.
      .map((wish) => {
        let checkStatus = verifyStatus(wish.isExpress, wish);

        let checkIsInTime;

        //popupInfo (separe /popup/[spydeMessage[1]] in client description)

        let popupInfo;
        if (wish["Client"].spydeMessage) {
          popupInfo = wish["Client"].spydeMessage.split("/popup/")[1];
        }

        if (!popupInfo) {
          popupInfo = "Pas de contraintes";
          checkIsInTime = "good";
        } else checkIsInTime = "alert";

        let interventionDate = dateFormat(`${wish.dateOfIntervention}`);

        let newWishToAdd = {
          id: wish.id,
          name: wish["Client"].clientName,
          site: wish["Client"].agency,
          contact: "no found",
          lat: wish["Client"].lat,
          long: wish["Client"].long,
          demande: "DE" + wish.id,
          popup: popupInfo,
          status: checkStatus,
          typeOfCollecte: wish.isExpress ? "Express" : "Contrat",
          is_in_time: checkIsInTime,
          date: interventionDate,
          address: wish["Client"].address,
          position: wish.position,
        };

        return newWishToAdd;
      });

    if (isEmpty(allWishesForMap)) {
      let phantomWish = {
        id: 999999,
        name: "Fictif",
        site: "Los Angeles",
        contact: "no found",
        lat: 34.003342,
        long: -118.485832,
        demande: "",
        popup: "Ne pas selectionné !",
        status: "disable",
        typeOfCollecte: "Contrat",
        is_in_time: "good",
        date: "01/01/2999",
        address: "NE PAS SELECTIONNER",
        position: null,
      };

      await setWishesForMap([phantomWish]);
    } else {
      await setWishesForMap(allWishesForMap);
    }
  };

  useEffect(() => {
    if (!isEmpty(arrayWithNoDouble)) {
      transformDataForMap(arrayWithNoDouble);
    }
  }, [arrayWithNoDouble]);

  // END FOR GET POINTS FOR MAP
  //* delete the roadmap :

  const deleteThisRoadmap = async (e, id) => {
    e.preventDefault();
    try {
      await dispatch(deleteOneRoadMap(id));
      await dispatch(getRoadMaps());
      await dispatch(getWishes());
      await navigate("/dashboard/spyde/logistic");
    } catch (err) {
      console.log("err from deleteThisRoadMap");
    }
  };

  const publishThisRoadmap = async (e, id) => {
    e.preventDefault();

    try {
      let data = {
        driver_id: userData.id,
        moment: roadmapSelected[0].moment,
        roadmapDate: roadmapSelected[0].roadmapDate,
        creator_id: roadmapSelected[0].creator_id,
        isAtSpyde: roadmapSelected[0].isAtSpyde,
        isOnline: roadmapSelected[0].isOnline,
      };
      let dataAndId = {
        data: data,
        id: id,
      };

      await dispatch(patchOneRoadMap(dataAndId));
      await dispatch(getRoadMaps());
      await dispatch(getWishes());
      await toast.success("Vous venez de publier la feuille RM" + id);
      await document.body.classList.remove("active-modal");
      await navigate("/dashboard/spyde/logistic");
    } catch (err) {
      console.log("err from publishThisRoadMap");
    }
  };

  const toggleModal = (e) => {
    setModal(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const toggleModalCollecte = (e) => {
    setModalCollecte(e.target.value);
    setSelectedRacks([]);
    setRackComment("");
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const addCollecte = (e, wish) => {
    e.preventDefault();
    setModalCollecte(true);
    setWishForCollecte(wish);
  };

  const addRackToSelection = (e, rack) => {
    e.preventDefault();

    const addSelected = [...selectedRacks, rack];
    setSelectedRacks(addSelected);

    toast.success("Rack " + rack.rackNumber + " ajouté");
  };

  const removeRackToSelection = (e, rack) => {
    e.preventDefault();

    let removeToSelected = selectedRacks.filter((item) => item.id !== rack.id);

    setSelectedRacks(removeToSelected);

    toast.error("Rack " + rack.rackNumber + " retiré");
  };

  const pushInRacks = async (e) => {
    e.preventDefault();

    try {
      //* pour chaque rack selectionné : ajout des informations :

      selectedRacks &&
        (await selectedRacks.map(async (rack) => {
          //* récupérer id sur wishForCollecte puis recherche des informations sur wishesData : roadmap_id, client_id

          let selectedWish = wishesData.data.filter(
            (wish) => wish.id === wishForCollecte.id
          );

          let newComment = null;

          if (rackComment) {
            newComment = rackComment;
          }

          let dataForRacks = {
            rackNumber: rack.rackNumber,
            client_id: selectedWish[0].client_id,
            roadmap_id: selectedWish[0].roadmap_id,
            wish_id: selectedWish[0].id,
            spyder_id: userData.id,
            comment: newComment,
            isAtSpyde: false,
            typeOfCollecte: selectedWish[0].isExpress ? "Express" : "Contrat",
          };

          let dataAndId = {
            data: dataForRacks,
            id: rack.id,
          };
          await dispatch(pushInRack(dataAndId));

          await setModalCollecte(false);
          await setSelectedRacks([]);
          await setRackComment("");
          document.body.classList.remove("active-modal");
        }));
    } catch (err) {
      toast.error("Erreur lors du pushInRacks");
    }
  };

  const toggleSelectedRack = async (e, rack, boolean) => {
    if (boolean) {
      setRackToUnpush(rack);
    } else {
      setRackToUnpush(null);
    }

    //toast.info("test clic racks");
  };

  const unpushThisRack = async (e) => {
    e.preventDefault();

    try {
      let unpushData = {
        rackNumber: rackToUnpush.rackNumber,
        client_id: null,
        spyder_id: null,
        wish_id: null,
        comment: null,
        roadmap_id: null,
        isAtSpyde: false,
        typeOfCollecte: null,
      };
      let dataAndId = {
        data: unpushData,
        id: rackToUnpush.id,
      };
      await dispatch(unpushInRack(dataAndId));
      await setRackToUnpush(null);
      await setModalCollecte(false);
      await setSelectedRacks([]);
      await setRackComment("");

      document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error("Erreur lors du vidage du rack");
    }
  };

  const declareRackAtSpyde = async (rackAtSpyde) => {
    await rackAtSpyde.forEach((rackinspyde) => {
      let data = {
        roadmap_id: roadmapSelected[0].id,
        isAtSpyde: true,
        typeOfCollecte: rackinspyde.typeOfCollecte,
      };

      let dataAndId = {
        data,
        id: rackinspyde.id,
      };

      //* activer Rack arrivée à spyde :
      dispatch(backToSpyde(dataAndId)).then(
        toast.success("rack " + dataAndId.id + " arrivé à spyde")
      );
    });
  };

  const getUniqueIdWish = async (rackAtSpyde) => {
    let idContractWish = [];

    await rackAtSpyde.forEach((rackinspyde) => {
      if (rackinspyde.typeOfCollecte === "Contrat") {
        idContractWish.push(rackinspyde.client_id);
      } else if (rackinspyde.typeOfCollecte === "Express") {
        //Do nothing
      } else {
        toast.error("Bad typeOfCollecte");
      }
    });

    let uniqueIdContractWish = Array.from(new Set(idContractWish));

    return uniqueIdContractWish;
  };

  const getUniqueExpressIdWish = async (rackAtSpyde) => {
    let idExpressWish = [];

    await rackAtSpyde.forEach((rackinspyde) => {
      if (rackinspyde.typeOfCollecte === "Contrat") {
        //do nothing
      } else if (rackinspyde.typeOfCollecte === "Express") {
        idExpressWish.push(rackinspyde.client_id);
      } else {
        toast.error("Bad typeOfCollecte");
      }
    });

    let uniqueIdExpressWish = Array.from(new Set(idExpressWish));

    return uniqueIdExpressWish;
  };

  const deleteOldWish = (uniqueIdContractWish, uniqueIdExpressWish) => {
    uniqueIdContractWish.forEach((id) => {
      dispatch(trashOneWish(id));
    });
    uniqueIdExpressWish.forEach((id) => {
      dispatch(trashExpressWish(id));
    });
  };

  const finishRoadMap = async (e) => {
    e.preventDefault();

    // Mise en loading à réaliser ::::
    //"ETAPE 1 : Lancement;

    //* Récupération des racks ayant cette roadmap :
    let rackAtSpyde = await rackData.data.filter(
      (rack) => rack.roadmap_id === roadmapSelected[0].id
    );

    //* mettre tous les racks de cette roadmap à isAtSpde = true :
    // ETAPE 2 : Déclaration des rack comme arrivés ;
    await declareRackAtSpyde(rackAtSpyde);

    //? 2 Suppression des anciennes demandes (trashOneWish) :
    //ETAPE 3 : Séparation des demandes contrat/express ;
    let myUniqueIdWish = await getUniqueIdWish(rackAtSpyde);
    let myUniqueExpressIdWish = await getUniqueExpressIdWish(rackAtSpyde);

    //ETAPE 4 : Effacement des anciennes demandes;
    await deleteOldWish(myUniqueIdWish, myUniqueExpressIdWish);

    await slowCode(1500);

    //? 3 Vérification des contrats si périodique et si contrat toujours en cours :
    //* recuperation des contrats pour les clients:
    //ETAPE 5 : Récupération des contrats des clients;
    let contractsOfClients = [];
    myUniqueIdWish.forEach((id) => {
      //* Suppression de la demande en cours :::
      // Est-ce qu'un contrat existe pour ce client ? :
      let testingContract = contractData.data.filter(
        (contract) => contract.client_id === id
      );
      contractsOfClients.push(...testingContract);
    });

    //* Vérification de la validité des contrats et reconduction :
    //ETAPE 6 : Vérification des contrats des clients
    contractsOfClients.forEach((contract) => {
      //* Est-ce que ces contrats seront valides pour la prochaine collecte :
      let today = dayjs(new Date());
      //? ??????Today??????????? A VOIR SI ON UTILISE LA DATE DE LA ROADMAP ...;
      let dateStartContract = dayjs(contract.startDate);
      let dateEndContract = dateStartContract.add(contract.duration, "month");
      let dateNewWish = today.add(contract.frequency, "day");
      let diffDays = dateNewWish.diff(dateEndContract, "days");

      //* Mise en place d'alerte :
      if (diffDays >= 1) {
        toast.error(
          "Contrat non valide :" +
            contract["Client"].clientName +
            " Merci de prévenir le commercial"
        );
      } else if (diffDays >= -7) {
        toast.info(
          "Dernier semaine du contrat pour l'entreprise " +
            contract["Client"].clientName +
            " Merci de prévenir le commercial"
        );
      } else if (diffDays < -7 && diffDays > -30) {
        toast.info(
          "Dernier mois du contrat restant pour l'entreprise " +
            contract["Client"].clientName +
            " Merci de prévenir le commercial"
        );
      } else {
        toast.success(
          "Demande de renouvellement acceptée pour l'entreprise : " +
            contract["Client"].clientName
        );
      }

      let newWish = {
        dateOfIntervention: dateNewWish,
        client_id: contract.client_id,
        isExpress: false,
        isDone: false,
      };

      //? 4 Reprogramation des nouvelles demandes ( addNewWish) :
      //* Reprogrammation des demandes avec un contrat valide :

      if (diffDays < 0) {
        //ETAPE 7 : Ajout nouvelle demande
        dispatch(addNewWish(newWish));
        toast.info("Demande renouvellée");
      } else {
        toast.error("Impossible à renouveller : ");
      }
    });

    //? 5 Suppression de la feuille de route
    //attente 1,5s pour laisser le temps de finaliser les précédentes requêtes.
    await slowCode(1500);
    //ETAPE 8 : Suppression de la roadmap " + rackAtSpyde[0].roadmap_id
    dispatch(trashOneRoadMap(rackAtSpyde[0].roadmap_id)).then((res) =>
      cancelRoadmapState()
    );

    //? 6 retour Page Logistique
    document.body.classList.remove("active-modal");
    navigate("/dashboard/spyde/logistic");
  };

  const toggleModalCloseRoadmap = (e) => {
    //e.preventDefault();
    setCheckA(false);
    setModalCloseRoadmap(e.target.value);
    //toast.success("TOGGLE Close Roadmap");
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const closeRoadmap = (e) => {
    e.preventDefault();
    setModalCloseRoadmap(true);
    //toast.success("Close Roadmap");
  };

  useEffect(() => {
    if (isEmpty(wishesForMap)) {
      setWishesAreReady(false);
    } else {
      setWishesAreReady(true);
    }
  }, [wishesForMap]);

  return (
    <div className="roadmap">
      {roadmapSelected ? (
        <>
          <div className="roadmap__header">
            <div className="roadmap__header__part">
              <h3 className="roadMapFont--title roadMapFont--title--dyn">
                Numero
              </h3>
              <p className="roadMapFont--titleValue roadMapFont--titleValue--dyn">
                {"RM" + roadmapSelected[0]?.id}
              </p>
            </div>
            <div className="roadmap__header__part">
              <h3 className="roadMapFont--title roadMapFont--title--dyn">
                Date
              </h3>
              <p className="roadMapFont--titleValue roadMapFont--titleValue--dyn">
                {dateFormat(`${roadmapSelected[0]?.roadmapDate}`) +
                  " - " +
                  roadmapSelected[0]?.moment}
              </p>
            </div>
            <div className="roadmap__header__part">
              <h3 className="roadMapFont--title roadMapFont--title--dyn">
                Pour
              </h3>
              <p className="roadMapFont--titleValue roadMapFont--titleValue--dyn">
                {roadmapSelected[0]?.comment}
              </p>
            </div>
          </div>
          <div className="separator"></div>
          <div className="roadmap__status">
            <div className="roadmap__status__part">
              <h3 className="roadMapFont--title roadMapFont--title--dyn">
                Statut de la feuille de route :
              </h3>
            </div>
            <div className="roadmap__status__part">
              {isPublished ? (
                <p className="roadMapFont--titleValue roadMapFont--titleValue--dyn">
                  PUBLIÉE
                </p>
              ) : (
                <p className="roadMapFont--titleValue roadMapFont--titleValue--dyn">
                  EN COURS DE MODIFICATION
                </p>
              )}
            </div>
          </div>
          <div className="separator"></div>
        </>
      ) : (
        <p className="centerLign roadMapFont--titleValue roadMapFont--titleValue--dyn">
          Erreur lors du chargement de la feuille de route, veuillez revenir à
          la liste et sélectionner à nouveau votre feuille de route ...
        </p>
      )}
      {wishesAreReady ? (
        <>
          <h3 className="roadMapFont--action">
            Ajouter les demandes de collectes à cette feuille de route, en
            cliquant sur les points sur la carte :
          </h3>
          <div className="mapContainer mapContainer--full">
            <MapBoxType
              data={wishesForMap}
              dataSelected={changeSelectedData}
              listOfSelected={wishesSelected}
              isPublished={isPublished}
            />
          </div>
        </>
      ) : (
        <LoaderM
          color="red"
          text="Chargement des demandes en cours ..."
          lg="2500"
        />
      )}
      <h3 className="roadMapFont--action">
        Etapes sélectionnées sur cette feuille de route :
      </h3>

      {wishesSelected && (
        <div className="roadmap__grid">
          {wishesSelected.map((wish, index) => {
            getWishContact(wish);

            return (
              <React.Fragment key={index}>
                <div onClick={(e) => isPublished && addCollecte(e, wish)}>
                  <PublishedCollectBlock
                    data={wish}
                    contact={{ ...wishAllsContacts }}
                    theme={isPublished ? "published" : "selected"}
                    stopButtonPublication={stopButtonPublication}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
      <div className="roadmap__button">
        {isPublished ? (
          isFull ? (
            <DashButton
              type="button"
              action="modify"
              text="Clôturer la feuille de route"
              //onClick={(e) => finishRoadMap(e)}
              onClick={(e) => closeRoadmap(e, roadmapId)}
            />
          ) : (
            <DashButton
              type="button"
              action="inactive"
              text="Saisir les racks utilisés pour chaque collecte"
              //onClick={""}
            />
          )
        ) : wishesSelected && wishesSelected[0] ? (
          validationToPublish ? (
            <DashButton
              type="button"
              action="action"
              text="Publier et prendre en charge la feuille de route"
              //onClick={(e) => publishThisRoadmap(e, roadmapId)}
              onClick={() => setModal(true)}
            />
          ) : (
            <DashButton
              type="button"
              action="inactive"
              text="Attention, vous ne pouvez pas prendre en charge un client qui a encore des racks non recyclés"
            />
          )
        ) : (
          <DashButton
            type="button"
            action="cancel"
            text="Supprimer cette feuille de route"
            onClick={(e) => deleteThisRoadmap(e, roadmapId)}
          />
        )}

        {modal && roadmapSelected && (
          <ModalLayout
            toggle={toggleModal}
            modal={modal}
            label="Publication de la feuille de route"
            colorClass="blue"
          >
            <ModalContent
              text={
                "___Vous êtes sur le point de réaliser la /g/ publication de la feuille de route /g/ suivante : :::" +
                "___/g/RM" +
                roadmapSelected[0]?.id +
                " - " +
                dateFormat(`${roadmapSelected[0]?.roadmapDate}`) +
                " - " +
                roadmapSelected[0]?.moment +
                " /g/- " +
                roadmapSelected[0]?.comment +
                ":::Cela implique que : :::___1. Les clients seront prévenus de votre passage :::___2. Cette feuille de route ne sera plus modifiable ni supprimable :::___3. Les demandes sélectionnées ne seront plus modifiables"
              }
              moreText="Souhaitez-vous poursuivre la publication ?"
            >
              <div className="dashform__form__block dashform__form__block--padding ">
                <CheckBox
                  order="023"
                  text="Je confirme avoir pris connaissance des implications de cette publication"
                  afterDisable={() => setCheckA(false)}
                  afterSelect={() => setCheckA(true)}
                />
              </div>

              <div className="buttonsContainer">
                <div className="buttonContainer--small">
                  {checkA ? (
                    <DashButton
                      //disabled={disabled}
                      action="action"
                      text="Valider la publication"
                      onClick={(e) => publishThisRoadmap(e, roadmapId)}
                    />
                  ) : (
                    <DashButton
                      disabled={true}
                      action="inactive"
                      text="Veuillez confirmer"
                    />
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="cancel"
                    text="Annuler"
                    onClick={(e) => toggleModal(e)}
                  />
                </div>
              </div>
            </ModalContent>
          </ModalLayout>
        )}
        {modalCollecte && rackData?.data && (
          <ModalLayout
            toggle={toggleModalCollecte}
            modal={modalCollecte}
            label="Collecte des déchêts chez le client"
            colorClass="blue"
          >
            <ModalContent
              text={
                "Vous êtes sur le point d'effectuer la collecte pour l'entreprise : :::___/g/" +
                wishForCollecte.name +
                "/g/ - " +
                wishForCollecte.site
              }
              moreText={wishForCollecte.address}
              //moreText="Merci de sélectionner le ou les racks utiliser pour continuer :"
            >
              <div className="rackSelection">
                <p className="rackSelection--title roadMapFont--titleValue--dyn">
                  Rack(s) déjà chargé(s) pour ce client :
                </p>
                <div className="rackButtons">
                  {rackData &&
                    rackData.data
                      .filter(
                        (rack) =>
                          rack.client_id !== null &&
                          rack.wish_id === wishForCollecte.id &&
                          !rack.isAtSpyde
                      )
                      .map((rack, index) => {
                        return rackToUnpush && rack.id === rackToUnpush?.id ? (
                          <div
                            key={index}
                            className="rackButtons__btn rackButtons__btn--selected"
                            onClick={(e) => toggleSelectedRack(e, rack, false)}
                          >
                            <span>{rack.rackNumber}</span>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="rackButtons__btn rackButtons__btn--ontheroad"
                            onClick={(e) => toggleSelectedRack(e, rack, true)}
                          >
                            <span>{rack.rackNumber}</span>
                          </div>
                        );
                      })}
                </div>
                {rackToUnpush && (
                  <div
                    onClick={(e) => unpushThisRack(e)}
                    className="rackButtons__btn rackButtons__btn--trash"
                  >
                    <ion-icon icon="trash-outline"></ion-icon>
                  </div>
                )}
              </div>
              <div className="rackSelection">
                <p className="rackSelection--title centerLign roadMapFont--titleValue roadMapFont--titleValue--dyn">
                  Sélectionner le ou les racks utilisés pour continuer :
                </p>
                <div className="rackButtons">
                  {rackData &&
                    rackData.data
                      .filter(
                        (rack) => rack.client_id === null && !rack.isAtSpyde
                      )
                      .map((rack, index) => {
                        let isSelected = false;
                        selectedRacks.map((selectedRack) => {
                          if (selectedRack.id === rack.id) {
                            isSelected = true;
                          }
                        });

                        return isSelected ? (
                          <div
                            key={index}
                            className="rackButtons__btn rackButtons__btn--selected"
                            onClick={(e) => removeRackToSelection(e, rack)}
                          >
                            <span>{rack.rackNumber}</span>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="rackButtons__btn rackButtons__btn--empty"
                            onClick={(e) => addRackToSelection(e, rack)}
                          >
                            <span>{rack.rackNumber}</span>
                          </div>
                        );
                      })}
                </div>
              </div>
              <div className="rackComment">
                <label
                  htmlFor="commentCollecte"
                  className="rackSelection--title centerLign roadMapFont--titleValue roadMapFont--titleValue--dyn"
                >
                  Commentaire sur la collecte :
                </label>
                <textarea
                  type="textarea"
                  id="commentCollecte"
                  autoComplete="off"
                  onChange={(e) => setRackComment(e.target.value)}
                  required={false}
                  placeholder="Si vous avez des remarques sur la collecte, vous pouvez les détailler ici ..."
                ></textarea>
              </div>

              <div className="buttonsContainer">
                <div className="buttonContainer--small">
                  {selectedRacks.length > 0 ? (
                    <DashButton
                      //disabled={disabled}
                      action="action"
                      text="Valider la collecte"
                      onClick={(e) => pushInRacks(e)}
                    />
                  ) : (
                    <DashButton
                      disabled={true}
                      action="inactive"
                      text="Choisir au moins un rack"
                    />
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="cancel"
                    text="Annuler"
                    onClick={(e) => toggleModalCollecte(e)}
                  />
                </div>
              </div>
            </ModalContent>
          </ModalLayout>
        )}
        {modalCloseRoadmap && (
          <ModalLayout
            toggle={toggleModalCloseRoadmap}
            modal={modalCloseRoadmap}
            label="Clôture de la feuille de route"
            colorClass="green"
          >
            <ModalContent
              text={
                "___Vous êtes sur le point de /g/ clôturer la feuille de route /g/ suivante : :::" +
                "___/g/RM" +
                roadmapSelected[0]?.id +
                " - " +
                dateFormat(`${roadmapSelected[0]?.roadmapDate}`) +
                " - " +
                roadmapSelected[0]?.moment +
                " /g/- " +
                roadmapSelected[0]?.comment +
                ":::Cela implique que : :::___1. Les clients seront prévenus du déchargement de leur collecte :::___2. Cette feuille de route sera supprimée :::___3. Les demandes en cours seront supprimées :::___4. Le renouvellement automatique des demandes type 'contrat' sera activé pour tous les entreprises encore sous contrat"
              }
              moreText="Souhaitez-vous clôturer cette feuille de route ?"
            >
              <div className="dashform__form__block dashform__form__block--padding ">
                <CheckBox
                  order="023"
                  text="Je confirme avoir pris connaissance des implications de la clôture de cette feuille de route"
                  afterDisable={() => setCheckA(false)}
                  afterSelect={() => setCheckA(true)}
                />
              </div>

              <div className="buttonsContainer">
                <div className="buttonContainer--small">
                  {checkA ? (
                    <DashButton
                      //disabled={disabled}
                      action="action"
                      text="Clôturer la feuille"
                      onClick={(e) => finishRoadMap(e)}
                    />
                  ) : (
                    <DashButton
                      disabled={true}
                      action="inactive"
                      text="Veuillez confirmer"
                    />
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="cancel"
                    text="Annuler"
                    onClick={(e) => toggleModalCloseRoadmap(e)}
                  />
                </div>
              </div>
            </ModalContent>
          </ModalLayout>
        )}
      </div>
    </div>
  );
};

export default RoadMap;
