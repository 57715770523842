import ComboTtBtn from "../blocks/ComboTtBtn";
import BlockPoint from "../blocks/BlockPoint";

const SectHomeB = () => {
  return (
    <section className="sectHomeB">
      <ComboTtBtn
        titleA="Nos Services"
        paraA="Spyde vous propose un large choix de services et de solutions, adaptés à votre entreprise."
        paraC="Grâce à sa logistique performante et le dynamisme de ses équipes, Spyde vous assure des interventions rapides et de qualité sur toute la région Bordelaise."
        paraB=""
        textColor="dark"
        btnA="Prendre rendez-vous"
        goToA="/#sectRdv"
        goToB="/services"
        btnB="Découvrir Nos Services"
        btnColorB="red"
      />
      <div className="sectHomeB__points">
        <BlockPoint
          number="01"
          title="Collecte"
          text="Nous récupérons plusieurs types de déchets, sur la région Bordelaise, directement dans votre entreprise, avec une logistique adaptée."
        />
        <BlockPoint
          number="02"
          title="Recyclage"
          text="Grâce à nos différents partenariats, nous recyclons pour vous tous les déchets pouvant l'être et ainsi réduire votre impact Carbone."
        />
        <BlockPoint
          number="03"
          title="Valorisation"
          text="Travaillant avec plusieurs entreprises de recyclage, nous optimisons pour un meilleur rendement avec un cash-back performant. "
        />
        <BlockPoint
          number="04"
          title="Fidélisation"
          text="Et un bonus, ça vous intéresse ? Pour fidéliser nos clients, nous avons mis en place un processus qui réduit votre facture !"
        />
      </div>
    </section>
  );
};
export default SectHomeB;
