import WidgetContainer from "../../components/widgets/WidgetContainer";
import MySpydeInfos from "../../components/blocks/MySpydeInfos";
import MyAgencySpyde from "../../components/blocks/MyAgencySpyde";
import MyConnexion from "../../components/blocks/MyConnexion";

const SpydeProfil = () => {
  return (
    <div className="configGrid">
      <section className="widget widget--half">
        <WidgetContainer
          //key="1"
          icon="person-circle-outline"
          title="Mes informations"
          order={1}
          open={true}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MySpydeInfos />
        </WidgetContainer>
        <WidgetContainer
          //key="1"
          icon="business-outline"
          title="Mon Agence Spyde"
          order={2}
          open={true}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyAgencySpyde />
        </WidgetContainer>
        <WidgetContainer
          //key="1"
          icon="shield-half-outline"
          title="Mes identifiants"
          order={4}
          open={true}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyConnexion />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default SpydeProfil;
