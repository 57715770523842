import { NavLink } from "react-router-dom";
import SvgArrow from "../elements/SvgArrow";

const ComboTtBtn = (props) => {
  return (
    <div
      className={"comboTtBtn comboTtBtn__text comboTtBtn--" + props.textColor}
    >
      <h2 className="comboTtBtn__text--reduce font__bigTitle">
        {!props.colorA ? (
          props.titleA
        ) : (
          <span
            className={"font__blockPoint font__blockPoint--" + props.colorA}
          >
            {props.titleA}
          </span>
        )}
        <br />
        {props.titleB}
        <br />
        {props.titleC}
      </h2>
      <p className="font__simpleText comboTtBtn__text--pad">
        {props.paraA}
        <br />
        {props.paraB}
        <br />
        {props.paraC}
      </p>

      <div className="comboTtBtn__btn">
        {props.btnA && (
          <a href="#sectRdv">
            <button className="btn btn__callToAction">{props.btnA}</button>
          </a>
        )}
        {props.btnB && (
          <NavLink to={props.goToB} className="">
            <button
              className={
                "font__btn btn btn__simple btn__simple--" + props.btnColorB
              }
            >
              {props.btnB}
              <SvgArrow className={"btn__arrow--" + props.btnColorB} />
            </button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ComboTtBtn;
