import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import isEmpty from "../../utils/isEmpty";

ChartJS.register(ArcElement, Tooltip, Legend);

export const TotalRecycles = () => {
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const [totalWeight, setTotalWeight] = useState(0);
  const [percentPlastic, setPercentPlastic] = useState(0);
  const [percentPaper, setPercentPaper] = useState(0);
  const [percentInk, setPercentInk] = useState(0);
  const [percentElectronic, setPercentElectronic] = useState(0);
  const [percentAluminium, setPercentAluminium] = useState(0);

  useLayoutEffect(() => {
    let weights = [];
    recyclesData?.data.map((recycle) => {
      const { plastic, paper, electronic, aluminium, ink } = recycle;
      return weights.push(plastic + paper + electronic + aluminium + ink);
    });
    const reducer = (accumulator, curr) => accumulator + curr;
    if (!isEmpty(weights)) {
      setTotalWeight(weights.reduce(reducer));
    }
  }, [recyclesData]);

  //percentage weight
  useLayoutEffect(() => {
    let allWeights = [];
    let plasticWeight = 0;
    let paperWeight = 0;
    let electronicWeight = 0;
    let inkWeight = 0;
    let aluminiumWeight = 0;
    const getWeights = async () => {
      await recyclesData?.data.map((recycle) => {
        const { plastic, paper, electronic, aluminium, ink } = recycle;
        return allWeights.push([plastic, paper, electronic, aluminium, ink]);
      });
    };

    const getDetailWeight = async () => {
      await allWeights.map((weightsByRecycle) => {
        plasticWeight = plasticWeight + weightsByRecycle[0];
        paperWeight = paperWeight + weightsByRecycle[1];
        electronicWeight = electronicWeight + weightsByRecycle[2];
        aluminiumWeight = aluminiumWeight + weightsByRecycle[3];
        inkWeight = inkWeight + weightsByRecycle[4];
      });
    };
    getWeights();
    getDetailWeight();

    setPercentPlastic(
      Math.round(((plasticWeight * 100) / totalWeight) * 100) / 100
    );
    setPercentPaper(
      Math.round(((paperWeight * 100) / totalWeight) * 100) / 100
    );
    setPercentElectronic(
      Math.round(((electronicWeight * 100) / totalWeight) * 100) / 100
    );
    setPercentAluminium(
      Math.round(((aluminiumWeight * 100) / totalWeight) * 100) / 100
    );
    setPercentInk(Math.round(((inkWeight * 100) / totalWeight) * 100) / 100);
  }, [totalWeight]);

  const dataBis = {
    labels: ["Plastique", "Papier", "Aluminium", "Cartouche", "Electronique"],
    datasets: [
      {
        label: "%",
        data: [
          percentPlastic,
          percentPaper,
          percentAluminium,
          percentInk,
          percentElectronic,
        ],

        //nota = mettre les pourcentages
        backgroundColor: [
          "rgba(71, 138, 204, 0.8)",
          "rgba(65, 176, 137, 0.8)",
          "rgba(191, 29, 34, 0.8)",
          "rgba(66, 32, 33, 0.8)",
          "rgba(246, 214, 63, 0.8)",
        ],
        borderColor: ["white", "white", "white", "white", "white"],

        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <div className="donuts__weight">
        <p className="myrecycle__container__text">Poids Total</p>
        <h3 className="myrecycle__container__weight">
          {totalWeight < 1000000
            ? Math.round((totalWeight / 1000) * 10) / 10
            : Math.round((totalWeight / 1000000) * 100) / 100}
        </h3>
        <p className="myrecycle__container__text">
          {totalWeight < 1000000 ? "Kg" : "Tonnes"}
        </p>
      </div>
      <div className="donuts">{dataBis && <Doughnut data={dataBis} />}</div>
    </>
  );
};
