import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
//import useInputHook from "../../services/useInputHook";
import MapShowAddress from "../widgets/MapShowAddress";
import { useDispatch, useSelector } from "react-redux";
import { addClient, getClients } from "../../feature/client.slice";
import { toast } from "react-toastify";
import LoaderM from "../../components/blocks/LoaderM";
import useAuth from "../../services/useAuth";
import InputForm from "../elements/InputForm";
import InputAddress from "../elements/InputAddress";

import {
  infobulle_agency,
  infobulle_clientname,
  infobulle_zipCode,
  infobulle_address,
} from "../../utils/infoBulleText";

import { CLIENT_REGEX, SITE_REGEX, ZIPCODE_REGEX } from "../../utils/regex";
import IsCommercial from "../../services/contexts/IsCommercial";
import DirectorAccess from "../../services/contexts/DirectorAccess";

const CreateClient = ({ setDataClient }) => {
  const [isAuthorizedForModification, setIsAuthorizedForModification] =
    useState(false);

  const [clientName, setClientName] = useState("");
  const [site, setSite] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLong, setSelectedLong] = useState("");

  const [validZipCode, setValidZipCode] = useState(false);
  const [validSite, setValidSite] = useState(false);
  const [validClient, setValidClient] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const [clientIsSelected, setClientIsSelected] = useState(false);
  const [newDataClient, setNewDataClient] = useState(null);

  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.client.client);
  const userData = useSelector((state) => state.user.user);

  const auth = useAuth();
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  useEffect(() => {
    clientData.data.map((client) => {
      if (client.address == `${address}`) {
        setNewDataClient({ ...client });
        setDataClient({ ...client });
      }
    });
  }, [clientData]);

  useEffect(() => {
    setValidZipCode(ZIPCODE_REGEX.test(zipCode));
  }, [zipCode]);

  useEffect(() => {
    setValidSite(SITE_REGEX.test(site));
  }, [site]);

  useEffect(() => {
    setValidClient(CLIENT_REGEX.test(clientName));
  }, [clientName]);

  useEffect(() => {
    if (selectedLat && selectedLong) {
      setValidAddress(true);
    } else {
      setValidAddress(false);
    }
  }, [address]);

  const [creatorLoginId, setCreatorLoginId] = useState("");

  const initialiseUseState = () => {
    setClientIsSelected(false);
    setClientName("");
    setSite("");
    setAddress("");
    setZipCode("");
    setSelectedLat("");
    setSelectedLong("");
    setValidZipCode(false);
    setValidSite(false);
    setValidClient(false);
    setValidAddress(false);
  };

  useEffect(() => {
    if (userData) {
      setCreatorLoginId(userData.id);
    }
  }, []);

  const changeClientname = (e) => {
    setClientName(e.target.value);
  };

  const changeAgency = (e) => {
    setSite(e.target.value);
  };

  const changeZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const changeAddress = (e) => {
    setAddress(e);
  };

  const changePosition = (lat, long) => {
    setSelectedLong(long);
    setSelectedLat(lat);
  };

  const saveClient = async (e) => {
    e.preventDefault();

    const data = {
      address,
      clientName,
      agency: site,
      codeEts: zipCode,
      lat: selectedLat,
      long: selectedLong,
      spyder_login_id: creatorLoginId,
    };

    try {
      await dispatch(addClient(data));

      await initialiseUseState();
      await setClientIsSelected(true);
    } catch (error) {
      toast.error("Erreur lors de la création");
      setClientIsSelected(false);
    }
  };

  //check for authorization :
  useEffect(() => {
    setIsAuthorizedForModification(IsCommercial(auth) || DirectorAccess(auth));
  }, []);

  const clientStatus = useSelector((state) => state.client.status);

  return clientStatus === "loading" ? (
    <div className="loaderContainer loaderContainer--h100">
      <LoaderM
        color="red"
        text="Création de l'entreprise en cours ..."
        lg="2500"
      />
    </div>
  ) : (
    <>
      {clientIsSelected ? (
        <div className="responseContainer">
          <h3 className="dashform__title dashform__title--client">
            Client créé et sélectionné !
          </h3>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Raison sociale :</p>
            <p className="responseContainer__result">
              {newDataClient.clientName}
            </p>
          </div>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Nom du site :</p>

            <p className="responseContainer__result">{newDataClient.agency}</p>
          </div>

          <div className="responseContainer__block">
            <p className="responseContainer__title">Adresse de collecte :</p>

            <p className="responseContainer__result">{newDataClient.address}</p>
          </div>
        </div>
      ) : (
        <>
          <h3 className="dashform__title dashform__title--client">
            Création de l'entreprise
          </h3>

          <div className="formContainer">
            <div className="dashfrom">
              <form onSubmit={saveClient} className="dashform__form">
                <InputForm
                  label="Raison sociale :"
                  htmlfor="clientname"
                  setData={changeClientname}
                  info={infobulle_clientname}
                  placeholder="Saisir le nom de l'entreprise ..."
                  validation={validClient}
                  newValue={clientName}
                  isModify={true}
                  isAuthorized={isAuthorizedForModification}
                  required={true}
                />

                <InputForm
                  label="Nom du site :"
                  htmlfor="agency"
                  setData={changeAgency}
                  info={infobulle_agency}
                  placeholder="Saisir le nom de l'agence ..."
                  validation={validSite}
                  newValue={site}
                  isModify={true}
                  isAuthorized={isAuthorizedForModification}
                  required={true}
                />
                <InputForm
                  label="Code postal :"
                  htmlfor="zipcode"
                  setData={changeZipCode}
                  info={infobulle_zipCode}
                  placeholder="Saisir le code postal du point de collecte ..."
                  validation={validZipCode}
                  newValue={zipCode}
                  isModify={true}
                  isAuthorized={isAuthorizedForModification}
                  required={true}
                />

                <InputAddress
                  label="Adresse de collecte :"
                  htmlfor="address"
                  setAddress={changeAddress}
                  setPosition={changePosition}
                  info={infobulle_address}
                  //defaultValue={dataToShow.address}
                  placeholder="Sélectionner le lieu de collecte ..."
                  validation={validAddress}
                  isModify={true}
                  isAuthorized={isAuthorizedForModification}
                  //dataToShow={dataToShow.address}
                  required={true}
                />

                <div className="dashform__form--btn">
                  {clientName.length > 3 &&
                  address &&
                  selectedLat &&
                  selectedLong &&
                  site &&
                  validZipCode &&
                  validClient &&
                  validSite ? (
                    <DashButton
                      type="submit"
                      action="modify"
                      text="Créer l'entreprise"
                      //onClick = ""
                    />
                  ) : (
                    <DashButton
                      type="button"
                      action="inactive"
                      text="Compléter le formulaire"
                      //onClick={""}
                    />
                  )}
                </div>
              </form>
            </div>

            <div className="mapContainer mapContainer--small">
              <MapShowAddress
                clientLat={selectedLat}
                clientLong={selectedLong}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateClient;
