import { NavLink } from "react-router-dom";
import SmallBlockBlog from "../blocks/SmallBlockBlog";
import SvgArrow from "../elements/SvgArrow";
import { useSelector } from "react-redux";
import React from "react";
import isEmpty from "../../utils/isEmpty";

const SectHomeBlog = () => {
  const publicImagesData = useSelector(
    (state) => state.publishImagesBlog.imagesBlog
  );
  const publicPagesData = useSelector(
    (state) => state.publishPagesBlog.pagesBlog
  );

  return (
    <section className="sectHomeBlog">
      <div className="sectHomeBlog__container">
        <div className="sectHomeC__title">
          <h2 className="font__bigTitle">Notre Blog</h2>
          <NavLink to="/blog">
            <button className="btn btn__simple btn__simple--dark">
              Voir Tous Nos Articles
              <SvgArrow className="btn__arrow--dark" />
            </button>
          </NavLink>
        </div>
        <div className="sectHomeBlog__container__articles">
          {!isEmpty(publicPagesData) &&
            publicPagesData?.data.slice(0, 3).map((page, index) => {
              let imagesForPage = publicImagesData?.data.filter(
                (image) => image.pageBlog_id === page.id
              );

              return (
                <React.Fragment key={index}>
                  <SmallBlockBlog images={imagesForPage} data={page} />
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default SectHomeBlog;
