const IsItMyClient = (auth, dataToShow, userData) => {
  //check if rules Commercial ok :
  if (auth.auth.rules.includes(4002)) {
    // i'm Commercial but check is he's my client :
    if (dataToShow?.Spyder?.id === userData.id) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default IsItMyClient;
