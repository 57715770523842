import { useState } from "react";

const BtnOnOff = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  const actionSelect = () => {
    setIsSelected(true);
    props.afterSelect();
  };

  const actionDisable = () => {
    setIsSelected(false);
    props.afterDisable();
  };

  return (
    <>
      {isSelected && (
        <button
          className="btnOnOff btnOnOff--selected"
          onClick={() => actionDisable()}
        >
          {props.name}
        </button>
      )}
      {!isSelected && (
        <button className="btnOnOff" onClick={() => actionSelect()}>
          {props.name}
        </button>
      )}
    </>
  );
};

export default BtnOnOff;
