import axios from "axios";

const Axios = axios.create({
  //baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

let refreshToken;
Axios.interceptors.response.use(
  (res) => {
    // if AccesToken still valid :
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    // RECUPERATION DU REFRESH TOKEN BY SERVER :

    if (
      originalConfig.url !== "refresh" &&
      err.response.status === 401 &&
      originalConfig._retry !== true
    ) {
      originalConfig._retry = true;

      await Axios.get(process.env.REACT_APP_API_URL + "/autologin")
        .then((response) => {
          if (response.data.loggedIn) {
            refreshToken = response.data.user.refresh_token;
          } else {
            console.log("NO TOKEN : NO ACCESS");
          }
        })
        .catch((err) => {
          console.log("error server");
        });

      if (refreshToken) {
        Axios.defaults.headers.common[
          "authorization"
        ] = `Bearer ${refreshToken}`;
        await Axios.post(process.env.REACT_APP_API_URL + "/refresh")
          .then((response) => {
            Axios.defaults.headers.common[
              "authorization"
            ] = `Bearer ${response.data.responseToken.access_token}`;
            originalConfig.headers.authorization = `Bearer ${response.data.responseToken.access_token}`;
          })
          .catch((err) => {
            console.log("REFRESH TOKEN : Error");
            return Promise.reject(err);
          });
        return Axios(originalConfig);
      }
    } else return Promise.reject(err);
  }
);

export default Axios;
