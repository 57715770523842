const InputForm = (props) => {
  return !props.isModify || !props.isAuthorized ? (
    <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
      <label>{props.label}</label>
      <p className="responseContainer__result">{props.dataToShow}</p>
    </div>
  ) : (
    <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
      <div className="info" data-tooltip={props.info}>
        <ion-icon icon="information-circle-outline"></ion-icon>
      </div>

      <label htmlFor={props.htmlfor}>
        {props.labelBis ? props.labelBis : props.label}
      </label>

      {props.validation ? (
        <div className="check check__valid">
          <ion-icon icon="checkmark-circle-outline"></ion-icon>
        </div>
      ) : props.newValue ? (
        <div className="check check__invalid">
          <ion-icon icon="backspace-outline"></ion-icon>
        </div>
      ) : (
        ""
      )}

      {props.type && props.type === "textarea" ? (
        <textarea
          className="preline"
          type="textarea"
          id={props.htmlfor}
          autoComplete="off"
          onChange={(e) => props.setData(e)}
          required={props.required}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        ></textarea>
      ) : (
        <input
          type={props.type}
          id={props.htmlfor}
          autoComplete="off"
          onChange={(e) => props.setData(e)}
          required={props.required}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        ></input>
      )}
    </div>
  );
};

export default InputForm;
