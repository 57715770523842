import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import imgHelpForForest from "../../img/TreesImage.svg";
import isEmpty from "../../utils/isEmpty";

export const HelpForForest = () => {
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    let weights = [];
    recyclesData?.data.map((recycle) => {
      const { plastic, paper, electronic, aluminium, ink } = recycle;
      return weights.push(plastic + paper + electronic + aluminium + ink);
    });
    const reducer = (accumulator, curr) => accumulator + curr;

    if (!isEmpty(weights)) {
      recyclesData && setTotalWeight(weights.reduce(reducer) / 1000000);
    }
  }, [recyclesData]);

  return (
    <div className="helpForest">
      <div className="helpForest__count">
        {totalWeight < 1 ? (
          <>
            <p className="helpForest__count--title">Chaque geste compte !</p>
            <p className="helpForest__count--thanks">1 tonne recyclée =</p>
            <p className="helpForest__count--thanks">1 Arbre planté</p>
          </>
        ) : (
          <>
            <p className="helpForest__count--title">
              Grâce à vous, nous avons replanté :
            </p>
            <p className="helpForest__count--nbre">{Math.trunc(totalWeight)}</p>

            <p className="helpForest__count--article">
              {Math.trunc(totalWeight) < 2 ? "Arbre" : "Arbres"}
            </p>
            <p className="helpForest__count--thanks">
              Merci pour votre contribution !
            </p>
          </>
        )}
      </div>
      <div className="helpForest__img">
        <img
          src={imgHelpForForest}
          alt={"forêt de pins vue en contre plongée"}
        />
      </div>
    </div>
  );
};
