import isEmpty from "../utils/isEmpty";

const dateFormat = (date) => {
  if (!isEmpty(date)) {
    const elementDate = date.split("T")[0].split("-");
    return elementDate[2] + "/" + elementDate[1] + "/" + elementDate[0];
  } else return "No date";
};

export default dateFormat;
