import WidgetContainer from "../../components/widgets/WidgetContainer";
import { PublicMessages } from "../../components/widgets/PublicMessages";

const SpydeDocs = () => {
  return (
    <div className="configGrid">
      <section className="widget">
        <WidgetContainer
          //key="1"
          icon="document-text-outline"
          title="Demandes de rendez-vous"
          order={1}
          open={true}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <PublicMessages />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default SpydeDocs;
