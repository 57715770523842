import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getRacks = createAsyncThunk("Racks/gets", async () => {
  return await Axios.get(process.env.REACT_APP_API_URL + "/rack").then(
    (res) => res.data
  );
});

export const getClientRacks = createAsyncThunk(
  "Racks/getClientRacks",
  async (idClient) => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/rack/client/" + idClient
    ).then((res) => res.data);
  }
);

export const addNewRack = createAsyncThunk("Racks/addNewRack", async (data) => {
  return await Axios.put(process.env.REACT_APP_API_URL + "/rack/add", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err.response.data.message));
});

export const pushInRack = createAsyncThunk(
  "Racks/pushInRack",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/rack/push/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const unpushInRack = createAsyncThunk(
  "Racks/unpushInRack",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/rack/unpush/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const backToSpyde = createAsyncThunk(
  "Racks/backToSpyde",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/rack/backtospyde/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const deleteOneRack = createAsyncThunk(
  "Racks/deleteOneRack",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/rack/delete/" + id
    ).then((res) => res && { data: id });
  }
);

const rackSlice = createSlice({
  name: "Racks",
  initialState: { racks: null, status: null, loading: false, error: "" },

  reducers: {
    deleteRack: (state, action) => {},
  },

  extraReducers: {
    [getRacks.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getRacks.fulfilled]: (state, { payload }) => {
      state.racks = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getRacks.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getClientRacks.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getClientRacks.fulfilled]: (state, { payload }) => {
      state.racks = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getClientRacks.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewRack.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewRack.fulfilled]: (state, { payload }) => {
      let newRack = { ...payload.data };
      state.racks.data = [...state.racks.data, newRack];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewRack.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [deleteOneRack.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteOneRack.fulfilled]: (state, { payload }) => {
      state.racks.data = state.racks.data.filter(
        (roadmap) => roadmap.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteOneRack.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [pushInRack.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [pushInRack.fulfilled]: (state, { payload }) => {
      state.racks.data = state.racks.data.map((rack) => {
        if (rack.id === payload.data.id) {
          return {
            ...rack,
            ...payload.data,
          };
        } else return rack;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [pushInRack.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [unpushInRack.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [unpushInRack.fulfilled]: (state, { payload }) => {
      state.racks.data = state.racks.data.map((rack) => {
        if (rack.id === payload.data.id) {
          return {
            ...rack,
            ...payload.data,
          };
        } else return rack;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [unpushInRack.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [backToSpyde.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [backToSpyde.fulfilled]: (state, { payload }) => {
      state.racks.data = state.racks.data.map((rack) => {
        if (rack.id === payload.data.id) {
          return {
            ...rack,
            ...payload.data,
          };
        } else return rack;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [backToSpyde.rejected]: (state, action) => {
      state.status = "failed";
      state.racks = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteRack } = rackSlice.actions;

export default rackSlice;
