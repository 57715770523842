import SvgArrow from "../elements/SvgArrow";
import BlockHoverImage from "../blocks/BlockHoverImage";
import ImageA from "../../../src/img/reforestation.jpg";
import ImageB from "../../../src/img/usine_fumée.jpg";
import ImageC from "../../../src/img/bouteille_ocean.jpg";
import { NavLink } from "react-router-dom";

const SectHomeC = () => {
  return (
    <section className="sectHomeC">
      <div className="max1200">
        <div className="sectHomeC__title">
          <h2 className="font__bigTitle">Nos Actions</h2>
          <NavLink to="/actions">
            <button className="btn btn__simple btn__simple--dark">
              Découvrir Nos Actions
              <SvgArrow className={"btn__arrow--dark"} />
            </button>
          </NavLink>
        </div>
        <div className="sectHomeC__actions">
          <div className="sectHomeC__actions__largePart">
            <BlockHoverImage
              image={ImageA}
              alt="Arbres vue de la terre vers le ciel"
              text="Spyde et son programme de reforestation "
              button="Voir notre action"
              size="large"
            />
          </div>
          <div className="sectHomeC__actions__smallPart">
            <BlockHoverImage
              image={ImageB}
              alt="Cheminée d'usine fumante"
              text="Réduire la pollution au quotidien "
              button="Voir notre action"
              size="small"
            />
            <BlockHoverImage
              image={ImageC}
              alt="Bouteille de plastique flottante dans l'océan"
              text="Protégeons nos océans !"
              button="Voir notre action"
              size="small"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectHomeC;
