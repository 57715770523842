import { useState, useEffect } from "react";

const ImageForm = (props) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    setImageUrl(props.defaultValue);
  }, []);

  useEffect(() => {
    if (props.isModify && props.newValue) {
      setImageUrl(props.newValue);
    } else {
      setImageUrl(props.defaultValue);
    }
  }, [props.newValue, props.defaultValue]);

  return props.isModify && props.isAuthorized ? (
    <div className="imageContainer">
      <label className="imageLabel" htmlFor={props.htmlfor}>
        <div className="imageBlock">
          <img
            className="imageBlock__img "
            src={imageUrl ? imageUrl : props.defaultImage}
            alt={props.alt}
          />

          <div className="imageBlock__overlay">
            <p>{props.textOverlay}</p>
          </div>
        </div>
      </label>
      <input
        id={props.htmlfor}
        type="file"
        name={props.inputName}
        accept={props.accept}
        onChange={(e) => props.setData(e)}
      />
    </div>
  ) : (
    <div className="imageContainer">
      <div className="dashform--row imageLabel">
        <div className="imageBlock">
          <img
            className="imageBlock__img"
            src={imageUrl ? imageUrl : props.defaultImage}
            alt={props.alt}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageForm;
