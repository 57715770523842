import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";

const EndFormThreeButtons = (props) => {
  const [readyForSend, setReadyForSend] = useState(false);
  useEffect(() => {
    setReadyForSend(props.checkModification());
  }, [props.inputListener]);

  return (
    <div className="buttonsContainer">
      {(props.isAuthorized || props.isDirecteur) && (
        <>
          {props.isModify ? (
            <>
              <div className="buttonContainer--small">
                {readyForSend ? (
                  <DashButton
                    action="action"
                    text={props.validText}
                    onClick={(e) => props.sendData(e)}
                  />
                ) : (
                  <DashButton action="inactive" text={props.inactiveText} />
                )}
              </div>
              <div className="buttonContainer--small">
                <DashButton
                  action="cancel"
                  text={props.cancelText}
                  onClick={() => props.setForModification(false)}
                />
              </div>
            </>
          ) : (
            <div className="buttonContainer--small">
              <DashButton
                action="modify"
                text={props.modifText}
                onClick={() => props.setForModification(true)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EndFormThreeButtons;
