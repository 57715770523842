import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../feature/client.slice";
import LoaderM from "./LoaderM";

const FindClient = ({ setDataClient }) => {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.client.client);
  const clientLoading = useSelector((state) => state.client.loading);

  const [searchClient, setSearchClient] = useState("");
  const [clientIsSelected, setClientIsSelected] = useState(false);
  const [newDataClient, setNewDataClient] = useState(false);
  const [isReady, setIsReady] = useState(false);

  //!getdata
  useEffect(() => {
    dispatch(getClients());
    setIsReady(true);
  }, [dispatch]);

  return clientLoading ? (
    <div className="loaderContainer loaderContainer--h100">
      <LoaderM
        color="red"
        text="Recherche des clients disponibles ..."
        lg="2500"
      />
    </div>
  ) : (
    <>
      {clientIsSelected ? (
        <div className="responseContainer">
          <h3 className="responseContainer__success">Client sélectionné !</h3>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Raison sociale :</p>
            <p className="responseContainer__result">
              {newDataClient.clientName}
            </p>
          </div>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Nom du site :</p>
            <p className="responseContainer__result">{newDataClient.agency}</p>
          </div>

          <div className="responseContainer__block">
            <p className="responseContainer__title">Adresse de collecte :</p>
            <p className="responseContainer__result">{newDataClient.address}</p>
          </div>
        </div>
      ) : (
        <div className="dashform--row dashform__form">
          <div className="dashform__form__block">
            <label htmlFor="clientName">Rechercher l'entreprise :</label>
            <input
              type="text"
              id="clientName"
              autoComplete="off"
              onChange={(e) => setSearchClient(e.target.value)}
              required
              placeholder="Saisir le nom de l'entreprise recherchée ..."
            ></input>
          </div>

          <ul className="resultsAddress">
            {isReady &&
              searchClient &&
              clientData.data
                .filter((val) => {
                  if (searchClient == "") {
                    return val;
                  } else if (
                    val.clientName
                      .toLowerCase()
                      .includes(searchClient.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .slice(0, 5)
                .map((client, index) => {
                  return (
                    <li
                      key={index}
                      className="resultsAddress__result"
                      onClick={() => {
                        setDataClient(client);
                        setClientIsSelected(true);
                        setNewDataClient(client);
                      }}
                    >
                      {client.clientName +
                        " - " +
                        client.agency +
                        " - " +
                        client.address}
                    </li>
                  );
                })}
          </ul>
        </div>
      )}
    </>
  );
};

export default FindClient;
