import SvgArrow from "../components/elements/SvgArrow";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import dateFormat from "../utils/dateFormat";
import { getPublishPagesBlog } from "../feature/publishPageBlog.slice";
import { getPublishImagesBlog } from "../feature/publishImagesBlog.slice";
import isEmpty from "../utils/isEmpty";

const Article = () => {
  const dispatch = useDispatch();
  const publicImagesData = useSelector(
    (state) => state.publishImagesBlog.imagesBlog
  );
  const publicPagesData = useSelector(
    (state) => state.publishPagesBlog.pagesBlog
  );
  let { uid } = useParams();
  const [articleId, setArticleId] = useState(uid);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setArticleId(uid);
  }, [uid]);

  useEffect(() => {
    dispatch(getPublishPagesBlog());
    dispatch(getPublishImagesBlog());
  }, []);

  return (
    <section className="sectBlogLarge">
      {publicPagesData?.data
        .filter((page) => page.id == articleId)
        .map((page, index) => {
          let imgForArticle = publicImagesData?.data.filter(
            (image) => image.pageBlog_id == page.id
          );

          return (
            <React.Fragment key={index}>
              <div className="sectBlogLarge__title">
                <h1 className="font__blockPoint">{page.pageName}</h1>
                <p className="sectBlogLarge__text sectBlogLarge__text--date font__date">
                  Edité le {dateFormat(page.updatedAt)}
                </p>
              </div>
              {!isEmpty(imgForArticle) && (
                <div className="sectBlogLarge__img">
                  <img
                    src={imgForArticle[0].addressURL}
                    alt={imgForArticle[0].description}
                  ></img>
                  <p className="font__italic sectBlogLarge__img--detail">
                    {imgForArticle[0].detail}
                  </p>
                </div>
              )}
              <div className="sectBlogLarge__article">
                <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                  {page.intro}
                </p>
                {page.title_A && (
                  <h2 className="font__question">{page.title_A}</h2>
                )}
                {page.paragraph_A && (
                  <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                    {page.paragraph_A}
                  </p>
                )}

                {imgForArticle.length >= 1 && (
                  <div className="sectBlogLarge__img--bis">
                    {imgForArticle.slice(1, 2).map((image) => {
                      return (
                        <React.Fragment key={index}>
                          <img
                            src={image.addressURL}
                            alt={image.description}
                          ></img>
                          <p className="font__italic sectBlogLarge__img--detail">
                            {image.detail}
                          </p>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}

                {page.title_B && (
                  <h2 className="font__question">{page.title_B}</h2>
                )}
                {page.paragraph_B && (
                  <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                    {page.paragraph_B}
                  </p>
                )}
                <br />
                {page.title_C && (
                  <h2 className="font__question">{page.title_C}</h2>
                )}
                {page.paragraph_C && (
                  <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                    {page.paragraph_C}
                  </p>
                )}
                <br />
                {page.title_D && (
                  <h2 className="font__question">{page.title_D}</h2>
                )}
                {page.paragraph_D && (
                  <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                    {page.paragraph_D}
                  </p>
                )}
                <br />

                <div className="sectBlogLarge__grid">
                  {imgForArticle.length >= 2 &&
                    imgForArticle.slice(2).map((image) => {
                      return (
                        <div className="sectBlogLarge__img sectBlogLarge__img--small">
                          <img
                            className="sectBlogLarge__img--small"
                            src={image.addressURL}
                            alt={image.description}
                          ></img>
                        </div>
                      );
                    })}
                </div>
                <br />
                {page.link_name_A && page.link_url_A && (
                  <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
                    Pour en savoir plus, quelques liens utiles :
                  </p>
                )}

                {page.link_name_A && page.link_url_A && (
                  <a
                    href={page.link_url_A}
                    className="sectBlogLarge__link sectBlogLarge__text sectBlogLarge__text--article font__simpleText"
                  >
                    {page.link_name_A}
                  </a>
                )}
                {page.link_name_B && page.link_url_B && (
                  <a
                    href={page.link_url_B}
                    className="sectBlogLarge__link sectBlogLarge__text sectBlogLarge__text--article font__simpleText"
                  >
                    {page.link_name_B}
                  </a>
                )}
                {page.link_name_C && page.link_url_C && (
                  <a
                    href={page.link_url_C}
                    className="sectBlogLarge__link sectBlogLarge__text sectBlogLarge__text--article font__simpleText"
                  >
                    {page.link_name_C}
                  </a>
                )}
              </div>
              <div className="sectBlogLarge__btn">
                <NavLink to={"/blog"}>
                  <button className="font__btn btn btn__simple btn__simple--red">
                    Retourner aux articles
                    <SvgArrow className="btn__arrow--red" />
                  </button>
                </NavLink>
              </div>
            </React.Fragment>
          );
        })}
    </section>
  );
};

export default Article;
