//* See scss in _buttons.scss *//

const Point =(props)=>{
    return (
        <div className="point">
            <div className="point__number">
                <span className="point__number--text">{props.number}</span>
            </div>
            <div className="point__bg">
                <span className="point__bg--small"></span>
            </div>
        </div>
    )
}

export default Point;