import Point from "../elements/Point";

const BlockPoint =(props)=>{
    return (
        <div className="blockPoint">
            <Point number={props.number}/>
            <h3 className="font__blockPoint">{props.title}</h3>
            <p className="font__simpleText">{props.text}</p>
        </div>
    )
}

export default BlockPoint;