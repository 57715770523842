import React, { useState, useEffect } from "react";
import useAuth from "../../services/useAuth";
import DashLi from "./DashLi";
import { Navigate } from "react-router-dom";

const NavDash = () => {
  const [toggleMenu, setToggleMenu] = useState(true);

  const dashBar = document.getElementById("dashBar");
  //const changeWidth = document.getElementById("changeWidth");

  // const handleMenu = () => {
  //   setToggleMenu(!toggleMenu);
  //   dashBar && dashBar.classList.toggle("modifyWidth");
  //   //changeWidth && changeWidth.classList.toggle("modifyWidth");
  //   //dashBar && dashBar.classList.toggle("modifyWidth");
  // };

  useEffect(() => {
    dashBar && dashBar.classList.toggle("modifyWidth");
    //changeWidth && changeWidth.classList.toggle("modifyWidth");
  }, [toggleMenu]);

  const closeMenu = () => {
    if (toggleMenu) {
      setToggleMenu(false);
    }
  };

  const auth = useAuth();

  //? NOTA : think to change largeur if you change breakpoints //
  //! Modifier les après création des routes //
  return (
    <nav id="dashBar" className="menu" role="navigation">
      <button
        id="btnChange"
        className="btn__reducer"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        {toggleMenu ? (
          <ion-icon icon="chevron-forward-outline" />
        ) : (
          <ion-icon icon="chevron-back-outline" />
        )}
      </button>

      {auth?.auth.rules ? (
        auth?.auth.rules[0] === 2000 ? (
          <ul className="naviDash">
            <span
              className={
                toggleMenu ? "naviDash__section " : "naviDash__section unShow"
              }
            >
              MON COMPTE
            </span>
            {auth?.auth.rules.includes(2000) && (
              <span onClick={() => closeMenu()}>
                <DashLi
                  goTo="spyde/profil"
                  icon="person-outline"
                  name="Mon profil"
                />
              </span>
            )}

            <span
              className={
                toggleMenu ? "naviDash__section " : "naviDash__section unShow"
              }
            >
              MES OUTILS
            </span>
            {auth?.auth.rules.includes(4001) && (
              <span onClick={() => closeMenu()}>
                <DashLi
                  goTo="spyde/clients"
                  icon="people-circle-outline"
                  name="Comptes Clients"
                />
              </span>
            )}
            {auth?.auth.rules.includes(5000) ||
              (auth?.auth.rules.includes(4002) && (
                <span onClick={() => closeMenu()}>
                  <DashLi
                    goTo="spyde/documents"
                    icon="documents-outline"
                    name="Demande Rdv"
                  />
                </span>
              ))}
            {(auth?.auth.rules.includes(3001) ||
              auth?.auth.rules.includes(3002)) && (
              <span onClick={() => closeMenu()}>
                <DashLi
                  goTo="spyde/logistic"
                  icon="git-compare-outline"
                  name="Logistique"
                />
              </span>
            )}

            {auth?.auth.rules.includes(6000) && (
              <>
                <span
                  className={
                    toggleMenu
                      ? "naviDash__section "
                      : "naviDash__section unShow"
                  }
                >
                  DIRECTION
                </span>
                <span onClick={() => closeMenu()}>
                  <DashLi
                    goTo="spyde/blog"
                    icon="newspaper-outline"
                    name="Blog Spyde"
                  />
                </span>
              </>
            )}
            {auth?.auth.rules.includes(7000) && (
              <span onClick={() => closeMenu()}>
                <DashLi
                  goTo="spyde/gestion"
                  icon="globe-outline"
                  name="Gestion Spyde"
                />
              </span>
            )}
          </ul>
        ) : (
          <ul className="naviDash">
            <span
              className={
                toggleMenu ? "naviDash__section " : "naviDash__section unShow"
              }
            >
              MON COMPTE
            </span>

            <span onClick={() => closeMenu()}>
              <DashLi goTo="profil" icon="person-outline" name="Mon profil" />
            </span>
            <span onClick={() => closeMenu()}>
              <DashLi
                goTo="contrat"
                icon="document-text-outline"
                name="Mon contrat"
              />
            </span>
            {/* <span onClick={() => closeMenu()}>
              <DashLi
                goTo="documents"
                icon="documents-outline"
                name="Mes documents"
              />
            </span> */}

            <span
              className={
                toggleMenu ? "naviDash__section " : "naviDash__section unShow"
              }
            >
              MON SUIVI
            </span>
            <span onClick={() => closeMenu()}>
              <DashLi
                goTo="indicators"
                icon="bar-chart-outline"
                name="Mes Indicateurs"
              />
            </span>
            <span onClick={() => closeMenu()}>
              <DashLi
                goTo="mycollect"
                icon="swap-horizontal-outline"
                name="Mes collectes"
              />
            </span>
          </ul>
        )
      ) : (
        <Navigate to="/seconnecter" />
      )}
    </nav>
  );
};

export default NavDash;
