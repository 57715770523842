


const SvgBlog =()=> {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 1100">
            <path fill="#F4F4F4" d="M1321.77,350.89h-2.869v4.69L1321.77,350.89z"/>
            <path fill="#F4F4F4" d="M1388.78,444.9l-2.58-9.92l-6.181,9.92H1388.78z"/>
            <path fill="#DADDDF" d="M1322.561,677.7l-0.101,0.149l9.06-0.069v-0.08H1322.561z"/>
            <path opacity="0.1" d="M1223.82,684.65h-0.12v3.27h0.12V684.65z"/>
            <path fill="#3F4249" d="M1018.62,837.42h-93.271c-0.079,0.01-0.158,0.004-0.235-0.019c-0.076-0.023-0.147-0.062-0.207-0.115
                c-0.061-0.052-0.108-0.117-0.142-0.19c-0.032-0.072-0.05-0.15-0.05-0.23s0.018-0.159,0.05-0.232
                c0.033-0.072,0.081-0.137,0.142-0.189c0.06-0.053,0.131-0.093,0.207-0.115c0.077-0.023,0.156-0.029,0.235-0.019h93.271
                c0.13,0.019,0.26,0.084,0.35,0.187c0.09,0.102,0.13,0.232,0.13,0.369c0,0.135-0.04,0.266-0.13,0.368
                C1018.88,837.335,1018.75,837.402,1018.62,837.42z"/>
            <path fill="#3F4249" d="M1111.42,783.7H924.23c-0.079,0.011-0.159,0.004-0.236-0.02c-0.076-0.022-0.147-0.062-0.207-0.114
                c-0.061-0.053-0.108-0.117-0.141-0.19c-0.033-0.072-0.051-0.151-0.051-0.231c0-0.079,0.018-0.158,0.051-0.231
                c0.032-0.072,0.08-0.137,0.141-0.19c0.06-0.052,0.131-0.091,0.207-0.113c0.077-0.023,0.157-0.03,0.236-0.02h187.189
                c0.13,0.019,0.26,0.084,0.35,0.187c0.091,0.102,0.141,0.232,0.141,0.368c0,0.137-0.05,0.267-0.141,0.369
                C1111.68,783.616,1111.55,783.682,1111.42,783.7z"/>
            <path fill="#3F4249" d="M1016.39,893.08h-93.27c-0.134-0.018-0.257-0.084-0.347-0.188c-0.09-0.102-0.139-0.232-0.139-0.367
                c0-0.137,0.049-0.268,0.139-0.369c0.09-0.103,0.213-0.168,0.347-0.187h93.27c0.08-0.011,0.16-0.004,0.24,0.02
                c0.07,0.022,0.14,0.062,0.2,0.114c0.06,0.053,0.11,0.117,0.14,0.189c0.04,0.073,0.05,0.152,0.05,0.232s-0.01,0.158-0.05,0.23
                c-0.029,0.073-0.08,0.138-0.14,0.19c-0.061,0.053-0.13,0.092-0.2,0.114C1016.55,893.084,1016.47,893.091,1016.39,893.08
                L1016.39,893.08z"/>
            <path fill="#3F4249" d="M1017.51,973.65h-93.271c-0.146,0-0.285-0.059-0.389-0.161c-0.104-0.104-0.161-0.243-0.161-0.39
                c0-0.146,0.058-0.285,0.161-0.389c0.104-0.103,0.243-0.161,0.389-0.161h93.271c0.15,0,0.29,0.059,0.391,0.161
                c0.1,0.104,0.159,0.243,0.159,0.389c0,0.146-0.06,0.286-0.159,0.39C1017.8,973.592,1017.66,973.65,1017.51,973.65L1017.51,973.65z"
                />
            <path fill="#3F4249" d="M1110.311,919.94H923.12c-0.134-0.019-0.257-0.084-0.347-0.187c-0.09-0.102-0.139-0.232-0.139-0.369
                c0-0.136,0.049-0.267,0.139-0.369c0.09-0.102,0.213-0.168,0.347-0.186h187.19c0.129,0.018,0.26,0.084,0.35,0.186
                c0.09,0.103,0.14,0.233,0.14,0.369c0,0.137-0.05,0.268-0.14,0.369C1110.57,919.856,1110.439,919.922,1110.311,919.94
                L1110.311,919.94z"/>
            <path fill="#21252B" d="M1170.21,499.48H376.951c-8.047,0-14.57,6.523-14.57,14.569v538.03c0,8.05,6.523,14.57,14.57,14.57h793.259
                c8.05,0,14.57-6.521,14.57-14.57V514.05C1184.78,506.004,1178.26,499.48,1170.21,499.48z"/>
            <path fill="#21252B" d="M329.141,1024.7h890.999v31.83c0,4.72-1.87,9.25-5.21,12.59c-3.34,3.33-7.869,5.21-12.59,5.21H346.941
                c-4.722,0-9.249-1.88-12.588-5.21c-3.338-3.34-5.213-7.87-5.213-12.59V1024.7z"/>
            <path fill="#3B3B3B" d="M1161.561,522.01H385.609v478.971h775.951V522.01z"/>
            <path fill="#3F4249" d="M1184.78,1026.74c0,0.27-184.12,0.5-411.18,0.5s-411.22-0.23-411.22-0.5c0-0.271,184.08-0.5,411.22-0.5
                S1184.78,1026.46,1184.78,1026.74z"/>
            <path fill="#3F4249" d="M686.461,1024.7h176.37l-9.36,16.08H696.191L686.461,1024.7z"/>
            <path fill="#FFFFFF" d="M1098.061,407.54h-573.21c-24.729,0-44.79,19.58-44.79,43.729v550h587V457.46
                c0.029-8.363,2-16.604,5.77-24.07c3.77-7.465,9.23-13.946,15.95-18.93L1098.061,407.54z"/>
            <path fill="#B2B6BB" d="M1095,407.54h6c7.43,0,14.55,2.95,19.8,8.201s8.2,12.373,8.2,19.799v64.08h-62v-64.08
                c0-7.426,2.95-14.548,8.2-19.799S1087.57,407.54,1095,407.54L1095,407.54z"/>
            <g opacity="0.1">
                <path opacity="0.1" d="M1095,408.5h6c7.43,0,14.55,2.95,19.8,8.201s8.2,12.373,8.2,19.799v64.08h-62
                    V436.5c0-7.426,2.95-14.548,8.2-19.799S1087.57,408.5,1095,408.5z"/>
            </g>
            <path fill="#3F4249" d="M878.06,439.11H610.521c-0.136-0.019-0.259-0.084-0.348-0.187c-0.09-0.102-0.139-0.232-0.139-0.369
                c0-0.135,0.049-0.266,0.139-0.369c0.089-0.102,0.212-0.168,0.348-0.186H878.06c0.08-0.011,0.16-0.004,0.237,0.019
                c0.076,0.023,0.147,0.062,0.207,0.115c0.061,0.052,0.108,0.117,0.141,0.19c0.033,0.072,0.051,0.15,0.051,0.23
                s-0.018,0.159-0.051,0.232c-0.032,0.072-0.08,0.137-0.141,0.189c-0.06,0.053-0.131,0.092-0.207,0.114
                C878.22,439.114,878.14,439.121,878.06,439.11z"/>
            <path fill="#3F4249" d="M1031.6,439.11H898.291c-0.079,0.011-0.16,0.004-0.236-0.02c-0.076-0.022-0.146-0.062-0.207-0.114
                s-0.108-0.117-0.142-0.189c-0.033-0.073-0.05-0.152-0.05-0.232s0.017-0.158,0.05-0.23c0.033-0.073,0.081-0.139,0.142-0.19
                c0.061-0.053,0.131-0.092,0.207-0.115c0.076-0.022,0.157-0.029,0.236-0.019H1031.6c0.141,0.018,0.261,0.084,0.351,0.186
                c0.09,0.104,0.14,0.234,0.14,0.369c0,0.137-0.05,0.268-0.14,0.369C1031.86,439.026,1031.74,439.092,1031.6,439.11L1031.6,439.11z"/>
            <path fill="#3F4249" d="M1029.76,498.37H609.601c-0.135-0.019-0.258-0.085-0.347-0.188c-0.09-0.102-0.139-0.232-0.139-0.367
                c0-0.137,0.049-0.268,0.139-0.369c0.089-0.103,0.212-0.168,0.347-0.187h420.159c0.13,0.019,0.26,0.084,0.351,0.187
                c0.09,0.102,0.14,0.232,0.14,0.369c0,0.135-0.05,0.266-0.14,0.367C1030.02,498.285,1029.89,498.352,1029.76,498.37L1029.76,498.37z"
                />
            <path fill="#3F4249" d="M1031.561,739.83H516.56c-0.135-0.018-0.258-0.085-0.347-0.188c-0.09-0.102-0.139-0.232-0.139-0.367
                c0-0.137,0.049-0.268,0.139-0.369c0.089-0.103,0.212-0.168,0.347-0.187h515.001c0.129,0.019,0.26,0.084,0.35,0.187
                c0.09,0.102,0.13,0.232,0.13,0.369c0,0.135-0.04,0.266-0.13,0.367C1031.82,739.745,1031.689,739.812,1031.561,739.83
                L1031.561,739.83z"/>
            <path fill="#3F4249" d="M1031.561,765.3H516.56c-0.135-0.018-0.258-0.084-0.347-0.186c-0.09-0.103-0.139-0.233-0.139-0.369
                c0-0.135,0.049-0.267,0.139-0.368c0.089-0.102,0.212-0.168,0.347-0.187h515.001c0.129,0.019,0.26,0.085,0.35,0.187
                s0.13,0.233,0.13,0.368c0,0.136-0.04,0.267-0.13,0.369C1031.82,765.216,1031.689,765.282,1031.561,765.3L1031.561,765.3z"/>
            <path fill="#3F4249" d="M1033.78,968H518.781c-0.146,0-0.286-0.058-0.39-0.161c-0.104-0.103-0.161-0.243-0.161-0.389
                s0.058-0.285,0.161-0.39c0.104-0.103,0.243-0.16,0.39-0.16h514.999c0.149,0,0.29,0.058,0.39,0.16c0.1,0.104,0.16,0.244,0.16,0.39
                s-0.061,0.286-0.16,0.389C1034.07,967.942,1033.93,968,1033.78,968z"/>
            <path fill="#3F4249" d="M1031.6,465.14H764.06c-0.078,0.012-0.158,0.005-0.235-0.018c-0.076-0.023-0.147-0.062-0.207-0.115
                c-0.061-0.053-0.108-0.117-0.142-0.189c-0.032-0.073-0.05-0.152-0.05-0.232c0-0.079,0.018-0.158,0.05-0.231
                c0.033-0.072,0.081-0.138,0.142-0.189c0.06-0.053,0.131-0.092,0.207-0.115c0.077-0.022,0.157-0.029,0.235-0.019h267.54
                c0.131,0.018,0.261,0.085,0.351,0.187c0.09,0.103,0.14,0.233,0.14,0.368c0,0.136-0.05,0.267-0.14,0.369
                C1031.86,465.056,1031.73,465.122,1031.6,465.14L1031.6,465.14z"/>
            <path fill="#3F4249" d="M743.831,465.14h-133.31c-0.136-0.018-0.259-0.084-0.348-0.186c-0.09-0.103-0.139-0.233-0.139-0.369
                c0-0.135,0.049-0.266,0.139-0.368c0.089-0.102,0.212-0.169,0.348-0.187h133.31c0.079-0.011,0.159-0.004,0.236,0.019
                c0.075,0.023,0.146,0.062,0.207,0.115c0.06,0.052,0.107,0.117,0.141,0.189c0.033,0.073,0.05,0.152,0.05,0.231
                c0,0.08-0.017,0.159-0.05,0.232c-0.033,0.072-0.081,0.137-0.141,0.189c-0.061,0.053-0.132,0.092-0.207,0.115
                C743.99,465.145,743.91,465.151,743.831,465.14z"/>
            <path fill="#F81C1C" d="M1012.55,537.588l-171.843,1.109l0.834,129.168l171.849-1.11L1012.55,537.588z"/>
            <path fill="#F81C1C" d="M535.108,801.255l-0.835,129.167l171.847,1.11l0.834-129.168L535.108,801.255z"/>
            <path fill="#B2B6BB" d="M1032.7,707.71H515.449v16.61H1032.7V707.71z"/>
            <path fill="#B2B6BB" d="M807.861,528.27H516.561v16.61h291.301V528.27z"/>
            <path fill="#3F4249" d="M809.001,570.36H621.771c-0.146,0-0.286-0.059-0.39-0.161c-0.103-0.104-0.161-0.243-0.161-0.39
                c0-0.146,0.059-0.285,0.161-0.389c0.104-0.103,0.243-0.161,0.39-0.161h187.229c0.146,0,0.286,0.059,0.389,0.161
                c0.104,0.104,0.161,0.243,0.161,0.389c0,0.146-0.058,0.286-0.161,0.39C809.287,570.302,809.146,570.36,809.001,570.36
                L809.001,570.36z"/>
            <path fill="#3F4249" d="M607.621,570.36h-93.271c-0.146,0-0.285-0.059-0.389-0.161c-0.103-0.104-0.161-0.243-0.161-0.39
                c0-0.146,0.059-0.285,0.161-0.389c0.104-0.103,0.243-0.161,0.389-0.161h93.271c0.146,0,0.286,0.059,0.389,0.161
                c0.104,0.104,0.161,0.243,0.161,0.389c0,0.146-0.058,0.286-0.161,0.39C607.907,570.302,607.767,570.36,607.621,570.36
                L607.621,570.36z"/>
            <path fill="#3F4249" d="M810.08,650.94H622.89c-0.135-0.019-0.258-0.084-0.347-0.187c-0.09-0.102-0.139-0.232-0.139-0.369
                c0-0.136,0.049-0.267,0.139-0.369c0.089-0.102,0.212-0.168,0.347-0.186h187.19c0.134,0.018,0.257,0.084,0.347,0.186
                c0.089,0.103,0.139,0.233,0.139,0.369c0,0.137-0.05,0.268-0.139,0.369C810.337,650.856,810.214,650.922,810.08,650.94L810.08,650.94
                z"/>
            <path fill="#3F4249" d="M608.741,650.94h-93.271c-0.08,0.011-0.16,0.004-0.236-0.02c-0.076-0.022-0.147-0.062-0.207-0.114
                c-0.061-0.053-0.108-0.117-0.142-0.189c-0.032-0.073-0.05-0.152-0.05-0.232s0.018-0.159,0.05-0.23
                c0.033-0.073,0.081-0.139,0.142-0.19c0.06-0.053,0.131-0.092,0.207-0.115c0.076-0.022,0.156-0.028,0.236-0.019h93.271
                c0.134,0.018,0.257,0.084,0.347,0.187c0.089,0.103,0.138,0.233,0.138,0.368c0,0.137-0.049,0.268-0.138,0.369
                C608.998,650.856,608.875,650.922,608.741,650.94L608.741,650.94z"/>
            <path fill="#3F4249" d="M701.53,597.22H514.349c-0.079,0.012-0.158,0.005-0.235-0.018c-0.076-0.023-0.147-0.062-0.207-0.115
                c-0.061-0.053-0.108-0.117-0.142-0.19c-0.032-0.072-0.05-0.151-0.05-0.231c0-0.079,0.018-0.158,0.05-0.231
                c0.033-0.072,0.081-0.137,0.142-0.189c0.06-0.053,0.131-0.092,0.207-0.115c0.077-0.022,0.156-0.029,0.235-0.019H701.53
                c0.079-0.011,0.159-0.004,0.235,0.019c0.076,0.023,0.147,0.062,0.207,0.115c0.061,0.053,0.108,0.117,0.142,0.189
                c0.032,0.073,0.05,0.152,0.05,0.231c0,0.08-0.018,0.159-0.05,0.231c-0.033,0.073-0.081,0.138-0.142,0.19
                c-0.06,0.053-0.131,0.092-0.207,0.115C701.689,597.225,701.609,597.231,701.53,597.22L701.53,597.22z"/>
            <path fill="#3F4249" d="M808.999,597.22h-93.31c-0.079,0.012-0.159,0.005-0.236-0.018c-0.076-0.023-0.147-0.062-0.207-0.115
                c-0.061-0.053-0.108-0.117-0.141-0.19c-0.033-0.072-0.051-0.151-0.051-0.231c0-0.079,0.018-0.158,0.051-0.231
                c0.032-0.072,0.08-0.137,0.141-0.189c0.06-0.053,0.131-0.092,0.207-0.115c0.077-0.022,0.157-0.029,0.236-0.019h93.31
                c0.135,0.019,0.258,0.085,0.347,0.187c0.089,0.103,0.14,0.233,0.14,0.368c0,0.136-0.051,0.267-0.14,0.369
                C809.257,597.136,809.134,597.202,808.999,597.22L808.999,597.22z"/>
            <path fill="#3F4249" d="M808.4,624.08H516c-0.079,0.011-0.16,0.004-0.236-0.02c-0.076-0.022-0.146-0.062-0.207-0.114
                s-0.108-0.117-0.142-0.19c-0.033-0.072-0.05-0.15-0.05-0.23s0.017-0.159,0.05-0.232c0.033-0.072,0.081-0.137,0.142-0.189
                s0.131-0.092,0.207-0.114c0.076-0.023,0.157-0.03,0.236-0.02h292.4c0.134,0.019,0.258,0.084,0.347,0.187
                c0.089,0.102,0.138,0.232,0.138,0.369c0,0.135-0.049,0.266-0.138,0.367C808.658,623.996,808.534,624.062,808.4,624.08L808.4,624.08z
                "/>
            <path fill="#3F4249" d="M809,677.8H516.59c-0.079,0.01-0.16,0.004-0.236-0.019c-0.076-0.023-0.146-0.062-0.207-0.115
                c-0.061-0.052-0.108-0.117-0.142-0.19c-0.033-0.072-0.05-0.151-0.05-0.23c0-0.08,0.017-0.159,0.05-0.232
                c0.033-0.072,0.081-0.137,0.142-0.189c0.061-0.054,0.131-0.092,0.207-0.115s0.157-0.029,0.236-0.018H809
                c0.134,0.018,0.257,0.084,0.347,0.186c0.089,0.103,0.139,0.233,0.139,0.369c0,0.135-0.05,0.266-0.139,0.368
                C809.257,677.715,809.134,677.781,809,677.8L809,677.8z"/>
            <path fill="#B2B6BB" d="M741.399,807.39H1032.7V790.78H741.399V807.39z"/>
            <path fill="#3F4249" d="M927.5,832.87H740.31c-0.135-0.019-0.258-0.085-0.347-0.188c-0.09-0.102-0.139-0.232-0.139-0.367
                c0-0.137,0.049-0.268,0.139-0.369c0.089-0.103,0.212-0.168,0.347-0.187H927.5c0.134,0.019,0.257,0.084,0.346,0.187
                c0.09,0.102,0.14,0.232,0.14,0.369c0,0.135-0.05,0.266-0.14,0.367C927.757,832.785,927.634,832.852,927.5,832.87L927.5,832.87z"/>
            <path fill="#3F4249" d="M1034.92,832.87h-93.271c-0.135-0.019-0.258-0.084-0.348-0.187c-0.089-0.103-0.138-0.233-0.138-0.368
                c0-0.137,0.049-0.268,0.138-0.369c0.09-0.103,0.213-0.168,0.348-0.187h93.271c0.08-0.011,0.16-0.004,0.24,0.02
                c0.07,0.022,0.14,0.062,0.2,0.113c0.06,0.054,0.109,0.118,0.14,0.19c0.04,0.073,0.05,0.152,0.05,0.232c0,0.079-0.01,0.158-0.05,0.23
                c-0.03,0.073-0.08,0.138-0.14,0.19c-0.061,0.053-0.13,0.092-0.2,0.114C1035.08,832.874,1035,832.881,1034.92,832.87L1034.92,832.87z
                "/>
            <path fill="#3F4249" d="M926.381,913.44H739.191c-0.146,0-0.286-0.059-0.39-0.161c-0.103-0.104-0.161-0.243-0.161-0.39
                c0-0.146,0.059-0.286,0.161-0.389c0.104-0.103,0.243-0.161,0.39-0.161h187.189c0.146,0,0.285,0.059,0.389,0.161
                s0.161,0.243,0.161,0.389c0,0.146-0.058,0.286-0.161,0.39C926.666,913.382,926.526,913.44,926.381,913.44L926.381,913.44z"/>
            <path fill="#3F4249" d="M1033.8,913.44h-93.269c-0.146,0-0.286-0.059-0.39-0.161c-0.104-0.104-0.161-0.243-0.161-0.39
                c0-0.146,0.058-0.286,0.161-0.389s0.243-0.161,0.39-0.161h93.269c0.15,0,0.29,0.059,0.39,0.161c0.101,0.103,0.16,0.243,0.16,0.389
                c0,0.146-0.06,0.286-0.16,0.39C1034.09,913.382,1033.95,913.44,1033.8,913.44L1033.8,913.44z"/>
            <path fill="#3F4249" d="M1034.92,859.73H847.729c-0.134-0.019-0.257-0.084-0.346-0.187c-0.09-0.102-0.139-0.233-0.139-0.369
                c0-0.135,0.049-0.266,0.139-0.368c0.089-0.102,0.212-0.169,0.346-0.187h187.191c0.08-0.01,0.16-0.004,0.24,0.019
                c0.07,0.023,0.14,0.062,0.2,0.115c0.06,0.052,0.109,0.117,0.14,0.19c0.04,0.071,0.05,0.15,0.05,0.23s-0.01,0.159-0.05,0.232
                c-0.03,0.071-0.08,0.137-0.14,0.189c-0.061,0.053-0.13,0.091-0.2,0.114C1035.08,859.734,1035,859.741,1034.92,859.73L1034.92,859.73
                z"/>
            <path fill="#3F4249" d="M833.58,859.73H740.31c-0.135-0.019-0.258-0.084-0.347-0.187c-0.09-0.102-0.139-0.233-0.139-0.369
                c0-0.135,0.049-0.266,0.139-0.368c0.089-0.102,0.212-0.169,0.347-0.187h93.271c0.079-0.01,0.159-0.004,0.236,0.019
                c0.075,0.023,0.146,0.062,0.207,0.115c0.06,0.052,0.107,0.117,0.141,0.19c0.033,0.071,0.05,0.15,0.05,0.23s-0.017,0.159-0.05,0.232
                c-0.033,0.071-0.081,0.137-0.141,0.189c-0.061,0.053-0.132,0.091-0.207,0.114C833.739,859.734,833.659,859.741,833.58,859.73
                L833.58,859.73z"/>
            <path fill="#3F4249" d="M1033.24,886.581H740.87c-0.146,0-0.285-0.058-0.389-0.162c-0.104-0.103-0.161-0.243-0.161-0.388
                c0-0.146,0.058-0.286,0.161-0.39s0.242-0.161,0.389-0.161h292.37c0.149,0,0.29,0.058,0.39,0.161c0.101,0.104,0.16,0.243,0.16,0.39
                c0,0.145-0.06,0.285-0.16,0.388C1033.53,886.523,1033.39,886.581,1033.24,886.581L1033.24,886.581z"/>
            <path fill="#3F4249" d="M1032.68,940.3H740.31c-0.146,0-0.285-0.058-0.389-0.161c-0.103-0.103-0.161-0.242-0.161-0.389
                s0.059-0.286,0.161-0.389c0.104-0.104,0.243-0.161,0.389-0.161h292.37c0.15,0,0.29,0.058,0.391,0.161
                c0.1,0.103,0.16,0.242,0.16,0.389s-0.061,0.286-0.16,0.389C1032.97,940.242,1032.83,940.3,1032.68,940.3L1032.68,940.3z"/>
            <path fill="#FF0000" d="M525.6,437.71h55l0.091,18.43h-2.631c-0.859-6.587-3.329-11.047-7.409-13.38
                c-2.287-1.279-5.707-1.979-10.261-2.1v48.14c0,3.373,0.586,5.607,1.761,6.7c1.18,1.1,3.619,1.66,7.34,1.66v2.32H536.93v-2.32
                c3.57,0,6-0.561,7.12-1.68c1.12-1.12,1.76-3.341,1.76-6.681v-48.14c-4.46,0.12-7.88,0.82-10.26,2.1
                c-4.374,2.388-6.844,6.848-7.41,13.38h-2.63L525.6,437.71z"/>
            <path fill="#FC9D9D" d="M1209.388,915.72l17.64,29c2.271,3.509,3.08,7.767,2.25,11.862c-0.83,4.097-3.229,7.706-6.68,10.058
                l-67.34,41.471l-11.811-15.2l44.24-43.13l-9-13.3L1209.388,915.72z"/>
            <path fill="#FC9D9D" d="M992.17,915.72l-17.64,29c-2.268,3.514-3.068,7.774-2.233,11.871c0.836,4.097,3.242,7.704,6.703,10.049
                l69.3,41.471l11.811-15.2l-46.19-43.13l9-13.3L992.17,915.72z"/>
            <path fill="#F81C1C" d="M1124.93,848.25l-48-1.66L1035,855c-3.35,0.589-6.54,1.872-9.36,3.766c-2.819,1.896-5.22,4.359-7.029,7.234
                L990,916.86l32.38,21.83l15.62-22.82v96.31h125.37v-96.31l14.11,21.71l33-20.75l-27.94-51.05c-1.851-3.033-4.351-5.625-7.31-7.593
                c-2.961-1.968-6.32-3.264-9.83-3.798l-40.54-6.14"/>
            <path fill="#FC9D9D" d="M1076.561,804.2l-0.431,43.58c-0.01,0.976,0.3,1.93,0.87,2.72l0.39,0.68c10.36,16,35.671,10.9,43.82,2.62
                c0.67-0.556,1.19-1.278,1.5-2.097c0.3-0.817,0.39-1.701,0.25-2.563l-7.28-48.14L1076.561,804.2z"/>
            <path fill="#FC9D9D" d="M1120,784.39c0,0,8,52.62-21.061,54.761c-28.35,2.13-29.229-51.07-29.229-51.07l-0.771-15.24l36.5-6.41
                l9.221,4.761L1120,784.39z"/>
            <path opacity="0.15" d="M1120.63,834.78l-2.13-14.471c-2.63,9.32-8.061,17.091-17.58,18.641h0.1
                l-2,0.29c-7.569,0.58-13.88-2.91-18.64-10.391c-0.09-0.141-0.229-0.239-0.399-0.274c-0.16-0.036-0.33-0.006-0.471,0.085
                c-0.07,0.043-0.13,0.101-0.18,0.169c-0.05,0.067-0.08,0.144-0.1,0.225c-0.021,0.081-0.021,0.165,0,0.247
                c0.01,0.082,0.039,0.159,0.09,0.229c5,8,11.74,11.649,19.8,11.069c1.7-0.121,3.38-0.457,5-1
                C1110.04,840.177,1115.95,838.451,1120.63,834.78L1120.63,834.78z"/>
            <path fill="#21252B" d="M1113.061,772.65c0,0,9.22-6.12,13.59,4.359c3.689,8.84-2.521,18.45-2.521,18.45l-5.34-0.58
                c-2.24-0.1-3.79-1.17-4-3.3L1113.061,772.65z"/>
            <path fill="#21252B" d="M1119.66,766.24c0.779,10-7.771,12.62-11.26,12.91l-28.641,2.43c-1.13,0.097-2.17,0.625-2.92,1.475
                c-0.74,0.851-1.13,1.955-1.08,3.085l0.49,9.13c0.05,0.596-0.13,1.187-0.5,1.653c-0.37,0.468-0.91,0.778-1.5,0.867l-1.75,0.19
                l-1.939,2.43c-11.261-2.72-15.921-34.66,4.76-41.061c2.229-0.718,4.069-2.29,5.14-4.369c3-6.221,15.34-7.87,21.55-0.881
                c0.61,0.726,1.38,1.297,2.24,1.669c0.87,0.372,1.82,0.533,2.76,0.472C1114.42,756.05,1119.27,760.71,1119.66,766.24z"/>
            <path fill="#FC9D9D" d="M1072.58,801.09c-0.08-0.848-0.4-1.657-0.9-2.342c-0.51-0.686-1.189-1.22-1.979-1.548
                s-1.65-0.438-2.49-0.316c-0.84,0.122-1.64,0.47-2.3,1.006l-0.1,0.091c-5,4.27-1.75,17.67,9.129,16.51L1072.58,801.09z"/>
            <path fill="#FC9D9D" d="M1126.65,794l-0.101-0.09c-0.729-0.427-1.56-0.648-2.41-0.643c-0.84,0.007-1.67,0.24-2.399,0.677
                c-0.721,0.437-1.32,1.061-1.721,1.806c-0.399,0.746-0.59,1.585-0.55,2.43l0.58,12.811C1131,810.6,1132.09,797.5,1126.65,794z"/>
            <path fill="#21252B" d="M1073.939,802.74c0.021,0.334-0.09,0.661-0.31,0.914s-0.53,0.413-0.86,0.445
                c-0.319,0.044-0.649-0.033-0.92-0.219c-0.27-0.185-0.46-0.463-0.539-0.781l-1.261-8.64l3.59,0.1L1073.939,802.74z"/>
            <path fill="#21252B" d="M1121.7,799.25c0.02,0.334-0.09,0.663-0.311,0.915c-0.22,0.253-0.529,0.409-0.859,0.435
                c-0.32,0.047-0.65-0.031-0.92-0.217c-0.271-0.185-0.46-0.465-0.53-0.783l-1.26-8.64l3.59,0.1L1121.7,799.25z"/>
            <path fill="#FC9D9D" d="M1177.85,1059.59l-24.899-2.7l-1.53,23.65l24.27,0.82L1177.85,1059.59z"/>
            <path opacity="0.15" d="M1155.15,1080.53l6.819-23.24l0.53,11.72c0.14,0.53,0.45,0.99,0.87,1.34
                c0.43,0.34,0.95,0.53,1.5,0.561l11.79,0.6l-1,9.851l-20.54-0.83H1155.15z"/>
            <path fill="#F81C1C" d="M1196.77,1095.55c-4.35-0.29-8.449-2.12-11.58-5.16l-3.689-3.569c-2.22-2.181-4.98-3.74-8-4.521
                c-2.45-0.66-4.6-2.16-6.08-4.229c-1.48-2.07-2.189-4.58-2.02-7.12v-0.5c0.21-3.08,1.63-5.95,3.939-7.98
                c2.32-2.04,5.35-3.069,8.43-2.88L1199,1061L1196.77,1095.55z"/>
            <path fill="#FFFFFF" d="M1176,1080c-0.17,0.08-0.5,0.061-0.57-0.2c-1-2.02-1.449-4.26-1.3-6.5c0.15-2.25,0.88-4.41,2.13-6.28
                c0.08-0.069,0.18-0.109,0.28-0.119c0.11,0,0.21,0.039,0.29,0.109c0.07,0.07,0.12,0.16,0.14,0.271c0.011,0.1-0.02,0.2-0.08,0.29
                c-1.17,1.729-1.859,3.74-2,5.819c-0.13,2.091,0.29,4.171,1.23,6.04c0.03,0.03,0.05,0.07,0.069,0.12c0.011,0.04,0.011,0.09,0,0.13
                C1176.16,1079.8,1176.1,1079.92,1176,1080L1176,1080z"/>
            <path fill="#FFFFFF" d="M1178.93,1081.49c-0.17,0.07-0.5,0-0.569-0.21c-1-2.011-1.44-4.25-1.3-6.5c0.149-2.24,0.879-4.4,2.129-6.271
                c0.07-0.08,0.171-0.14,0.28-0.14c0.11-0.01,0.22,0.02,0.3,0.1c0.091,0.07,0.141,0.17,0.15,0.28s-0.03,0.22-0.1,0.3
                c-1.181,1.73-1.87,3.74-2,5.83c-0.141,2.08,0.29,4.16,1.229,6.03c0.03,0.029,0.05,0.07,0.061,0.12c0.02,0.04,0.02,0.09,0.01,0.13
                C1179.11,1081.33,1179,1081.49,1178.93,1081.49z"/>
            <path fill="#FFFFFF" d="M1182,1083.27c-0.17,0.07-0.5,0-0.57-0.209c-0.99-2.011-1.439-4.25-1.29-6.5c0.141-2.24,0.88-4.4,2.12-6.271
                c0.07-0.08,0.17-0.13,0.28-0.14c0.11-0.011,0.22,0.02,0.31,0.1c0.08,0.07,0.131,0.17,0.141,0.28c0.01,0.109-0.03,0.22-0.101,0.3
                c-1.17,1.73-1.859,3.74-2,5.83c-0.13,2.08,0.29,4.16,1.23,6.029c0.03,0.03,0.06,0.08,0.069,0.121c0.011,0.05,0.011,0.09,0,0.14
                c-0.01,0.06-0.029,0.13-0.06,0.18C1182.1,1083.189,1182.05,1083.23,1182,1083.27L1182,1083.27z"/>
            <path fill="#3F4249" d="M1199.67,1095.83l-2.82-0.19l2.24-34.68l2.82,0.19c0.2,0.01,0.39,0.06,0.57,0.149
                c0.17,0.08,0.33,0.2,0.459,0.351c0.131,0.149,0.23,0.319,0.291,0.51c0.069,0.189,0.09,0.38,0.08,0.58l-2,31.609
                c-0.011,0.2-0.061,0.41-0.15,0.59c-0.09,0.19-0.21,0.351-0.36,0.49c-0.16,0.141-0.33,0.24-0.53,0.311
                C1200.08,1095.811,1199.87,1095.84,1199.67,1095.83z"/>
            <path fill="#FC9D9D" d="M1016.15,1075.189l24.25-1.319l-2-23.601l-24.851,3.2L1016.15,1075.189z"/>
            <path opacity="0.15" d="M1033.59,1074.21l-17.439,1l-1.17-9.83l11.77-0.83
                c0.55-0.04,1.08-0.26,1.5-0.62c0.42-0.359,0.71-0.84,0.84-1.38l4.28-11.66l0.3,23.29L1033.59,1074.21z"/>
            <path fill="#F81C1C" d="M992.42,1055.27l21.12-1.8c3.07-0.25,6.12,0.72,8.479,2.71c2.36,1.99,3.841,4.83,4.11,7.9v0.5
                c0.22,2.53-0.44,5.06-1.87,7.16c-1.439,2.1-3.55,3.63-5.99,4.35c-2.979,0.85-5.689,2.46-7.859,4.68l-3.61,3.65
                c-3.06,3.1-7.128,5.01-11.47,5.4L992.42,1055.27z"/>
            <path fill="#FFFFFF" d="M1015.59,1073.49c-0.01-0.04-0.01-0.09,0-0.141c0.01-0.039,0.03-0.08,0.061-0.119
                c0.899-1.881,1.29-3.971,1.109-6.061c-0.18-2.08-0.899-4.07-2.109-5.78c-0.04-0.04-0.07-0.079-0.101-0.13
                c-0.02-0.05-0.03-0.109-0.03-0.16c0-0.06,0.011-0.119,0.03-0.17c0.03-0.05,0.061-0.09,0.101-0.13s0.08-0.069,0.13-0.09
                s0.109-0.04,0.16-0.04c0.06,0,0.109,0.02,0.159,0.04c0.061,0.021,0.101,0.05,0.141,0.09c1.279,1.851,2.06,4,2.25,6.23
                c0.189,2.239-0.21,4.489-1.16,6.53c-0.07,0.26-0.4,0.289-0.57,0.22C1015.68,1073.7,1015.62,1073.6,1015.59,1073.49L1015.59,1073.49z
                "/>
            <path fill="#FFFFFF" d="M1012.72,1075.07c-0.01-0.04-0.01-0.09,0-0.141c0.011-0.04,0.03-0.08,0.061-0.119
                c0.91-1.881,1.29-3.971,1.12-6.061c-0.181-2.08-0.91-4.08-2.12-5.78c-0.04-0.04-0.07-0.08-0.101-0.13
                c-0.02-0.05-0.029-0.109-0.029-0.16c0-0.06,0.01-0.119,0.029-0.17c0.03-0.05,0.061-0.09,0.101-0.13s0.08-0.069,0.13-0.09
                c0.06-0.021,0.109-0.04,0.17-0.04c0.05,0,0.1,0.02,0.16,0.04c0.05,0.021,0.09,0.05,0.13,0.09c1.28,1.851,2.06,4,2.25,6.23
                c0.189,2.239-0.21,4.489-1.16,6.529c-0.07,0.261-0.4,0.29-0.57,0.221C1012.72,1075.29,1012.73,1075.24,1012.72,1075.07z"/>
            <path fill="#FFFFFF" d="M1009.7,1076.91c-0.01-0.09,0.01-0.18,0.06-0.25c0.91-1.88,1.3-3.98,1.12-6.061
                c-0.18-2.09-0.91-4.08-2.12-5.789c-0.04-0.041-0.069-0.08-0.1-0.131c-0.021-0.05-0.03-0.109-0.03-0.16c0-0.06,0.01-0.119,0.03-0.17
                c0.03-0.05,0.06-0.09,0.1-0.13s0.08-0.069,0.13-0.1c0.051-0.021,0.11-0.03,0.16-0.03c0.061,0,0.11,0.01,0.16,0.03
                c0.05,0.03,0.09,0.06,0.13,0.1c1.29,1.841,2.07,3.99,2.26,6.23c0.19,2.239-0.21,4.489-1.17,6.52c-0.06,0.261-0.39,0.29-0.56,0.22
                C1009.78,1077.12,1009.72,1077.02,1009.7,1076.91L1009.7,1076.91z"/>
            <path fill="#3F4249" d="M990.85,1088.7l-2.689-31.561c-0.018-0.2,0.004-0.39,0.062-0.579c0.059-0.19,0.154-0.36,0.28-0.511
                c0.127-0.149,0.282-0.28,0.456-0.37c0.175-0.09,0.366-0.14,0.561-0.16l2.82-0.239l2.95,34.63l-2.81,0.24
                c-0.406,0.02-0.804-0.12-1.108-0.4C991.068,1089.48,990.881,1089.11,990.85,1088.7L990.85,1088.7z"/>
            <g opacity="0.3">
                <path opacity="0.3" d="M1069.86,808.08c-0.19-0.008-0.37-0.08-0.511-0.203
                    c-0.14-0.124-0.239-0.292-0.27-0.476l-0.19-1.552c-0.04-1.258-0.52-2.463-1.359-3.399c-0.16-0.142-0.341-0.249-0.54-0.316
                    c-0.21-0.066-0.42-0.092-0.63-0.074c-0.19,0.039-0.391-0.001-0.561-0.11c-0.16-0.11-0.28-0.28-0.319-0.475
                    c-0.03-0.192,0.01-0.394,0.119-0.558c0.11-0.164,0.28-0.278,0.471-0.317c0.819-0.089,1.649,0.12,2.33,0.591
                    c1.069,0.77,1.75,2.329,1.939,4.369l0.2,1.551c0,0.213-0.06,0.423-0.18,0.597C1070.24,807.882,1070.061,808.012,1069.86,808.08
                    L1069.86,808.08z"/>
            </g>
            <g opacity="0.3">
                <path opacity="0.3" d="M1123.25,804.1c0.189-0.018,0.37-0.107,0.5-0.254
                    c0.12-0.145,0.189-0.333,0.18-0.525v-1.551c-0.109-0.611-0.08-1.239,0.07-1.843c0.15-0.602,0.43-1.167,0.811-1.657
                    c0.14-0.177,0.31-0.32,0.51-0.422c0.21-0.1,0.43-0.153,0.649-0.157c0.19-0.009,0.37-0.08,0.511-0.203
                    c0.14-0.124,0.239-0.292,0.27-0.478c-0.01-0.187-0.08-0.366-0.2-0.506c-0.13-0.141-0.29-0.234-0.479-0.264
                    c-0.41,0.013-0.801,0.108-1.17,0.281c-0.37,0.172-0.7,0.416-0.971,0.719c-1,1-1.359,2.52-1.26,4.66v1.55
                    C1122.48,803.81,1122.77,804.1,1123.25,804.1z"/>
            </g>
            <path opacity="0.15" d="M986.379,966c-14.4-7-10.6-23.33-10.6-23.33l-1.25,2.061
                c-2.264,3.512-3.063,7.771-2.228,11.864c0.835,4.096,3.239,7.7,6.697,10.045l69.541,41.471l-1.57-0.811l4.771-3.3L986.379,966z"/>
            <path fill="#61656D" d="M981.34,1002.35l56.7,6.41h125.37l53.84-6.41l-9.38,53.601l-203.69,3l-20.88-54.98"/>
            <path opacity="0.3" d="M981.34,1002.35l56.7,6.41h125.37l53.84-6.41l-9.38,53.601l-203.69,3
                l-20.88-54.98"/>
            <path fill="#61656D" d="M1092.32,1035.58l95.22-27.33c9.75-2.8,19.729-4.73,29.83-5.77l0,0c5.47-0.57,10.97,0.729,15.61,3.67l0,0
                c6.479,4.1,11.08,10.609,12.779,18.09l0.34,1.479c1.431,6.32,0.301,12.96-3.149,18.45c-1.91,3.05-4.47,5.63-7.5,7.58
                c-3.021,1.95-6.44,3.2-10,3.68l-190.88,25.5l-4.7-30.52L1092.32,1035.58z"/>
            <path opacity="0.2" d="M1243,1044.17c3.45-5.49,4.58-12.13,3.15-18.45l-0.34-1.479
                c-0.98-4.32-2.94-8.36-5.73-11.811c0,0,6,32.25-27.77,36.061l-28.23,4.64c-6.96,0.98-14.05,0.32-20.71-1.93l-61-18.66l-14,4
                l38.28,32.62l98.8-13.73c3.569-0.47,7-1.72,10.03-3.67C1238.51,1049.811,1241.08,1047.22,1243,1044.17L1243,1044.17z"/>
            <path fill="#61656D" d="M1100.7,1037.53l-94.45-29.891c-9.674-3.06-19.599-5.26-29.66-6.569l0,0
                c-5.452-0.71-10.983,0.43-15.71,3.239l0,0c-6.595,3.931-11.364,10.311-13.26,17.75l-0.38,1.471c-1.602,6.28-0.648,12.95,2.649,18.53
                l0,0c1.831,3.1,4.322,5.75,7.294,7.779c2.974,2.021,6.355,3.37,9.906,3.94l192.48,30.46l3.16-30.21L1100.7,1037.53z"/>
            <path opacity="0.2" d="M1032.47,1066.42l42.641,9.09l16.13-1.779l-58.92-9.091L1032.47,1066.42z"/>
            <path opacity="0.2" d="M1160.53,1079.58l-165.892-31.19c-4-0.77-8-1.38-12-2
                c-33.25-4.79-34.1-27.26-34.1-27.26c-0.354,0.95-0.658,1.91-0.91,2.89l-0.38,1.471c-1.6,6.279-0.648,12.949,2.65,18.529l0,0
                c1.832,3.101,4.322,5.75,7.295,7.78c2.973,2.021,6.354,3.37,9.904,3.94l192.481,30.46L1160.53,1079.58z"/>
            <path fill="#FFFFFF" d="M1099.88,865.77c-3.05,0.009-6.08-0.43-9-1.3c-4.21-1.278-8.11-3.385-11.49-6.193
                c-3.38-2.811-6.17-6.267-8.189-10.166l5.12-1.32c0.16,0.38,4.46,8.92,16,12.37c6.13,1.764,12.68,1.412,18.59-1
                c4.57-1.771,10.95-4.521,12.43-9.86l5,0.82c-1.65,6-7.56,11.06-15.7,14.229C1108.57,864.939,1104.25,865.76,1099.88,865.77z"/>
            <path opacity="0.2" d="M1163.41,915.87l-6.58-7.8l-1.08,46.16h7.97L1163.41,915.87z"/>
            <path fill="#21252B" d="M1038.57,1024l-6.11-71.3c-0.03-0.354,0.01-0.709,0.13-1.044c0.11-0.335,0.3-0.644,0.54-0.903
                c0.24-0.261,0.53-0.469,0.851-0.61c0.33-0.142,0.68-0.215,1.029-0.213h131.4c0.359,0,0.72,0.077,1.05,0.226
                c0.33,0.147,0.62,0.364,0.86,0.635c0.24,0.27,0.42,0.588,0.529,0.932c0.11,0.346,0.141,0.709,0.101,1.068l-8.74,72.13L1038.57,1024z
                "/>
</svg>
    
    )
  }
  
  export default SvgBlog;