import WidgetContainer from "../../components/widgets/WidgetContainer";
import MyInfos from "../../components/blocks/MyInfos";
import MyCompany from "../../components/blocks/MyCompany";
import MyConnexion from "../../components/blocks/MyConnexion";
import MyLogo from "../../components/blocks/MyLogo";

const ClientProfil = () => {
  return (
    <div className="configGrid">
      <section className="widget widget--half">
        <WidgetContainer
          //key="1"
          icon="person-circle-outline"
          title="Mes informations"
          order={2}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyInfos />
        </WidgetContainer>
        <WidgetContainer
          //key="1"
          icon="shield-half-outline"
          title="Mes identifiants"
          order={4}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyConnexion />
        </WidgetContainer>
        <WidgetContainer
          //key="1"
          icon="business-outline"
          title="Mon Entreprise"
          order={1}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyCompany />
        </WidgetContainer>

        <WidgetContainer
          //key="1"
          icon="flag-outline"
          title="Logo d'entreprise"
          order={3}
          //unSelectLine={unSelectLine} //!pour modification state depuis enfant//
        >
          <MyLogo />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default ClientProfil;
