import React, { useEffect } from "react";
import DashButton from "../elements/DashButton";
import { useSelector, useDispatch } from "react-redux";
import dateFormat from "../../utils/dateFormat";
import { getRoadMaps } from "../../feature/roadmap.slice";
import { NavLink } from "react-router-dom";

export const ListeRoadmap = () => {
  const dispatch = useDispatch();
  const roadmapData = useSelector((state) => state.roadmaps.roadmaps);

  useEffect(() => {
    dispatch(getRoadMaps());
  }, [dispatch]);

  return (
    <div className="recycle">
      <div className="recycle__container">
        <div className="recycle__container__table">
          <table className="tableRecycle">
            <thead className="tableRecycle__lines tableRecycle__line--header">
              <tr className="tableRecycle__columns tableRecycle__columns--header">
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  N°
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Date
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Moment
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Pour
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Etat
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tableRecycle__lines">
              {roadmapData &&
                roadmapData.data.map((roadmap, index) => {
                  return (
                    <tr key={index} className="tableRecycle__columns">
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {"FR" + roadmap.id}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {dateFormat(roadmap.roadmapDate)}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {roadmap.moment}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {roadmap.comment}
                      </th>
                      <th className="tableRecycle__columns--text widgetFont__table--body">
                        {roadmap.driver_id ? "Publiée" : "Non validée"}
                      </th>

                      <th className="tableRecycle__lines__btnBox">
                        <div className="three_quarters">
                          <NavLink
                            to={
                              "/dashboard/spyde/logistic/roadmap/id/" +
                              roadmap.id
                            }
                            className="dashboardMenu__link"
                            replace={true}
                          >
                            {roadmap.driver_id ? (
                              <DashButton
                                type="button"
                                id={roadmap.id}
                                action="action"
                                text="Prendre en charge"
                              />
                            ) : (
                              <DashButton
                                type="button"
                                id={roadmap.id}
                                action="modify"
                                text="Modifier"
                              />
                            )}
                          </NavLink>
                        </div>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
