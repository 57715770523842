import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";
import { toast } from "react-toastify";

export const getContracts = createAsyncThunk(
  "Contract/getContracts",
  async () => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/spyde_contract"
    ).then((res) => res.data);
  }
);

export const getMyContract = createAsyncThunk(
  "Contract/getMyContract",
  async (id) => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/spyde_contract/client/" + id
    )
      .then((res) => res.data)
      .catch((err) => toast.error("Erreur lors du chargement des données"));
  }
);

export const addNewContract = createAsyncThunk(
  "Contract/addNewContract",
  async (data) => {
    return await Axios.put(
      process.env.REACT_APP_API_URL + "/spyde_contract/add",
      data
    ).then((res) => res.data);
  }
);

export const patchContract = createAsyncThunk(
  "Contract/patchContract",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/spyde_contract/modif/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const trashContract = createAsyncThunk(
  "Contract/trashContract",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/spyde_contract/trash/" + id
    ).then((res) => res && { data: id });
  }
);

const contractSlice = createSlice({
  name: "Contract",
  initialState: { contract: null, status: null, loading: false, error: "" },

  reducers: {
    deleteContract: (state, action) => {},
  },

  extraReducers: {
    [getContracts.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getContracts.fulfilled]: (state, { payload }) => {
      state.contract = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getContracts.rejected]: (state, action) => {
      state.status = "failed";
      state.contract = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getMyContract.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getMyContract.fulfilled]: (state, { payload }) => {
      state.contract = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getMyContract.rejected]: (state, action) => {
      state.status = "failed";
      state.contract = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewContract.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewContract.fulfilled]: (state, { payload }) => {
      let newcontract = { ...payload.data };
      state.contract.data = [...state.contract.data, newcontract];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewContract.rejected]: (state, action) => {
      state.status = "failed";
      state.contract = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchContract.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchContract.fulfilled]: (state, { payload }) => {
      state.contract.data = state.contract.data.map((contract) => {
        if (contract.id === payload.data.id) {
          return {
            ...contract,
            ...payload.data,
          };
        } else return contract;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchContract.rejected]: (state, action) => {
      state.status = "failed";
      state.contract = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [trashContract.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [trashContract.fulfilled]: (state, { payload }) => {
      state.contract.data = state.contract.data.filter(
        (contract) => contract.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [trashContract.rejected]: (state, action) => {
      state.status = "failed";
      state.contract = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteContract } = contractSlice.actions;

export default contractSlice;
