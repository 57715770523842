import React, { useEffect, useState } from "react";
import DashButton from "../elements/DashButton";
import { useSelector, useDispatch } from "react-redux";
import { getRecycles, patchOneRecycle } from "../../feature/recycle.slice";
import dateFormat from "../../utils/dateFormat";
import ModalContent from "../layouts/ModalContent";
import ModalLayout from "../layouts/ModalLayout";
import plasticImage from "../../img/Plastic.svg";
import paperImage from "../../img/Paper.svg";
import aluImage from "../../img/Alu.svg";
import DEEImage from "../../img/DEE.svg";
import inkImage from "../../img/Ink.svg";
import InputMaterial from "../blocks/InputMaterial";
import { toast } from "react-toastify";
import { RatingStar } from "./RatingStar";
import { getSpyders } from "../../feature/spyder.slice";
import { phoneNumber } from "../../utils/phoneNumber";
import useAuth from "../../services/useAuth";
import DirectorAccess from "../../services/contexts/DirectorAccess";

export const ListeRecyclesFinish = () => {
  const dispatch = useDispatch();

  const recyclesData = useSelector((state) => state.recycles.recycles);
  const userData = useSelector((state) => state.user.user);
  const spyderData = useSelector((state) => state.spyder.spyder);

  const [modalWeight, setModalWeight] = useState(false);
  const [modalValidation, setModalValidation] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);

  const [validationWeights, setValidationWeights] = useState(false);
  const [showArchives, setShowArchives] = useState(false);
  const [selectedRecycle, setSelectedRecycle] = useState("");
  const [message, setMessage] = useState(null);

  const [plasticValue, setPlasticValue] = useState(0);
  const [paperValue, setPaperValue] = useState(0);
  const [electronicValue, setElectronicValue] = useState(0);
  const [aluminiumValue, setAluminiumValue] = useState(0);
  const [inkValue, setInkValue] = useState(0);
  const [dataRate, setDataRate] = useState(0);

  const [clientSearch, setClientSearch] = useState("");
  const [noteSearch, setNoteSearch] = useState("");
  const [dateSearch, setDateSearch] = useState("");

  const [isDirecteur, setIsDirecteur] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setIsDirecteur(DirectorAccess(auth));
  }, []);

  useEffect(() => {
    setPlasticValue(selectedRecycle.plastic);
    setPaperValue(selectedRecycle.paper);
    setInkValue(selectedRecycle.ink);
    setElectronicValue(selectedRecycle.electronic);
    setAluminiumValue(selectedRecycle.aluminium);
  }, [selectedRecycle]);

  useEffect(() => {
    dispatch(getRecycles());
    dispatch(getSpyders());
  }, [dispatch]);

  useEffect(() => {
    if (
      `${plasticValue}` === `${selectedRecycle.plastic}` &&
      `${paperValue}` === `${selectedRecycle.paper}` &&
      `${inkValue}` === `${selectedRecycle.ink}` &&
      `${aluminiumValue}` === `${selectedRecycle.aluminium}` &&
      `${electronicValue}` === `${selectedRecycle.electronic}`
    ) {
      if (
        `${plasticValue}` === "0" &&
        `${paperValue}` === "0" &&
        `${inkValue}` === "0" &&
        `${aluminiumValue}` === "0" &&
        `${electronicValue}` === "0"
      ) {
        setValidationWeights(true);
      } else {
        setValidationWeights(false);
      }
    } else {
      setValidationWeights(true);
    }
  }, [plasticValue, paperValue, inkValue, aluminiumValue, electronicValue]);

  // const weightInKilo = (weightInGramme) => {
  //   return Math.round((weightInGramme / 1000) * 10) / 10;
  // };

  const toggleModalWeight = (e) => {
    // modalWeight && setNewRackNumber("");
    setModalWeight(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const toggleModalValidation = (e) => {
    // modalValidation && setNewRackNumber("");
    setModalValidation(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const toggleModalDetail = (e) => {
    // modalValidation && setNewRackNumber("");
    setModalDetail(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  // const openModalValidation = (recycle) => {
  //   setSelectedRecycle(recycle);
  //   setModalValidation(true);
  // };

  // const openModalWeight = (recycle) => {
  //   setSelectedRecycle(recycle);
  //   setModalWeight(true);
  // };

  const changeDataRate = (e) => {
    setDataRate(e);
  };

  const changePlasticValue = (e) => {
    setPlasticValue(e.target.value);
  };

  const changePaperValue = (e) => {
    setPaperValue(e.target.value);
  };

  const changeInkValue = (e) => {
    setInkValue(e.target.value);
  };

  const changeElectronicValue = (e) => {
    setElectronicValue(e.target.value);
  };

  const changeAluminiumValue = (e) => {
    setAluminiumValue(e.target.value);
  };

  const finishRecycle = async (e) => {
    e.preventDefault();

    try {
      let dataForRecycle = {
        comment_collecte: selectedRecycle.comment_collecte,
        comment_tri: message,
        note: dataRate,
        rackNumber: selectedRecycle.rackNumber,
        client_id: selectedRecycle.client_id,
        roadmap_id: selectedRecycle.roadmap_id,
        driver_id: selectedRecycle.driver_id,
        trieur_id: userData.id,
        peseur_id: selectedRecycle.peseur_id,
        dateOfCollecte: selectedRecycle.dateOfCollecte,
        typeOfCollecte: selectedRecycle.typeOfCollecte,
        //note: Joi.number().integer(1).min(1).max(4).allow(null),
        plastic: selectedRecycle.plastic,
        aluminium: selectedRecycle.aluminium,
        electronic: selectedRecycle.electronic,
        ink: selectedRecycle.ink,
        paper: selectedRecycle.paper,
        isFinish: true,
        billIsDone: false,
      };

      let dataAndId = {
        data: dataForRecycle,
        id: selectedRecycle.id,
      };

      await dispatch(patchOneRecycle(dataAndId));
      await dispatch(getRecycles());
      await setModalValidation(false);
      await setSelectedRecycle("");
      await setPlasticValue(0);
      await setPaperValue(0);
      await setElectronicValue(0);
      await setInkValue(0);
      await setAluminiumValue(0);
      await setDataRate(0);
      await setMessage(null);

      await document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error("Finish Recycle ERROR");
    }
  };

  const addWeightsToRecycleData = async (e) => {
    e.preventDefault();

    try {
      let dataForRecycle = {
        comment_collecte: selectedRecycle.comment_collecte,
        comment_tri: null,
        rackNumber: selectedRecycle.rackNumber,
        client_id: selectedRecycle.client_id,
        roadmap_id: selectedRecycle.roadmap_id,
        driver_id: selectedRecycle.driver_id,
        trieur_id: null,
        peseur_id: userData.id,
        dateOfCollecte: selectedRecycle.dateOfCollecte,
        typeOfCollecte: selectedRecycle.typeOfCollecte,
        //note: Joi.number().integer(1).min(1).max(4).allow(null),
        plastic: plasticValue,
        aluminium: aluminiumValue,
        electronic: electronicValue,
        ink: inkValue,
        paper: paperValue,
        isFinish: false,
        billIsDone: false,
      };

      let dataAndId = {
        data: dataForRecycle,
        id: selectedRecycle.id,
      };

      await dispatch(patchOneRecycle(dataAndId));
      await dispatch(getRecycles());
      await setModalWeight(false);
      await setSelectedRecycle("");
      await setPlasticValue(0);
      await setPaperValue(0);
      await setElectronicValue(0);
      await setInkValue(0);
      await setAluminiumValue(0);

      await document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error("AddWeights ERROR");
    }
  };

  const toggleBill = async (e, recycle) => {
    e.preventDefault();

    try {
      let dataForBill = {
        comment_collecte: recycle.comment_collecte,
        comment_tri: recycle.comment_tri,
        rackNumber: recycle.rackNumber,
        client_id: recycle.client_id,
        roadmap_id: recycle.roadmap_id,
        driver_id: recycle.driver_id,
        trieur_id: recycle.trieur_id,
        peseur_id: recycle.peseur_id,
        dateOfCollecte: recycle.dateOfCollecte,
        typeOfCollecte: recycle.typeOfCollecte,
        //note: Joi.number().integer(1).min(1).max(4).allow(null),
        plastic: recycle.plastic,
        aluminium: recycle.aluminium,
        electronic: recycle.electronic,
        ink: recycle.ink,
        paper: recycle.paper,
        isFinish: true,
      };

      let idRecycle = recycle.id;

      let dataAndId;

      if (recycle.billIsDone) {
        //si non facturée alors active la facturation
        dataAndId = {
          data: { ...dataForBill, billIsDone: false },
          id: idRecycle,
        };
      } else {
        //si facturée alors annule la facturation
        dataAndId = {
          data: { ...dataForBill, billIsDone: true },
          id: idRecycle,
        };
      }

      await dispatch(patchOneRecycle(dataAndId));
      await dispatch(getRecycles());
    } catch (err) {
      toast.error("Toggle BILL ERROR");
    }
  };

  //search :

  const searchByClient = (evt) => {
    setClientSearch(evt);
  };

  const searchByNote = (evt) => {
    setNoteSearch(evt);
  };
  const searchByDate = (evt) => {
    setDateSearch(evt);
  };

  const showDetailRecycle = (e, recycle) => {
    e.preventDefault();
    setSelectedRecycle(recycle);
    setModalDetail(true);
  };

  const noteInStars = (note) => {
    if (note === 1) {
      return (
        <div className="starNote">
          <ion-icon icon="star" />
        </div>
      );
    }
    if (note === 2) {
      return (
        <div className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </div>
      );
    }
    if (note === 3) {
      return (
        <div className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </div>
      );
    }
    if (note === 4) {
      return (
        <div className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </div>
      );
    }
    if (note === 5) {
      return (
        <div className="starNote">
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
          <ion-icon icon="star" />
        </div>
      );
    }
  };

  return (
    <div className="recycle">
      <div className="recycle__container">
        <h3 className="tableGenerator__title widgetFont__table--title">
          Recyclages finalisées :
        </h3>
        <div className="recycle__container__table">
          <table className="tableRecycle">
            <thead className="tableRecycle__lines tableRecycle__line--header">
              <tr className="tableRecycle__search">
                <th className="tableRecycle__search--element widgetFont__table--search">
                  Rech.
                </th>
                <th className="tableRecycle__search--element widgetFont__table--search">
                  <input
                    type="text"
                    id="clientName"
                    autoComplete="off"
                    onChange={(e) => searchByClient(e.target.value)}
                    defaultValue={clientSearch}
                    placeholder="Client recherché ..."
                  ></input>
                </th>
                <th className="tableRecycle__search--element widgetFont__table--search">
                  <input
                    type="text"
                    id="dateOfCollecte"
                    autoComplete="off"
                    onChange={(e) => searchByDate(e.target.value)}
                    defaultValue={dateSearch}
                    placeholder="Date recherchée ..."
                  ></input>
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__search--element widgetFont__table--header"></th>
                <th className="displayNoneOnSmallScreen tableRecycle__search--element widgetFont__table--header"></th>
                <th className="displayNoneOnSmallScreen tableRecycle__search--element widgetFont__table--header"></th>

                <th className="tableRecycle__search--element">
                  <input
                    type="text"
                    id="note"
                    autoComplete="off"
                    onChange={(e) => searchByNote(e.target.value)}
                    defaultValue={noteSearch}
                    placeholder="Note..."
                  ></input>
                </th>
                <th className="tableRecycle__search--element"></th>
                {isDirecteur && (
                  <th className="widgetFont__table--header">
                    <button onClick={() => setShowArchives(!showArchives)}>
                      Archives
                    </button>
                  </th>
                )}
              </tr>

              {/* TABLEAU */}
              <tr className="tableRecycle__columns tableRecycle__columns--header">
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  n°
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Client
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Collecte
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Type
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--header">
                  Info
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--header">
                  Tri
                </th>

                <th className="tableRecycle__columns--text tableRecycle__columns--small">
                  Note
                </th>
                <th className="tableRecycle__columns--text">Détails</th>

                {isDirecteur && (
                  <th className="widgetFont__table--header">Facturation</th>
                )}
              </tr>
            </thead>
            <tbody className="tableRecycle__lines">
              {recyclesData &&
                recyclesData.data
                  .filter(
                    (recycle) =>
                      recycle.isFinish === true &&
                      recycle.billIsDone === showArchives
                  )
                  .filter((recycle) =>
                    recycle["Client"]?.clientName
                      .toLowerCase()
                      .includes(clientSearch.toLowerCase())
                  )
                  .filter((recycle) =>
                    noteSearch ? `${recycle.note}` === `${noteSearch}` : true
                  )
                  .filter((recycle) =>
                    dateFormat(recycle.dateOfCollecte).includes(dateSearch)
                  )
                  .map((recycle, index) => {
                    return (
                      <tr key={index} className="tableRecycle__columns">
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {"BR " + recycle.id}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          <span>{recycle["Client"]?.clientName}</span>
                          <span className="displayNoneOnSmallScreen font__italic font__italic--grey">
                            {" - " + recycle["Client"]?.agency}
                          </span>
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {dateFormat(recycle.dateOfCollecte)}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {recycle.typeOfCollecte}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {recycle.comment_collecte &&
                          recycle.comment_collecte !== "" ? (
                            <div
                              className="info info--recycle"
                              data-tooltip={recycle.comment_collecte}
                            >
                              <ion-icon icon="car-sport-outline"></ion-icon>
                            </div>
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {recycle.comment_tri && recycle.comment_tri !== "" ? (
                            <div
                              className="info info--recycle"
                              data-tooltip={recycle.comment_tri}
                            >
                              <ion-icon icon="document-text-outline"></ion-icon>
                            </div>
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {recycle.note}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body ">
                          <div className="btnBox">
                            <div className="outShadowBtn">
                              <DashButton
                                type="button"
                                action="search"
                                text="D"
                                onClick={(e) => showDetailRecycle(e, recycle)}
                              />
                            </div>
                          </div>
                        </th>
                        {isDirecteur && (
                          <th className="tableRecycle__lines__btnBox">
                            <div className="btnBox">
                              {recycle.billIsDone ? (
                                <>
                                  <DashButton
                                    type="button"
                                    action="inactive"
                                    text="OK"
                                    //onClick={() => setModalValidation(true)}
                                  />
                                  <div className="lineBtn">
                                    <DashButton
                                      type="button"
                                      action="cancel"
                                      text="X"
                                      onClick={(e) => toggleBill(e, recycle)}
                                    />
                                  </div>
                                </>
                              ) : (
                                <DashButton
                                  type="button"
                                  action="cancel"
                                  text="Facturer"
                                  onClick={(e) => toggleBill(e, recycle)}
                                />
                              )}
                            </div>
                          </th>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
      {modalWeight && (
        <ModalLayout
          toggle={toggleModalWeight}
          modal={modalWeight}
          label="Pesée de la collecte"
          colorClass="green"
        >
          <ModalContent
            text="Merci de remplir ou de modifier les informations ci-dessous, en adéquation avec le rack traité : "
            moreText="Indiquez les poids en grammes suivant la nature du dechêts"
          >
            <h3 className="tableGenerator__title centerLign">
              {"Rack " +
                selectedRecycle.rackNumber +
                " pour le client : " +
                selectedRecycle[`Client`]?.clientName}
            </h3>

            <form className="materialForm">
              <InputMaterial
                label="Collecte des plastiques"
                htmlfor={"trashPlastic" + selectedRecycle.id}
                defaultValue={selectedRecycle.plastic}
                onChange={changePlasticValue}
                //ref={plasticRef}
                image={plasticImage}
                color="blue"
              />
              <InputMaterial
                label="Collecte du papier"
                htmlfor={"trashPaper" + selectedRecycle.id}
                defaultValue={selectedRecycle.paper}
                onChange={changePaperValue}
                image={paperImage}
                color="green"
              />
              <InputMaterial
                label="Collecte de l'aluminium"
                htmlfor={"trashAlu" + selectedRecycle.id}
                defaultValue={selectedRecycle.aluminium}
                onChange={changeAluminiumValue}
                image={aluImage}
                color="red"
              />
              <InputMaterial
                label="Cartouches d'encres"
                htmlfor={"trashInk" + selectedRecycle.id}
                defaultValue={selectedRecycle.ink}
                onChange={changeInkValue}
                image={inkImage}
                color="brown"
              />
              <InputMaterial
                label="Collecte de l'électronique"
                htmlfor={"trash" + selectedRecycle.id}
                defaultValue={selectedRecycle.electronic}
                onChange={changeElectronicValue}
                image={DEEImage}
                color="yellow"
              />
            </form>
            <div className="buttonsContainer">
              <div className="buttonContainer--small">
                {validationWeights ? (
                  <DashButton
                    //disabled={disabled}
                    action="modify"
                    text="Enregistrer la pesée"
                    onClick={(e) => addWeightsToRecycleData(e)}
                  />
                ) : (
                  <DashButton
                    disabled={true}
                    action="inactive"
                    text="Veuillez confirmer"
                  />
                )}
              </div>
              <div className="buttonContainer--small">
                <DashButton
                  //disabled={disabled}
                  action="cancel"
                  text="Annuler"
                  onClick={(e) => toggleModalWeight(e)}
                />
              </div>
            </div>
          </ModalContent>
        </ModalLayout>
      )}
      {modalValidation && (
        <ModalLayout
          toggle={toggleModalValidation}
          modal={modalValidation}
          label="Finaliser le recyclage"
          colorClass="blue"
        >
          <ModalContent
            text="Vous êtes sur le point de noter et de valider le recyclage : "
            moreText={"Notez le tri du rack " + selectedRecycle.rackNumber}
          >
            <div className="centerLign">
              <RatingStar dataRate={changeDataRate} />
              <div>
                {dataRate === 0 && (
                  <p className="">Cliquez sur une étoile pour commencer</p>
                )}
                {dataRate === 1 && (
                  <p className="tableGenerator__title">
                    Impossible à nettoyer, merci de prendre des photos
                  </p>
                )}
                {dataRate === 2 && (
                  <p className="tableGenerator__title">
                    Sale et dangereux, merci de prendre des photos
                  </p>
                )}
                {dataRate === 3 && (
                  <p className="tableGenerator__title">
                    Beaucoup d'erreurs et perte de temps pendant le tri
                  </p>
                )}
                {dataRate === 4 && (
                  <p className="tableGenerator__title">
                    Quelques erreurs mais pas de perte de temps
                  </p>
                )}
                {dataRate === 5 && (
                  <p className="tableGenerator__title">
                    Tri sans aucun problème
                  </p>
                )}
              </div>
            </div>
            <div className="centerLign recycle__validation">
              <label className="modalContent__moreText">
                Si besoin, vous pouvez apporter des précisions sur le tri :
              </label>
              <textarea
                type="textarea"
                id="message"
                className="recycle__validation__textarea"
                autoComplete="off"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Apportez des précisions au tri réalisé, Etat du rack, dechêt hors protocole, etc ..."
              ></textarea>
            </div>

            <div className="buttonsContainer">
              <div className="buttonContainer--small">
                {dataRate !== 0 ? (
                  <DashButton
                    //disabled={disabled}
                    action="action"
                    text="Je valide ce recyclage"
                    onClick={(e) => finishRecycle(e)}
                  />
                ) : (
                  <DashButton
                    disabled={true}
                    action="inactive"
                    text="Notez le tri"
                  />
                )}
              </div>
              <div className="buttonContainer--small">
                <DashButton
                  //disabled={disabled}
                  action="cancel"
                  text="Annuler"
                  onClick={(e) => toggleModalValidation(e)}
                />
              </div>
            </div>
          </ModalContent>
        </ModalLayout>
      )}
      {modalDetail && (
        <ModalLayout
          toggle={toggleModalDetail}
          modal={modalDetail}
          label="Détail du recyclage"
          colorClass="yellow"
        >
          <div className="detailGrid">
            <div className="detailGrid__element detailGrid__element--half">
              <h3>N° de bon :</h3>
              <p>BR {selectedRecycle.id}</p>
            </div>
            <div className="detailGrid__element detailGrid__element--half detailGrid__element--right">
              <h3>Date de la collecte :</h3>
              <p>{dateFormat(selectedRecycle.dateOfCollecte)} </p>
            </div>
            <div className="detailGrid__element detailGrid__element--full detailGrid__element--align">
              <h3>Client:</h3>
              <p>
                {selectedRecycle["Client"]?.clientName +
                  " - " +
                  selectedRecycle["Client"]?.agency}{" "}
              </p>
              {selectedRecycle["Client"]?.addressURL && (
                <div className="detailClient">
                  <img
                    src={selectedRecycle["Client"]?.addressURL}
                    alt={
                      "Logo de l'entreprise " +
                      selectedRecycle["Client"]?.clientName
                    }
                  />
                </div>
              )}
            </div>

            <div className="detailGrid__element  detailGrid__element--align detailGrid__element--half">
              <h3>Note :</h3>
              {noteInStars(selectedRecycle.note)}
            </div>
            <div className="detailGrid__element detailGrid__element--align detailGrid__element--half">
              <h3>Feuille de route :</h3>
              <p>FR {selectedRecycle.roadmap_id}</p>
            </div>

            <div className="detailGrid__element detailGrid__element--full">
              <h3>Poids par produit</h3>
            </div>
            <div className="detailGrid__element detailGrid__element--align detailGrid__element--full weightbox">
              {selectedRecycle.plastic +
                selectedRecycle.ink +
                selectedRecycle.electronic +
                selectedRecycle.paper +
                selectedRecycle.aluminium ===
                0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--alert">
                    Pesée nulle - Aucun enregistrement de poids
                  </p>
                </div>
              )}
              {selectedRecycle.plastic !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--plastic">
                    {selectedRecycle.plastic + " "}
                    <span>g</span>
                  </p>
                  <img
                    src={plasticImage}
                    alt="pictogramme blue pour le plastique"
                  />
                </div>
              )}
              {selectedRecycle.paper !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--paper">
                    {selectedRecycle.paper + " "}
                    <span>g</span>
                  </p>
                  <img src={paperImage} alt="pictogramme vert pour le papier" />
                </div>
              )}
              {selectedRecycle.aluminium !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--aluminium">
                    {selectedRecycle.aluminium + " "}
                    <span>g</span>
                  </p>
                  <img
                    src={aluImage}
                    alt="pictogramme rouge pour l'aluminium'"
                  />
                </div>
              )}
              {selectedRecycle.ink !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--ink">
                    {selectedRecycle.ink + " "}
                    <span>g</span>
                  </p>
                  <img
                    src={inkImage}
                    alt="pictogramme marron pour les cartouches d'encre"
                  />
                </div>
              )}
              {selectedRecycle.electronic !== 0 && (
                <div className="weightbox__element">
                  <p className="weightbox__element--electronic">
                    {selectedRecycle.electronic + " "}
                    <span>g</span>
                  </p>
                  <img
                    src={DEEImage}
                    alt="pictogramme jaune pour le matériel informatique et electronique"
                  />
                </div>
              )}
            </div>

            <div className="detailGrid__element detailGrid__element--align detailGrid__element--half">
              <h3>Collecte :</h3>
              <p>{selectedRecycle.typeOfCollecte}</p>
            </div>
            <div className="detailGrid__element  detailGrid__element--align detailGrid__element--half">
              <h3>Poids total :</h3>
              <p>
                {(selectedRecycle.plastic +
                  selectedRecycle.ink +
                  selectedRecycle.electronic +
                  selectedRecycle.paper +
                  selectedRecycle.aluminium) /
                  1000 +
                  " kg"}
              </p>
            </div>
            {/* <div className="detailGrid__element detailGrid__element--tierce">
              <h3>Collecteur :</h3>
            </div>
            <div className="detailGrid__element detailGrid__element--tierce">
              <h3>Peseur :</h3>
            </div>
            <div className="detailGrid__element detailGrid__element--tierce">
              <h3>Recycleur :</h3>
            </div> */}
            {/* Generation des vignettes Spyder */}
            <>
              {selectedRecycle.driver_id === selectedRecycle.peseur_id &&
                selectedRecycle.trieur_id === selectedRecycle.peseur_id && (
                  //si tous identiques affiche 1 seul
                  <>
                    {" "}
                    <div className="detailGrid__element detailGrid__element--full">
                      <h3>Collecteur / Peseur / Recycleur :</h3>
                    </div>
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder) => {
                        return (
                          <>
                            <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--full">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "Portrait de " +
                                    spyder.firstname +
                                    " " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                )}

              {selectedRecycle.driver_id !== selectedRecycle.peseur_id &&
                selectedRecycle.trieur_id === selectedRecycle.peseur_id && (
                  <>
                    <div className="detailGrid__element detailGrid__element--half">
                      <h3>Collecteur :</h3>
                    </div>
                    <div className="detailGrid__element detailGrid__element--half">
                      <h3>Peseur / Recycleur :</h3>
                    </div>
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder) => {
                        return (
                          <>
                            <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--half">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "Portrait de " +
                                    spyder.firstname +
                                    " " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.peseur_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--half">
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "Portrait de " +
                                  spyder.firstname +
                                  " " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}

              {selectedRecycle.driver_id === selectedRecycle.peseur_id &&
                selectedRecycle.trieur_id !== selectedRecycle.peseur_id && (
                  <>
                    <div className="detailGrid__element detailGrid__element--half">
                      <h3>Collecteur / Peseur :</h3>
                    </div>
                    <div className="detailGrid__element detailGrid__element--half">
                      <h3>Recycleur :</h3>
                    </div>
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element detailGrid__element--center detailGrid__element--half">
                            <div className="detailSpyder--center">
                              <div className="detailSpyder">
                                <img
                                  src={
                                    spyder.addressURL
                                      ? spyder.addressURL
                                      : "/img/spyde_avatar.png"
                                  }
                                  alt={
                                    "Portrait de " +
                                    spyder.firstname +
                                    " " +
                                    spyder.lastname
                                  }
                                />
                                <p>{spyder.firstname}</p>
                                <p>{spyder.lastname}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.trieur_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element  detailGrid__element--nopadding detailGrid__element--half">
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "Portrait de " +
                                  spyder.firstname +
                                  " " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}

              {selectedRecycle.driver_id !== selectedRecycle.peseur_id &&
                selectedRecycle.trieur_id !== selectedRecycle.peseur_id && (
                  <>
                    <div className="detailGrid__element detailGrid__element--tierce">
                      <h3>Collecteur :</h3>
                    </div>
                    <div className="detailGrid__element detailGrid__element--tierce">
                      <h3>Peseur :</h3>
                    </div>
                    <div className="detailGrid__element detailGrid__element--tierce">
                      <h3>Recycleur :</h3>
                    </div>
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.driver_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--tierce">
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "Portrait de " +
                                  spyder.firstname +
                                  " " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.peseur_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--tierce">
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "Portrait de " +
                                  spyder.firstname +
                                  " " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                    {spyderData.data
                      .filter(
                        (spyder) => spyder.id === selectedRecycle.trieur_id
                      )
                      .map((spyder) => {
                        return (
                          <div className="detailGrid__element  detailGrid__element--nopadding detailGrid__element--tierce">
                            <div className="detailSpyder">
                              <img
                                src={
                                  spyder.addressURL
                                    ? spyder.addressURL
                                    : "/img/spyde_avatar.png"
                                }
                                alt={
                                  "Portrait de " +
                                  spyder.firstname +
                                  " " +
                                  spyder.lastname
                                }
                              />
                              <p>{spyder.firstname}</p>
                              <p>{spyder.lastname}</p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
            </>
            <div className="detailGrid__element detailGrid__element--full">
              <h3>Commentaire lors de la collecte :</h3>
              <p>
                {selectedRecycle.comment_collecte ? (
                  selectedRecycle.comment_collecte
                ) : (
                  <span className="detailGrid__element--span">
                    Pas de commentaire ...
                  </span>
                )}
              </p>
            </div>

            <div className="detailGrid__element detailGrid__element--full">
              <h3>Commentaire lors du recyclage :</h3>
              <p>
                {selectedRecycle.comment_tri ? (
                  selectedRecycle.comment_tri
                ) : (
                  <span className="detailGrid__element--span">
                    Pas de commentaire ...
                  </span>
                )}
              </p>
            </div>
            <div className="detailGrid__element detailGrid__element--full">
              <h3>Commercial Spyde :</h3>
              {spyderData.data
                .filter(
                  (spyder) =>
                    spyder.id === selectedRecycle["Client"].spyder_login_id
                )
                .map((spyder) => {
                  return (
                    <div className="detailGrid__element detailGrid__element--nopadding detailGrid__element--full">
                      <div className="detailCommercial">
                        <img
                          src={
                            spyder.addressURL
                              ? spyder.addressURL
                              : "/img/spyde_avatar.png"
                          }
                          alt={
                            "Portrait de " +
                            spyder.firstname +
                            " " +
                            spyder.lastname
                          }
                        />
                        <div className="detailCommercial__text">
                          <p>{spyder.firstname}</p>
                          <p>{spyder.lastname}</p>
                          <p>{"Tel : " + phoneNumber(spyder.phone)}</p>
                          <p className="detailGrid__element--span">
                            {"Agence Spyde de " +
                              spyder["SpyderAccount"].agency}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="buttonsContainer">
            <div className="buttonContainer--small">
              <DashButton
                //disabled={disabled}
                action="cancel"
                text="Fermer"
                onClick={(e) => toggleModalDetail(e)}
              />
            </div>
          </div>
        </ModalLayout>
      )}
    </div>
  );
};
