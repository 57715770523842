import { Outlet } from "react-router-dom";
import DashBoard from "../elements/DashBoard";

function SpydeLayout() {
  return (
    <>
      <DashBoard />
      <Outlet />
    </>
  );
}

export default SpydeLayout;
