import { createSlice } from "@reduxjs/toolkit";

export const spydeAccountSlice = createSlice({
  name: "SpydeAccount",
  initialState: { spydeAccount: null },

  reducers: {
    setSpydeAccount: (state, { payload }) => {
      state.spydeAccount = payload;
    },
    addSpydeAccount: (state, { payload }) => {
      state.spydeAccount.data = { ...state.spydeAccount.data, payload };
      //state.client.push(payload);
    },
    changeSpydeAccount: (state, action) => {},
    deleteSpydeAccount: (state, action) => {},
  },
});

export const {
  setSpydeAccount,
  addSpydeAccount,
  changeSpydeAccount,
  deleteSpydeAccount,
} = spydeAccountSlice.actions;

export default spydeAccountSlice;
