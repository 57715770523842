const DashButton = (props) => {
  return (
    <button
      type={props.type}
      className={"dashButton dashButton--" + props.action}
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      {props.iconA && (
        <ion-icon
          className={"dashButton--" + props.action}
          icon={props.iconA + "-sharp"}
        ></ion-icon>
      )}
      {props.text && <span className="dashButton__text">{props.text}</span>}

      {props.iconB && (
        <ion-icon
          className={"dashButton--" + props.action}
          icon={props.iconB + "-sharp"}
        ></ion-icon>
      )}
    </button>
  );
};

export default DashButton;
