import { NavLink } from "react-router-dom";

const Connect = () => {
  return (
    <div className="activeMode">
      <NavLink to="/seconnecter" className="btnConnect">
        <div className="icon icon__hideOnTablet">
          <ion-icon icon="log-in-outline"></ion-icon>
        </div>
        <p>Espace Client</p>
      </NavLink>
    </div>
  );
};

export default Connect;
