import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";

export const RatingStar = ({ dataRate }) => {
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  useEffect(() => {
    dataRate(rating);
  }, [rating]);

  return <Rating fillColor="#D02D2D" onClick={handleRating} />;
};
