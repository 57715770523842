import React, { useEffect, useState } from "react";
//import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import DashButton from "../elements/DashButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const FollowRecycles = () => {
  const recyclesData = useSelector((state) => state.recycles.recycles);

  const [lastMonthSelected, setLastMonthSelected] = useState(dayjs(new Date()));
  const [weAreToday, setWeAreToday] = useState(dayjs(new Date()));
  //!! Pour changement de mois : A FAIRE setLastMonthSelected - ou + ou init.

  let month0 = lastMonthSelected.format("MM/YY");
  let month1 = lastMonthSelected.subtract(1, "month").format("MM/YY");
  let month2 = lastMonthSelected.subtract(2, "month").format("MM/YY");
  let month3 = lastMonthSelected.subtract(3, "month").format("MM/YY");
  let month4 = lastMonthSelected.subtract(4, "month").format("MM/YY");
  let month5 = lastMonthSelected.subtract(5, "month").format("MM/YY");

  let dataMonth0 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(1, "month")
  );
  let dataMonth1 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected.subtract(1, "month") &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(2, "month")
  );
  let dataMonth2 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected.subtract(2, "month") &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(3, "month")
  );
  let dataMonth3 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected.subtract(3, "month") &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(4, "month")
  );
  let dataMonth4 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected.subtract(4, "month") &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(5, "month")
  );
  let dataMonth5 = recyclesData?.data.filter(
    (recycle) =>
      dayjs(recycle.dateOfCollecte) < lastMonthSelected.subtract(5, "month") &&
      dayjs(recycle.dateOfCollecte) >= lastMonthSelected.subtract(6, "month")
  );

  //For data plastic :
  let plasticMonth0 =
    dataMonth0
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let plasticMonth1 =
    dataMonth1
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let plasticMonth2 =
    dataMonth2
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let plasticMonth3 =
    dataMonth3
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let plasticMonth4 =
    dataMonth4
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let plasticMonth5 =
    dataMonth5
      .map((data) => data.plastic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  //For data paper :

  let paperMonth0 =
    dataMonth0
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let paperMonth1 =
    dataMonth1
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let paperMonth2 =
    dataMonth2
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let paperMonth3 =
    dataMonth3
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let paperMonth4 =
    dataMonth4
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let paperMonth5 =
    dataMonth5
      .map((data) => data.paper)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  //For data aluminium :

  let aluminiumMonth0 =
    dataMonth0
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let aluminiumMonth1 =
    dataMonth1
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let aluminiumMonth2 =
    dataMonth2
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let aluminiumMonth3 =
    dataMonth3
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let aluminiumMonth4 =
    dataMonth4
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let aluminiumMonth5 =
    dataMonth5
      .map((data) => data.aluminium)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  //For data ink :

  let inkMonth0 =
    dataMonth0
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let inkMonth1 =
    dataMonth1
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let inkMonth2 =
    dataMonth2
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let inkMonth3 =
    dataMonth3
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let inkMonth4 =
    dataMonth4
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let inkMonth5 =
    dataMonth5
      .map((data) => data.ink)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  //For data electronic :

  let electronicMonth0 =
    dataMonth0
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let electronicMonth1 =
    dataMonth1
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let electronicMonth2 =
    dataMonth2
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let electronicMonth3 =
    dataMonth3
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  let electronicMonth4 =
    dataMonth4
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;
  let electronicMonth5 =
    dataMonth5
      .map((data) => data.electronic)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
    1000;

  const data = {
    labels: [month5, month4, month3, month2, month1, month0],
    datasets: [
      {
        label: "Kg Plastique",
        data: [
          plasticMonth5,
          plasticMonth4,
          plasticMonth3,
          plasticMonth2,
          plasticMonth1,
          plasticMonth0,
        ],
        fill: false,
        borderColor: "rgba(71, 138, 204, 0.8)",
      },
      {
        label: "Kg Papier",
        data: [
          paperMonth5,
          paperMonth4,
          paperMonth3,
          paperMonth2,
          paperMonth1,
          paperMonth0,
        ],
        fill: false,
        borderColor: "rgba(65, 176, 137, 0.8)",
      },
      {
        label: "Kg Aluminium",
        data: [
          aluminiumMonth5,
          aluminiumMonth4,
          aluminiumMonth3,
          aluminiumMonth2,
          aluminiumMonth1,
          aluminiumMonth0,
        ],
        fill: false,
        borderColor: "rgba(191, 29, 34, 0.8)",
      },
      {
        label: "Kg Cartouche",
        data: [
          inkMonth5,
          inkMonth4,
          inkMonth3,
          inkMonth2,
          inkMonth1,
          inkMonth0,
        ],
        fill: false,
        borderColor: "rgba(66, 32, 33, 0.8)",
      },
      {
        label: "Kg Electronique",
        data: [
          electronicMonth5,
          electronicMonth4,
          electronicMonth3,
          electronicMonth2,
          electronicMonth1,
          electronicMonth0,
        ],
        fill: false,
        borderColor: "rgba(246, 214, 63, 0.8)",
      },
    ],
  };

  const toggleMonth = (val) => {
    if (val) {
      if (val <= 0) {
        setLastMonthSelected(lastMonthSelected.subtract(1, "month"));
      } else if (val > 0) {
        setLastMonthSelected(lastMonthSelected.add(1, "month"));
      } else console.log("Error Value Toggle");
    } else {
      setLastMonthSelected(dayjs(new Date()));
    }
  };

  useEffect(() => {
    let today = dayjs(new Date());
    if (lastMonthSelected.format("MM/YY") === today.format("MM/YY")) {
      setWeAreToday(true);
    } else {
      setWeAreToday(false);
    }
  }, [lastMonthSelected]);

  return (
    <div className="followMyRecycle">
      <Line data={data} />
      <div className="followMyRecycle__controls">
        <div className="followMyRecycle__controls__btn buttonContainer--small">
          {!weAreToday ? (
            <DashButton
              type="button"
              action="search"
              text="Précédent"
              onClick={() => toggleMonth(-1)}
            />
          ) : (
            <DashButton
              type="button"
              action="action"
              text="Voir les mois précédents"
              onClick={() => toggleMonth(-1)}
            />
          )}
          {!weAreToday && (
            <DashButton
              type="button"
              action="search"
              text="Suivant"
              onClick={() => toggleMonth(1)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
