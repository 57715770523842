import { useState } from "react";

const WidgetContainer = ({ type, icon, title, children, open }) => {
  const [openContainer, setOpenContainer] = useState(open);

  const toggleContainerAndClear = () => {
    setOpenContainer(!openContainer);
  };

  return (
    <div className={"widgetContainer widgetContainer--" + type}>
      <div
        className="widgetContainer__header"
        onClick={() => toggleContainerAndClear()}
      >
        <div className="widgetContainer__header__title">
          <ion-icon icon={icon}></ion-icon>
          <h2 className="widgetFont--container">{title}</h2>
        </div>
        <ion-icon
          className="widgetContainer__icon"
          icon={openContainer ? "remove-sharp" : "add-sharp"}
        ></ion-icon>
      </div>
      {openContainer && children}
    </div>
  );
};

export default WidgetContainer;
