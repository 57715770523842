import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const Bonus = () => {
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const contractData = useSelector((state) => state.contract.contract);

  const [bonus, setBonus] = useState("Inactif");
  const [note, setNote] = useState("0");
  const [inactiveContract, setInactiveContract] = useState(true);
  const [progressBeforeNewBonus, setProgressBeforeNewBonus] = useState("0");

  let today = dayjs(new Date());
  let startContract = dayjs(contractData.data.startDate);
  let endContract = dayjs(contractData.data.startDate).add(4, "month");
  let diffMonth = today.diff(startContract, "month");
  let diffDays = today.diff(startContract, "days");
  let totalDaysBeforeActivation = endContract.diff(startContract, "days");
  //pourcentage de la barre de progression lors du bonus inactif.
  let percentTimeBeforeBonus = Math.round(
    (diffDays / totalDaysBeforeActivation) * 100
  );

  //Moyenne des notes :
  const getNote = async () => {
    let allNotes = await recyclesData?.data.map((recycle) => recycle.note);
    let totalNotes = await allNotes.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    let moyNotes = Math.round((totalNotes / allNotes.length) * 10) / 10;

    setNote(moyNotes);
  };

  useEffect(() => {
    getNote();
  }, []);

  // Affectation du Bonus et progression suivant la note :
  useEffect(() => {
    if (note > 4) {
      setBonus("-15%");
    } else if (note > 3) {
      setBonus("-10%");
    } else if (note > 2) {
      setBonus("-5%");
    } else if (note > 1) {
      setBonus("0%");
    } else {
      setBonus("0%");
    }
    let percentProgress = (note - Math.floor(note)) * 100;
    setProgressBeforeNewBonus(percentProgress);
  }, [note]);

  // Activation du bonus au bout de 4 mois :
  useEffect(() => {
    if (diffMonth > 3) {
      setInactiveContract(false);
    } else {
      setInactiveContract(true);
    }
  }, [contractData]);

  return (
    <div className="bonus">
      {inactiveContract ? (
        <>
          <div className="bonus__state">
            <p className="font__question">Bonus actuel :</p>
            <p className="font__mainInfo">Inactif</p>
          </div>
          <div className="bonus__progress">
            <div
              className="bonus__progress--run runBar"
              style={{ width: `${percentTimeBeforeBonus + "%"}` }}
            ></div>
            <p className="bonus__progress--next font__sublineInfo">
              Déclenchement du Bonus
            </p>
          </div>

          <div className="bonus__moy">
            <h3 className="font__italic ">Note globale de vos tris :</h3>
            <div className="starNote bonus__moy--note">
              <p className="font__mainInfo">{note}</p>
              <ion-icon icon="star" />
            </div>
          </div>
          <div className="bonus__nota">
            <p className="font__sublineInfo">
              Nota: Le déclenchement du bonus intervient uniquement après 4 mois
              consécutifs de contrat avec Spyde.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="bonus__state">
            <p className="font__question">Bonus actuel :</p>
            <p className="font__mainInfo">{bonus}</p>
          </div>
          <div className="bonus__progress">
            <div
              className="bonus__progress--run runBar"
              style={{ width: `${progressBeforeNewBonus + "%"}` }}
            ></div>
            <p className="bonus__progress--next font__sublineInfo">
              Prochain Bonus
            </p>
          </div>
          <div className="bonus__moy">
            <h3 className="font__italic ">Note globale de vos tris :</h3>
            <div className="starNote bonus__moy--note">
              <p className="font__mainInfo">{note}</p>
              <ion-icon icon="star" />
            </div>
          </div>
          <div className="bonus__nota">
            <p className="font__sublineInfo">
              Nota: Ce bonus s'applique uniquement aux frais de collecte et de
              recyclage. Reportez-vous à votre contrat pour connaitre les termes
              de celui-ci.
            </p>
          </div>
        </>
      )}
    </div>
  );
};
