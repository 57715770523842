import SvgArrow from "../components/elements/SvgArrow";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const Mentions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="sectBlogLarge">
      <div className="sectBlogLarge__title">
        <h1 className="font__blockPoint">Mentions Légales</h1>
        <p className="sectBlogLarge__text sectBlogLarge__text--date font__date">
          Informations réglementaires
        </p>
      </div>
      <div className="sectBlogLarge__politics">
        <h2 className="font__oneClient">IDENTIFICATION</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Identité de l'entreprise : <strong> SPYDE</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Forme Juridique : <strong> S.A.S.</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Adresse du siège social :
          <strong> 10 B, Rue Jean Mazaryck 33700 MERIGNAC, France</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Montant du capital social : <strong> 10 000€</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Numéro d'immatriculation : <strong> Siret n°92139613100010</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Mail de contact : <strong>service-commercial@spyde.fr</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          N° de téléphone : <strong>06.52.78.28.84 / 07.82.20.75.32</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Numéro d'identification à la TVA : <strong>FR66921396131</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Hébergeur du site : <strong>L.W.S</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Créateur de l'application web : <strong>JAWA Design</strong>
        </p>

        <h2 className="font__oneClient">CONDITIONS GENERALES DE VENTE</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Conformément à la règlementation en vigueur, nos Conditions générales
          de vente sont disponibles sur simple demande, soit auprès d'un
          commercial Spyde, soit via la "Demande de Rendez-vous" depuis la page
          d'acceuil du site ou encore par e-mail.
        </p>
        <br />
        <h2 className="font__oneClient">
          TRAITEMENT DES DONNÉES PERSONNELLES ET UTILISATION DES COOKIES
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Une donnée personnelle fait référence à toute information se
          rapportant à une personne physique identifiée ou identifiable,
          directement ou non, grâce à un identifiant ou à un ou plusieurs
          éléments propres à son identité.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Ces données sont collectées dans un but de fonctionnement de nos
            services uniquement. Sur simple demande auprès d'un de nos
            commerciaux, nous vous transmettrons vos données personnelles.
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__italic">
          Pour plus d'informations, merci de consulter
          <NavLink to="/notrepolitique">
            <span className="link"> Notre Politique de confidentialité.</span>
          </NavLink>
        </p>
      </div>
      <div className="sectBlogLarge__btn">
        <NavLink to="/">
          <button className="font__btn btn btn__simple btn__simple--red">
            Retour à l'acceuil
            <SvgArrow className="btn__arrow--red" />
          </button>
        </NavLink>
      </div>
    </section>
  );
};

export default Mentions;
