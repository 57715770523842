import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getRoadMaps = createAsyncThunk(
  "RoadMaps/getRoadMaps",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/roadmap").then(
      (res) => res.data
    );
  }
);

export const getOneRoadMap = createAsyncThunk(
  "RoadMaps/getOneRoadMap",
  async (Id) => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/roadmap/" + Id
    ).then((res) => res.data);
  }
);

export const addNewRoadMap = createAsyncThunk(
  "RoadMaps/addNewRoadMap",
  async (data) => {
    return await Axios.put(process.env.REACT_APP_API_URL + "/roadmap/add", data)
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const patchOneRoadMap = createAsyncThunk(
  "RoadMaps/patchOneRoadMap",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/roadmap/modify/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const deleteOneRoadMap = createAsyncThunk(
  "RoadMaps/deleteOneRoadMap",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/roadmap/delete/" + id
    ).then((res) => res && { data: id });
  }
);

//*mise à la poubelle : softDelete
export const trashOneRoadMap = createAsyncThunk(
  "RoadMaps/trashOneRoadMap",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/roadmap/trash/" + id
    ).then((res) => res && { data: id });
  }
);

const roadMapSlice = createSlice({
  name: "RoadMaps",
  initialState: { roadmaps: null, status: null, loading: false, error: "" },

  reducers: {
    deleteRoadMap: (state, action) => {},
  },

  extraReducers: {
    [getRoadMaps.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getRoadMaps.fulfilled]: (state, { payload }) => {
      state.roadmaps = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getRoadMaps.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getOneRoadMap.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getOneRoadMap.fulfilled]: (state, { payload }) => {
      state.roadmaps = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getOneRoadMap.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewRoadMap.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewRoadMap.fulfilled]: (state, { payload }) => {
      let newRoadMap = { ...payload.data };
      state.roadmaps.data = [...state.roadmaps.data, newRoadMap];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewRoadMap.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [deleteOneRoadMap.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteOneRoadMap.fulfilled]: (state, { payload }) => {
      state.roadmaps.data = state.roadmaps.data.filter(
        (roadmap) => roadmap.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteOneRoadMap.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [trashOneRoadMap.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [trashOneRoadMap.fulfilled]: (state, { payload }) => {
      state.roadmaps.data = state.roadmaps.data.filter(
        (roadmap) => roadmap.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [trashOneRoadMap.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchOneRoadMap.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchOneRoadMap.fulfilled]: (state, { payload }) => {
      state.roadmaps.data = state.roadmaps.data.map((roadmaps) => {
        if (roadmaps.id === payload.data.id) {
          return {
            ...roadmaps,
            ...payload.data,
          };
        } else return roadmaps;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchOneRoadMap.rejected]: (state, action) => {
      state.status = "failed";
      state.roadmaps = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteRoadMap } = roadMapSlice.actions;

export default roadMapSlice;
