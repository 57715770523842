//import Navigation from "../elements/Navigation"
import NaviDash from "./NaviDash";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";
//import BlockLogo from "../blocks/BlockLogo"

const DashBoard = () => {
  return (
    <header className="dashboardMenu" id="dashBar">
      <div className="dashboardMenu__top">
        <NavLink to="/" className="dashboardMenu__link">
          <Logo className="dashboardMenu__logo" />
        </NavLink>

        <NaviDash />
      </div>
    </header>
  );
};

export default DashBoard;
/*
<BlockLogo/>
<NavBurger/>
<Connect/>
*/
