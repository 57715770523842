import useInputHook from "../../services/useInputHook";
import { useState, useEffect } from "react";

const InputAddress = (props) => {
  const addressRecup = useInputHook("");

  const [address, setAddress] = useState("");

  const [selectedLat, setSelectedLat] = useState("");
  const [selectedLong, setSelectedLong] = useState("");

  useEffect(() => {
    props.setPosition && props.setPosition(selectedLat, selectedLong);
  }, [selectedLat, selectedLong]);

  useEffect(() => {
    props.setAddress(address);
  }, [address]);

  return !props.isModify || !props.isAuthorized ? (
    <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
      <label>{props.label}</label>
      <p className="responseContainer__result">{props.dataToShow}</p>
    </div>
  ) : (
    <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
      <div className="addressForm">
        <div className="addressForm__lign">
          <div className="addressForm__lign__label">
            <label htmlFor={props.htmlfor}>
              <div className="info" data-tooltip={props.info}>
                <ion-icon icon="information-circle-outline"></ion-icon>
              </div>

              {props.labelBis ? props.labelBis : props.label}
            </label>
          </div>
          <input
            id={props.htmlfor}
            className="full"
            type="text"
            autoComplete="off"
            required={props.required}
            placeholder={
              props.defaultValue ? props.defaultValue : props.placeholder
            }
            {...addressRecup}
            onClick={() => {
              setAddress("");
              setSelectedLat("");
              setSelectedLong("");
            }}

            //onChange={(e) => props.setData(e)}
          />
          {props.validation ? (
            <div className="check check__valid">
              <ion-icon icon="checkmark-circle-outline"></ion-icon>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="addressForm__selection">
          <p className="font__italic">
            Merci de sélectionner l'adresse parmi celles qui vous seront
            proposées
          </p>
          {addressRecup.suggestions?.length > 0 && (
            <div className="resultsAddress">
              {addressRecup.suggestions.map((suggestion, index) => {
                return (
                  <p
                    key={index}
                    className="resultsAddress__result"
                    onClick={() => {
                      addressRecup.setValue(suggestion.place_name);
                      setAddress(suggestion.place_name);
                      addressRecup.setLong(suggestion.center[0]);
                      setSelectedLong(suggestion.center[0]);
                      addressRecup.setLat(suggestion.center[1]);
                      setSelectedLat(suggestion.center[1]);
                      addressRecup.setSuggestions([]);
                    }}
                  >
                    {suggestion.place_name}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputAddress;
