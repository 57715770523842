import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StepA from "../../img/step/StepA.svg";
import StepB from "../../img/step/StepB.svg";
import StepC from "../../img/step/StepC.svg";
import StepD from "../../img/step/StepD.svg";
import StepE from "../../img/step/StepE.svg";
import StepF from "../../img/step/StepF.svg";
import DefaultStep from "../../img/step/DefaultStep.svg";
import dayjs from "dayjs";
import isEmpty from "../../utils/isEmpty";
import { getOneRoadMap } from "../../feature/roadmap.slice";
import { getMyContract } from "../../feature/contract.slice";
import { getClientRecycles } from "../../feature/recycle.slice";
import { getClientWish } from "../../feature/wish.slice";
import { getClientRacks } from "../../feature/rack.slice";
import { AskForExpress } from "./AskForExpress";

export const ExpressRecycle = () => {
  const dispatch = useDispatch();
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const userData = useSelector((state) => state.user.user);
  const wishesData = useSelector((state) => state.wishes.wishes);
  const racksData = useSelector((state) => state.racks.racks);
  const roadmapsData = useSelector((state) => state.roadmaps.roadmaps);
  const [stepValue, setStepValue] = useState();
  const [findExpressRecycle, setFindExpressRecycle] = useState(false);

  const [dateOfCollecte, setDateOfCollecte] = useState("test");

  const dataImages = {
    step1: StepF,
    step2: StepA,
    step3: StepB,
    step4: StepC,
    step5: StepD,
    step6: StepE,
  };

  const stepTitles = {
    step1: "Ma Collecte Express",
    step2: "Collecte planifiée",
    step3: "Jour de collecte",
    step4: "Collecte terminée",
    step5: "Retour au centre de tri",
    step6: "Recyclage en cours",
  };

  const textDuration = {
    step1: "Votre demande de collecte Express a bien été prise en compte, ",
    step2:
      "Votre collecte vient d'être planifiée, Spyde intervient chez vous le :",
    step3: "C'est le jour J !",
    step4: "La collecte de vos déchets vient d'être réalisée",
    step5: "Votre collecte vient d'arriver au centre de tri de Spyde",
    step6:
      "Le tri et le recyclage de vos déchets est en cours, votre bon de recyclage sera bientôt disponible ",
  };

  const alertDuration = {
    step1: "Nous faisons tout pour évacuer vos déchets au plus tôt",
    //dayjs(),
    step2: dateOfCollecte,
    step3: "Spyde passe aujourd'hui",
    step4: "Collecte récupérée",
    step5: "Recyclage en attente",
    step6: "Merci pour notre planète ;)",
    delay:
      "Oups ... Nous avons pris du retard, nous redoublons d'effort pour traiter votre demande au plus tôt",
  };

  // GET DATA :::
  useEffect(() => {
    dispatch(getClientRecycles(userData.contact["Client.id"]));
    dispatch(getMyContract(userData.contact["Client.id"]));
    dispatch(getClientWish(userData.contact["Client.id"]));
    dispatch(getClientRacks(userData.contact["Client.id"]));
  }, []);

  // INITIALIZE DATA :::

  const [myRecycle, setMyRecycle] = useState([]);
  const [myContractRecycle, setMyContractRecycle] = useState([]);
  const [myWish, setMyWish] = useState([]);
  const [roadmapDate, setRoadmapDate] = useState("");
  const [racksForClient, setRacksForClient] = useState([]);

  //!modif before production : desactivation setShowResult
  //const [showResult, setShowResult] = useState(false);

  let today = dayjs(new Date());

  useEffect(() => {
    setRacksForClient(racksData?.data);
  }, [racksData]);

  useEffect(() => {
    let filtredExpressWish = wishesData?.data.filter(
      (wish) => wish.isExpress === 1
    );
    setMyWish(filtredExpressWish);
  }, [wishesData]);

  useEffect(() => {
    let allClientRecycles = recyclesData?.data.map((recycle) => {
      return recycle;
    });
    let recycleFiltred = recyclesData?.data.filter(
      (recycle) => recycle.isFinish === false
    );
    setMyRecycle(allClientRecycles);
    setMyContractRecycle(recycleFiltred);
  }, [recyclesData]);

  useEffect(() => {
    roadmapsData &&
      setRoadmapDate(dayjs(roadmapsData.data.roadmapDate).format("DD/MM/YY"));
  }, [roadmapsData]);

  useEffect(() => {
    roadmapDate && setDateOfCollecte(roadmapDate);
  }, [roadmapDate]);

  // COMPARE DATA :::

  // get data in roadmap :
  const getRoadmapData = async (id) => {
    await dispatch(getOneRoadMap(id));
  };

  const setToStepOne = () => {
    setStepValue("step1");
  };

  const checkRecycle = async () => {
    if (myRecycle) {
      if (!isEmpty(myContractRecycle)) {
        setStepValue("step6");
      } else {
        // je regarde dans le rack :
        if (!isEmpty(racksForClient)) {
          if (racksForClient.isAtSpyde === 0) {
            setStepValue("step4");
          } else {
            setStepValue("step5");
          }
        } else {
          // je regarde dans les demandes
          if (isEmpty(myWish)) {
            setStepValue(null);
          } else {
            if (!myWish[0].roadmap_id) {
              setStepValue("step1");
            } else {
              await getRoadmapData(myWish[0].roadmap_id);

              if (!roadmapsData?.data.driver_id) {
                setStepValue("step1");
              } else {
                if (roadmapDate === today.format("DD/MM/YY")) {
                  setStepValue("step3");
                } else {
                  setStepValue("step2");
                }
              }
            }
          }
        }
      }
    } else {
      setStepValue(null);
      console.log("ERREUR : PAS DE RECYCLAGE ENREGISTRE");
    }
  };

  useEffect(() => {
    checkRecycle();
  }, [myRecycle]);

  useEffect(() => {
    let expressWish = wishesData?.data.filter((wish) => wish.isExpress === 1);
    if (isEmpty(expressWish)) {
      setFindExpressRecycle(false);
    } else {
      setFindExpressRecycle(true);
    }
  }, [wishesData]);

  return (
    <div className="following">
      {findExpressRecycle ? (
        <div className="following__flex">
          <div className="following__flex--title ">
            <div className="blockTittle blockTittle--express">
              <div className="blockTittle__container blockTittle__container--express">
                <p className="blockTittle__text">
                  {stepTitles[stepValue] || "Aucune collecte n'est prévue"}
                </p>
              </div>
            </div>
          </div>
          <div className="following__flex--image ">
            <img
              src={dataImages[stepValue] || DefaultStep}
              alt="Illustration de l'étape"
            />
          </div>
          <div className="following__flex--duration">
            <p className="font__italic ">
              {textDuration[stepValue] ||
                "Il semblerait que votre contrat ne soit plus à jour ou désactivé"}
            </p>
            <p className="font__mainInfo">
              {alertDuration[stepValue] || "Contactez votre commercial Spyde"}
            </p>
          </div>
        </div>
      ) : (
        <AskForExpress validation={setToStepOne} />
      )}
    </div>
  );
};
