export default function ramdomPassword(nb) {
  let password;
  let centralpart;
  let numberpart;
  let characterpart;
  let centralpartB;
  let numberpartB;
  let characterpartB;

  const getRamdomPassword = () => {
    const maxLetter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const minLetter = "abcdefghijklmnopqrstuvwxyz";
    const number = "0123456789";
    const character = "_@#$%&+-_";

    centralpart = Array(5)
      .fill(maxLetter)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    centralpartB = Array(3)
      .fill(minLetter)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    numberpart = Array(3)
      .fill(number)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    numberpartB = Array(5)
      .fill(number)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    characterpart = Array(1)
      .fill(character)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    characterpartB = Array(1)
      .fill(character)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    password = numberpartB
      .concat(characterpartB)
      .concat(centralpart)

      .concat(numberpart)
      .concat(characterpart)

      .concat(centralpartB);
  };

  let regexSpecialCharacter =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&+-_])([a-zA-Z0-9@#$%&+-_]){8,30}$/;

  for (let i = 0; i <= Math.pow(2, nb); i++) {
    getRamdomPassword();
    if (regexSpecialCharacter.test(password)) {
      i = nb + 1;

      return password;
    } else {
      getRamdomPassword();
    }
  }

  return "Error";
}
