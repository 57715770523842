export const phoneNumber = (tel) => {
  const arr = [...tel];

  const newPhone =
    arr[0] +
    arr[1] +
    "." +
    arr[2] +
    arr[3] +
    "." +
    arr[4] +
    arr[5] +
    "." +
    arr[6] +
    arr[7] +
    "." +
    arr[8] +
    arr[9];

  return newPhone;
};
