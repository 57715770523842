import React from "react";
import SvgArrow from "../elements/SvgArrow";
import { useSelector } from "react-redux";
import dateFormat from "../../utils/dateFormat";
import { NavLink } from "react-router-dom";
import isEmpty from "../../utils/isEmpty";

const SectBlogLarge = () => {
  const publicImagesData = useSelector(
    (state) => state.publishImagesBlog.imagesBlog
  );
  const publicPagesData = useSelector(
    (state) => state.publishPagesBlog.pagesBlog
  );
  return (
    <section className="sectBlogLarge">
      {publicPagesData?.data.slice(0, 1).map((page, index) => {
        let imagesForPage = publicImagesData?.data.filter(
          (image) => image.pageBlog_id === page.id
        );
        return (
          <React.Fragment key={index}>
            <h2 className="font__btn">Notre Dernier Article</h2>
            <h3 className="font__question">{page.pageName}</h3>
            <p className="sectBlogLarge__text sectBlogLarge__text--date font__date">
              Edité le {dateFormat(page.updatedAt)}
            </p>
            {!isEmpty(imagesForPage) && (
              <div className="sectBlogLarge__img">
                <img
                  src={imagesForPage ? imagesForPage[0].addressURL : ""}
                  alt={imagesForPage ? imagesForPage[0].description : ""}
                ></img>
              </div>
            )}

            <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
              {page.intro}
            </p>
            <div className="sectBlogLarge__btn">
              <NavLink to={"/blog/article/" + page.id}>
                <button className="font__btn btn btn__simple btn__simple--red">
                  Lire l'article
                  <SvgArrow className="btn__arrow--red" />
                </button>
              </NavLink>
            </div>
          </React.Fragment>
        );
      })}
    </section>
  );
};

export default SectBlogLarge;
