import { useState } from "react";
import formatLog from "../utils/formatLog";

const useInputHook = (initialvalue) => {
  const [value, setValue] = useState(initialvalue);
  const [suggestions, setSuggestions] = useState([]);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  // const changesuggestions = (evt) => {
  //   setSuggestions(evt.target.value);
  // };
  // const changelat = (evt) => {
  //   setLat(evt.target.value);
  // };
  // const changelong = (evt) => {
  //   setLong(evt.target.value);
  // };

  const handleChange = async (event) => {
    setValue(event.target.value);

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}&autocomplete=true`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results?.features);
    } catch (error) {
      formatLog("Error fetching Data ...", error);
    }
  };

  return {
    value,
    onChange: handleChange,
    setValue,
    setSuggestions,
    //changesuggestions,
    suggestions,
    //changelat,
    //changelong,
    setLat,
    setLong,
    lat,
    long,
  };
};

export default useInputHook;
