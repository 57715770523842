import { useDispatch } from "react-redux";
import { useEffect } from "react";
import WidgetContainer from "../../components/widgets/WidgetContainer";
import NewClient from "../../components/blocks/NewClient";
import { getClients } from "../../feature/client.slice";

import AllClients from "../../components/blocks/AllClients";
import IsCommercial from "../../services/contexts/IsCommercial";
import useAuth from "../../services/useAuth";
import DirectorAccess from "../../services/contexts/DirectorAccess";

const SpydeClients = () => {
  const dispatch = useDispatch();
  const auth = useAuth();

  //!getData Clients:
  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  return (
    <div className="configGrid">
      <section className="widget">
        <WidgetContainer
          icon="people-outline"
          title="Comptes Clients"
          order={1}
          open={true}
        >
          <AllClients />
        </WidgetContainer>

        {(DirectorAccess(auth) || IsCommercial(auth)) && (
          <WidgetContainer
            icon="person-add-outline"
            title="Ajouter un Compte"
            order={4}
          >
            <NewClient />
          </WidgetContainer>
        )}
      </section>
    </div>
  );
};

export default SpydeClients;
