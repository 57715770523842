import React from "react";

export const RecycleBox = ({ logoUrl, title, color, weight }) => {
  return (
    <div className="myrecycle__container">
      <img src={logoUrl} alt={"pictogramme de " + title} />
      <h3 className={"weightbox__element--" + color}>{title}</h3>
      <p className="myrecycle__container__text">Poids Recyclé</p>
      <p
        className={"myrecycle__container__weight weightbox__element--" + color}
      >
        {weight < 1000000
          ? Math.round((weight / 1000) * 10) / 10
          : Math.round((weight / 1000000) * 100) / 100}
      </p>

      <p className="myrecycle__container__text">
        {weight < 1000000 ? "Kg" : weight < 2000000 ? "Tonne" : "Tonnes"}
      </p>
    </div>
  );
};
