export const CLIENT_REGEX = /^[àùïîéèöôêëça-zA-Z0-9-_ ']{3,100}$/;
export const SITE_REGEX = /^[a-zA-Z][àùïîéèöôêëça-zA-Z0-9-_ ']{3,50}$/;
export const CONTRACT_NUMBER_REGEX = /^[a-zA-Z0-9-_ ]{3,50}$/;
export const SIRET_REGEX = /^[0-9]{14}$/;
export const CODE_REGEX = /^[A-Z]{4}[0-9]{5}$/;
export const ZIPCODE_REGEX = /^[0-9]{5}$/;
export const NAME_RDV_REGEX = /^[àùïîéèöôêëça-zA-Z0-9-_ ']{3,50}$/m;
export const MESSAGE_REGEX =
  /^[àùïîéèöôêëçA-Za-z0-9()/:+!?'&=;.,$€@%-_" (\r\n)]{10,500}$/m;
export const INFO_REGEX =
  /^[àùïîéèöôêëçA-Za-z0-9()/:+!?'&=;.,$€@%-_ "]{3,100}$/;

export const CONTACT_NAME_REGEX = /^[àùïîéèöôêëça-zA-Z0-9-_ ']{3,50}$/;
export const TEL_REGEX = /^[0]{1}[1-9]{1}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/;

export const EMAIL_REGEX = /^[a-zA-Z0-9_.-]+[@]{1}[a-z0-9-_]+[.][a-z]+$/;

export const PASSWORD_REGEX =
  /^(?=.*[@#$€%^&+=*.\/!-_])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9@#$€%^&+=*.\/!-_]){8,30}$/m;

export const NUMBER_DUO_REGEX = /^[0-9]{1,2}$/m;
export const WEIGHT_REGEX = /^[0-9]{1,}$/m;

export default {
  CLIENT_REGEX,
  SITE_REGEX,
  CODE_REGEX,
  ZIPCODE_REGEX,
  MESSAGE_REGEX,
  CONTACT_NAME_REGEX,
  NAME_RDV_REGEX,
  TEL_REGEX,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  SIRET_REGEX,
  NUMBER_DUO_REGEX,
  CONTRACT_NUMBER_REGEX,
  INFO_REGEX,
  WEIGHT_REGEX,
};
