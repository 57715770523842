import React, { useState } from "react";
import ExpressBig from "../../img/Group.svg";
import DashButton from "../elements/DashButton";
import Btn_Express_Left from "../../img/Btn_Express_Left.svg";
import Btn_Express_Right from "../../img/Btn_Express_Right.svg";
import { useSelector, useDispatch } from "react-redux";
import { addNewWish, getClientWish } from "../../feature/wish.slice";
import ModalLayout from "../layouts/ModalLayout";
import { toast } from "react-toastify";

export const AskForExpress = ({ validation }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const [modal, setModal] = useState(false);

  const generateExpressRecycle = async (e) => {
    e.preventDefault();
    let newWish = {
      dateOfIntervention: new Date(),
      client_id: userData.contact.client_id,
      isExpress: true,
      isDone: false,
      contact_id: userData.contact.id,
    };

    try {
      await dispatch(addNewWish(newWish));
      await dispatch(getClientWish(userData.contact.client_id));

      await validation();
      await setModal(false);

      await document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error(
        "Erreur lors de la création de la demande Express. Veuillez contacter votre commercial Spyde"
      );
      document.body.classList.remove("active-modal");
    }
  };

  const toggleModal = (e) => {
    e.preventDefault();

    setModal(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const cancel = (e) => {
    e.preventDefault();
    setModal(false);
    document.body.classList.remove("active-modal");
  };

  return (
    <>
      <div className="askGrid">
        <div className="askGrid__element askGrid__element--svg">
          <img src={ExpressBig} alt="Illustration poubelle qui déborde" />
        </div>
        <div className="askGrid__element">
          <p className="font__sublineInfo askGrid__element--text">
            Ça déborde ?!
          </p>
          <p className="askGrid__element--text">Besoin d'aide ?</p>
          <h2 className="font__mainInfo askGrid__element--text">
            Spyde intervient au plus vite en 2 clics seulement
          </h2>
          <p className="font__sublineInfo askGrid__element--text">
            Par contre, pour vos collègues, on ne peut rien faire ...
          </p>
        </div>
        <div className="askGrid__element">
          <p className="askGrid__element--text askGrid__element--textLeft">
            Grâce à son système{" "}
            <span className="font__oneClient">“Collecte Express”</span>, Spyde
            vous garantie une intervention rapide et efficace, dès que vous en
            avez besoin.
          </p>
          <p className="askGrid__element--text askGrid__element--textLeft">
            Nous intervenons dès que possible, et si l’un de nos collecteurs est
            dans votre secteur, le soucis disparait en quelques heures seulement
            ...
          </p>
        </div>
        <div className="askGrid__element askGrid__element--btn">
          <div className="expressBtn">
            <div className="expressBtn__images">
              <img
                src={Btn_Express_Left}
                alt="Illustration d'une femme souhaitant appuyer sur le bouton "
              />
              <img
                src={Btn_Express_Right}
                alt="Illustration d'une autre femme souhaitant appuyer sur le bouton "
              />
            </div>
            <div className="expressBtn__text">
              <h2 className="font__mainInfo">Essayez "Collecte Express" !</h2>
              <p className="font__sublineInfo">Satisfaction garantie ;)</p>
            </div>
            <div className="expressBtn__btn">
              <DashButton
                //disabled={disabled}
                action="search"
                text="Activer Collecte Express"
                onClick={() => setModal(true)}
              />
            </div>
          </div>
        </div>
        <div className="askGrid__element askGrid__element--full">
          <p className="font__sublineInfo askGrid__element--text">
            “Collecte Express” est un service complémentaire et facturable de
            Spyde. Reportez vous à votre contrat pour connaître les tarifs
            applicables.
          </p>
        </div>
      </div>

      {modal && (
        <ModalLayout
          toggle={toggleModal}
          modal={modal}
          label="Activation de Collecte Express"
          colorClass="yellow"
        >
          <div className="activeExpress">
            <p className="font__oneClient">
              Vous êtes sur le point de réaliser une demande de collecte
              "Express"
            </p>
            <p className="font__sublineInfo">
              Ce service de Spyde est facturable en supplément. Reportez-vous à
              votre contrat pour connaître les tarifs négociés.
            </p>
            <p className="font__simpleText">
              Spyde viendra dans un délai de 1 à 2 jours maximum afin d'évacuer
              les déchets qui vous emcombrent. Cependant, la demande de collecte
              prévue au contrat ne sera pas supprimée.
            </p>
            <div className="activeExpress__btn">
              <div className="buttonContainer">
                <DashButton
                  //disabled={disabled}
                  action="action"
                  text="Activer une Collecte Express"
                  onClick={(e) => generateExpressRecycle(e)}
                />
              </div>
              <div className="buttonContainer">
                <DashButton
                  //disabled={disabled}
                  action="cancel"
                  text="Annuler"
                  onClick={(e) => cancel(e)}
                />
              </div>
            </div>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
