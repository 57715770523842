import { useSelector } from "react-redux";

const MyCompany = () => {
  const userData = useSelector((state) => state.user.user);

  return (
    <div className="dashfrom">
      <form className="dashform__form">
        {/* onSubmit={saveClient} */}
        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Raison sociale :</label>

          <p className="responseContainer__result">
            {userData?.contact["Client.clientName"]}
          </p>
        </div>
        <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label>Nom du site :</label>
          <p className="responseContainer__result">
            {userData?.contact["Client.agency"]}
          </p>
        </div>
        {/* <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
          <label htmlFor="zipCode">Code Ets :</label>
          <p className="responseContainer__result">
            {userData?.contact["Client.codeEts"]}
          </p>
        </div> */}

        <div className="dashform__form__block dashform__form__block--padding dashform__form__block--address  responseContainer__split--half">
          <label>Adresse de collecte :</label>

          <p className="responseContainer__result">
            {userData?.contact["Client.address"]}
          </p>

          <br />
          <p className="font__italic font__italic--red">
            Si des informations sont incorrectes, merci de prévenir votre
            contact Spyde. Nota : Leur modification peut entraîner un
            modification de votre contrat.
          </p>
        </div>
      </form>
    </div>
  );
};

export default MyCompany;
