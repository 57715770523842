import NaviBurger from "../elements/NaviBurger";
import Connect from "../elements/Connect";
import BlockLogo from "../blocks/BlockLogo";
import ConnectUser from "../elements/ConnectUser";
import useAuth from "../../services/useAuth";

const Header = () => {
  const auth = useAuth();

  return (
    <header className="classicHeader">
      <BlockLogo />
      <NaviBurger />
      {auth?.auth.rules ? (
        auth?.auth.rules[0] === 2000 ? (
          <ConnectUser text="Mon Espace Spyde" link="/dashboard/spyde/home" />
        ) : (
          <ConnectUser text="Mon compte Spyde" link="/dashboard/home" />
        )
      ) : (
        <Connect />
      )}
      {/* auth?.auth.rules[0] === 1000 ? (
        auth?.auth.rules[0] === 2000 ? (
          <ConnectSpyder />
        ) : (
          <ConnectUser />
        )
      ) : (
        <Connect />
      )} */}
    </header>
  );
};

export default Header;
