import { useState, useEffect } from "react";
import { phoneNumber } from "../../utils/phoneNumber";
import { useSelector, useDispatch } from "react-redux";
import DashButton from "../elements/DashButton";
import Axios from "../../api/axios";
import { modifUser } from "../../feature/user.slice";

import Compressor from "compressorjs";

import { CONTACT_NAME_REGEX, TEL_REGEX } from "../../utils/regex";

const MyInfos = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  const [isModify, setIsModify] = useState(false);
  const [lastname, setLastname] = useState(userData.contact.lastname);
  const [firstname, setFirstname] = useState(userData.contact.firstname);
  const [tel, setTel] = useState(userData.contact.phone);

  const [file, setFile] = useState();
  const [pictureUrl, setPictureUrl] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);

  const [validTel, setValidTel] = useState(false);
  const [validFirstname, setValidFirstname] = useState(false);
  const [validLastname, setValidLastname] = useState(false);

  useEffect(() => {
    setValidFirstname(CONTACT_NAME_REGEX.test(firstname));
  }, [firstname]);

  useEffect(() => {
    setValidLastname(CONTACT_NAME_REGEX.test(lastname));
  }, [lastname]);

  useEffect(() => {
    setValidTel(TEL_REGEX.test(tel));
  }, [tel]);

  useEffect(() => {
    if (pictureUrl !== null) {
      setImageUrl(pictureUrl);
    } else {
      if (userData.contact.addressURL) {
        setImageUrl(userData.contact.addressURL);
      } else {
        setImageUrl("/img/spyde_avatar.png");
      }
    }
  }, []);

  useEffect(() => {
    if (pictureUrl !== null) {
      setImageUrl(pictureUrl);
    } else {
      if (userData.contact.addressURL) {
        setImageUrl(userData.contact.addressURL);
      } else {
        setImageUrl("/img/spyde_avatar.png");
      }
    }
  }, [pictureUrl]);

  const makeCompressor = (file, options) => {
    return new Compressor(file, options);
  };

  const handlePicture = (e) => {
    makeCompressor(e.target.files[0], {
      maxWidth: 200,
      quality: 0.8,
      success(imgBlob) {
        setPictureUrl(URL.createObjectURL(imgBlob));
        setFile(imgBlob);
      },
      error(err) {
        console.log("Err : MyInfos");
      },
    });
  };

  const handleFormData = async (e) => {
    const formData = new FormData();

    formData.append("lastname", `${lastname}`);
    formData.append("firstname", `${firstname}`);
    formData.append("phone", `${tel}`);
    formData.append("login_id", `${userData.contact.login_id}`);
    formData.append("client_id", `${userData.contact.client_id}`);
    if (file) {
      formData.append("file", file);
      if (userData.contact.addressURL) {
        formData.append("imageToDelete", userData.contact.addressURL);
      }
    }

    await Axios.patch(
      process.env.REACT_APP_API_URL +
        "/contact/modif/" +
        `${userData.contact.id}`,
      formData
    )
      .then((res) => {
        dispatch(modifUser(res.data));
        setIsModify(false);
      })
      .then((err) => console.log("err :  MyInfos patch"));
  };

  const handleCancel = () => {
    setLastname(userData.contact.lastname);
    setFirstname(userData.contact.firstname);
    setTel(userData.contact.phone);
    setFile(null);
    setPictureUrl(null);
    setIsModify(false);
  };

  return !isModify ? (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Nom :</label>

            <p className="responseContainer__result">
              {userData?.contact.lastname}
            </p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Prénom :</label>

            <p className="responseContainer__result">
              {userData?.contact.firstname}
            </p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Téléphone :</label>
            <p className="responseContainer__result">
              {phoneNumber(userData?.contact.phone)}
            </p>
          </div>
        </form>

        <div className="imageContainer">
          <label>
            <div className="imageBlock">
              <img
                className="imageBlock__img"
                src={imageUrl}
                alt="votre photo de profil"
              />
            </div>
          </label>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          <DashButton
            //type="submit"
            action="modify"
            text="Modifier"
            onClick={() => setIsModify(true)}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div
              className="info"
              data-tooltip="Est autorisé : lettres, espace, tirets et underscore, max.50 caractères"
            >
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="lastname">Nom :</label>

            <input
              type="text"
              id="lastname"
              autoComplete="off"
              onChange={(e) => setLastname(e.target.value)}
              required
              defaultValue={lastname}
              placeholder="Saisir le nom du contact ..."
            ></input>
            {validLastname && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(lastname.length < 1 && !validLastname) ||
            (lastname.length > 2 && validLastname) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div
              className="info"
              data-tooltip="Est autorisé : lettres, espace, tirets et underscore, max.50 caractères"
            >
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="firstname">Prénom :</label>
            <input
              type="text"
              id="firstname"
              autoComplete="off"
              onChange={(e) => setFirstname(e.target.value)}
              required
              defaultValue={firstname}
              placeholder="Saisir le prénom du contact ..."
            ></input>
            {validFirstname && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(firstname.length < 1 && !validFirstname) ||
            (firstname.length > 2 && validFirstname) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <div
              className="info"
              data-tooltip="Doit être un numéro français valide : 10 chiffres uniquement"
            >
              <ion-icon icon="information-circle-outline"></ion-icon>
            </div>
            <label htmlFor="tel">Téléphone :</label>

            <input
              type="text"
              id="tel"
              autoComplete="off"
              onChange={(e) => setTel(e.target.value)}
              required
              defaultValue={tel}
              placeholder="Saisir un numéro de téléphone ..."
            ></input>
            {validTel && (
              <div className="check check__valid">
                <ion-icon icon="checkmark-circle-outline"></ion-icon>
              </div>
            )}
            {(tel.length < 1 && !validTel) || (tel.length > 2 && validTel) ? (
              ""
            ) : (
              <div className="check check__invalid">
                <ion-icon icon="backspace-outline"></ion-icon>
              </div>
            )}
          </div>
        </form>

        <div className="imageContainer">
          <label htmlFor="selectImg">
            <div className="imageBlock">
              <img
                className="imageBlock__img"
                src={imageUrl}
                alt="votre photo de profil"
              />
              <div className="imageBlock__overlay">
                <p>Cliquer pour modifier</p>
              </div>
            </div>
          </label>
          <input
            id="selectImg"
            type="file"
            name="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => handlePicture(e)}
          />
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          {validFirstname &&
          firstname &&
          validLastname &&
          lastname &&
          validTel &&
          tel &&
          (firstname !== userData.contact.firstname ||
            lastname !== userData.contact.lastname ||
            tel !== userData.contact.phone ||
            file) ? (
            <DashButton
              //type="submit"
              action="action"
              text="Valider"
              onClick={(e) => handleFormData(e)}
            />
          ) : (
            <DashButton
              type="button"
              action="inactive"
              text="Modifier le formulaire"
              //onClick={""}
            />
          )}
        </div>
        <div className="buttonContainer--small">
          <DashButton
            //type="submit"
            action="cancel"
            text="Annuler"
            onClick={() => handleCancel()}
          />
        </div>
      </div>
    </>
  );
};

export default MyInfos;
