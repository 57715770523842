import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";

export const getPublishImagesBlog = createAsyncThunk(
  "ImagesBlog/getPublishImagesBlog",
  async () => {
    return await Axios.get(
      process.env.REACT_APP_API_URL + "/public_blog/images"
    ).then((res) => res.data);
  }
);

export const publishImagesBlogSlice = createSlice({
  name: "ImagesBlog",
  initialState: { imagesBlog: null },

  reducers: {
    setPublishImagesBlog: (state, { payload }) => {
      state.imagesBlog = payload.data;
    },
  },
  extraReducers: {
    [getPublishImagesBlog.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getPublishImagesBlog.fulfilled]: (state, { payload }) => {
      state.imagesBlog = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getPublishImagesBlog.rejected]: (state, action) => {
      state.status = "failed";
      state.imagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setPublishImagesBlog } = publishImagesBlogSlice.actions;

export default publishImagesBlogSlice;
