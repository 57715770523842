import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getContacts = createAsyncThunk("Contact/getContacts", async () => {
  return await Axios.get(process.env.REACT_APP_API_URL + "/contact").then(
    (res) => res.data
  );
});

export const addContact = createAsyncThunk(
  "Contact/addContact",
  async (data) => {
    return await Axios.put(
      process.env.REACT_APP_API_URL + "/contact/add",
      data
    ).then((res) => res.data);
  }
);

export const patchContact = createAsyncThunk(
  "Contact/patchContact",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/contact/modif/" + dataAndId.id,
      dataAndId.data
    ).then((res) => res.data);
  }
);

export const blokAccessToContact = createAsyncThunk(
  "Contact/blokAccessToContact",
  async (dataAndId) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/login/trash/" + dataAndId.id,
      {
        data: dataAndId.data,
      }
    ).then((res) => {
      toast.info(
        "Accès du client bloqué. Pour réactiver, veuillez contacter un administrateur."
      );
    });
  }
);

const contactSlice = createSlice({
  name: "Contact",
  initialState: { contact: null, status: null, loading: false, error: "" },

  reducers: {
    deleteContact: (state, action) => {},
  },

  extraReducers: {
    [getContacts.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getContacts.fulfilled]: (state, { payload }) => {
      state.contact = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getContacts.rejected]: (state, action) => {
      state.status = "failed";
      state.contact = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addContact.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addContact.fulfilled]: (state, { payload }) => {
      let newcontact = { ...payload.data };
      state.contact.data = [...state.contact.data, newcontact];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addContact.rejected]: (state, action) => {
      state.status = "failed";
      state.contact = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchContact.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchContact.fulfilled]: (state, { payload }) => {
      state.contact.data = state.contact.data.map((contact) => {
        if (contact.login_id === payload.data.login_id) {
          return {
            ...contact,
            ...payload.data,
          };
        } else return contact;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchContact.rejected]: (state, action) => {
      state.status = "failed";
      state.contact = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [blokAccessToContact.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [blokAccessToContact.fulfilled]: (state, { payload }) => {
      state.contact.data = state.contact.data;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [blokAccessToContact.rejected]: (state, action) => {
      state.status = "failed";
      state.contact = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { changeContact, deleteContact } = contactSlice.actions;

export default contactSlice;
