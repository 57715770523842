import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getRdvFromPublic = createAsyncThunk(
  "Rdvs/getRdvFromPublic",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/rdv").then(
      (res) => res.data
    );
  }
);

export const deleteRdvFromPublic = createAsyncThunk(
  "Rdvs/deleteRdvFromPublic",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/rdv/delete/" + id
    )
      .then((res) => res && { data: id })
      .catch((err) =>
        toast.error("Une erreur est survenue, merci de recommencer")
      );
  }
);

const rdvSlice = createSlice({
  name: "Rdvs",
  initialState: { rdvs: null, status: null, loading: false, error: "" },

  reducers: {
    deleteRdv: (state, action) => {},
  },

  extraReducers: {
    [getRdvFromPublic.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getRdvFromPublic.fulfilled]: (state, { payload }) => {
      state.rdvs = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getRdvFromPublic.rejected]: (state, action) => {
      state.status = "failed";
      state.rdvs = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [deleteRdvFromPublic.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteRdvFromPublic.fulfilled]: (state, { payload }) => {
      state.rdvs.data = state.rdvs.data.filter(
        (rdv) => rdv.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteRdvFromPublic.rejected]: (state, action) => {
      state.status = "failed";
      state.rdvs = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { deleteRdv } = rdvSlice.actions;

export default rdvSlice;
