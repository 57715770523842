import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";

export const getPublishPagesBlog = createAsyncThunk(
  "PagesBlog/getPublishPagesBlog",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/public_blog").then(
      (res) => res.data
    );
  }
);

export const publishPagesBlogSlice = createSlice({
  name: "PagesBlog",
  initialState: { pagesBlog: null },

  reducers: {
    setPublishPagesBlog: (state, { payload }) => {
      state.pagesBlog = payload.data;
    },
  },
  extraReducers: {
    [getPublishPagesBlog.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getPublishPagesBlog.fulfilled]: (state, { payload }) => {
      state.pagesBlog = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getPublishPagesBlog.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setPublishPagesBlog } = publishPagesBlogSlice.actions;

export default publishPagesBlogSlice;
