import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const DatePicker = (props) => {
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    props.changeStartDate(value);
  }, [value]);

  return (
    <Calendar
      onChange={onChange}
      value={value}
      //minDate={new Date()}
    />
  );
};

export default DatePicker;
