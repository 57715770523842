import { NavLink } from "react-router-dom";
import useAuth from "../../services/useAuth";

import Axios from "../../api/axios";

const ConnectUser = (props) => {
  const { setAuth } = useAuth();

  const handleLogOut = async () => {
    try {
      localStorage.removeItem("spy_tn");
      localStorage.removeItem("spy_sc");
      await Axios.post(process.env.REACT_APP_API_URL + "/logout");
      setAuth("");
    } catch (err) {
      console.log("Error LOG OUT");
    }
  };

  return (
    <div className="activeMode">
      <NavLink to={props.link} className="btnConnect">
        <div className="icon icon__hideOnDesktop">
          <ion-icon icon="person-outline"></ion-icon>
        </div>
        <p className="btnConnect__text">{props.text}</p>
      </NavLink>

      <button className="btnConnect btnConnect__logout" onClick={handleLogOut}>
        <div className="icon">
          <ion-icon icon="log-out-outline"></ion-icon>
        </div>
      </button>
    </div>
  );
};

export default ConnectUser;
