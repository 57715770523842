import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../api/axios";
import { setSpydeAccount } from "../../feature/spyde_account.slice";
import { selectLoading, toggleLoading } from "../../feature/loading.slice";
//import slowCode from "../../services/helpers/slowCode";
import LoaderM from "./LoaderM";
import useAuth from "../../services/useAuth";
import { useNavigate } from "react-router";

const FindSpydeAccount = ({ setDataSpydeAccount }) => {
  const dispatch = useDispatch();
  const spydeAccountData = useSelector(
    (state) => state.spydeAccount.spydeAccount
  );
  const isLoading = useSelector(selectLoading);

  const [isReady, setIsReady] = useState(false);
  const [IsSelected, setIsSelected] = useState(false);
  const [newData, setNewData] = useState(false);

  const { setAuth } = useAuth();

  useEffect(() => {
    dispatch(toggleLoading());
    Axios.get(process.env.REACT_APP_API_URL + "/spyde_accounts")
      .then((res) => {
        dispatch(setSpydeAccount(res.data));
        dispatch(toggleLoading());
        setIsReady(true);
      })
      .catch((err) => {
        dispatch(toggleLoading());
        setIsReady(false);

        setAuth("");
      });
  }, []);

  return isLoading && !IsSelected ? (
    <div className="loaderContainer loaderContainer--h100">
      <LoaderM
        color="red"
        text="Recherche des agences disponibles ..."
        lg="2500"
      />
    </div>
  ) : (
    <>
      {IsSelected ? (
        <div className="responseContainer">
          <h3 className="responseContainer__success">Agence sélectionnée !</h3>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Nom de l'agence :</p>
            <p className="responseContainer__result">{newData.agency}</p>
          </div>
          <div className="responseContainer__block">
            <p className="responseContainer__title">Responsable :</p>
            <p className="responseContainer__result">
              {newData.respLastname + " " + newData.respFirstname}
            </p>
          </div>

          <div className="responseContainer__block">
            <p className="responseContainer__title">Adresse de l'agence :</p>
            <p className="responseContainer__result">{newData.address}</p>
          </div>
        </div>
      ) : (
        <div className="dashform--row dashform__form">
          <div className="dashform__form__block">
            <label htmlFor="search">Sélectionner l'agence :</label>
            <ul className="resultsAddress resultsAddress--agency">
              {isReady &&
                spydeAccountData.data.map((agency, index) => {
                  if (spydeAccountData.data.length > 0) {
                    return (
                      <li
                        key={index}
                        className="resultsAddress__result--agency"
                        onClick={() => {
                          setDataSpydeAccount(agency);
                          setIsSelected(true);
                          setNewData(agency);
                        }}
                      >
                        {agency.agency + " - " + agency.address}
                      </li>
                    );
                  } else return <p>Aucune agence</p>;
                })}
            </ul>
            {/* <ul className="resultsAddress resultsAddress--agency">
              {!isLoading &&
                spydeAccountData.data.map((agency, index) => {
                  if (spydeAccountData.data.length > 0) {
                    return (
                      <li
                        key={index}
                        className="resultsAddress__result--agency"
                        onClick={() => {
                          setDataSpydeAccount(agency);
                          setIsSelected(true);
                          setNewData(agency);
                        }}
                      >
                        {"SPYDE - " + agency.agency + " - " + agency.address}
                      </li>
                    );
                  } else return <p>Aucune agence</p>;
                })}
            </ul> */}

            {/* <input
              type="text"
              id="search"
              autoComplete="off"
              onChange={(e) => setSearchSpydeAccount(e.target.value)}
              required
              placeholder="Saisir le nom de l'agence recherchée ..."
            ></input> */}
          </div>
        </div>
      )}
    </>
  );
};

export default FindSpydeAccount;
