import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "User",
  initialState: { user: null },

  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    modifUser: (state, { payload }) => {
      console.log("modifUser : payload", payload);
      state.user.contact = { ...state.user.contact, ...payload.data };
    },
    modifUserSpyde: (state, { payload }) => {
      state.user = { ...state.user, ...payload.data };
    },

    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, modifUser, modifUserSpyde, clearUser } =
  userSlice.actions;

export default userSlice;
