import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../useAuth";
import LoaderM from "../../components/blocks/LoaderM";
import Axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../feature/user.slice";
import isEmpty from "../../utils/isEmpty.js";
//import { useSelector } from "react-redux";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  //const [getRefresh, setGetRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, setAuth, stayConnect } = useAuth();
  const location = useLocation();
  const from = location.pathname;

  let storageToken = localStorage.getItem("spy_tn");

  const goToDashPage = (rules) => {
    if (rules[0] === 2000) {
      if (from === "/") {
        navigate("/dashboard/spyde/home", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } else if (rules[0] === 1000) {
      if (from === "/") {
        navigate("/dashboard/home", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } else {
      navigate("/seconnecter", { replace: true });
    }
  };

  const autoLogin = async () => {
    await Axios.get(process.env.REACT_APP_API_URL + "/autologin")
      .then(async (response) => {
        console.log("AUTO LOG : Response");

        if (response.data.loggedIn == true) {
          const rules = response.data.user.rules;
          const email = response.data.user.email;

          Axios.defaults.headers.common[
            "authorization"
          ] = `Bearer ${response.data.user.refresh_token}`;

          // Enregistrement RefreshToken dans local storage
          if (stayConnect) {
            localStorage.setItem("spy_tn", response.data.user.refresh_token);
          }

          await Axios.post(process.env.REACT_APP_API_URL + "/refresh")
            .then((response) => {
              setAuth({
                email: email,
                accessToken: response.data.responseToken.access_token,
                rules: rules,
              });

              Axios.defaults.headers.common[
                "authorization"
              ] = `Bearer ${response.data.responseToken.access_token}`;

              dispatch(setUser(response?.data.userData));
              goToDashPage(response?.data.rules);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
              navigate("/seconnecter", { replace: true });
            });

          // Si RefreshToken dans local storage alors set Refresh avec les informations de auth ...
        } else if (stayConnect && !isEmpty(storageToken)) {
          //* Ajouter les fonctionalité de refreshToken avec storageToken

          let goToMyAccount;

          Axios.defaults.headers.common[
            "authorization"
          ] = `Bearer ${storageToken}`;

          await Axios.post(process.env.REACT_APP_API_URL + "/refresh")
            .then((response) => {
              //? Si response ok :
              setAuth({
                email: response.data.responseToken.email,
                accessToken: response.data.responseToken.access_token,
                rules: response.data.rules,
              });

              Axios.defaults.headers.common[
                "authorization"
              ] = `Bearer ${response.data.responseToken.access_token}`;

              localStorage.setItem("spy_tn", response.data.newRefreshToken);

              dispatch(setUser(response?.data.userData));
              goToMyAccount = response?.data.rules;
            })
            .catch((err) => {
              setIsLoading(false);
              navigate("/seconnecter", { replace: true });
            });

          await Axios.get(process.env.REACT_APP_API_URL + "/me")
            .then((response) => {
              console.log(" /me from token in storage");
            })
            .catch((err) => {
              console.log(" Error  /me from token in storage");
            });

          setIsLoading(false);
          goToDashPage(goToMyAccount);
        } else {
          console.log(
            "PAS DE TOKEN : PAS DE VERIFCATION  __________________________"
          );
          setIsLoading(false);
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (auth.accessToken) {
      setIsLoading(false);
    } else if (!auth.accessToken) {
      setIsLoading(true);
      autoLogin();
    }
  }, [auth]);

  return (
    <>
      {!stayConnect ? (
        <Outlet />
      ) : isLoading ? (
        <LoaderM
          color="red"
          text="Vérification de votre identité ..."
          lg="2500"
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
