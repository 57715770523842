import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TitleBlockForm from "./TitleBlockForm";
import InputForm from "../elements/InputForm";
import EndFormThreeButtons from "./EndFormThreeButtons";
import { phoneNumber } from "../../utils/phoneNumber";
import DashButton from "../elements/DashButton";
import IsItMyClient from "../../services/contexts/IsItMyClient";
import DirectorAccess from "../../services/contexts/DirectorAccess";
import Compressor from "compressorjs";
import ImageForm from "./ImageForm";
import {
  getContacts,
  patchContact,
  blokAccessToContact,
} from "../../feature/contact.slice";
import valueOrReplace from "../../utils/valueOrReplace";
//import slowCode from "../../services/helpers/slowCode";
import { toast } from "react-toastify";
import Axios from "../../api/axios";
import LoaderS from "./LoaderS";
import ModalLayout from "../layouts/ModalLayout";
import CheckBox from "../elements/CheckBox";
import ramdomPassword from "../../utils/ramdomPassword";
import sendMailForNewAccount from "../../utils/sendMailForNewAccount";

import {
  infobulle_email,
  infobulle_phone,
  infobulle_name,
} from "../../utils/infoBulleText";

import { CONTACT_NAME_REGEX, TEL_REGEX } from "../../utils/regex";
import useAuth from "../../services/useAuth";
import ModalContent from "../layouts/ModalContent";

const FormContacts = ({ dataToShow, contact }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => state.contact.loading);
  const [isModify, setIsModify] = useState(false);
  const [isAuthorizedForModification, setIsAuthorizedForModification] =
    useState(false);
  const [isDirecteur, setIsDirecteur] = useState(false);

  const [validFirstname, setValidFirstname] = useState(false);
  const [firstname, setFirstname] = useState("");

  const [validLastname, setValidLastname] = useState(false);
  const [lastname, setLastname] = useState("");

  const [validPhone, setValidPhone] = useState(false);
  const [phone, setPhone] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalLockUser, setModalLockUser] = useState(false);

  const [newPasswordCreated, setNewPasswordCreated] = useState(false);
  const [activateNewPassword, setActivateNewPassword] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);
  // for image : To MODIFY
  const [file, setFile] = useState();
  const [pictureUrl, setPictureUrl] = useState(null);

  const [checkA, setCheckA] = useState(false);
  const [checkB, setCheckB] = useState(false);
  const [checkC, setCheckC] = useState(false);
  const [checkD, setCheckD] = useState(false);

  const makeCompressor = (file, options) => {
    return new Compressor(file, options);
  };

  const handlePicture = (e) => {
    makeCompressor(e.target.files[0], {
      maxWidth: 200,
      quality: 0.8,
      success(imgBlob) {
        setPictureUrl(URL.createObjectURL(imgBlob));
        setFile(imgBlob);
      },
      error(err) {
        console.log("err : FormContact");
      },
    });
  };

  //check for authorization :
  useEffect(() => {
    //reset details :
    cancelModify();
    //check authorization :
    setIsAuthorizedForModification(
      IsItMyClient(auth, dataToShow, userData) || DirectorAccess(auth)
    );
    //check if rules Directeur ok :
    setIsDirecteur(DirectorAccess(auth));
  }, [dataToShow]);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  const cancelModify = () => {
    setFile("");
    setPictureUrl("");
    setIsModify(false);
    setValidFirstname(false);
    setFirstname("");
    setValidLastname(false);
    setLastname("");
    setValidPhone(false);
    setPhone("");
  };

  const setForModification = (boolean) => {
    if (boolean === true) {
      setPhone(contact.phone);
      setFirstname(contact.firstname);
      setLastname(contact.lastname);
      setIsModify(boolean);
    } else {
      cancelModify();
    }
  };

  const changeFirstname = (e) => {
    setFirstname(e.target.value);
  };

  const toggleModal = (e) => {
    setModal(e.target.value);
  };

  const toggleLockUser = (e) => {
    setModalLockUser(e.target.value);
  };

  const changeLastname = (e) => {
    setLastname(e.target.value);
  };

  const changePhone = (e) => {
    setPhone(e.target.value);
  };

  const generateNewPassword = async (e) => {
    e.preventDefault();
    let newPassword;
    if (!checkA || !checkB || !checkC) {
      newPassword = "";
    } else if (checkC) {
      newPassword = ramdomPassword(16);
    }

    //first : create Login :
    const dataLogin = {
      email: contact.Login["email"],
      password: newPassword,
      isspyde: false,
    };

    const dataForMail = {
      data: {
        firstname: contact.firstname,
        lastname: contact.lastname,
      },
      message:
        "Votre mot de passe vient d'être ré-initialisé, voici vos nouveaux identifiants de connexion. Si vous n'êtes pas à l'origine de cette demande, merci d'en informer au plus tôt votre commercial Spyde, par téléphone ou par e-mail.",
    };

    await Axios.patch(
      process.env.REACT_APP_API_URL + "/login/modify/pass/" + contact.login_id,
      dataLogin
    )
      .then((res) => {
        sendMailForNewAccount(
          dataForMail.data,
          dataForMail.message,
          dataLogin.email,
          dataLogin.password
        );
      })
      .catch((err) => {
        if (err?.response) {
          if (err.response.status === 409) {
            const errMsg = err.response.data.message.split(" : ");
            toast.error(errMsg[1]);
          } else if (err.response.status === 401) {
            const errMsg =
              "Erreur lors de la génération du mot de passe : Merci de recommencer l'opération";
            toast.error(errMsg);
          } else if (err.response.status === 400) {
            const errMsg =
              "Erreur lors de la transmission, Formulaire non conforme aux attentes du serveur";
            toast.error(errMsg);
          }
        } else {
          toast.error("Erreur serveur");
        }
      });

    toast.info("Ré-initialisation en cours");
    document.body.classList.remove("active-modal");
    setNewPasswordCreated(true);
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    //required :
    formData.append("firstname", valueOrReplace(firstname, contact.firstname));
    formData.append("lastname", valueOrReplace(lastname, contact.lastname));
    formData.append("phone", valueOrReplace(phone, contact.phone));
    formData.append("login_id", contact.login_id);
    formData.append("client_id", dataToShow.id);

    if (file) {
      formData.append("file", file);
      if (contact.addressURL) {
        formData.append("imageToDelete", `${contact.addressURL}`);
      }
    }
    try {
      let dataAndId = { data: formData, id: contact.id };
      dispatch(patchContact(dataAndId));
      cancelModify();
      setIsModify(false);
    } catch (err) {
      toast.error("Erreur lors de la mise à jour");
      cancelModify();
    }
  };

  useEffect(() => {
    setValidFirstname(CONTACT_NAME_REGEX.test(firstname));
    setValidLastname(CONTACT_NAME_REGEX.test(lastname));
    setValidPhone(TEL_REGEX.test(phone));
    if (
      firstname === contact.firstname &&
      lastname === contact.lastname &&
      phone === contact.phone &&
      !file
    ) {
      setActivateNewPassword(true);
      if (onMouseOver) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setActivateNewPassword(false);
    }
  }, [phone, firstname, lastname, file]);

  useEffect(() => {
    if (
      firstname === contact.firstname &&
      lastname === contact.lastname &&
      phone === contact.phone
    ) {
      setActivateNewPassword(true);
      if (onMouseOver) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setActivateNewPassword(false);
    }
  }, [onMouseOver]);

  //* ------ Button validator :
  const checkModification = () => {
    if (file) {
      return true;
    }
    if (
      file ||
      (validFirstname && firstname && firstname !== contact.firstname) ||
      (validLastname && lastname && lastname !== contact.lastname) ||
      (validPhone && phone && phone !== contact.phone)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const changeModal = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const changeLockUser = (e) => {
    e.preventDefault();

    setModalLockUser(true);
  };

  const lockUser = async (e) => {
    e.preventDefault();
    const dataToLock = {
      id: contact.login_id,
      data: {
        email: contact.Login["email"],
        contact_id: contact.id,
      },
    };

    await dispatch(blokAccessToContact(dataToLock));
    await dispatch(getContacts());
    await document.body.classList.remove("active-modal");
    setModalLockUser(false);
    setModal(false);
    cancelModify();

    toast.info("Contact bloqué");
  };

  const [inputListener, setInputListener] = useState({});

  useEffect(() => {
    setInputListener({
      file: file,
      firstname: firstname,
      lastname: lastname,
      phone: phone,
    });
  }, [file, firstname, lastname, phone]);

  return (
    <div className="detail">
      <form className="dashform__form">
        <TitleBlockForm
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          title={"Contact : " + contact.firstname + " " + contact.lastname}
          changeTitle="Modification du contact"
        />
        <div className="dashTwoParts">
          <div className="dashTwoParts__partTwo">
            <InputForm
              label="Prénom :"
              htmlfor={"firstname" + contact.id}
              setData={changeFirstname}
              info={infobulle_name}
              defaultValue={contact.firstname}
              placeholder="Changer le prénom du contact ..."
              validation={validFirstname}
              newValue={firstname}
              isModify={isModify}
              isAuthorized={isAuthorizedForModification}
              dataToShow={contact.firstname}
              required={true}
            />
            <InputForm
              label="Nom :"
              htmlfor={"lastname" + contact.id}
              setData={changeLastname}
              info={infobulle_name}
              defaultValue={contact.lastname}
              placeholder="Changer le nom du contact ..."
              validation={validLastname}
              newValue={lastname}
              isModify={isModify}
              isAuthorized={isAuthorizedForModification}
              dataToShow={contact.lastname}
              required={true}
            />
            <InputForm
              label="Tel :"
              htmlfor={"tel" + contact.id}
              setData={changePhone}
              info={infobulle_phone}
              defaultValue={contact.phone}
              placeholder="Changer le téléphone du contact ..."
              validation={validPhone}
              newValue={phone}
              isModify={isModify}
              isAuthorized={isAuthorizedForModification}
              dataToShow={phoneNumber(contact.phone)}
              required={true}
            />
            {!isModify && (
              <InputForm
                label="Email :"
                htmlfor={"email" + contact.id}
                info={infobulle_email}
                defaultValue={!isLoading && contact.Login["email"]}
                placeholder="Changer l'email du contact ..."
                isModify={false}
                isAuthorized={false}
                dataToShow={!isLoading && contact.Login["email"]}
              />
            )}
          </div>
          <div className="dashTwoParts__partOne">
            {isLoading ? (
              <div className="loaderPosition">
                <LoaderS color="blue" />
              </div>
            ) : (
              <ImageForm
                htmlfor={
                  "Logo_" + contact.firstname.split(" ").join("") + contact.id
                }
                accept=".jpg, .jpeg, .png"
                alt={
                  "Contact de " +
                  dataToShow.clientName +
                  ":" +
                  contact.firstname +
                  " " +
                  contact.lastname
                }
                inputName="file"
                setData={handlePicture}
                defaultValue={contact.addressURL}
                newValue={pictureUrl}
                isModify={isModify}
                rounded="rounded"
                defaultImage="/img/spyde_avatar.png"
                isAuthorized={isAuthorizedForModification}
                textOverlay="Cliquer pour modifier"
              />
            )}
          </div>
        </div>

        {isModify &&
          isAuthorizedForModification &&
          (newPasswordCreated ? (
            <div className="buttonContainer--small">
              Nouveau mot de passe envoyé au contact !!
            </div>
          ) : activateNewPassword ? (
            <div
              className="buttonContainer--small"
              onMouseEnter={() => setOnMouseOver(true)}
              onMouseLeave={() => setOnMouseOver(false)}
            >
              <DashButton
                disabled={disabled}
                action="search"
                text="Ré-initialisation du mot de passe"
                onClick={(e) => changeModal(e)}
              />

              <ModalLayout
                toggle={toggleModal}
                modal={modal}
                label="Ré-initialisation en cours ..."
                colorClass="yellow"
              >
                <ModalContent
                  text={
                    "Vous êtes sur le point de réaliser la /g/ ré-initialisation du mot de passe /g/ pour ce contact. :::Si vous validez, votre contact /g/" +
                    contact.firstname +
                    " " +
                    contact.lastname +
                    "/g/ ne sera plus en mesure  de se connecter avec son ancien mot de passe. :::Il recevra cependant un mail contenant son nouvel identifiant et un nouveau mot de passe temporaire."
                  }
                  moreText="Veuillez valider les points suivants pour confirmer :"
                >
                  <div className="dashform__form__block dashform__form__block--padding ">
                    <CheckBox
                      order="023"
                      text="Je reconnais l’exactitude des informations renseignées ci-dessus. Elles m’ont été transmises directement par le client sus mentionné."
                      afterDisable={() => setCheckA(false)}
                      afterSelect={() => setCheckA(true)}
                    />
                  </div>
                  <div className="dashform__form__block dashform__form__block--padding ">
                    <CheckBox
                      order="024"
                      text="Un contrat physique est signé, ou en cours de signature, auprès de ce client."
                      afterDisable={() => setCheckB(false)}
                      afterSelect={() => setCheckB(true)}
                    />
                  </div>
                  <div className="dashform__form__block dashform__form__block--padding ">
                    <CheckBox
                      order="025"
                      text="Le mot de passe temporaire de ce compte sera généré automatiquement, et sera envoyé par e-mail au nouveau client. Je reconnais ne jamais avoir eu accès à ce mot de passe, ou de la façon dont il a été généré. Le titulaire de ce compte est le seul à avoir connaissance de ce mot de passe."
                      afterDisable={() => setCheckC(false)}
                      afterSelect={() => setCheckC(true)}
                    />
                  </div>
                  <div className="buttonsContainer">
                    <div className="buttonContainer--small">
                      {checkA && checkB && checkC ? (
                        <DashButton
                          disabled={disabled}
                          action="action"
                          text="Valider la ré-initialisation"
                          onClick={(e) => generateNewPassword(e)}
                        />
                      ) : (
                        <DashButton
                          disabled={true}
                          action="inactive"
                          text="Veuillez confirmer"
                          //onClick={(e) => generateNewPassword(e)}
                        />
                      )}
                    </div>
                    <div className="buttonContainer--small">
                      <DashButton
                        //disabled={disabled}
                        action="cancel"
                        text="Annuler"
                        onClick={(e) => toggleModal(e)}
                      />
                    </div>
                  </div>
                </ModalContent>
              </ModalLayout>
            </div>
          ) : (
            <div
              className="buttonContainer--small"
              //onMouseOver={() => setOnMouseOver(true)}
            >
              <DashButton
                disabled={true}
                action="inactive"
                text="Ré-initialisation du mot de passe"
              />
            </div>
          ))}
      </form>
      <EndFormThreeButtons
        modifText="Modifier ce contact"
        cancelText="Annuler"
        inactiveText="Pas de modification"
        validText="Valider les modifications"
        sendData={handleFormData}
        setForModification={setForModification}
        checkModification={checkModification}
        isModify={isModify}
        isAuthorized={isAuthorizedForModification}
        isDirecteur={isDirecteur}
        inputListener={inputListener}
      />
      {isDirecteur && isModify && (
        <div className="buttonContainer__direction">
          <div className="buttonContainer--small">
            <ModalLayout
              toggle={toggleLockUser}
              modal={modalLockUser}
              label="Blocage du contact en cours ..."
              colorClass="red"
            >
              <ModalContent
                text={
                  "Vous êtes sur le point de bloquer l'accès au site Spyde pour ce contact, \n Si vous validez, votre contact " +
                  contact.firstname +
                  " " +
                  contact.lastname +
                  " ne sera plus en mesure de se connecter. \n Aucun autre compte ne pourra être réalisé sur cette adresse mail tant que le compte restera bloqué. Ce contact n'apparaitra plus dans vos comptes clients, et aucun opération sur lui ne sera plus possible."
                }
                moreText="Cependant, l'accès au site Spyde pour les autres contacts de ce client resteront actifs, ainsi que le contrat du client. Le déblocage d'un compte ne peut être réalisé que par un administrateur réseau."
              >
                <div className="dashform__form__block dashform__form__block--padding ">
                  <CheckBox
                    order="033"
                    text={
                      "Je confirme vouloir bloquer l'accès au compte de ce contact :" +
                      contact.firstname +
                      " " +
                      contact.lastname
                    }
                    afterDisable={() => setCheckD(false)}
                    afterSelect={() => setCheckD(true)}
                  />
                </div>

                <div className="buttonsContainer">
                  <div className="buttonContainer--small">
                    {checkD ? (
                      <DashButton
                        //disabled={disabled}
                        action="action"
                        text="Je confirme le blocage du contact"
                        onClick={(e) => lockUser(e)}
                      />
                    ) : (
                      <DashButton
                        disabled={true}
                        action="inactive"
                        text="Veuillez confirmer"
                        //onClick={(e) => generateNewPassword(e)}
                      />
                    )}
                  </div>
                  <div className="buttonContainer--small">
                    <DashButton
                      //disabled={disabled}
                      action="cancel"
                      text="Annuler"
                      onClick={(e) => toggleLockUser(e)}
                    />
                  </div>
                </div>
              </ModalContent>
            </ModalLayout>

            <DashButton
              action="delete"
              text="Bloquer ce contact"
              onClick={(e) => changeLockUser(e)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FormContacts;
