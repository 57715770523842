import React, { useEffect, useState } from "react";
import DashButton from "../elements/DashButton";
import { useSelector, useDispatch } from "react-redux";
import { getRecycles, patchOneRecycle } from "../../feature/recycle.slice";
import dateFormat from "../../utils/dateFormat";
import ModalContent from "../layouts/ModalContent";
import ModalLayout from "../layouts/ModalLayout";
import plasticImage from "../../img/Plastic.svg";
import paperImage from "../../img/Paper.svg";
import aluImage from "../../img/Alu.svg";
import DEEImage from "../../img/DEE.svg";
import inkImage from "../../img/Ink.svg";
import InputMaterial from "../blocks/InputMaterial";
import { toast } from "react-toastify";
import { RatingStar } from "./RatingStar";
import { WEIGHT_REGEX } from "../../utils/regex";

export const ListeRecycles = () => {
  const dispatch = useDispatch();
  const recyclesData = useSelector((state) => state.recycles.recycles);
  const userData = useSelector((state) => state.user.user);

  const [modalWeight, setModalWeight] = useState(false);
  const [modalValidation, setModalValidation] = useState(false);
  const [validationWeights, setValidationWeights] = useState(false);
  const [selectedRecycle, setSelectedRecycle] = useState("");
  const [message, setMessage] = useState(null);

  const [plasticValue, setPlasticValue] = useState(0);
  const [paperValue, setPaperValue] = useState(0);
  const [electronicValue, setElectronicValue] = useState(0);
  const [aluminiumValue, setAluminiumValue] = useState(0);
  const [inkValue, setInkValue] = useState(0);
  const [dataRate, setDataRate] = useState(0);

  useEffect(() => {
    setPlasticValue(selectedRecycle.plastic);
    setPaperValue(selectedRecycle.paper);
    setInkValue(selectedRecycle.ink);
    setElectronicValue(selectedRecycle.electronic);
    setAluminiumValue(selectedRecycle.aluminium);
  }, [selectedRecycle]);

  useEffect(() => {
    dispatch(getRecycles());
  }, [dispatch]);

  useEffect(() => {
    if (
      WEIGHT_REGEX.test(plasticValue) &&
      WEIGHT_REGEX.test(paperValue) &&
      WEIGHT_REGEX.test(inkValue) &&
      WEIGHT_REGEX.test(aluminiumValue) &&
      WEIGHT_REGEX.test(electronicValue)
    ) {
      if (
        `${plasticValue}` === `${selectedRecycle.plastic}` &&
        `${paperValue}` === `${selectedRecycle.paper}` &&
        `${inkValue}` === `${selectedRecycle.ink}` &&
        `${aluminiumValue}` === `${selectedRecycle.aluminium}` &&
        `${electronicValue}` === `${selectedRecycle.electronic}`
      ) {
        if (
          `${plasticValue}` === "0" &&
          `${paperValue}` === "0" &&
          `${inkValue}` === "0" &&
          `${aluminiumValue}` === "0" &&
          `${electronicValue}` === "0"
        ) {
          setValidationWeights(true);
        } else {
          setValidationWeights(false);
        }
      } else {
        setValidationWeights(true);
      }
    } else setValidationWeights(false);
  }, [plasticValue, paperValue, inkValue, aluminiumValue, electronicValue]);

  const weightInKilo = (weightInGramme) => {
    return Math.round((weightInGramme / 1000) * 10) / 10;
  };

  const toggleModalWeight = (e) => {
    // modalWeight && setNewRackNumber("");
    setModalWeight(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const toggleModalValidation = (e) => {
    // modalValidation && setNewRackNumber("");
    setModalValidation(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const openModalValidation = (recycle) => {
    setSelectedRecycle(recycle);
    setModalValidation(true);
  };

  const openModalWeight = (recycle) => {
    setSelectedRecycle(recycle);
    setModalWeight(true);
  };

  const changeDataRate = (e) => {
    setDataRate(e);
  };

  const changePlasticValue = (e) => {
    setPlasticValue(e.target.value);
  };

  const changePaperValue = (e) => {
    setPaperValue(e.target.value);
  };

  const changeInkValue = (e) => {
    setInkValue(e.target.value);
  };

  const changeElectronicValue = (e) => {
    setElectronicValue(e.target.value);
  };

  const changeAluminiumValue = (e) => {
    setAluminiumValue(e.target.value);
  };

  const finishRecycle = async (e) => {
    e.preventDefault();

    try {
      let dataForRecycle = {
        comment_collecte: selectedRecycle.comment_collecte,
        comment_tri: message,
        note: dataRate,
        rackNumber: selectedRecycle.rackNumber,
        client_id: selectedRecycle.client_id,
        roadmap_id: selectedRecycle.roadmap_id,
        driver_id: selectedRecycle.driver_id,
        trieur_id: userData.id,
        peseur_id: selectedRecycle.peseur_id,
        dateOfCollecte: selectedRecycle.dateOfCollecte,
        //note: Joi.number().integer(1).min(1).max(4).allow(null),
        plastic: selectedRecycle.plastic,
        aluminium: selectedRecycle.aluminium,
        electronic: selectedRecycle.electronic,
        ink: selectedRecycle.ink,
        paper: selectedRecycle.paper,
        isFinish: true,
        billIsDone: false,
      };

      let dataAndId = {
        data: dataForRecycle,
        id: selectedRecycle.id,
      };

      await dispatch(patchOneRecycle(dataAndId));
      await dispatch(getRecycles());
      await setModalValidation(false);
      await setSelectedRecycle("");
      await setPlasticValue(0);
      await setPaperValue(0);
      await setElectronicValue(0);
      await setInkValue(0);
      await setAluminiumValue(0);
      await setDataRate(0);
      await setMessage(null);

      await document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error("ERROR Finish Recycle");
    }
  };

  const addWeightsToRecycleData = async (e) => {
    e.preventDefault();

    try {
      let dataForRecycle = {
        comment_collecte: selectedRecycle.comment_collecte,
        comment_tri: null,
        rackNumber: selectedRecycle.rackNumber,
        client_id: selectedRecycle.client_id,
        roadmap_id: selectedRecycle.roadmap_id,
        driver_id: selectedRecycle.driver_id,
        trieur_id: null,
        peseur_id: userData.id,
        dateOfCollecte: selectedRecycle.dateOfCollecte,
        //note: Joi.number().integer(1).min(1).max(4).allow(null),
        plastic: plasticValue,
        aluminium: aluminiumValue,
        electronic: electronicValue,
        ink: inkValue,
        paper: paperValue,
        isFinish: false,
        billIsDone: false,
      };

      let dataAndId = {
        data: dataForRecycle,
        id: selectedRecycle.id,
      };

      await dispatch(patchOneRecycle(dataAndId));
      await dispatch(getRecycles());
      await setModalWeight(false);
      await setSelectedRecycle("");
      await setPlasticValue(0);
      await setPaperValue(0);
      await setElectronicValue(0);
      await setInkValue(0);
      await setAluminiumValue(0);

      await document.body.classList.remove("active-modal");
    } catch (err) {
      toast.error("ERROR AddWeights");
    }
  };

  return (
    <div className="recycle">
      <div className="recycle__container">
        <h3 className="tableGenerator__title widgetFont__table--title">
          Rack en attente de vérification :
        </h3>
        <div className="recycle__container__table">
          <table className="tableRecycle">
            <thead className="tableRecycle__lines tableRecycle__line--header">
              <tr className="tableRecycle__columns tableRecycle__columns--header">
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Rack
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Client
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Collecte
                </th>
                <th className="tableRecycle__columns--text widgetFont__table--header">
                  Info
                </th>

                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--plastic">
                  P
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--paper ">
                  P
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--alu ">
                  A
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--ink ">
                  E
                </th>
                <th className="displayNoneOnSmallScreen tableRecycle__columns--text tableRecycle__columns--electronic ">
                  D
                </th>

                <th className="widgetFont__table--header">
                  Pesée et Validation
                </th>
              </tr>
            </thead>
            <tbody className="tableRecycle__lines">
              {recyclesData &&
                recyclesData.data
                  .filter(
                    (recycle) =>
                      recycle.isFinish === false && recycle.billIsDone === false
                  )
                  .map((recycle, index) => {
                    return (
                      <tr key={index} className="tableRecycle__columns">
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {recycle.rackNumber}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          <span>{recycle["Client"]?.clientName}</span>
                          <span className="displayNoneOnSmallScreen font__italic font__italic--grey">
                            {" - " + recycle["Client"]?.agency}
                          </span>
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {dateFormat(recycle.dateOfCollecte)}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {recycle.comment_collecte &&
                          recycle.comment_collecte !== "" ? (
                            <div
                              className="info info--recycle"
                              data-tooltip={recycle.comment_collecte}
                            >
                              <ion-icon icon="car-sport-outline"></ion-icon>
                            </div>
                          ) : (
                            ""
                          )}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {weightInKilo(recycle.plastic)}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {weightInKilo(recycle.paper)}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {weightInKilo(recycle.aluminium)}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {weightInKilo(recycle.ink)}
                        </th>
                        <th className="displayNoneOnSmallScreen tableRecycle__columns--text widgetFont__table--body">
                          {weightInKilo(recycle.electronic)}
                        </th>
                        <th className="tableRecycle__lines__btnBox">
                          <div className="lineBtn">
                            <DashButton
                              type="button"
                              action="modify"
                              text="P"
                              onClick={() => openModalWeight(recycle)}
                            />
                          </div>

                          <div className="lineBtn">
                            {recycle.peseur_id === null ? (
                              <DashButton
                                type="button"
                                action="inactive"
                                text="V"
                                //onClick={() => setModalValidation(true)}
                              />
                            ) : (
                              <DashButton
                                type="button"
                                action="action"
                                text="V"
                                onClick={() => openModalValidation(recycle)}
                              />
                            )}
                          </div>
                        </th>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
      {modalWeight && (
        <ModalLayout
          toggle={toggleModalWeight}
          modal={modalWeight}
          label="Pesée de la collecte"
          colorClass="green"
        >
          <ModalContent
            text="Merci de remplir ou de modifier les informations ci-dessous, en adéquation avec le rack traité : "
            moreText="Indiquez les poids en grammes suivant la nature du dechêts"
          >
            <h3 className="tableGenerator__title centerLign">
              {"Rack " +
                selectedRecycle.rackNumber +
                " pour le client : " +
                selectedRecycle[`Client`]?.clientName}
            </h3>

            <form className="materialForm">
              <InputMaterial
                label="Collecte des plastiques"
                htmlfor={"trashPlastic" + selectedRecycle.id}
                defaultValue={selectedRecycle.plastic}
                onChange={changePlasticValue}
                //ref={plasticRef}
                image={plasticImage}
                color="blue"
              />
              <InputMaterial
                label="Collecte du papier"
                htmlfor={"trashPaper" + selectedRecycle.id}
                defaultValue={selectedRecycle.paper}
                onChange={changePaperValue}
                image={paperImage}
                color="green"
              />
              <InputMaterial
                label="Collecte de l'aluminium"
                htmlfor={"trashAlu" + selectedRecycle.id}
                defaultValue={selectedRecycle.aluminium}
                onChange={changeAluminiumValue}
                image={aluImage}
                color="red"
              />
              <InputMaterial
                label="Cartouches d'encres"
                htmlfor={"trashInk" + selectedRecycle.id}
                defaultValue={selectedRecycle.ink}
                onChange={changeInkValue}
                image={inkImage}
                color="brown"
              />
              <InputMaterial
                label="Collecte de l'électronique"
                htmlfor={"trash" + selectedRecycle.id}
                defaultValue={selectedRecycle.electronic}
                onChange={changeElectronicValue}
                image={DEEImage}
                color="yellow"
              />
            </form>
            <div className="buttonsContainer">
              <div className="buttonContainer--small">
                {validationWeights ? (
                  <DashButton
                    //disabled={disabled}
                    action="modify"
                    text="Enregistrer la pesée"
                    onClick={(e) => addWeightsToRecycleData(e)}
                  />
                ) : (
                  <DashButton
                    disabled={true}
                    action="inactive"
                    text="Veuillez confirmer"
                  />
                )}
              </div>
              <div className="buttonContainer--small">
                <DashButton
                  //disabled={disabled}
                  action="cancel"
                  text="Annuler"
                  onClick={(e) => toggleModalWeight(e)}
                />
              </div>
            </div>
          </ModalContent>
        </ModalLayout>
      )}
      {modalValidation && (
        <ModalLayout
          toggle={toggleModalValidation}
          modal={modalValidation}
          label="Finaliser le recyclage"
          colorClass="blue"
        >
          <ModalContent
            text="Vous êtes sur le point de noter et de valider le recyclage : "
            moreText={"Notez le tri du rack " + selectedRecycle.rackNumber}
          >
            <div className="centerLign">
              <RatingStar dataRate={changeDataRate} />
              <div>
                {dataRate === 0 && (
                  <p className="">Cliquez sur une étoile pour commencer</p>
                )}
                {dataRate === 1 && (
                  <p className="tableGenerator__title">
                    Impossible à nettoyer, merci de prendre des photos
                  </p>
                )}
                {dataRate === 2 && (
                  <p className="tableGenerator__title">
                    Sale et dangereux, merci de prendre des photos
                  </p>
                )}
                {dataRate === 3 && (
                  <p className="tableGenerator__title">
                    Beaucoup d'erreurs et perte de temps pendant le tri
                  </p>
                )}
                {dataRate === 4 && (
                  <p className="tableGenerator__title">
                    Quelques erreurs mais pas de perte de temps
                  </p>
                )}
                {dataRate === 5 && (
                  <p className="tableGenerator__title">
                    Tri sans aucun problème
                  </p>
                )}
              </div>
            </div>
            <div className="centerLign recycle__validation">
              <label className="modalContent__moreText">
                Si besoin, vous pouvez apporter des précisions sur le tri :
              </label>
              <textarea
                type="textarea"
                id="message"
                className="recycle__validation__textarea"
                autoComplete="off"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Apportez des précisions au tri réalisé, Etat du rack, dechêt hors protocole, etc ..."
              ></textarea>
            </div>

            <div className="buttonsContainer">
              <div className="buttonContainer--small">
                {dataRate !== 0 ? (
                  <DashButton
                    //disabled={disabled}
                    action="action"
                    text="Je valide ce recyclage"
                    onClick={(e) => finishRecycle(e)}
                  />
                ) : (
                  <DashButton
                    disabled={true}
                    action="inactive"
                    text="Notez le tri"
                  />
                )}
              </div>
              <div className="buttonContainer--small">
                <DashButton
                  //disabled={disabled}
                  action="cancel"
                  text="Annuler"
                  onClick={(e) => toggleModalValidation(e)}
                />
              </div>
            </div>
          </ModalContent>
        </ModalLayout>
      )}
    </div>
  );
};
