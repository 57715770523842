import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAuth from "../../services/useAuth";
import dateFormat from "../../utils/dateFormat";

import dayjs from "dayjs";

const OneClient = ({
  client,
  showContrat,
  showCollecte,
  showCodeEts,
  showBonus,
}) => {
  const auth = useAuth();
  const userData = useSelector((state) => state.user.user);
  const contractData = useSelector((state) => state.contract.contract);
  const wishesData = useSelector((state) => state.wishes.wishes);
  const recyclesData = useSelector((state) => state.recycles.recycles);

  const mineClient = () => {
    if (auth.auth.rules.includes(4002)) {
      if (client.Spyder?.id === userData.id) {
        return "oneLignInfos oneLignInfos--mine";
      } else {
        return "oneLignInfos";
      }
    } else return "oneLignInfos";
  };

  const sourceImage = () => {
    if (client.addressURL) {
      return client.addressURL;
    } else {
      return "/img/LogoEts.png";
    }
  };

  const dateIntervention = (clientId) => {
    let nextInterventionDate = wishesData.data.filter(
      (wish) => wish.client_id === clientId
    );

    if (nextInterventionDate[0]) {
      return dateFormat(`${nextInterventionDate[0].dateOfIntervention}`);
    } else return "Aucune";
  };

  const typeCollect = (clientId) => {
    let nextInterventionDate = wishesData.data.filter(
      (wish) => wish.client_id === clientId
    );

    if (nextInterventionDate[0]) {
      if (nextInterventionDate[0].isExpress) {
        return "Express";
      } else return "Régulière";
    } else return "Aucune";
  };

  const contractNumber = (clientId) => {
    let filterContract = contractData.data.filter(
      (contract) => contract.client_id === clientId
    );

    if (filterContract[0]) {
      return filterContract[0].contractNumber;
    } else return "Aucun";
  };

  const endContract = (clientId) => {
    let filterContract = contractData.data.filter(
      (contract) => contract.client_id === clientId
    );

    if (filterContract[0]) {
      let start = dayjs(filterContract[0].startDate);
      let end = start.add(filterContract[0].duration, "month");

      return end.format("DD/MM/YYYY");
    } else return "Aucun";
  };

  const myBonus = (clientId) => {
    //! NOTA : CALCUL DU BONUS A CREER :::::
    let startContract = dayjs(contractData.data.startDate);
    let today = dayjs(new Date());
    let diffMonth = today.diff(startContract, "month");

    let myRecycles = recyclesData?.data
      .filter((recycle) => recycle.client_id === clientId)
      .map((recycle) => recycle.note);
    //let allNotes = recyclesData?.data.map((recycle) => recycle.note);

    let totalNotes = myRecycles.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    let moyNotes = Math.round((totalNotes / myRecycles.length) * 10) / 10;

    if (diffMonth > 3) {
      if (moyNotes > 4) {
        return "-15%";
      } else if (moyNotes > 3) {
        return "-10%";
      } else if (moyNotes > 2) {
        return "-5%";
      } else if (moyNotes > 1) {
        return "0%";
      } else {
        return "Err";
      }
    } else {
      return "N/A";
    }
  };

  return (
    <div id={"Lign" + client.id} className={mineClient()}>
      <div className="oneLignInfos__image">
        <img src={sourceImage()} alt="logo de l'entreprise" />
      </div>
      <div className="oneLignInfos__infos">
        <div className="oneLignInfos__info">
          <p className="font__oneClient">{client.clientName}</p>
          <p className="font__sublineInfo">{client.agency}</p>
        </div>

        {showCollecte && (
          <>
            <div className="oneLignInfos__info">
              <p className="font__oneClient">
                {wishesData.data ? dateIntervention(client.id) : ""}
              </p>
              <p className="font__sublineInfo">Prochaine Collecte</p>
            </div>
            <div className="oneLignInfos__info">
              <p className="font__oneClient">{typeCollect(client.id)}</p>
              <p className="font__sublineInfo">Type de collecte</p>
            </div>
          </>
        )}
        {showContrat && (
          <>
            <div className="oneLignInfos__info">
              <p className="font__oneClient">{contractNumber(client.id)}</p>
              <p className="font__sublineInfo">N° de contrat</p>
            </div>
            <div className="oneLignInfos__info">
              <p className="font__oneClient">{endContract(client.id)}</p>
              <p className="font__sublineInfo">Fin de contrat</p>
            </div>
          </>
        )}
        {showBonus && (
          <div className="oneLignInfos__info">
            <p className="font__oneClient">{myBonus(client.id)}</p>
            <p className="font__sublineInfo">Bonus</p>
          </div>
        )}
        {showCodeEts && (
          <div className="oneLignInfos__info">
            <p className="font__oneClient">{client.codeEts}</p>
            <p className="font__sublineInfo">Code Postal</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OneClient;
