import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../api/axios";

export const getSpyders = createAsyncThunk("Spyder/getSpyders", async () => {
  return await Axios.get(process.env.REACT_APP_API_URL + "/spyder").then(
    (res) => res.data
  );
});
export const getSpydersForClient = createAsyncThunk(
  "Spyder/getSpydersForClient",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/spyder/team").then(
      (res) => res.data
    );
  }
);

const spyderSlice = createSlice({
  name: "Spyder",
  initialState: { spyder: null },

  reducers: {
    setSpyder: (state, { payload }) => {
      state.spyder = payload;
    },
    addSpyder: (state, { payload }) => {
      state.spyder.data = { ...state.spyder.data, payload };
    },
    changeSpyder: (state, action) => {},
    deleteSpyder: (state, action) => {},
  },
  extraReducers: {
    [getSpyders.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getSpyders.fulfilled]: (state, { payload }) => {
      state.spyder = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getSpyders.rejected]: (state, action) => {
      state.status = "failed";
      state.spyder = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [getSpydersForClient.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getSpydersForClient.fulfilled]: (state, { payload }) => {
      state.spyder = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getSpydersForClient.rejected]: (state, action) => {
      state.status = "failed";
      state.spyder = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setSpyder, addSpyder, changeSpyder, deleteSpyder } =
  spyderSlice.actions;

export default spyderSlice;
