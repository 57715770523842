import React from "react";
//import formatLog from "../../utils/formatLog";

const MapBlock = ({ selection }) => {
  //formatLog("selection", selection);
  return (
    <div className="mapBlock">
      <span className="mapBlock__position">{selection.position}</span>
      <div className="mapBlock__status">
        <div className={"mapPoints mapPoints--" + selection.status}></div>
      </div>

      <div className="mapBlock__name">
        <p className="mapBlock__name--title">{selection.name}</p>
        <p className="mapBlock__name--subtitle">{selection.site}</p>
      </div>
      <p className="mapBlock__address">{selection.address}</p>
    </div>
  );
};

export default MapBlock;
