import { BlogSelectArticle } from "../../components/widgets/BlogSelectArticle";
import WidgetContainer from "../../components/widgets/WidgetContainer";

const SpydeBlog = () => {
  return (
    <div className="configGrid">
      <section className="widget">
        <WidgetContainer
          icon="wifi-outline"
          title="Gestion du blog"
          order={1}
          open={true}
        >
          <BlogSelectArticle />
        </WidgetContainer>
      </section>
    </div>
  );
};

export default SpydeBlog;
