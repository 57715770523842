import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "../../utils/isEmpty";

const PublishedCollectBlock = ({
  data,
  contact,
  theme,
  stopButtonPublication,
}) => {
  const [addressEts, setAddressEts] = useState("");
  const [isExpress, setIsExpress] = useState(false);
  const [isEmptyRack, setIsEmptyRack] = useState(true);
  const [racksInBlock, setRacksInBlock] = useState(null);
  const [showContact, setShowContact] = useState(false);

  const wishesData = useSelector((state) => state.wishes.wishes);
  const rackData = useSelector((state) => state.racks.racks);

  //* suppression de , France dans l'adresse :
  useEffect(() => {
    if (!isEmpty(contact)) {
      const splitAddress = contact[0]["Client"].address.split(", France")[0];
      setAddressEts(splitAddress);
    }
  }, [contact]);

  //* vérification si demande est de type Express :
  useEffect(() => {
    wishesData &&
      wishesData?.data.map((wish) => {
        if (wish.id === data.id) {
          return setIsExpress(wish.isExpress);
        }
      });
  }, []);

  //? vérification si un rack est déjà rempli pour cette demande :
  //* récupération des racks déjà utilisés par ce client :
  useEffect(() => {
    let filtredRacks = rackData?.data.filter(
      (rack) => rack.client_id === contact[0].client_id
    );
    setRacksInBlock(filtredRacks);
  }, [rackData]);

  //* changement de l'affichage si des racks sont utilisés par le client :
  useEffect(() => {
    if (racksInBlock?.length < 1) {
      setIsEmptyRack(true);
    } else {
      setIsEmptyRack(false);
    }
  }, [racksInBlock]);

  useEffect(() => {
    if (!isEmpty(contact)) {
      setShowContact(true);
    } else {
      setShowContact(false);
    }
  }, [contact]);

  return (
    <div
      className={
        isEmptyRack
          ? "roadmapElement roadmapElement--" + theme
          : "roadmapElement roadmapElement--charged"
      }
    >
      {showContact && (
        <>
          <div className="roadmapElement__top">
            <div className="roadmapElement__top__position">
              <p className="roadMapFont--position roadMapFont--position--dyn">
                {data.position}
              </p>
            </div>

            <div className="roadmapElement__top__infos">
              <div className="roadmapElement__top__infos--row">
                <p className="roadMapFont--titleValue">
                  {contact[0]["Client"].clientName}
                </p>
              </div>
              <div>
                <p className="roadMapFont--action">{addressEts}</p>
              </div>
            </div>

            <div className="roadmapElement__top__image">
              <img
                src={
                  contact[0].addressURL
                    ? contact[0].addressURL
                    : "/img/spyde_avatar.png"
                }
                alt={contact[0].firstname + " " + contact[0].lastname}
              />
            </div>
          </div>

          <div className="separator"></div>
          {isEmptyRack ? (
            <div className="roadmapElement__bottom">
              <div className="roadmapElement__bottom__infos">
                <p className="roadMapFont--titleValue">
                  {isExpress ? "Express" : "Contrat"}
                </p>
                <p className="roadMapFont--action">{data.popup}</p>
              </div>
              <div className="roadmapElement__bottom__contact">
                <p className="roadMapFont--titleValue">{contact[0].lastname}</p>
                <p className="roadMapFont--title">{contact[0].firstname}</p>
                <p className="roadMapFont--action">{contact[0].phone}</p>
              </div>
            </div>
          ) : (
            <div className="roadmapElement__bottom mgbot--1">
              <div className="rackButtons">
                {racksInBlock?.map((rack, index) => {
                  if (rack.isAtSpyde) {
                    stopButtonPublication(rack.isAtSpyde);
                  }
                  return (
                    <p
                      className={
                        rack.isAtSpyde
                          ? "rackButtons__btn rackButtons__btn--recycle"
                          : "rackButtons__btn rackButtons__btn--ontheroad"
                      }
                      key={index}
                    >
                      {rack.rackNumber}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PublishedCollectBlock;
