const svgLogin =(props)=> {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 942.54724 631.43903">
		<path d="M879.59026,413.72015l2.79791-22.42655a30.28454,30.28454,0,0,1,60.10315,7.4984l-2.79791,22.42654a4.07267,4.07267,0,0,1-4.5404,3.53316l-52.02959-6.49115A4.07266,4.07266,0,0,1,879.59026,413.72015Z" transform="translate(-128.72638 -134.28048)" fill="#2f2e41"/>
		<circle cx="775.78648" cy="263.92678" r="22.20356" fill="#ffb8b8"/>
		<path d="M875.05486,392.20076a23.98352,23.98352,0,0,1,26.73792-20.80636l4.48553.55961a23.98334,23.98334,0,0,1,20.80614,26.73789l-.056.44853-9.47894-1.18258-2.10358-9.45631L913.67,397.47385l-4.8988-.61117-1.06132-4.77116-.89618,4.52695-31.81482-3.96918Z" transform="translate(-128.72638 -134.28048)" fill="#2f2e41"/>
		<path d="M795.91611,477.41149a10.22784,10.22784,0,0,0,15.28426,3.51477L831.3673,492.74l10.16823-10.50441-28.71392-16.28326a10.28328,10.28328,0,0,0-16.9055,11.45917Z" transform="translate(-128.72638 -134.28048)" fill="#ffb8b8"/>
		<path d="M862.01251,498.63944c-14.87549,0-41.052-6.65918-42.50146-7.03125l-.45777-.11719,4.11988-20.85889,39.98193,7.897,21.7207-30.58692,24.93726-2.53515-.69605.916c-.32422.42676-32.46972,42.74317-37.47241,49.8418C870.38214,497.95585,866.77618,498.63944,862.01251,498.63944Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M866.00055,576.92752l-.59522-.28417c-.12671-.06055-12.76513-6.208-19.31128-18.209-6.51489-11.94434,23.98316-62.6123,26.1167-66.12793l.031-16.09082L883.213,446.59452l13.9917-7.90821L885.19171,466.7161Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<polygon points="723.257 615.591 709.885 615.59 703.523 564.011 723.259 564.012 723.257 615.591" fill="#ffb8b8"/>
		<path d="M855.39347,762.83333l-43.11785-.00159v-.54537A16.78359,16.78359,0,0,1,829.05829,745.504h.00107l26.33491.00107Z" transform="translate(-128.72638 -134.28048)" fill="#2f2e41"/>
		<polygon points="866.891 603.872 854.184 608.035 832.077 561.002 850.832 554.857 866.891 603.872" fill="#ffb8b8"/>
		<path d="M1002.89464,749.40842,961.92,762.83333l-.16982-.51825a16.78358,16.78358,0,0,1,10.72241-21.174l.001-.00033,25.02593-8.1994Z" transform="translate(-128.72638 -134.28048)" fill="#2f2e41"/>
		<path d="M969.72638,729.71952l-38.1205-63.64087L899.4256,598.08573l-28.95826,60.06054-11.50366,77.78809-40.343.56055.15893-.63086L880.51715,490.5535l48.352,7.22363-2.20093,31.916,1.31079,1.86426c10.92261,15.5166,22.21607,31.55957,15.73877,48.85351l18.36231,54.52246,40.64624,90.78614Z" transform="translate(-128.72638 -134.28048)" fill="#2f2e41"/>
		<path d="M882.72638,543.71952c-7.00635,0-15-8-10.853-21.65821l-.26855-.19433,9.96069-44.00781c-8.69263-12.43165,2.76807-22.69141,3.842-23.60791l5.4707-9.84717,24.1377-15.30811L927.22882,530.5701l-.18066.17285C918.2276,539.18241,888.60358,543.71952,882.72638,543.71952Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M932.15948,566.617a11.88134,11.88134,0,0,1-2.85131-.30176c-.93873-.23535-2.4585-1.43066-4.80689-9.07715-9.33618-30.39746-20.35913-121.86035-12.35083-130.81055l.18335-.20507,13.14893,2.1914c1.093-.80664,6.82861-4.81054,12.12133-4.11425a8.033,8.033,0,0,1,5.49659,3.249l.09057.11865,4.189,59.59424,15.34619,70.13086-.36792.167C961.46564,557.96561,942.303,566.617,932.15948,566.617Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M923.31655,543.32446a10.22788,10.22788,0,0,0,9.66238-12.35317l19.24856-13.25752-5.20361-13.66228-26.91975,19.104a10.28328,10.28328,0,0,0,3.21242,20.169Z" transform="translate(-128.72638 -134.28048)" fill="#ffb8b8"/>
		<path d="M945.876,528.3367l-17.14551-12.57324,24.10083-32.86426L934.33624,450.261l8.27466-23.66113.53467,1.019c.249.47461,24.94824,47.52686,29.25683,55.06738,4.48267,7.84375-24.97,43.76075-26.22583,45.28516Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M560.20614,676.255l-14.5923-6.1443-10.01026-73.15138H402.299L391.4486,669.81186l-13.05511,6.52746a3.10016,3.10016,0,0,0,1.38657,5.873H559.00349A3.1,3.1,0,0,0,560.20614,676.255Z" transform="translate(-128.72638 -134.28048)" fill="#e6e6e6"/>
		<path d="M797.09757,606.69181H142.26837a12.97344,12.97344,0,0,1-12.9443-12.97332V501.379h680.7178v92.33952A12.97357,12.97357,0,0,1,797.09757,606.69181Z" transform="translate(-128.72638 -134.28048)" fill="#ccc"/>
		<path d="M810.72638,545.02084h-682V149.91957A15.65719,15.65719,0,0,1,144.366,134.28048H795.08655a15.65736,15.65736,0,0,1,15.63983,15.63909Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M769.99322,516.34435H169.45954a12.07024,12.07024,0,0,1-12.057-12.05667v-329.274a12.07088,12.07088,0,0,1,12.057-12.05741H769.99322a12.07088,12.07088,0,0,1,12.057,12.05741v329.274A12.07024,12.07024,0,0,1,769.99322,516.34435Z" transform="translate(-128.72638 -134.28048)" fill="#fff"/>
		<path d="M638.14638,682.97542l-337.44822,0a1.56681,1.56681,0,0,1-1.53908-1.13363,1.52911,1.52911,0,0,1,1.47725-1.91893l337.385,0a1.61535,1.61535,0,0,1,1.61617,1.19368A1.52819,1.52819,0,0,1,638.14638,682.97542Z" transform="translate(-128.72638 -134.28048)" fill="#ccc"/>
		<rect x="489.85973" y="81.90943" width="144.99594" height="110.7733" fill="#f2f2f2"/>
		<rect x="497.0645" y="87.41369" width="130.58641" height="99.76477" fill="#fff"/>
		<path d="M693.37738,295.69477q.02592.00028.05185-.00109a34.81675,34.81675,0,0,0,.779-69.48892.79424.79424,0,0,0-.60112.20836.78592.78592,0,0,0-.25678.57553l-.7612,67.902a.79707.79707,0,0,0,.78826.80412Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M666.072,237.43291a1.02524,1.02524,0,0,1,.71962.30735l23.37584,23.90588a1.01418,1.01418,0,0,1,.29094.72511l-.36424,32.49143a1.01059,1.01059,0,0,1-.33018.73985,1.026,1.026,0,0,1-.77126.26782,35.04446,35.04446,0,0,1-23.69951-58.09062,1.02636,1.02636,0,0,1,.74091-.34658Q666.05306,237.4327,666.072,237.43291Z" transform="translate(-128.72638 -134.28048)" fill="#e6e6e6"/>
		<path d="M689.84193,225.9272a1.02931,1.02931,0,0,1,.69948.28585,1.01135,1.01135,0,0,1,.31394.74786l-.32864,29.31578a1.02077,1.02077,0,0,1-1.75041.70223L668.378,236.11808a1.02291,1.02291,0,0,1,.05493-1.48163,35.11806,35.11806,0,0,1,21.34564-8.708C689.79961,225.92739,689.82088,225.927,689.84193,225.9272Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M679.33353,309.77539a6.25343,6.25343,0,1,1-6.18294-6.32313A6.26054,6.26054,0,0,1,679.33353,309.77539Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M697.19935,309.97567a6.25343,6.25343,0,1,1-6.18294-6.32313A6.26056,6.26056,0,0,1,697.19935,309.97567Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M715.06516,310.176a6.25343,6.25343,0,1,1-6.18294-6.32314A6.26054,6.26054,0,0,1,715.06516,310.176Z" transform="translate(-128.72638 -134.28048)" fill="#e6e6e6"/>
		<rect x="488.33346" y="210.11637" width="144.99594" height="110.7733" fill="#f2f2f2"/>
		<rect x="495.53823" y="215.62063" width="130.58641" height="99.76477" fill="#fff"/>
		<circle cx="517.69473" cy="242.98812" r="5.42203" fill="#3f3d56"/>
		<path d="M733.53881,373.0493a3.67713,3.67713,0,1,1,0,7.35425H676.51323a3.67712,3.67712,0,1,1,0-7.35425h57.02558m0-.9006H676.51323a4.57773,4.57773,0,1,0,0,9.15545h57.02558a4.57773,4.57773,0,1,0,0-9.15545Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M711.03754,381.30414h-42.9283a4.57772,4.57772,0,1,1,0-9.15544h42.9283a4.57772,4.57772,0,0,1,0,9.15544Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<circle cx="517.69473" cy="265.50302" r="5.42203" fill="#3f3d56"/>
		<path d="M733.53881,395.5642a3.67713,3.67713,0,1,1,0,7.35425H676.51323a3.67712,3.67712,0,1,1,0-7.35425h57.02558m0-.9006H676.51323a4.57772,4.57772,0,1,0,0,9.15545h57.02558a4.57772,4.57772,0,1,0,0-9.15545Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M727.24826,403.819h-59.139a4.57772,4.57772,0,1,1,0-9.15544h59.139a4.57772,4.57772,0,0,1,0,9.15544Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<circle cx="517.69473" cy="288.01792" r="5.42203" fill="#3f3d56"/>
		<path d="M733.53881,418.0791a3.67713,3.67713,0,1,1,0,7.35425H676.51323a3.67712,3.67712,0,1,1,0-7.35425h57.02558m0-.9006H676.51323a4.57772,4.57772,0,1,0,0,9.15545h57.02558a4.57772,4.57772,0,1,0,0-9.15545Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M692.125,426.33393H668.10924a4.57772,4.57772,0,1,1,0-9.15543H692.125a4.57772,4.57772,0,0,1,0,9.15543Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<rect x="51.18842" y="101.50782" width="405.09331" height="207.61032" fill="#f2f2f2"/>
		<rect x="64.69153" y="111.82386" width="378.08709" height="186.97824" fill="#fff"/>
		<path d="M524.61425,404.55724H248.996a.8626.8626,0,0,1-.86256-.86256V271.59086a.86256.86256,0,1,1,1.72512,0V402.83212H524.61425a.86256.86256,0,0,1,0,1.72512Z" transform="translate(-128.72638 -134.28048)" fill="#3f3d56"/>
		<path d="M307.88363,395.06909H282.81508a2.56336,2.56336,0,0,1-2.56051-2.5603V357.95166a2.56337,2.56337,0,0,1,2.56051-2.5603h25.06855a2.56337,2.56337,0,0,1,2.56051,2.5603v34.55713A2.56336,2.56336,0,0,1,307.88363,395.06909Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M353.59927,395.06909H328.53072a2.56336,2.56336,0,0,1-2.56051-2.5603V325.17441a2.56336,2.56336,0,0,1,2.56051-2.5603h25.06855a2.56336,2.56336,0,0,1,2.56051,2.5603v67.33438A2.56336,2.56336,0,0,1,353.59927,395.06909Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M399.31491,395.06909H374.24636a2.56337,2.56337,0,0,1-2.56051-2.5603V357.95166a2.56337,2.56337,0,0,1,2.56051-2.5603h25.06855a2.56337,2.56337,0,0,1,2.56052,2.5603v34.55713A2.56337,2.56337,0,0,1,399.31491,395.06909Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/
		><path d="M445.03056,395.06909H419.962a2.50734,2.50734,0,0,1-2.56052-2.44431V312.12a2.50734,2.50734,0,0,1,2.56052-2.44431h25.06855a2.50734,2.50734,0,0,1,2.56051,2.44431v80.50475A2.50734,2.50734,0,0,1,445.03056,395.06909Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<path d="M490.7462,395.06909H465.67765a2.56336,2.56336,0,0,1-2.56051-2.5603V288.94692a2.56336,2.56336,0,0,1,2.56051-2.5603H490.7462a2.56336,2.56336,0,0,1,2.56051,2.5603V392.50879A2.56336,2.56336,0,0,1,490.7462,395.06909Z" transform="translate(-128.72638 -134.28048)" fill="#ff0000"/>
		<circle cx="166.62298" cy="205.58481" r="5.17536" fill="#3f3d56"/>
		<circle cx="212.33862" cy="171.945" r="5.17536" fill="#3f3d56"/>
		<circle cx="258.05426" cy="205.58481" r="5.17536" fill="#3f3d56"/>
		<circle cx="303.7699" cy="155.55637" r="5.17536" fill="#3f3d56"/>
		<circle cx="349.48555" cy="136.58007" r="5.17536" fill="#3f3d56"/>
		<polygon points="258.163 206.744 212.339 172.421 167.14 206.275 166.106 204.895 212.339 170.265 257.945 204.425 303.266 154.83 303.447 154.756 349.163 136.337 349.808 137.937 304.274 156.283 258.163 206.744" fill="#3f3d56"/>
		<path d="M1070.27362,765.71952h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-128.72638 -134.28048)" fill="#ccc"/>		
	</svg>
  )
}

export default svgLogin;