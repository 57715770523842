import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getImagesBlog = createAsyncThunk(
  "ImagesBlog/getImagesBlog",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/image_blog").then(
      (res) => res.data
    );
  }
);

export const addNewImageToBlog = createAsyncThunk(
  "ImagesBlog/addNewImageToBlog",
  async (data) => {
    return await Axios.put(
      process.env.REACT_APP_API_URL + "/image_blog/add",
      data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const deleteOneImage = createAsyncThunk(
  "ImagesBlog/deleteOneImage",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/image_blog/delete/" + id
    )
      .then((res) => res && { data: id })
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const imagesBlogSlice = createSlice({
  name: "ImagesBlog",
  initialState: { imagesBlog: null },

  reducers: {
    setImagesBlog: (state, { payload }) => {
      state.imagesBlog = payload.data;
    },
  },
  extraReducers: {
    [getImagesBlog.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getImagesBlog.fulfilled]: (state, { payload }) => {
      state.imagesBlog = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getImagesBlog.rejected]: (state, action) => {
      state.status = "failed";
      state.imagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewImageToBlog.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewImageToBlog.fulfilled]: (state, { payload }) => {
      let newImageToBlog = { ...payload.data };
      state.imagesBlog.data = [...state.imagesBlog.data, newImageToBlog];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewImageToBlog.rejected]: (state, action) => {
      state.status = "failed";
      state.imagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [deleteOneImage.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteOneImage.fulfilled]: (state, { payload }) => {
      state.imagesBlog.data = state.imagesBlog.data.filter(
        (roadmap) => roadmap.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteOneImage.rejected]: (state, action) => {
      state.status = "failed";
      state.imagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setImagesBlog } = imagesBlogSlice.actions;

export default imagesBlogSlice;
