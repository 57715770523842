import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../api/axios";
import { addContact, getContacts } from "../../feature/contact.slice";
import { toast } from "react-toastify";
import CheckBox from "../elements/CheckBox";
import ramdomPassword from "../../utils/ramdomPassword";
import sendMailForNewAccount from "../../utils/sendMailForNewAccount";
import LoaderM from "../../components/blocks/LoaderM";
import {
  infobulle_phone,
  infobulle_confirmEmail,
  infobulle_email,
  infobulle_paragraphe,
  infobulle_name,
} from "../../utils/infoBulleText";

import {
  EMAIL_REGEX,
  CONTACT_NAME_REGEX,
  TEL_REGEX,
  MESSAGE_REGEX,
} from "../../utils/regex";
import useAuth from "../../services/useAuth";
import { useNavigate } from "react-router";

const FormCreateContact = ({ selectedClient, setDataContact }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [message, setMessage] = useState("");

  const [autoMessage, setAutomessage] = useState(false);

  const [validFirstname, setValidFirstname] = useState(false);
  const [validLastname, setValidLastname] = useState(false);
  const [validTel, setValidTel] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validConfirmEmail, setValidConfirmEmail] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const [checkA, setCheckA] = useState(false);
  const [checkB, setCheckB] = useState(false);
  const [checkC, setCheckC] = useState(false);

  const dispatch = useDispatch();
  //const userData = useSelector((state) => state.user.user);
  const contactData = useSelector((state) => state.contact.contact);
  const isLoading = useSelector((state) => state.contact.loading);

  //? message type for textarea :
  //? ------------------------- :
  const textAutoMessage =
    "Bienvenue chez Spyde !\nVotre compte vient d'être ouvert.\nVous trouverez ci-dessous votre identifiant et votre mot de passe temporaire.";

  const inputMessage = document.getElementById("message");

  useEffect(() => {
    if (autoMessage) {
      inputMessage.value = textAutoMessage;
      setMessage(textAutoMessage);
    }
  }, [autoMessage]);

  //? auto-password :
  //? ------------------------- :
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  let password;
  useEffect(() => {
    if (!checkC) {
      password = "";
    } else if (checkC) {
      password = ramdomPassword(16);
    }
  }, [checkC]);

  useEffect(() => {
    setValidFirstname(CONTACT_NAME_REGEX.test(firstname));
  }, [firstname]);

  useEffect(() => {
    setValidLastname(CONTACT_NAME_REGEX.test(lastname));
  }, [lastname]);

  useEffect(() => {
    setValidTel(TEL_REGEX.test(tel));
  }, [tel]);

  useEffect(() => {
    setValidMessage(MESSAGE_REGEX.test(message));
  }, [message]);

  useEffect(() => {
    setAutomessage(false);
  }, [message]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (!confirmEmail) {
      setValidConfirmEmail(false);
    } else if (confirmEmail === email) {
      setValidConfirmEmail(true);
    } else {
      setValidConfirmEmail(false);
    }
  }, [email, confirmEmail]);

  useEffect(() => {
    try {
      dispatch(getContacts());
    } catch (error) {
      console.log("error : FormCreateContact");
    }
  }, [dispatch]);

  const createContact = async (data, email) => {
    const dataToAdd = {
      firstname,
      lastname,
      phone: tel,
      login_id: data.data.id,
      client_id: selectedClient.id,
    };

    try {
      await dispatch(addContact(dataToAdd));
      await dispatch(getContacts());
      await sendMailForNewAccount(dataToAdd, message, email, password);
      await contactData?.data?.map((contact) => {
        if (contact.login_id === selectedClient.id) {
          setDataContact({ ...contact });
        }
      });
      await clearData();
    } catch (err) {
      if (err?.response) {
        if (err.response.status === 409) {
          const errMsg = err.response.data.message.split(" : ");
          toast.error(errMsg[1]);
        } else if (err.response.status === 400) {
          const errMsg =
            "Erreur lors de la transmission: Formulaire non conforme aux attentes du serveur";
          toast.error(errMsg);
        }
      } else {
        toast.error("Erreur serveur");
      }
    }
  };

  const clearData = () => {
    setFirstname("");
    setLastname("");
    setTel("");
    setEmail("");
    setConfirmEmail("");
    setMessage("");
    setAutomessage(false);
    setValidFirstname(false);
    setValidLastname(false);
    setValidTel(false);
    setValidEmail(false);
    setValidConfirmEmail(false);
    setValidMessage(false);
    setCheckA(false);
    setCheckB(false);
    setCheckC(false);
  };

  const saveContact = async (e) => {
    e.preventDefault();

    //first : create Login :
    const dataLogin = {
      email: email,
      password,
      isspyde: false,
    };

    await Axios.put(process.env.REACT_APP_API_URL + "/login/add", dataLogin)
      .then((res) => {
        createContact(res.data, dataLogin.email);
      })
      .catch((err) => {
        if (err?.response) {
          if (err.response.status === 409) {
            const errMsg = err.response.data.message.split(" : ");
            toast.error(errMsg[1]);
          } else if (err.response.status === 401) {
            const errMsg =
              "Erreur lors de la génération du mot de passe : Merci de recommencer l'opération";
            toast.error(errMsg);
          } else if (err.response.status === 400) {
            const errMsg =
              "Erreur lors de la transmission, Formulaire non conforme aux attentes du serveur";
            toast.error(errMsg);
          }
        } else {
          toast.error("Erreur serveur");
        }
      });
  };

  return isLoading ? (
    <div className="loaderContainer loaderContainer--h300">
      <LoaderM color="blue" text="Création du contact en cours ..." lg="2300" />
    </div>
  ) : (
    <>
      <h3 className="dashform__title dashform__title--client">
        Spyde Contact(s):
      </h3>

      <div className="dashform">
        {contactData?.data?.length > 0 &&
          contactData?.data?.map((contact, index) => {
            if (contact.client_id === selectedClient.id) {
              return (
                <p key={index} className="widgetFont__table--title">
                  {contact.firstname +
                    " - " +
                    contact.lastname +
                    " - tel : " +
                    contact.phone}
                </p>
              );
            } else return "";
          })}
      </div>

      <h3 className="dashform__title dashform__title--client">
        Ajouter un contact
      </h3>

      <div>
        {isLoading ? (
          <LoaderM />
        ) : (
          <div className="dashform">
            <form onSubmit={saveContact} className="dashform__form">
              <div className="splitForm">
                <div className="splitForm__block">
                  <div className="dashform__form__block">
                    <div className="info" data-tooltip={infobulle_name}>
                      <ion-icon icon="information-circle-outline"></ion-icon>
                    </div>

                    <label htmlFor="firstname">Prénom :</label>
                    <input
                      type="text"
                      id="firstname"
                      autoComplete="off"
                      onChange={(e) => setFirstname(e.target.value)}
                      required
                      placeholder="Saisir le prénom du contact ..."
                    ></input>

                    {validFirstname && (
                      <div className="check check__valid">
                        <ion-icon icon="checkmark-circle-outline"></ion-icon>
                      </div>
                    )}
                    {(firstname.length < 1 && !validFirstname) ||
                    (firstname.length > 2 && validFirstname) ? (
                      ""
                    ) : (
                      <div className="check check__invalid">
                        <ion-icon icon="backspace-outline"></ion-icon>
                      </div>
                    )}
                  </div>

                  <div className="dashform__form__block">
                    <div className="info" data-tooltip={infobulle_name}>
                      <ion-icon icon="information-circle-outline"></ion-icon>
                    </div>

                    <label htmlFor="lastname">Nom :</label>
                    <input
                      type="text"
                      id="lastname"
                      autoComplete="off"
                      onChange={(e) => setLastname(e.target.value)}
                      required
                      placeholder="Saisir le nom du contact ..."
                    ></input>

                    {validLastname && (
                      <div className="check check__valid">
                        <ion-icon icon="checkmark-circle-outline"></ion-icon>
                      </div>
                    )}
                    {(lastname.length < 1 && !validLastname) ||
                    (lastname.length > 2 && validLastname) ? (
                      ""
                    ) : (
                      <div className="check check__invalid">
                        <ion-icon icon="backspace-outline"></ion-icon>
                      </div>
                    )}
                  </div>

                  <div className="dashform__form__block">
                    <div className="info" data-tooltip={infobulle_phone}>
                      <ion-icon icon="information-circle-outline"></ion-icon>
                    </div>

                    <label htmlFor="tel">Téléphone :</label>
                    <input
                      type="text"
                      id="tel"
                      autoComplete="off"
                      onChange={(e) => setTel(e.target.value)}
                      required
                      placeholder="Saisir un numéro de téléphone ..."
                    ></input>

                    {validTel && (
                      <div className="check check__valid">
                        <ion-icon icon="checkmark-circle-outline"></ion-icon>
                      </div>
                    )}
                    {(tel.length < 1 && !validTel) ||
                    (tel.length > 2 && validTel) ? (
                      ""
                    ) : (
                      <div className="check check__invalid">
                        <ion-icon icon="backspace-outline"></ion-icon>
                      </div>
                    )}
                  </div>
                </div>
                <div className="splitForm__block">
                  <div className="dashform__form__block">
                    <div className="info" data-tooltip={infobulle_paragraphe}>
                      <ion-icon icon="information-circle-outline"></ion-icon>
                    </div>

                    <textarea
                      type="textarea"
                      id="message"
                      autoComplete="off"
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Ce message sera envoyé à votre contact avec le mail contenant le mot de passe ..."
                      required
                    ></textarea>

                    {validMessage && (
                      <div className="check check__valid">
                        <ion-icon icon="checkmark-circle-outline"></ion-icon>
                      </div>
                    )}
                    {(message.length < 1 && !validMessage) || validMessage ? (
                      ""
                    ) : (
                      <div className="check check__invalid">
                        <ion-icon icon="backspace-outline"></ion-icon>
                      </div>
                    )}
                  </div>
                  <div className="splitForm__block splitForm__block--checkbox">
                    <CheckBox
                      order="020"
                      text="Affiche le message standard"
                      afterDisable={() => setAutomessage(false)}
                      afterSelect={() => setAutomessage(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="dashform__form__block dashform__form__block--padding ">
                <div className="info" data-tooltip={infobulle_email}>
                  <ion-icon icon="information-circle-outline"></ion-icon>
                </div>

                <label htmlFor="email">E-mail du contact :</label>
                <input
                  type="text"
                  id="email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Saisir un e-mail valide pour le contact ..."
                ></input>

                {validEmail && (
                  <div className="check check__valid">
                    <ion-icon icon="checkmark-circle-outline"></ion-icon>
                  </div>
                )}
                {(email.length < 1 && !validEmail) ||
                (email.length > 2 && validEmail) ? (
                  ""
                ) : (
                  <div className="check check__invalid">
                    <ion-icon icon="backspace-outline"></ion-icon>
                  </div>
                )}
              </div>
              <div className="dashform__form__block dashform__form__block--padding ">
                <div className="info" data-tooltip={infobulle_confirmEmail}>
                  <ion-icon icon="information-circle-outline"></ion-icon>
                </div>

                <label htmlFor="confirmEmail">Confirmation de l'e-mail :</label>
                <input
                  type="text"
                  id="confirmEmail"
                  autoComplete="off"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  required
                  placeholder="Confirmer l'adresse e-mail du contact ..."
                ></input>

                {validConfirmEmail && (
                  <div className="check check__valid">
                    <ion-icon icon="checkmark-circle-outline"></ion-icon>
                  </div>
                )}
                {(confirmEmail.length < 1 && !validConfirmEmail) ||
                (confirmEmail.length > 2 && validConfirmEmail) ? (
                  ""
                ) : (
                  <div className="check check__invalid">
                    <ion-icon icon="backspace-outline"></ion-icon>
                  </div>
                )}
              </div>
              <div className="dashform__form__block dashform__form__block--padding ">
                <CheckBox
                  order="023"
                  text="Je reconnais l’exactitude des informations renseignées ci-dessus. Elles m’ont été transmises directement par le client sus mentionné."
                  afterDisable={() => setCheckA(false)}
                  afterSelect={() => setCheckA(true)}
                />
              </div>
              <div className="dashform__form__block dashform__form__block--padding ">
                <CheckBox
                  order="024"
                  text="Un contrat physique est signé, ou en cours de signature, auprès de ce client."
                  afterDisable={() => setCheckB(false)}
                  afterSelect={() => setCheckB(true)}
                />
              </div>
              <div className="dashform__form__block dashform__form__block--padding ">
                <CheckBox
                  order="025"
                  text="Le mot de passe temporaire de ce compte sera généré automatiquement, et sera envoyé par e-mail au nouveau client. Je reconnais ne jamais avoir eu accès à ce mot de passe, ou de la façon dont il a été généré. Le titulaire de ce compte est le seul à avoir connaissance de ce mot de passe."
                  afterDisable={() => setCheckC(false)}
                  afterSelect={() => setCheckC(true)}
                />
              </div>

              <div className="dashform__form--btn">
                {validFirstname &&
                firstname &&
                validLastname &&
                lastname &&
                validTel &&
                tel &&
                validEmail &&
                email &&
                validConfirmEmail &&
                confirmEmail &&
                validMessage &&
                message &&
                checkA &&
                checkB &&
                password !== null &&
                checkC ? (
                  <DashButton
                    type="submit"
                    action="action"
                    text="Ajouter le contact au client"
                    //onClick = ""
                  />
                ) : (
                  <DashButton
                    type="button"
                    action="inactive"
                    text="Compléter le formulaire"
                    //onClick={""}
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default FormCreateContact;
