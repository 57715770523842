import { useState, useEffect } from "react";
import { phoneNumber } from "../../utils/phoneNumber";
import { useSelector, useDispatch } from "react-redux";
import DashButton from "../elements/DashButton";
import Axios from "../../api/axios";
import { modifUser } from "../../feature/user.slice";

import Compressor from "compressorjs";

import { CONTACT_NAME_REGEX, TEL_REGEX } from "../../utils/regex";
import {
  getClients,
  patchClient,
  patchOneClient,
} from "../../feature/client.slice";

const MyLogo = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  const [file, setFile] = useState();
  const [pictureUrl, setPictureUrl] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (pictureUrl !== null) {
      setImageUrl(pictureUrl);
    } else {
      if (userData.contact["Client.addressURL"]) {
        setImageUrl(userData.contact["Client.addressURL"]);
      } else {
        setImageUrl("/img/LogoEts.png");
      }
    }
  }, []);

  useEffect(() => {
    if (pictureUrl !== null) {
      setImageUrl(pictureUrl);
    } else {
      if (userData.contact["Client.addressURL"]) {
        setImageUrl(userData.contact["Client.addressURL"]);
      } else {
        setImageUrl("/img/LogoEts.png");
      }
    }
  }, [pictureUrl]);

  const makeCompressor = (file, options) => {
    return new Compressor(file, options);
  };

  const cancelModify = () => {
    setFile("");
    setPictureUrl(null);

    setImageUrl(null);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  //! TO CHANGE : ERREUR D'ENREGISTREMENT :::::::
  const handleFormData = async (e) => {
    //e.preventDefault();
    const formData = new FormData();
    //required :
    formData.append("clientName", userData.contact["Client.clientName"]);
    formData.append("agency", userData.contact["Client.agency"]);
    formData.append("codeEts", userData.contact["Client.codeEts"]);
    formData.append("address", userData.contact["Client.address"]);
    formData.append("lat", userData.contact["Client.lat"]);
    formData.append("long", userData.contact["Client.long"]);
    formData.append(
      "spyder_login_id",
      userData.contact["Client.spyder_login_id"]
    );

    if (file) {
      console.log("I have one file !");
      formData.append("file", file);
      if (userData.contact["Client.addressURL"]) {
        formData.append(
          "imageToDelete",
          `${userData.contact["Client.addressURL"]}`
        );
      }
    }
    try {
      let dataAndId = { data: formData, id: userData.contact.id };
      console.log("dataAndId", dataAndId);
      dispatch(patchClient(dataAndId)).then((res) => {
        if (res) {
          refreshPage();
        }
      });

      //setIsModify(false);
    } catch (err) {
      //toast.error("Erreur lors de la mise à jour");
      cancelModify();
      console.log("err", err);
    }
  };

  const handlePicture = (e) => {
    makeCompressor(e.target.files[0], {
      maxWidth: 200,
      quality: 0.8,
      success(imgBlob) {
        setPictureUrl(URL.createObjectURL(imgBlob));
        setFile(imgBlob);
      },
      error(err) {
        console.log("Err : MyInfos");
      },
    });

    //handleFormData();
    console.log("client", userData.contact["Client.clientName"]);
  };

  // const handleFormDataOld = async (e) => {
  //   const formData = new FormData();

  //   //formData.append("lastname", `${lastname}`);
  //   //formData.append("firstname", `${firstname}`);
  //   //formData.append("phone", `${tel}`);
  //   formData.append("login_id", `${userData.contact.login_id}`);
  //   formData.append("client_id", `${userData.contact.client_id}`);
  //   if (file) {
  //     formData.append("file", file);
  //     if (userData.contact.addressURL) {
  //       formData.append("imageToDelete", userData.contact.addressURL);
  //     }
  //   }

  //   await Axios.patch(
  //     process.env.REACT_APP_API_URL +
  //       "/contact/modif/" +
  //       `${userData.contact.id}`,
  //     formData
  //   )
  //     .then((res) => {
  //       dispatch(modifUser(res.data));
  //       //setIsModify(false);
  //     })
  //     .then((err) => console.log("err :  MyInfos patch"));
  // };

  // const handleCancel = () => {
  //   setFile(null);
  //   setPictureUrl(null);
  //   //setIsModify(false);
  // };

  return (
    <>
      <div className="dashform">
        <div className="imageContainer morePadding">
          <label htmlFor="selectImg">
            <div className="imageBlock">
              <img
                className="imageBlock__img"
                src={imageUrl}
                alt="Le logo de votre entreprise"
              />
              <div className="imageBlock__overlay">
                <p>MODIFIER</p>
              </div>
            </div>
          </label>
          <input
            id="selectImg"
            type="file"
            name="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => handlePicture(e)}
          />
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer--small">
          {file ? (
            <DashButton
              //type="submit"
              action="action"
              text="Valider"
              onClick={(e) => handleFormData(e)}
            />
          ) : (
            <DashButton
              type="button"
              action="inactive"
              text="Cliquez sur le logo"
              //onClick={""}
            />
          )}
        </div>
        {/* <div className="buttonContainer--small">
          <DashButton
            //type="submit"
            action="cancel"
            text="Annuler"
            onClick={() => handleCancel()}
          />
        </div> */}
      </div>
    </>
  );
};

export default MyLogo;
