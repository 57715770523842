import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../../services/useAuth";
import DashButton from "../elements/DashButton";
import InputForm from "../elements/InputForm";
import {
  addNewContract,
  getContracts,
  patchContract,
  trashContract,
} from "../../feature/contract.slice";
import ModalContent from "../layouts/ModalContent";
import ModalLayout from "../layouts/ModalLayout";
import CheckBox from "../elements/CheckBox";
import dateFormat from "../../utils/dateFormat";

import TitleBlockForm from "./TitleBlockForm";
import EndFormThreeButtons from "./EndFormThreeButtons";
import { toast } from "react-toastify";

import DatePicker from "../elements/DatePicker";

import {
  infobulle_name,
  infobulle_frequency,
  infobulle_contract_time,
  infobulle_contract_number,
  infobulle_paragraphe,
} from "../../utils/infoBulleText";
import {
  CONTACT_NAME_REGEX,
  NUMBER_DUO_REGEX,
  CONTRACT_NUMBER_REGEX,
  MESSAGE_REGEX,
} from "../../utils/regex";
import { addNewWish, getWishes, trashOneWish } from "../../feature/wish.slice";
import dayjs from "dayjs";

const FormSpydeContract = ({ dataToShow }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const contractData = useSelector((state) => state.contract.contract);

  const [isAuthorizedForModification, setIsAuthorizedForModification] =
    useState(false);
  const [isDirecteur, setIsDirecteur] = useState(false);
  const [isModify, setIsModify] = useState(false);

  const [existingContract, setExistingContract] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalNewWish, setModalNewWish] = useState(false);
  const [checkA, setCheckA] = useState(false);
  const [modalStopContract, setModalStopContract] = useState(false);

  const [contractName, setContractName] = useState("");
  const [frequency, setFrequency] = useState("");
  const [contractTime, setContractTime] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [infoContract, setInfoContract] = useState("");

  const [clientContract, setClientContract] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [validContractName, setValidContractName] = useState(false);
  const [validFrequency, setValidFrequency] = useState(false);
  const [validContractTime, setValidContractTime] = useState(false);
  const [validContractNumber, setValidContractNumber] = useState(false);
  const [validInfoContract, setValidInfoContract] = useState(false);

  const [inputListener, setInputListener] = useState({});
  const [inputDatePicker, setInputDatePicker] = useState({});

  const changeStartDate = (date) => {
    //setStartDate(dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
    setStartDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const changeContractName = (e) => {
    setContractName(e.target.value);
  };
  const changeContractNumber = (e) => {
    setContractNumber(e.target.value);
  };
  const changeFrequency = (e) => {
    setFrequency(e.target.value);
  };
  const changeContractTime = (e) => {
    setContractTime(e.target.value);
  };
  const changeInfoContract = (e) => {
    setInfoContract(e.target.value);
  };

  const cancelModify = () => {
    setIsModify(false);
  };

  useEffect(() => {
    dispatch(getWishes());
  }, [dispatch]);

  const createNewContract = async (e) => {
    e.preventDefault();
    let data = {
      contractName: contractName,
      frequency: frequency,
      duration: contractTime,
      contractNumber: contractNumber,
      client_id: dataToShow.id,
      isActive: true,
      startDate: startDate,
    };
    if (infoContract) {
      data.detailsMessage = infoContract;
    }

    let newWish = {
      dateOfIntervention: startDate,
      client_id: dataToShow.id,
      isExpress: false,
      isDone: false,
    };

    try {
      await dispatch(addNewContract(data));
      await dispatch(getWishes());
      await dispatch(addNewWish(newWish));
      await dispatch(getContracts());
      setCancel();
      document.body.classList.remove("active-modal");
    } catch (err) {
      document.body.classList.remove("active-modal");
      toast.error("Erreur lors de la mise à jour");
      setCancel();
    }
  };

  const modifyContract = async (e) => {
    e.preventDefault();
    try {
      let dataForContract;
      if (infoContract) {
        dataForContract = {
          contractName: contractName,
          contractNumber: contractNumber,
          duration: contractTime,
          frequency: frequency,
          detailsMessage: infoContract,
          client_id: clientContract["Client"].id,
          startDate: clientContract.startDate,
          isActive: true,
        };
      } else {
        dataForContract = {
          contractName: contractName,
          contractNumber: contractNumber,
          duration: contractTime,
          frequency: frequency,
          //detailsMessage: infoContract,
          client_id: clientContract["Client"].id,
          startDate: clientContract.startDate,
          isActive: true,
        };
      }
      let dataAndId = { data: dataForContract, id: clientContract.id };

      await dispatch(patchContract(dataAndId));
      await setIsModify(false);
    } catch (err) {
      toast.error("ERREUR !!!!!!!");
    }
  };

  //Validation of entries :
  useEffect(() => {
    if (contractName) {
      setValidContractName(CONTACT_NAME_REGEX.test(contractName));
    } else setValidContractName(false);
    if (contractNumber) {
      setValidContractNumber(CONTRACT_NUMBER_REGEX.test(contractNumber));
    } else setValidContractNumber(false);
    if (frequency) {
      setValidFrequency(NUMBER_DUO_REGEX.test(frequency));
    } else setValidFrequency(false);
    if (contractTime) {
      setValidContractTime(NUMBER_DUO_REGEX.test(contractTime));
    } else setValidContractTime(false);
    if (infoContract) {
      setValidInfoContract(MESSAGE_REGEX.test(infoContract));
    } else setValidInfoContract(false);
  }, [contractName, frequency, contractTime, contractNumber, infoContract]);

  const checkModification = () => {
    //* si tous les champs sont identiques : impossible de valider
    if (
      clientContract.contractName === contractName &&
      clientContract.frequency == frequency &&
      clientContract.duration == contractTime &&
      clientContract.contractNumber === contractNumber &&
      clientContract.detailsMessage === infoContract
    ) {
      return false;
    } else if (
      validContractName &&
      contractName &&
      contractName !== "" &&
      validFrequency &&
      frequency &&
      frequency !== "" &&
      validContractTime &&
      contractTime &&
      contractTime !== "" &&
      validContractNumber &&
      contractNumber &&
      contractNumber !== "" &&
      ((validInfoContract && infoContract && infoContract !== "") ||
        infoContract === null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validModification = () => {
    if (
      validContractName &&
      contractName &&
      contractName !== "" &&
      validFrequency &&
      frequency &&
      frequency !== "" &&
      validContractTime &&
      contractTime &&
      contractTime !== "" &&
      validContractNumber &&
      contractNumber &&
      contractNumber !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const confirmStopContract = () => {
    return true;
  };

  const validDatePicker = () => {
    if (startDate && startDate !== "") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(getContracts());
    dispatch(getWishes());
  }, [dispatch]);

  //* Check existing Contract ___________________________________

  useEffect(() => {
    if (contractData?.data[0]?.id) {
      // check if client had a existing contract :
      let myContract = "";
      contractData?.data?.map((contract) => {
        if (dataToShow.id === contract.client_id) {
          myContract = contract;
        }

        if (myContract === "") {
          setExistingContract(false);
          setClientContract("");
        } else {
          setExistingContract(true);
          setClientContract(myContract);
        }
      });
    } else {
      setExistingContract(false);
      setClientContract("");
    }
  }, [contractData, dataToShow.id]);

  //* ________________________________End : Check existing Contract ___________________________________

  useEffect(() => {
    if (existingContract) {
      setContractName(clientContract.contractName);
    } else {
      setContractName("");
    }
  }, [dataToShow]);

  useEffect(() => {
    if (dataToShow.id !== clientContract?.client_id && isModify) {
      setIsModify(false);
    }
  }, [dataToShow]);

  const changeModal = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const setForModification = (boolean) => {
    setIsModify(!isModify);
    if (boolean === true) {
      setContractName(clientContract.contractName);
      setFrequency(clientContract.frequency);
      setContractTime(clientContract.duration);
      setContractNumber(clientContract.contractNumber);
      setInfoContract(clientContract.detailsMessage);
    } else {
      setCancel();
    }
  };

  //check for authorization :
  useEffect(() => {
    //reset details :

    cancelModify();
    //check if rules Commercial ok :
    if (auth.auth.rules.includes(4002)) {
      if (dataToShow?.Spyder?.id === userData.id) {
        setIsAuthorizedForModification(true);
      } else {
        setIsAuthorizedForModification(false);
      }
    } else {
      setIsAuthorizedForModification(false);
    }
    //check if rules Directeur ok :
    if (auth.auth.rules.includes(7000)) {
      setIsAuthorizedForModification(true);
      setIsDirecteur(true);
    } else {
      setIsDirecteur(false);
    }
  }, [dataToShow]);

  const toggleModal = (e) => {
    setCheckA(false);
    setModal(e.target.value);
  };

  const toggleModalNewWish = (e) => {
    setStartDate("");
    setModalNewWish(e.target.value);
  };

  const toggleModalStopContract = (e) => {
    setStartDate("");
    setModalStopContract(e.target.value);
  };

  const setCancel = () => {
    setModal(false);
    setModalNewWish(false);
    setModalStopContract(false);
    setContractName("");
    setFrequency("");
    setContractTime("");
    setContractNumber("");
    setInfoContract("");
    setValidContractName(false);
    setValidFrequency(false);
    setValidContractTime(false);
    setValidContractNumber(false);
    setValidInfoContract(false);
  };

  useEffect(() => {
    setInputListener({
      contractName: contractName,
      contractTime: contractTime,
      contractNumber: contractNumber,
      frequency: frequency,
      startDate: startDate,
      infoContract: infoContract,
    });
  }, [
    contractName,
    contractTime,
    contractNumber,
    frequency,
    startDate,
    infoContract,
  ]);

  useEffect(() => {
    setInputDatePicker({
      startDate: startDate,
    });
  }, [startDate]);

  const handleStopContract = async (e) => {
    e.preventDefault();

    //*1. Désactivation du contrat : isActive = false
    //*2. Suppression de la demande

    try {
      let toggleActiveData = {
        contractName: clientContract.contractName,
        contractNumber: clientContract.contractNumber,
        duration: clientContract.duration,
        frequency: clientContract.frequency,
        detailsMessage: clientContract.detailsMessage,
        client_id: clientContract["Client"].id,
        startDate: clientContract.startDate,
        isActive: false,
      };
      let dataAndId = { data: toggleActiveData, id: clientContract.id };

      await dispatch(trashOneWish(clientContract["Client"].id));
      await toast.success(
        "Demande supprimée au client : " + clientContract["Client"].id
      );
      await dispatch(patchContract(dataAndId));

      await setCancel();
      await toast.info("Contrat Mis en pause avec succès");
    } catch (err) {
      toast.error("ERREUR !!!!!!!");
    }
  };

  const deleteContract = async (e, id) => {
    e.preventDefault();
    await dispatch(trashContract(id));
    await setModal(false);
  };

  const createNewCollect = async (e) => {
    e.preventDefault();
    //*1 Activation du contrat :
    //*2 Création d'une nouvelle collecte :
    try {
      let toggleActiveData = {
        contractName: clientContract.contractName,
        contractNumber: clientContract.contractNumber,
        duration: clientContract.duration,
        frequency: clientContract.frequency,
        detailsMessage: clientContract.detailsMessage,
        client_id: clientContract["Client"].id,
        startDate: clientContract.startDate,
        isActive: true,
      };
      let dataAndId = { data: toggleActiveData, id: clientContract.id };

      let newWish = {
        dateOfIntervention: startDate,
        client_id: clientContract["Client"].id,
        isExpress: false,
        isDone: false,
      };

      await dispatch(patchContract(dataAndId));
      await dispatch(addNewWish(newWish));

      await setCancel();
      await toast.info("Activation du contrat avec succès");
    } catch (err) {
      toast.error("ERREUR !!!!!!!");
    }
  };

  return !existingContract ? (
    <div className="dashform__form">
      <TitleBlockForm
        isModify={false}
        isAuthorized={isAuthorizedForModification}
        title="Détail du Contrat"
        //changeTitle="Modification du contrat"
      />
      <div>
        <p> Aucun contrat n'est enregistré pour ce client </p>
        <br />

        {isAuthorizedForModification && (
          <>
            <DashButton
              //type="submit"
              action="action"
              text="Enregistrer un nouveau contrat"
              onClick={(e) => changeModal(e)}
            />

            <ModalLayout
              toggle={toggleModal}
              modal={modal}
              label="Création d'un nouveau contrat"
              colorClass="blue"
            >
              <ModalContent
                text="Remplissez le formulaire et valider pour enregistrer le contrat et programmer la première collecte"
                moreText="Le contat sera ajouté au client suivant :"
              >
                <div className="dashform__form__block dashform__form__block--padding ">
                  <div className="dashform__form__block--padding  centerLign">
                    <p>{dataToShow.clientName}</p>
                    <p>{dataToShow.agency}</p>
                    <p className="pad--1">{dataToShow.address}</p>
                  </div>
                </div>
                <form className="dashform__form__block--padding ">
                  <InputForm
                    label="Nom du contrat :"
                    htmlfor="spyderFirstname"
                    defaultValue=""
                    isModify={true}
                    isAuthorized={isAuthorizedForModification}
                    dataToShow=""
                    required={true}
                    setData={changeContractName}
                    info={infobulle_name}
                    placeholder="Saisissez un nom de contrat ..."
                    validation={validContractName}
                    newValue={contractName}
                  />
                  <InputForm
                    label="Numero du contrat :"
                    htmlfor="contractNumber"
                    defaultValue=""
                    isModify={true}
                    isAuthorized={isAuthorizedForModification}
                    dataToShow=""
                    required={true}
                    setData={changeContractNumber}
                    info={infobulle_contract_number}
                    placeholder="Saisissez un numéro du contrat"
                    validation={validContractNumber}
                    newValue={contractNumber}
                  />
                  <InputForm
                    label="Durée du contrat :"
                    htmlfor="contractTime"
                    defaultValue=""
                    isModify={true}
                    isAuthorized={isAuthorizedForModification}
                    dataToShow=""
                    required={true}
                    setData={changeContractTime}
                    info={infobulle_contract_time}
                    placeholder="Préciser la durée en mois du contrat"
                    validation={validContractTime}
                    newValue={contractTime}
                  />
                  <InputForm
                    label="Fréquence des collectes :"
                    htmlfor="frequency"
                    defaultValue=""
                    isModify={true}
                    isAuthorized={isAuthorizedForModification}
                    dataToShow=""
                    required={true}
                    setData={changeFrequency}
                    info={infobulle_frequency}
                    placeholder="Fréquence en jours entre les collectes"
                    validation={validFrequency}
                    newValue={frequency}
                  />
                  <InputForm
                    label="Informations complémentaires : (option)"
                    type="textarea"
                    htmlfor="infoContract"
                    setData={changeInfoContract}
                    info={infobulle_paragraphe}
                    defaultValue=""
                    placeholder="Si vous avez besoin, vous pouvez faire des précisions sur ce contrat. Ex: Liste matériel en location, etc ... "
                    validation={validInfoContract}
                    newValue={infoContract}
                    isModify={true}
                    isAuthorized={isAuthorizedForModification}
                    dataToShow=""
                    required={false}
                  />
                  <div className="centerLign">
                    <p className="pad--1">
                      Sélectionner la date de l'installation du matériel
                    </p>
                    <DatePicker changeStartDate={changeStartDate} />
                    <p>
                      {"Vous avez selectionné :" +
                        dayjs(startDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </form>
                <EndFormThreeButtons
                  //modifText="Modifier ce contact"
                  cancelText="Annuler"
                  inactiveText="Compléter le formulaire"
                  validText="Valider le contrat"
                  sendData={createNewContract}
                  setForModification={setCancel}
                  checkModification={validModification}
                  isModify={true}
                  isAuthorized={isAuthorizedForModification}
                  isDirecteur={isDirecteur}
                  inputListener={inputListener}
                />
              </ModalContent>
            </ModalLayout>
          </>
        )}
      </div>
    </div>
  ) : (
    <>
      <div className="dashform__form">
        <TitleBlockForm
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          title="Statut du contrat :"
          changeTitle="Modification du contrat"
        />

        <p
          className={
            clientContract.isActive
              ? "contractStatus contractStatus--active"
              : "contractStatus contractStatus--waiting"
          }
        >
          {clientContract.isActive ? "ACTIF" : "EN PAUSE"}
        </p>
      </div>
      <form className="dashform__form">
        <TitleBlockForm
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          title="Détail du Contrat"
          changeTitle="Modification du contrat"
        />

        <InputForm
          label="Nom du contrat :"
          htmlfor="contractName"
          defaultValue={clientContract.contractName}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={clientContract.contractName}
          required={true}
          setData={changeContractName}
          info={infobulle_name}
          placeholder="Saisissez un nom de contrat ..."
          validation={validContractName}
          newValue={contractName}
        />
        <InputForm
          label="Numero du contrat :"
          htmlfor="contractNumber"
          defaultValue={clientContract.contractNumber}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={clientContract.contractNumber}
          required={true}
          setData={changeContractNumber}
          info={infobulle_contract_number}
          placeholder="Saisissez un numéro du contrat"
          validation={validContractNumber}
          newValue={contractNumber}
        />
        <InputForm
          label="Date de début du contrat :"
          htmlfor="collect"
          defaultValue={dateFormat(`${clientContract.startDate}`)}
          isModify={false}
          isAuthorized={isAuthorizedForModification}
          dataToShow={dateFormat(`${clientContract.startDate}`)}
          required={false}
        />

        <InputForm
          label="Durée du contrat :"
          htmlfor="contractTime"
          defaultValue={clientContract.duration}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={clientContract.duration + " Mois"}
          required={true}
          setData={changeContractTime}
          info={infobulle_contract_time}
          placeholder="Préciser la durée en mois du contrat"
          validation={validContractTime}
          newValue={contractTime}
        />
        <InputForm
          label="Fréquence des collectes :"
          htmlfor="frequency"
          defaultValue={clientContract.frequency}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={clientContract.frequency + " Jours"}
          required={true}
          setData={changeFrequency}
          info={infobulle_frequency}
          placeholder="Fréquence en jours entre les collectes"
          validation={validFrequency}
          newValue={frequency}
        />
        <InputForm
          label="Informations complémentaires : (option)"
          type="textarea"
          htmlfor="infoContract"
          setData={changeInfoContract}
          info={infobulle_paragraphe}
          defaultValue={clientContract.detailsMessage}
          placeholder="Si vous avez besoin, vous pouvez faire des précisions sur ce contrat. Ex: Liste matériel en location, etc ... "
          validation={validInfoContract}
          newValue={infoContract}
          isModify={isModify}
          isAuthorized={isAuthorizedForModification}
          dataToShow={clientContract.detailsMessage}
          required={false}
        />
      </form>

      <EndFormThreeButtons
        modifText="Modifier le contrat"
        cancelText="Annuler"
        inactiveText="Compléter le formulaire"
        validText="Valider le contrat"
        sendData={modifyContract}
        setForModification={setForModification}
        checkModification={checkModification}
        isModify={isModify}
        isAuthorized={isAuthorizedForModification}
        isDirecteur={isDirecteur}
        inputListener={inputListener}
      />

      {isAuthorizedForModification && clientContract && (
        <div
          className={
            isDirecteur ? "buttonContainer__direction" : "buttonContainer"
          }
        >
          <div className="buttonsContainer">
            <div className="buttonContainer--small">
              {clientContract.isActive ? (
                <>
                  <DashButton
                    action="cancel"
                    text="Mettre en pause"
                    //onClick={(e) => toggleContract(e, !clientContract.isActive)}
                    onClick={() => setModalStopContract(true)}
                  />
                  <div className="buttonContainer--small">
                    <ModalLayout
                      toggle={toggleModalStopContract}
                      modal={modalStopContract}
                      label="Mise en pause du contrat"
                      colorClass="red"
                    >
                      <ModalContent
                        text="Vous êtes sur de stopper toutes les collectes périodiques et de supprimer les collectes en cours :"
                        moreText="Êtes vous sur de votre choix ?"
                      >
                        <EndFormThreeButtons
                          //modifText="Modifier ce contact"
                          cancelText="Annuler"
                          inactiveText="Compléter le formulaire"
                          validText="Valider l'arrêt du contrat"
                          sendData={handleStopContract}
                          setForModification={setCancel}
                          checkModification={confirmStopContract}
                          isModify={true}
                          isAuthorized={isAuthorizedForModification}
                          isDirecteur={isDirecteur}
                          inputListener={inputDatePicker}
                        />
                      </ModalContent>
                    </ModalLayout>
                  </div>
                </>
              ) : (
                <>
                  <DashButton
                    action="action"
                    text="Activer le contrat"
                    onClick={() => setModalNewWish(true)}
                  />

                  <div className="buttonContainer--small">
                    <ModalLayout
                      toggle={toggleModalNewWish}
                      modal={modalNewWish}
                      label="Activation du contrat"
                      colorClass="blue"
                    >
                      <ModalContent
                        text="Vous êtes sur le point de relancer les collectes périodiques :"
                        moreText="A quelle date souhaitez vous réaliser la prochaine collecte ?"
                      >
                        <form className="dashform__form__block--padding ">
                          <div className="centerLign">
                            <DatePicker changeStartDate={changeStartDate} />
                            <p>
                              {"Vous avez selectionné :" +
                                dayjs(startDate).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </form>
                        <EndFormThreeButtons
                          //modifText="Modifier ce contact"
                          cancelText="Annuler"
                          inactiveText="Compléter le formulaire"
                          validText="Valider la collecte"
                          sendData={createNewCollect}
                          setForModification={setCancel}
                          checkModification={validDatePicker}
                          isModify={true}
                          isAuthorized={isAuthorizedForModification}
                          isDirecteur={isDirecteur}
                          inputListener={inputDatePicker}
                        />
                      </ModalContent>
                    </ModalLayout>
                  </div>
                </>
              )}
            </div>

            {/* {clientContract.isActive &&
              isAuthorizedForModification &&
              !wishDetected && (
                <div className="buttonContainer--small">
                  <DashButton
                    action="search"
                    text="Relancer une collecte"
                    onClick={() => setModalNewWish(true)}
                  />
                  <ModalLayout
                    toggle={toggleModalNewWish}
                    modal={modalNewWish}
                    label="Relancer une collecte"
                    colorClass="yellow"
                  >
                    <ModalContent
                      text="Vous êtes sur le point de relancer les collectes périodiques :"
                      moreText="A quelle date souhaitez vous réaliser la prochaine collecte ?"
                    >
                      <form className="dashform__form__block--padding ">
                        <div className="centerLign">
                          <DatePicker changeStartDate={changeStartDate} />
                          <p>
                            {"Vous avez selectionné :" +
                              dayjs(startDate).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </form>
                      <EndFormThreeButtons
                        //modifText="Modifier ce contact"
                        cancelText="Annuler"
                        inactiveText="Compléter le formulaire"
                        validText="Valider la collecte"
                        sendData={createNewCollect}
                        setForModification={setCancel}
                        checkModification={validDatePicker}
                        isModify={true}
                        isAuthorized={isAuthorizedForModification}
                        isDirecteur={isDirecteur}
                        inputListener={inputDatePicker}
                      />
                    </ModalContent>
                  </ModalLayout>
                </div>
              )} */}

            {!clientContract.isActive && isDirecteur && (
              <div className="buttonContainer--small">
                <DashButton
                  action="delete"
                  text="Supprimer le contrat"
                  onClick={() => setModal(true)}
                />
                <ModalLayout
                  toggle={toggleModal}
                  modal={modal}
                  label="Suppression du contrat"
                  colorClass="red"
                >
                  <ModalContent
                    text={
                      "Vous êtes sur le point de supprimer le contrat pour le client : ::: /g/" +
                      clientContract["Client"].clientName +
                      " /g/ - " +
                      clientContract["Client"].agency
                    }
                    moreText="Après confirmation, ce contrat sera bloqué et toutes les demandes en cours seront également effacées."
                  >
                    <div className="dashform__form__block dashform__form__block--padding ">
                      <CheckBox
                        order="083"
                        text="Je confirme la suppression de ce contrat"
                        afterDisable={() => setCheckA(false)}
                        afterSelect={() => setCheckA(true)}
                      />
                    </div>

                    <div className="buttonsContainer">
                      <div className="buttonContainer--small">
                        {checkA ? (
                          <DashButton
                            //disabled={disabled}
                            action="action"
                            text="Valider la suppression"
                            onClick={(e) =>
                              deleteContract(e, clientContract.id)
                            }
                          />
                        ) : (
                          <DashButton
                            disabled={true}
                            action="inactive"
                            text="Veuillez confirmer"
                            //onClick={(e) => generateNewPassword(e)}
                          />
                        )}
                      </div>
                      <div className="buttonContainer--small">
                        <DashButton
                          //disabled={disabled}
                          action="cancel"
                          text="Annuler"
                          onClick={(e) => toggleModal(e)}
                        />
                      </div>
                    </div>
                  </ModalContent>
                </ModalLayout>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormSpydeContract;
