import { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../api/axios";
import { addSpyder, setSpyder } from "../../feature/spyder.slice";
import { toast } from "react-toastify";
import CheckBox from "../elements/CheckBox";
import ramdomPassword from "../../utils/ramdomPassword";
import sendMailForNewAccount from "../../utils/sendMailForNewAccount";
//import slowCode from "../../services/helpers/slowCode";
import { toggleLoading } from "../../feature/loading.slice";
import LoaderM from "./LoaderM";
import { selectLoading } from "../../feature/loading.slice";
import {
  infobulle_phone,
  infobulle_confirmEmail,
  infobulle_email,
  infobulle_paragraphe,
  infobulle_name,
} from "../../utils/infoBulleText";
import {
  EMAIL_REGEX,
  CONTACT_NAME_REGEX,
  TEL_REGEX,
  MESSAGE_REGEX,
} from "../../utils/regex";
import useAuth from "../../services/useAuth";
import { useNavigate } from "react-router";

const FormCreateSpyder = ({ selectedSpydeAccount, setDataSpyder }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [message, setMessage] = useState("");

  const [autoMessage, setAutomessage] = useState(false);

  const [validFirstname, setValidFirstname] = useState(false);
  const [validLastname, setValidLastname] = useState(false);
  const [validTel, setValidTel] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validConfirmEmail, setValidConfirmEmail] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const [checkA, setCheckA] = useState(false);
  const [checkB, setCheckB] = useState(false);
  const [checkC, setCheckC] = useState(false);
  const [isRecolte, setIsRecolte] = useState(false);
  const [isRecyclage, setIsRecyclage] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [isContract, setIsContract] = useState(false);
  const [isBlog, setIsBlog] = useState(false);
  const [isDirection, setIsDirection] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [newDataSpyder, setNewDataSpyder] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);

  //?! message type for textarea :
  //? ------------------------- :
  const textAutoMessage =
    "Nous sommes heureux de vous annoncer que votre compte vient d'être ouvert sur notre plateforme. Vous trouverez ci-dessous votre identifiant et votre mot de passe temporaire.";

  const inputMessage = document.getElementById("message");

  useEffect(() => {
    if (autoMessage) {
      inputMessage.value = textAutoMessage;
      setMessage(textAutoMessage);
    }
  }, [autoMessage]);

  //? auto-password :
  //? ------------------------- :

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  let password;
  useEffect(() => {
    if (!checkC) {
      password = "";
    } else if (checkC) {
      password = ramdomPassword(16);
    }
    //! PENSEZ A SUPPRIMER LE CONSOLE LOG !!!!!!!!!!!!!!!!
    // console.log("password");
    // console.log(password);
    //! PENSEZ A SUPPRIMER LE CONSOLE LOG !!!!!!!!!!!!!!!!
  }, [checkC]);

  useEffect(() => {
    setValidFirstname(CONTACT_NAME_REGEX.test(firstname));
  }, [firstname]);

  useEffect(() => {
    setValidLastname(CONTACT_NAME_REGEX.test(lastname));
  }, [lastname]);

  useEffect(() => {
    setValidTel(TEL_REGEX.test(tel));
  }, [tel]);

  useEffect(() => {
    setValidMessage(MESSAGE_REGEX.test(message));
  }, [message]);

  useEffect(() => {
    setAutomessage(false);
  }, [message]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (!confirmEmail) {
      setValidConfirmEmail(false);
    } else if (confirmEmail === email) {
      setValidConfirmEmail(true);
    } else {
      setValidConfirmEmail(false);
    }
  }, [email, confirmEmail]);

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "/spyder")
      .then((res) => dispatch(setSpyder(res.data)))
      .catch((err) => {
        setAuth("");
        navigate("/", {
          replace: true,
        });
      });
  }, []);

  const createSpyder = async (data, email) => {
    const dataSpyder = {
      firstname,
      lastname,
      phone: tel,
      login_id: data.data.id,
      spyde_account_id: selectedSpydeAccount.id,
    };
    dispatch(toggleLoading());

    await Axios.put(process.env.REACT_APP_API_URL + "/spyder/add", dataSpyder)
      .then((res) => {
        dispatch(addSpyder(res.data.data));
        createAccessRules(res.data);

        sendMailForNewAccount(res.data.data, message, email, password);
        dispatch(toggleLoading());

        setDataSpyder(res.data.data, email);
      })
      .catch((err) => {
        dispatch(toggleLoading());
        if (err?.response) {
          if (err.response.status === 409) {
            const errMsg = err.response.data.message.split(" : ");
            toast.error(errMsg[1]);
          } else if (err.response.status === 400) {
            const errMsg =
              "Erreur lors de la transmission: Formulaire non conforme aux attentes du serveur";
            toast.error(errMsg);
          }
        } else {
          toast.error("Erreur serveur");
        }
      });
  };

  const createAccessRules = async (data) => {
    const dataAccessRules = {
      spyder_id: data.data.id,
      isRecolte,
      isRecyclage,
      isClient,
      isDocument,
      isContract,
      isBlog,
      isDirection,
      isAdmin,
    };

    await Axios.put(
      process.env.REACT_APP_API_URL + "/access_rules/add",
      dataAccessRules
    )
      .then((res) => {
        toast.success("Droits d'accès créé avec succès !");
      })
      .catch((err) => {
        if (err?.response) {
          if (err.response.status === 409) {
            const errMsg = err.response.data.message.split(" : ");
            toast.error(errMsg[1]);
          } else if (err.response.status === 400) {
            const errMsg =
              "Erreur lors de l'ajout des droits: Formulaire non conforme aux attentes du serveur";
            toast.error(errMsg);
          }
        } else {
          toast.error("Erreur serveur");
        }
      });
  };

  const saveSpyder = async (e) => {
    e.preventDefault();

    //first : create Login :
    const dataLogin = {
      email: email,
      password,
      isspyde: true,
    };

    await Axios.put(process.env.REACT_APP_API_URL + "/login/add", dataLogin)
      .then((res) => {
        createSpyder(res.data, dataLogin.email);
      })
      .catch((err) => {
        if (err?.response) {
          if (err.response.status === 409) {
            const errMsg = err.response.data.message.split(" : ");
            toast.error(errMsg[1]);
          } else if (err.response.status === 401) {
            const errMsg =
              "Erreur lors de la génération du mot de passe : Merci de recommencer l'opération";
            toast.error(errMsg);
          } else if (err.response.status === 400) {
            const errMsg =
              "Erreur lors de la transmission, Formulaire non conforme aux attentes du serveur";
            toast.error(errMsg);
          }
        } else {
          toast.error("Erreur serveur");
        }
      });
  };

  return isLoading ? (
    <div className="loaderContainer loaderContainer--h300">
      <LoaderM color="blue" text="Création du compte en cours ..." lg="2300" />
    </div>
  ) : (
    <>
      <h3 className="dashform__title dashform__title--spyde">
        Création du compte Spyde
      </h3>

      <div>
        <div className="dashform">
          <form onSubmit={saveSpyder} className="dashform__form">
            <div className="splitForm">
              <div className="splitForm__block">
                <div className="dashform__form__block">
                  <div className="info" data-tooltip={infobulle_name}>
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>

                  <label htmlFor="firstname">Prénom :</label>
                  <input
                    type="text"
                    id="firstname"
                    autoComplete="off"
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                    placeholder="Saisir le prénom du compte Spyde ..."
                  ></input>

                  {validFirstname && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}
                  {(firstname.length < 1 && !validFirstname) ||
                  (firstname.length > 2 && validFirstname) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>

                <div className="dashform__form__block">
                  <div className="info" data-tooltip={infobulle_name}>
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>

                  <label htmlFor="lastname">Nom :</label>
                  <input
                    type="text"
                    id="lastname"
                    autoComplete="off"
                    onChange={(e) => setLastname(e.target.value)}
                    required
                    placeholder="Saisir le nom du compte Spyde ..."
                  ></input>

                  {validLastname && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}
                  {(lastname.length < 1 && !validLastname) ||
                  (lastname.length > 2 && validLastname) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>

                <div className="dashform__form__block">
                  <div className="info" data-tooltip={infobulle_phone}>
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>

                  <label htmlFor="tel">Téléphone :</label>
                  <input
                    type="text"
                    id="tel"
                    autoComplete="off"
                    onChange={(e) => setTel(e.target.value)}
                    required
                    placeholder="Saisir un numéro de téléphone ..."
                  ></input>

                  {validTel && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}
                  {(tel.length < 1 && !validTel) ||
                  (tel.length > 2 && validTel) ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>
              </div>
              <div className="splitForm__block">
                <div className="dashform__form__block">
                  <div className="info" data-tooltip={infobulle_paragraphe}>
                    <ion-icon icon="information-circle-outline"></ion-icon>
                  </div>
                  {/* <label htmlFor="message">
                    Ajouter un message :<span>(option)</span>
                  </label> */}
                  <textarea
                    type="textarea"
                    id="message"
                    autoComplete="off"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Ce message sera envoyé au nouvel employé Spyde avec le mail contenant le mot de passe temporaire ..."
                    required
                  ></textarea>

                  {validMessage && (
                    <div className="check check__valid">
                      <ion-icon icon="checkmark-circle-outline"></ion-icon>
                    </div>
                  )}
                  {(message.length < 1 && !validMessage) || validMessage ? (
                    ""
                  ) : (
                    <div className="check check__invalid">
                      <ion-icon icon="backspace-outline"></ion-icon>
                    </div>
                  )}
                </div>
                <div className="splitForm__block splitForm__block--checkbox">
                  <CheckBox
                    order="002"
                    text="Affiche le message standard"
                    afterDisable={() => setAutomessage(false)}
                    afterSelect={() => setAutomessage(true)}
                  />
                </div>
              </div>
            </div>
            <div className="dashform__form__block dashform__form__block--padding ">
              <div className="info" data-tooltip={infobulle_email}>
                <ion-icon icon="information-circle-outline"></ion-icon>
              </div>

              <label htmlFor="email">E-mail du compte Spyde :</label>
              <input
                type="text"
                id="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Saisir un e-mail valide pour le compte Spyde ..."
              ></input>

              {validEmail && (
                <div className="check check__valid">
                  <ion-icon icon="checkmark-circle-outline"></ion-icon>
                </div>
              )}
              {(email.length < 1 && !validEmail) ||
              (email.length > 2 && validEmail) ? (
                ""
              ) : (
                <div className="check check__invalid">
                  <ion-icon icon="backspace-outline"></ion-icon>
                </div>
              )}
            </div>
            <div className="dashform__form__block dashform__form__block--padding ">
              <div className="info" data-tooltip={infobulle_confirmEmail}>
                <ion-icon icon="information-circle-outline"></ion-icon>
              </div>

              <label htmlFor="confirmEmail">Confirmation de l'e-mail :</label>
              <input
                type="text"
                id="confirmEmail"
                autoComplete="off"
                onChange={(e) => setConfirmEmail(e.target.value)}
                required
                placeholder="Confirmer l'adresse e-mail du compte Spyde ..."
              ></input>

              {validConfirmEmail && (
                <div className="check check__valid">
                  <ion-icon icon="checkmark-circle-outline"></ion-icon>
                </div>
              )}
              {(confirmEmail.length < 1 && !validConfirmEmail) ||
              (confirmEmail.length > 2 && validConfirmEmail) ? (
                ""
              ) : (
                <div className="check check__invalid">
                  <ion-icon icon="backspace-outline"></ion-icon>
                </div>
              )}
            </div>

            <div className="dashform__form__block dashform__form__block--padding ">
              <div className="checkContainer">
                <label>Définir les droits d'accès :</label>
                <div className="checkContainer__block">
                  <CheckBox
                    order="003"
                    text="Collecte"
                    afterDisable={() => setIsRecolte(false)}
                    afterSelect={() => setIsRecolte(true)}
                  />
                  <CheckBox
                    order="004"
                    text="Triage"
                    afterDisable={() => setIsRecyclage(false)}
                    afterSelect={() => setIsRecyclage(true)}
                  />
                  {/* <CheckBox
                    order="005"
                    text="Document"
                    afterDisable={() => setIsDocument(false)}
                    afterSelect={() => setIsDocument(true)}
                  /> */}
                  <CheckBox
                    order="006"
                    text="Blog"
                    afterDisable={() => setIsBlog(false)}
                    afterSelect={() => setIsBlog(true)}
                  />
                  <CheckBox
                    order="007"
                    text="Liste Clients"
                    afterDisable={() => setIsClient(false)}
                    afterSelect={() => setIsClient(true)}
                  />
                  <CheckBox
                    order="008"
                    text="Commercial"
                    afterDisable={() => setIsContract(false)}
                    afterSelect={() => setIsContract(true)}
                  />
                  <CheckBox
                    order="009"
                    text="Direction"
                    afterDisable={() => setIsDirection(false)}
                    afterSelect={() => setIsDirection(true)}
                  />
                  {/* <CheckBox
                    order="010"
                    text="Administrateur"
                    afterDisable={() => setIsAdmin(false)}
                    afterSelect={() => setIsAdmin(true)}
                  /> */}
                </div>
              </div>
            </div>
            <div className="dashform__form__block dashform__form__block--padding ">
              <CheckBox
                order="014"
                text="Je reconnais l’exactitude des informations renseignées ci-dessus. Elles m’ont été transmises directement par le client sus mentionné."
                afterDisable={() => setCheckA(false)}
                afterSelect={() => setCheckA(true)}
              />
            </div>
            <div className="dashform__form__block dashform__form__block--padding ">
              <CheckBox
                order="015"
                text="Un contrat physique est signé, ou en cours de signature, auprès de ce client."
                afterDisable={() => setCheckB(false)}
                afterSelect={() => setCheckB(true)}
              />
            </div>
            <div className="dashform__form__block dashform__form__block--padding ">
              <CheckBox
                order="016"
                text="Le mot de passe temporaire de ce compte sera généré automatiquement, et sera envoyé par e-mail au nouveau client. Je reconnais ne jamais avoir eu accès à ce mot de passe, ou de la façon dont il a été généré. Le titulaire de ce compte est le seul à avoir connaissance de ce mot de passe."
                afterDisable={() => setCheckC(false)}
                afterSelect={() => setCheckC(true)}
              />
            </div>

            <div className="dashform__form--btn">
              {validFirstname &&
              firstname &&
              validLastname &&
              lastname &&
              validTel &&
              tel &&
              validEmail &&
              email &&
              validConfirmEmail &&
              confirmEmail &&
              validMessage &&
              message &&
              checkA &&
              checkB &&
              (isRecolte ||
                isRecyclage ||
                isClient ||
                isBlog ||
                isContract ||
                isDirection ||
                isDocument ||
                isAdmin) &&
              password !== null &&
              checkC ? (
                <DashButton
                  type="submit"
                  action="action"
                  text="Ajouter le compte Spyde au client"
                  //onClick = ""
                />
              ) : (
                <DashButton
                  type="button"
                  action="inactive"
                  text="Compléter le formulaire"
                  //onClick={""}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormCreateSpyder;
