const OneAction = (props) => {
  return (
    <div className={"oneAction oneAction__bg--" + props.bgColor}>
        <div
            className={
            "oneAction__container oneAction__container--" + props.reverse
            }
        >
            <div className="oneAction__container--image">
                <img src={props.image} alt=""></img>
            </div>
            <div className="oneAction__container--text">
                <h2 className="font__question">{props.action}</h2>
                <h3 className="font__bigTitle">{props.title}</h3>
                <p className="font__simpleText lineHeightx2 justifyText">
                    {props.text}
                </p>
            </div>
        </div>
    </div>
  );
};

export default OneAction;
