import PageDash from "../../pages/PageDash";

const DashConfig = (props) => {
  return (
    <div className="pageConfig">
      <div id="changeWidth" className="pageConfig__tampon"></div>
      <PageDash page={props.page} title={props.title} />
    </div>
  );
};

export default DashConfig;
