import SvgArrow from "../components/elements/SvgArrow";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const Politics = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="sectBlogLarge">
      <div className="sectBlogLarge__title">
        <h1 className="font__blockPoint">Politique de confidentialité</h1>
        <p className="sectBlogLarge__text sectBlogLarge__text--date font__date">
          Données Personnelles
        </p>
      </div>
      <div className="sectBlogLarge__politics">
        <h2 className="font__oneClient">Définitions</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>L'Éditeur : </strong>La personne, physique ou morale, qui
          édite les services de communication au public en ligne.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Le Site : </strong>L'ensemble des sites, pages Internet et
          services en ligne proposés par l'Éditeur.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>L'Utilisateur :</strong> La personne utilisant le Site et les
          services.
        </p>
        <h2 className="font__oneClient">1- Nature des données collectées</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Dans le cadre de l'utilisation des Sites, l'Éditeur est susceptible
            de collecter les catégories de données suivantes concernant ses
            Utilisateurs :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <ul>
            <li>- Données d'état-civil, d'identité, d'identification...</li>
            <li>
              - Données de connexion (adresses IP, journaux d'événements...){" "}
            </li>
            <li>
              - Données de localisation (déplacements, données GPS, GSM...
            </li>
          </ul>
        </p>
        <h2 className="font__oneClient">
          2- Communication des données personnelles à des tiers
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Pas de communication à des tiers :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Vos données ne font l'objet d'aucune communication à des tiers. Elles
          peuvent être toutefois divulguées en application d'une loi, d'un
          règlement ou en vertu d'une décision d'une autorité réglementaire ou
          judiciaire compétente.
        </p>
        <h2 className="font__oneClient">
          3- Information préalable pour la communication des données
          personnelles à des tiers en cas de fusion / absorption
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Information préalable et possibilité d’opt-out avant et après fusion
            / acquisition :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Dans le cas où nous prendrions part à une opération de fusion,
          d’acquisition ou à toute autre forme de cession d’actifs, nous nous
          engageons à garantir la confidentialité de vos données personnelles et
          à vous informer avant que celles-ci ne soient transférées ou soumises
          de nouvelles règles de confidentialité.
        </p>
        <h2 className="font__oneClient">4- Agrégation des données</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Agrégation avec des données non personnelles :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous pouvons publier, divulguer et utiliser les informations agrégées
          (informations relatives à tous nos Utilisateurs ou à des groupes ou
          catégories spécifiques d'Utilisateurs que nous combinons de manière à
          ce qu'un Utilisateur individuel ne puisse plus être identifié ou
          mentionné) et les informations non personnelles à des fins d'analyse
          du secteur et du marché, de profilage démographique, à des fins
          promotionnelles et publicitaires et à d'autres fins commerciales.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Agrégation avec des données personnelles disponibles sur les comptes
            sociaux de l'Utilisateur :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Si vous connectez votre compte à un compte d’un autre service afin de
          faire des envois croisés, ledit service pourra nous communiquer vos
          informations de profil, de connexion, ainsi que toute autre
          information dont vous avez autorisé la divulgation. Nous pouvons
          agréger les informations relatives à tous nos autres Utilisateurs,
          groupes, comptes, aux données personnelles disponibles sur
          l’Utilisateur.
        </p>
        <h2 className="font__oneClient">5- Collecte des données d'identité</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Inscription et identification préalable pour la fourniture du
            service :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          L’utilisation du Site nécessite une inscription et une identification
          préalable. Vos données nominatives (nom, prénom, adresse postale,
          e-mail, numéro de téléphone,...) sont utilisées pour exécuter nos
          obligations légales résultant de la livraison des produits et /ou des
          services, e n vertu du Contrat de licence utilisateur final, de la
          Limite de garantie, le cas échéant, ou de toute autre condition
          applicable. Vous ne fournirez pas de fausses informations nominatives
          et ne créerez pas de compte pour une autre personne sans son
          autorisation. Vos coordonnées devront toujours être exactes à jour.
        </p>
        <h2 className="font__oneClient">
          6- Collecte des données d'identification
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Utilisation de l'identifiant de l’utilisateur uniquement pour
            l’accès aux services :
          </strong>
        </p>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous utilisons vos identifiants électroniques pour :
          <ul>
            <li>- Collecte des données du terminal</li>
            <li>- Cookies seulement pour et pendant l'exécution du contrat.</li>
          </ul>
        </p>

        <h2 className="font__oneClient">7- Géolocalisation</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Géolocalisation à des fins de fourniture du service :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous collectons et traitons vos données de géolocalisation afin de
          vous fournir nos services, uniquement pour nos clients. Nous pouvons
          être amenés à faire usage des données personnelles dans le but de
          déterminer la position géographique du point de collecte. Conformément
          à votre droit d'opposition prévu par la loi n°78-17 du 6 janvier 1978
          relative à l'informatique, aux fichiers et aux libertés, vous avez la
          possibilité, à tout moment, de désactiver les fonctions relatives à la
          géolocalisation, en faisant la demande à votre commercial Spyde.
        </p>
        <h2 className="font__oneClient">8- Collecte des données du terminal</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Aucune collecte des données techniques :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous ne collectons ne conservons aucune donnée technique de votre
          appareil (adresse IP, fournisseur d'accès Internernet, etc ...)
        </p>
        <h2 className="font__oneClient">9- Cookies</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Durée de conservation des cookies :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Conformément aux recommandations de la CNIL, la durée maximale de
          conservation des cookies est de 13 mois au maximum après leur premier
          dépôt dans le terminal de l'Utilisateur, tout comme la durée de la
          validité du consentement de l’Utilisateur à l’utilisation de ces
          cookies. La durée de vie des cookies n’est pas prolongée à chaque
          visite. Le consentement de l’Utilisateur devra donc être renouvelé à
          l'issue de ce délai.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Finalité cookies :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Les cookies peuvent être utilisés pour des fins statistiques notamment
          pour optimiser les services rendus à l'Utilisateur, à partir du
          traitement des informations concernant la fréquence d'accès, la
          personnalisation des pages ainsi que les opérations réalisées et les
          informations consultées. Vous êtes informé que l'Éditeur est
          susceptible de déposer des cookies sur votre terminal. Le cookie
          enregistre des informations relatives à la navigation sur le service
          (les pages que vous avez consultées, la date et l'heure de la
          consultation...) que nous pourrons lire lors de vos visites
          ultérieures.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Droit de l'Utilisateur de refuser les cookies, la désactivation
            entraînant un fonctionnement dégradé du service :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Vous reconnaissez avoir été informé que l'Éditeur peut avoir recours à
          des cookies, et l'y autorisez. Si vous ne souhaitez pas que des
          cookies soient utilisés sur votre terminal, la plupart des navigateurs
          vous permettent de désactiver les cookies en passant par les options
          de réglage. Toutefois, vous êtes informé que certains services sont
          susceptibles de ne plus fonctionner correctement.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Association possible des cookies avec des données personnelles pour
            permettre le fonctionnement du service :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          L'Éditeur peut être amenée à recueillir des informations de navigation
          via l'utilisation de cookies.
        </p>
        <h2 className="font__oneClient">
          10- Conservation des données techniques
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Durée de conservation des données techniques :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Les données techniques sont conservées pour la durée strictement
          nécessaire à la réalisation des finalités visées ci-avant.
        </p>
        <br />
        <h2 className="font__oneClient">
          11- Délai de conservation des données personnelles et d'anonymisation
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Conservation des données pendant la durée de la relation
            contractuelle :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Conformément à l'article 6-5° de la loi n°78-17 du 6 janvier 1978
          relative à l'informatique, aux fichiers et aux libertés, les données à
          caractère personnel faisant l'objet d'un traitement ne sont pas
          conservées au-delà du temps nécessaire à l'exécution des obligations
          définies lors de la conclusion du contrat ou de la durée prédéfinie de
          la relation contractuelle.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Conservation des données anonymisées au delà de la relation
            contractuelle / après la suppression du compte :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous conservons les données personnelles pour la durée strictement
          nécessaire à la réalisation des finalités décrites dans les présentes
          Politiques de confidentialité. Au-delà de cette durée, elles seront
          anonymisées et conservées à des fins exclusivement statistiques et ne
          donneront lieu à aucune exploitation, de quelque nature que ce soit.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Suppression des données après suppression du compte :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Des moyens de purge de données sont mis en place afin d'en prévoir la
          suppression effective dès lors que la durée de conservation ou
          d'archivage nécessaire à l'accomplissement des finalités déterminées
          ou imposées est atteinte. Conformément à la loi n°78-17 du 6 janvier
          1978 relative à l'informatique, aux fichiers et aux libertés, vous
          disposez par ailleurs d'un droit de suppression sur vos données que
          vous pouvez exercer à tout moment en prenant contact avec l'Éditeur.
          Suppression des données après 3 ans d'inactivité Pour des raisons de
          sécurité, si vous ne vous êtes pas authentifié sur le Site pendant une
          période de trois ans, vous recevrez un e-mail vous invitant à vous
          connecter dans les plus brefs délais, sans quoi vos données seront
          supprimées de nos bases de données.
        </p>
        <br />
        <h2 className="font__oneClient">12- Suppression du compte</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Suppression du compte à la demande L'Utilisateur :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Vous avez la possibilité de supprimer votre Compte à tout moment, par
          simple demande à votre commercial Spyde.
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Suppression du compte en cas de violation de la Politique de
            Confidentialité{" "}
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          En cas de violation d'une ou de plusieurs dispositions de la Politique
          de Confidentialité ou de tout autre document incorporé aux présentes
          par référence, l'Éditeur se réserve le droit de mettre fin ou
          restreindre sans aucun avertissement préalable et à sa seule
          discrétion, votre usage et accès aux services, à votre compte et à
          tous les Sites.
        </p>
        <br />
        <h2 className="font__oneClient">
          13- Indications en cas de faille de sécurité décelée par l'Éditeur
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Information de l'Utilisateur en cas de faille de sécurité :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous nous engageons à mettre en oeuvre toutes les mesures techniques
          et organisationnelles appropriées afin de garantir un niveau de
          sécurité adapté au regard des risques d'accès accidentels, non
          autorisés ou illégaux, de divulgation, d'altération, de perte ou
          encore de destruction des données personnelles vous concernant. Dans
          l'éventualité où nous prendrions connaissance d'un accès illégal aux
          données personnelles vous concernant stockées sur nos serveurs ou ceux
          de nos prestataires, ou d'un accès non autorisé ayant pour conséquence
          la réalisation des risques identifiés ci-dessus, nous nous engageons à
          :
          <ul>
            <li>- Vous notifier l'incident dans les plus brefs délais ;</li>
            <li>- Examiner les causes de l'incident et vous en informer ;</li>
            <li>
              - Prendre les mesures nécessaires dans la limite du raisonnable
              afin d'amoindrir les effets négatifs et préjudices pouvant
              résulter dudit incident.
            </li>
          </ul>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Limitation de la responsabilité :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          En aucun cas les engagements définis au point ci-dessus relatifs à la
          notification en cas de faille de sécurité ne peuvent être assimilés à
          une quelconque reconnaissance de faute ou de responsabilité quant à la
          survenance de l'incident en question.
        </p>
        <h2 className="font__oneClient">
          14- Transfert des données personnelles à l'étranger
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Pas de transfert en dehors de l'Union européenne :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          L'Éditeur s 'engage à ne pas transférer les données personnelles de
          ses Utilisateurs en dehors de l'Union européenne.
        </p>
        <br />
        <a
          className="font__italic link"
          href="https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde"
        >
          https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde
        </a>
        <h2 className="font__oneClient">
          15- Modification de la politique de confidentialité
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            En cas de modification de la présente Politique de Confidentialité,
            engagement de ne pas baisser le niveau de confidentialité de manière
            substantielle sans l'information préalable des personnes concernées
            :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          Nous nous engageons à vous informer en cas de modification
          substantielle de la présente Politique de Confidentialité, et à ne pas
          baisser le niveau de confidentialité de vos données de manière
          substantielle sans vous en informer et obtenir votre consentement.
        </p>
        <h2 className="font__oneClient">
          16- Droit applicable et modalités de recours
        </h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>
            Application du droit français (législation CNIL) et compétence des
            tribunaux :
          </strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          La présente Politique de Confidentialité et votre utilisation du Site
          sont régies et interprétées conformément aux lois de France, et
          notamment à la Loi n° 78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés. Le choix de la loi
          applicable ne porte pas atteinte à vos droits en tant que consommateur
          conformément à la loi applicable de votre lieu de résidence. Si vous
          êtes un consommateur, vous et nous acceptons de se soumettre à la
          compétence non- exclusive des juridictions françaises, ce qui signifie
          que vous pouvez engager une action relative à la présente Politique de
          Confidentialité en France ou dans le pays de l'UE dans lequel vous
          vivez. Si vous êtes un professionnel, toutes les actions à notre
          encontre doivent être engagées devant une juridiction en France. En
          cas de litige, les parties chercheront une solution amiable avant
          toute action judiciaire. En cas d'échec de ces tentatives, toutes
          contestations à la validité, l'interprétation et / ou l'exécution de
          la Politique de Confidentialité devront être portées même en cas de
          pluralité des défendeurs ou d'appel en garantie, devant les tribunaux
          français.
        </p>
        <h2 className="font__oneClient">17- Portabilité des données</h2>
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          <strong>Portabilité des données :</strong>
        </p>
        <br />
        <p className="sectBlogLarge__text sectBlogLarge__text--article font__simpleText">
          L'Éditeur s'engage à vous offrir la possibilité de vous faire
          restituer l'ensemble des données vous concernant sur simple demande.
          L'Utilisateur se voit ainsi garantir une meilleure maîtrise de ses
          données, et garde la possibilité de les réutiliser. Ces données
          devront être fournies dans un format ouvert et aisément réutilisable.
        </p>
      </div>
      <div className="sectBlogLarge__btn">
        <NavLink to="/">
          <button className="font__btn btn btn__simple btn__simple--red">
            Retour à l'acceuil
            <SvgArrow className="btn__arrow--red" />
          </button>
        </NavLink>
      </div>
    </section>
  );
};

export default Politics;
