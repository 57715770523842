import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { useDispatch } from "react-redux";
import { selectWish } from "../../feature/wish.slice";

import DashButton from "../elements/DashButton";
import Point from "../elements/Point";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const MapPopupDemande = ({
  clusters,
  addToSelectedData,
  mapRef,
  listOfSelected,
}) => {
  const [selectedLocation, setSelectedLocation] = useState({});

  const dispatch = useDispatch();
  let { uid } = useParams();
  const roadmapId = uid;

  const addToSelected = async (cluster, e) => {
    e.preventDefault();

    try {
      let data = {
        roadmap_id: roadmapId,
        position: listOfSelected.length + 1,
      };
      let dataAndId = {
        data: data,
        id: cluster.properties.pointId,
      };

      await dispatch(selectWish(dataAndId));
      addToSelectedData(cluster);
      setSelectedLocation({});
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div key="dataMap">
      {clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={cluster.id.toString()}
              latitude={latitude}
              longitude={longitude}
            >
              <div
                className="mapCluster"
                onClick={() => {
                  mapRef.current.flyTo({
                    center: [
                      cluster.geometry.coordinates[0],
                      cluster.geometry.coordinates[1],
                    ],
                    zoom: 13,
                  });
                }}
              >
                <Point number={pointCount} />
              </div>
            </Marker>
          );
        } else {
          return (
            <React.Fragment key={cluster.properties.pointId.toString()}>
              <Marker latitude={latitude} longitude={longitude}>
                <div
                  className={
                    "mapPoints mapPoints--" +
                    cluster.properties.status +
                    " mapPoints--" +
                    cluster.properties.status +
                    "--anim"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    //formatLog("longLat", { long: longitude, lat: latitude });
                    setSelectedLocation({
                      long: cluster.geometry.coordinates[0],
                      lat: cluster.geometry.coordinates[1],
                    });

                    mapRef.current.flyTo({
                      center: [
                        cluster.geometry.coordinates[0],
                        (cluster.geometry.coordinates[1] * 1000 + 5) / 1000,
                      ],
                    });
                  }}
                ></div>
              </Marker>
              {/* Popup */}
              {selectedLocation.long === cluster.geometry.coordinates[0] ? (
                <Popup
                  onClose={() => {
                    //formatLog("onClose", "Fermeture popup");
                    setSelectedLocation({});
                  }}
                  closeOnClick={false}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div className="mapPopup">
                    <p className="mapPopup__title">Demande</p>
                    <p className="mapPopup__demande">
                      {cluster.properties.demande}
                    </p>
                    <div
                      className={
                        "mapPopup__status mapPopup__status--" +
                        cluster.properties.is_in_time
                      }
                    >
                      {/* <ion-icon
                        //!className= voir pour format icone fixe
                        icon={changeIcon(cluster.properties)}
                      ></ion-icon> */}
                      <p>{cluster.properties.popup}</p>
                    </div>
                    <p className="mapPopup__name">{cluster.properties.name}</p>
                    <p className="mapPopup__adress">
                      {cluster.properties.site}
                    </p>
                    <p className="mapPopup__adress">Collecte prévue le</p>
                    <p className="mapPopup__contact">
                      {cluster.properties.date}
                    </p>
                    <div className="mapPopup__button">
                      <DashButton
                        action="action"
                        text="Ajouter à la feuille"
                        onClick={(e) => addToSelected(cluster, e)}
                      />
                    </div>
                  </div>
                </Popup>
              ) : null}
            </React.Fragment>
          );
        }
      })}
      ;
    </div>
  );
};

export default MapPopupDemande;
