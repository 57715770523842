import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../api/axios";

export const getPagesBlog = createAsyncThunk(
  "PagesBlog/getPagesBlog",
  async () => {
    return await Axios.get(process.env.REACT_APP_API_URL + "/spyde_blog").then(
      (res) => res.data
    );
  }
);

export const addNewArticle = createAsyncThunk(
  "PagesBlog/addNewArticle",
  async (data) => {
    return await Axios.put(
      process.env.REACT_APP_API_URL + "/spyde_blog/add",
      data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const togglePublishPage = createAsyncThunk(
  "PagesBlog/togglePublishPage",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/spyde_blog/publish/" + dataAndId.id,
      dataAndId.data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const patchArticle = createAsyncThunk(
  "PagesBlog/patchArticle",
  async (dataAndId) => {
    return await Axios.patch(
      process.env.REACT_APP_API_URL + "/spyde_blog/modify/" + dataAndId.id,
      dataAndId.data
    )
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const deleteOnePage = createAsyncThunk(
  "PagesBlog/deleteOnePage",
  async (id) => {
    return await Axios.delete(
      process.env.REACT_APP_API_URL + "/spyde_blog/delete/" + id
    )
      .then((res) => res && { data: id })
      .catch((err) => toast.error(err.response.data.message));
  }
);

export const pagesBlogSlice = createSlice({
  name: "PagesBlog",
  initialState: { pagesBlog: null },

  reducers: {
    setPagesBlog: (state, { payload }) => {
      state.pagesBlog = payload.data;
    },
  },
  extraReducers: {
    [getPagesBlog.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [getPagesBlog.fulfilled]: (state, { payload }) => {
      state.pagesBlog = payload;
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [getPagesBlog.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [addNewArticle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [addNewArticle.fulfilled]: (state, { payload }) => {
      let newArticle = { ...payload.data };
      state.pagesBlog.data = [...state.pagesBlog.data, newArticle];
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [addNewArticle.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [patchArticle.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [patchArticle.fulfilled]: (state, { payload }) => {
      state.pagesBlog.data = state.pagesBlog.data.map((pagesBlog) => {
        if (pagesBlog.id === payload.data.id) {
          return {
            ...pagesBlog,
            ...payload.data,
          };
        } else return pagesBlog;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [patchArticle.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },

    [deleteOnePage.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [deleteOnePage.fulfilled]: (state, { payload }) => {
      state.pagesBlog.data = state.pagesBlog.data.filter(
        (roadmap) => roadmap.id !== payload.data
      );
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [deleteOnePage.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
    [togglePublishPage.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
    },
    [togglePublishPage.fulfilled]: (state, { payload }) => {
      state.pagesBlog.data = state.pagesBlog.data.map((rack) => {
        if (rack.id === payload.data.id) {
          return {
            ...rack,
            ...payload.data,
          };
        } else return rack;
      });
      state.status = "success";
      state.error = "";
      state.loading = false;
    },
    [togglePublishPage.rejected]: (state, action) => {
      state.status = "failed";
      state.pagesBlog = null;
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { setPagesBlog } = pagesBlogSlice.actions;

export default pagesBlogSlice;
