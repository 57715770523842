//import './App.css';
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Blog from "./pages/Blog";
import Actions from "./pages/Actions";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Services from "./pages/Services";
import Article from "./pages/Article";

import DashConfig from "./components/layouts/DashConfig";
//import DashHome from "./pages/DashHome";
import RequireAuth from "./services/contexts/RequireAuth";
import RoadMap from "./pages/RoadMap";
import Public from "./components/layouts/PublicLayout";
import Client from "./components/layouts/ClientLayout";
import Spyde from "./components/layouts/SpydeLayout";

import PersistLogin from "./services/contexts/PersistLogin";
import ClientIndicators from "./pages/Clients/ClientIndicators";
import ClientProfil from "./pages/Clients/ClientProfil";
import ClientContract from "./pages/Clients/ClientContract";
//import ClientDocuments from "./pages/Clients/ClientDocuments";
import ClientCollect from "./pages/Clients/ClientCollect";
import SpydeProfil from "./pages/Spyde/SpydeProfil";
import SpydeClients from "./pages/Spyde/SpydeClients";
import SpydeDocs from "./pages/Spyde/SpydeDocs";
import SpydeLogistic from "./pages/Spyde/SpydeLogistic";
import SpydeBlog from "./pages/Spyde/SpydeBlog";
import SpydeGestion from "./pages/Spyde/SpydeGestion";
import Politics from "./pages/Politics";
import Mentions from "./pages/Mentions";
import NewClient from "./components/blocks/NewClient";
import NewSpyder from "./components/blocks/NewSpyder";

//! Header (modifier bouton espace Client => mon tableau de bord et Déconnexion)
//! et DashBoard (menu à changer) à intégrer ensemble avec contexte svt utilisateurs

const RULES = {
  user: 1000,
  spyder: 2000,
  recolte: 3001,
  recyclage: 3002,
  client: 4001,
  contract: 4002,
  document: 5000,
  blog: 6000,
  direction: 7000,
  admin: 8888,
};

const App = () => {
  return (
    <>
      <Routes>
        {/*//! A SUPPRIMER APRES ENREGISTREMENT DES PREMIERS COMPTES SPYDER:*/}
        <Route exact path="/testclient" element={<NewClient />} />
        <Route exact path="/testspyder" element={<NewSpyder />} />
        {/*//! A SUPPRIMER APRES ENREGISTREMENT DES PREMIERS COMPTES SPYDER:*/}

        <Route path="/" element={<PersistLogin />}>
          <Route path="/" element={<Public />}>
            <Route exact path="" element={<Home />} />
            <Route exact path="blog" element={<Blog />} />
            <Route exact path="actions" element={<Actions />} />
            <Route exact path="contact" element={<Contact />} />
            <Route exact path="seconnecter" element={<Login />} />
            <Route exact path="services" element={<Services />} />
            <Route exact path="blog/article/:uid" element={<Article />} />
            <Route exact path="notrepolitique" element={<Politics />} />
            <Route exact path="mentions" element={<Mentions />} />
            <Route path="*/unauthorized" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        {/*//! For identicated Client:*/}
        <Route path="/dashboard/" element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRules={[RULES.user]} />}>
            <Route path="" element={<Client />}>
              <Route
                path=""
                element={
                  <DashConfig title="Mon Profil" page={<ClientProfil />} />
                }
              />
              <Route
                path="home"
                element={
                  <DashConfig title="Mon Profil" page={<ClientProfil />} />
                }
              />
              <Route
                path="profil"
                element={
                  <DashConfig title="Mon Profil" page={<ClientProfil />} />
                }
              />
              <Route
                path="contrat"
                element={
                  <DashConfig title="Mon Contrat" page={<ClientContract />} />
                }
              />
              {/* <Route
                path="documents"
                element={
                  <DashConfig
                    title="Mes documents"
                    page={<ClientDocuments />}
                  />
                }
              /> */}
              <Route
                path="indicators"
                element={
                  <DashConfig
                    title="Mes Indicateurs"
                    page={<ClientIndicators />}
                  />
                }
              />

              <Route
                path="mycollect"
                element={
                  <DashConfig
                    title="Suivi de mes collectes"
                    page={<ClientCollect />}
                  />
                }
              />
            </Route>
          </Route>
        </Route>

        {/*//! For identicated Employed: */}
        <Route path="/dashboard/spyde/" element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRules={[RULES.spyder]} />}>
            <Route path="" element={<Spyde />}>
              <Route
                path="home"
                element={
                  <DashConfig title="Mon profil Spyde" page={<SpydeProfil />} />
                }
              />
              <Route
                path="profil"
                element={
                  <DashConfig title="Mon profil Spyde" page={<SpydeProfil />} />
                }
              />
              <Route element={<RequireAuth allowedRules={[RULES.client]} />}>
                <Route
                  path="clients"
                  element={
                    <DashConfig
                      title="Comptes Clients"
                      page={<SpydeClients />}
                    />
                  }
                />
              </Route>
              <Route element={<RequireAuth allowedRules={[RULES.contract]} />}>
                <Route
                  path="documents"
                  element={
                    <DashConfig title="Administratifs" page={<SpydeDocs />} />
                  }
                />
              </Route>
              <Route
                element={
                  <RequireAuth
                    allowedRules={[RULES.recyclage, RULES.recolte]}
                  />
                }
              >
                <Route
                  path="logistic"
                  element={
                    <DashConfig title="Logistique" page={<SpydeLogistic />} />
                  }
                />
                <Route
                  path={"logistic/roadmap/id/:uid"}
                  //path={"logistic/roadmap/id/*"}
                  element={
                    <DashConfig title="Feuille de route" page={<RoadMap />} />
                  }
                />
              </Route>

              {/*//! For identicated Admin: */}
              <Route element={<RequireAuth allowedRules={[RULES.blog]} />}>
                <Route
                  path="blog"
                  element={
                    <DashConfig
                      title="Gestion du blog Spyde"
                      page={<SpydeBlog />}
                    />
                  }
                />
              </Route>
              <Route element={<RequireAuth allowedRules={[RULES.direction]} />}>
                <Route
                  path="gestion"
                  element={
                    <DashConfig
                      title="Gestion globale"
                      page={<SpydeGestion />}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
