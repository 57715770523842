import { useEffect } from "react";
import ComboText from "../components/blocks/ComboText";
import SvgActions from "../components/elements/SvgActions";
import OneAction from "../components/layouts/OneAction";
import ImageA from "../../src/img/reforestation.jpg";
import ImageB from "../../src/img/usine_fumée.jpg";
import ImageC from "../../src/img/bouteille_ocean.jpg";

const Actions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <section className="actions__title">
        <div className="image__full">
          <img
            className="actions__image image--full"
            src={ImageA}
            alt="Arbres vue de la terre vers le ciel"
          ></img>
        </div>
        <div className="actions__title--text">
          <div className="actions__bg">
            <ComboText
              titleA="Nos Actions"
              titleB="Solidaires de"
              titleC="l’ Environnement"
              paraB="Un engagement écologique fort et des actions concrètes, pour un monde plus propre."
              paraA=""
              paraC=""
              textColor="light"
            />
            <div className="actions__bg--image svgCircle">
              <SvgActions />
            </div>
          </div>
        </div>
      </section>
      <section className="actions__container">
        <OneAction
          reverse=""
          image={ImageA}
          bgColor="white"
          action="LA REFORESTATION"
          title="Un Acte Significatif"
          text=" Nous avons mis en place cet acte solidaire de l’environnement afin
        d’aider à baisser l’impact carbone et à lutter contre la
        déforestation. C’est un fléau qui nous touche tous et qui impacte la
        qualité de notre air. Alors qu’un arbre nouvellement planté
        rapporte en moyenne 40 à 60 kilos de Co2, on s’engage à en planter 3
        pour chaque tonne de matière recyclée. Une association partenaire
        sera chargée de les planter tout en respectant l’environnement et le
        lieu de la plantation. Cela permettra un meilleur développement de
        la faune qui voit leur foyer ravagé par la déforestation."
        />
        <OneAction
          reverse="reverse"
          image={ImageC}
          bgColor="pink"
          action="LE NETTOYAGE"
          title="L'Acte ''City Clean''"
          text="On le sait en France nous avons de magnifiques paysages, 
          que ce soit les plages, les forêts ou les villes avec leur belle 
          rue pavée à l’instar de la rue st Catherine à bordeaux. 
          Mais quel dommage qu’elles soient polluées par des déchets !
          Pour aider à résoudre ce problème nous avons mis en place l’acte City Clean. 
          Cet acte consistera à la mise en place de ramassage des déchets 
          par une équipe constituée d’associations et de salariés de Spyde, 
          afin de ramasser et valoriser ces déchets qui polluent nos océans, 
          nos paysages et nos villes."
        />
        <OneAction
          reverse=""
          image={ImageB}
          bgColor="white"
          action="ASSOCIATIONS PARTENAIRES"
          title="Nos Futures Actions"
          text="Nos associations partenaires sont exclusivement françaises. Fortes de leur expérience, elles nous permettent de sous-traiter la reforestation et le ramassage des déchets jetés dans la nature, en toute confiance.
          Bien sûr nous proposerons rapidement d’autres actions que nous mettrons en place au fur et à mesure. Toujours en collaboration avec nos partenaires associatifs, nous investirons financièrement et logistiquement dans des actes solidaires de l’environnement."
        />
      </section>
    </>
  );
};

export default Actions;
