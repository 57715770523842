import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useAuth from "../../services/useAuth";
import DashButton from "../elements/DashButton";
import InputForm from "../elements/InputForm";
import ImageForm from "./ImageForm";
import { phoneNumber } from "../../utils/phoneNumber";

import TitleBlockForm from "./TitleBlockForm";
import FormTransfertClient from "./FormTransfertClient";
import FormSpydeContract from "./FormSpydeContract";

const FormMyCommercial = ({ dataToShow }) => {
  const auth = useAuth();
  const userData = useSelector((state) => state.user.user);

  const [isAuthorizedForModification, setIsAuthorizedForModification] =
    useState(false);
  const [isDirecteur, setIsDirecteur] = useState(false);
  const [isModify, setIsModify] = useState(false);

  const [transfertClient, setTransfertClient] = useState(false);

  const cancelModify = () => {
    setIsModify(false);
    setIsModify(false);
  };

  //check for authorization :
  useEffect(() => {
    //reset details :
    cancelModify();
    //check if rules Commercial ok :
    if (auth.auth.rules.includes(4002)) {
      // i'm Commercial but check is he's my client :
      if (dataToShow?.Spyder?.id === userData.id) {
        setIsAuthorizedForModification(true);
      } else {
        setIsAuthorizedForModification(false);
      }
    } else {
      setIsAuthorizedForModification(false);
    }
    //check if rules Directeur ok :
    if (auth.auth.rules.includes(7000)) {
      setIsAuthorizedForModification(true);
      setIsDirecteur(true);
    } else {
      setIsDirecteur(false);
    }
  }, [dataToShow]);

  return (
    dataToShow && (
      <div className="detail">
        <form className="dashform__form">
          <TitleBlockForm
            isModify={isModify}
            isAuthorized={isAuthorizedForModification}
            title="Commercial Spyde"
            changeTitle="Transfert de compte Clients"
          />
          <div className="dashTwoParts">
            <div className="dashTwoParts__partTwo">
              <InputForm
                label="Prénom :"
                htmlfor="spyderFirstname"
                defaultValue={dataToShow.Spyder["firstname"]}
                isModify={false}
                isAuthorized={isAuthorizedForModification}
                dataToShow={dataToShow.Spyder["firstname"]}
                required={true}
              />
              <InputForm
                label="Nom :"
                htmlfor="spyderLastname"
                defaultValue={dataToShow.Spyder["lastname"]}
                isModify={false}
                isAuthorized={isAuthorizedForModification}
                dataToShow={dataToShow.Spyder["lastname"]}
                required={true}
              />
              <InputForm
                label="Téléphone :"
                htmlfor="spyderPhone"
                defaultValue={phoneNumber(dataToShow.Spyder["phone"])}
                isModify={false}
                isAuthorized={isAuthorizedForModification}
                dataToShow={phoneNumber(dataToShow.Spyder["phone"])}
                required={true}
              />
            </div>
            <div className="dashTwoParts__partOne">
              <ImageForm
                htmlfor={"Pict_" + dataToShow.id}
                accept=".jpg, .jpeg, .png"
                alt={"Employé Spyde " + dataToShow.clientName}
                inputName="file"
                rounded="rounded"
                //setData={handlePicture}
                //defaultValue="/img/spyde_avatar.png"
                defaultValue={dataToShow.Spyder["addressURL"]}
                //newValue={pictureUrl}
                isModify={false}
                defaultImage="/img/spyde_avatar.png"
                isAuthorized={isAuthorizedForModification}
                //textOverlay="Cliquer pour modifier"
              />
            </div>
          </div>
        </form>
        {isDirecteur && (
          <div className="buttonContainer__direction">
            <div className="buttonContainer--small">
              <DashButton
                action={transfertClient ? "cancel" : "delete"}
                text={
                  transfertClient
                    ? "Annuler le transfert"
                    : "Changer de commercial"
                }
                onClick={() => setTransfertClient(!transfertClient)}
              />
            </div>
          </div>
        )}
        {isDirecteur && transfertClient ? (
          <FormTransfertClient dataToShow={dataToShow} />
        ) : (
          <FormSpydeContract dataToShow={dataToShow} />
        )}
      </div>
    )
  );
};

export default FormMyCommercial;
