import React from "react";
//Formatage de props.text =
// "___" pour tabulation
// ":::" pour nouvelle Ligne
// "/g/" pour element Strong

const ModalContent = (props) => {
  const isNotPair = (number) => {
    return number % 2;
  };

  const cutByLine = props.text.split(":::");

  let separateStrongElement;
  let findTabulation;
  let elementWithNoTab;
  let lineWithNoTab;

  return (
    <>
      <div className="modalContent">
        {cutByLine.map((line, index) => {
          separateStrongElement = line.split("/g/");
          findTabulation = line.split("___");
          lineWithNoTab = line.split("___").join("");
          if (separateStrongElement.length > 1) {
            return (
              <p key={index} className="modalContent__text">
                {isNotPair(findTabulation.length) ? (
                  ""
                ) : (
                  <span className="modalContent__text--withtab"></span>
                )}
                {separateStrongElement.map((element, index) => {
                  elementWithNoTab = element.split("___").join("");
                  if (isNotPair(index)) {
                    //Strong element For SEO
                    return <strong key={index}>{elementWithNoTab}</strong>;
                  } else {
                    return (
                      <React.Fragment key={index}>
                        {elementWithNoTab}
                      </React.Fragment>
                    );
                  }
                })}
              </p>
            );
          } else {
            return (
              <p key={index} className="modalContent__text">
                {isNotPair(findTabulation.length) ? (
                  ""
                ) : (
                  <span className="modalContent__text--withtab"></span>
                )}
                {lineWithNoTab}
              </p>
            );
          }
        })}

        <p className="modalContent__moreText">{props.moreText}</p>

        {props.children}
      </div>
    </>
  );
};

export default ModalContent;
