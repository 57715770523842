import { useEffect, useState } from "react";

import DashButton from "../../components/elements/DashButton.js";
import WidgetContainer from "../../components/widgets/WidgetContainer.js";
import ModalContent from "../layouts/ModalContent.js";
import ModalLayout from "../layouts/ModalLayout.js";
import { toast } from "react-toastify";
import DatePicker from "../elements/DatePicker.js";
import dayjs from "dayjs";
import MultiBtnOnOff from "../elements/MultiBtnOnOff.js";

import { INFO_REGEX } from "../../utils/regex.js";
import { useDispatch, useSelector } from "react-redux";
import { addNewRoadMap, getRoadMaps } from "../../feature/roadmap.slice.js";
import dateFormat from "../../utils/dateFormat.js";
import { ListeRoadmap } from "./ListeRoadmap.js";
import isEmpty from "../../utils/isEmpty.js";

const ListeWidgetRoadMap = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const roadMapData = useSelector((state) => state.roadmaps.roadmaps);
  //for active Button :

  const [modal, setModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [moment, setMoment] = useState("");
  const [infoSup, setInfoSup] = useState("");
  const [noData, setNoData] = useState("");
  const [validInfoSup, setValidInfoSup] = useState(false);
  const [arrayData, setArrayData] = useState(null);

  useEffect(() => {
    dispatch(getRoadMaps());
    //toast.info("Chargement Roadmaps DISPATCH");
  }, [dispatch]);

  let dataConvert = [
    {
      data: [],
    },
    {
      config: ["Numero", "Date", "Moment", "Info", "Etat"],
    },
  ];

  //* Generate arrayData :
  useEffect(() => {
    setArrayData(dataConvert);
    let testData = roadMapData?.data;
    if (!isEmpty(testData)) {
      testData.map((roadmap) => {
        let isPublished;
        if (roadmap.driver_id) {
          isPublished = "PUBLIÉE";
        } else {
          isPublished = "EN PREPARATION";
        }

        //addnewData :
        let addData = {
          id: roadmap.id,
          number: "FR" + roadmap.id,
          date: dateFormat(`${roadmap.roadmapDate}`),
          period: roadmap.moment,
          note: roadmap.comment,
          state: isPublished,
        };

        //push addData in arrayData :
        return dataConvert[0].data.push(addData);
      });

      setArrayData(dataConvert);
    }
  }, [roadMapData]);

  const toggleModal = () => {
    if (modal) {
      setDate(new Date());
      setInfoSup("");
      setMoment("");
    }
    setModal(!modal);
  };

  const getSelectedPeriod = (selection) => {
    setMoment(selection);
  };

  const changeDate = (date) => {
    setDate(dayjs(date).format("YYYY-MM-DD"));
  };

  //pour modification state depuis enfant//
  const unSelectLine = () => {
    setDate(new Date());
    setModal(false);
    setDate(null);
    setMoment("");
    setInfoSup("");
    setValidInfoSup(false);
  };

  useEffect(() => {
    unSelectLine();
  }, []);

  useEffect(() => {
    if (infoSup) {
      setValidInfoSup(INFO_REGEX.test(infoSup));
    } else setValidInfoSup(false);
  }, [infoSup]);

  //Formatage data : //

  const [newTableData, setNewTableData] = useState([]);

  function isKeyExists(obj, key) {
    return key in obj;
  }

  // Generate array for data :
  useEffect(() => {
    arrayData && setNewTableData(arrayData);
    if (newTableData[0]) {
      let linesData = newTableData[0]?.data;
      const transformData = {
        col_title: newTableData[1]?.config,
        lines: linesData.map((line) => {
          let tabLine = [];

          // For RodMapData dans l'ordre d'affichage souhaité (idem .config):
          isKeyExists(line, "number") && tabLine.push(line.number);
          isKeyExists(line, "date") && tabLine.push(line.date);
          isKeyExists(line, "period") && tabLine.push(line.period);
          isKeyExists(line, "note") && tabLine.push(line.note);
          isKeyExists(line, "state") && tabLine.push(line.state);

          return tabLine;
        }),
      };
      setNewTableData(transformData);
    }
    if (newTableData[0] === [""]) {
      setNoData(
        "Aucune donnée ne semble disponible ! Actualiser la page ou créer une nouvelle feuille de route."
      );
    }
  }, [arrayData]);

  const changeInfoSup = (e) => {
    setInfoSup(e.target.value);
  };

  // GET NEW ROADMAP :
  useEffect(() => {
    dispatch(getRoadMaps());
  }, [dispatch]);

  const handleNewRoadMap = async (e) => {
    e.preventDefault();

    try {
      let data = {
        moment: moment,
        comment: infoSup,
        roadmapDate: date,
        creator_id: userData.id,
        driver_id: null,
        isAtSpyde: false,
        isOnline: false,
      };
      await dispatch(addNewRoadMap(data));
      await toggleModal(false);
    } catch (err) {
      toast.error("ERREUR lors de l'ajout de la feuille de route");
    }
  };

  return (
    <WidgetContainer
      key={props.order}
      icon="document-text-outline"
      title={props.containerName}
      order={1}
      // unSelectLine={unSelectLine} //!pour modification state depuis enfant//
    >
      <div className="tableGenerator__body">
        {newTableData ? (
          <ListeRoadmap />
        ) : (
          <p className="centerLign">{noData}</p>
        )}
      </div>
      <div className="buttonsTable">
        <div className="buttonsTable__buttonDim">
          <DashButton
            //disabled={false}
            iconA="add"
            action="action"
            text="Ajouter une feuille"
            onClick={() => setModal(true)}
          />

          <ModalLayout
            toggle={toggleModal}
            modal={modal}
            label="Création d'une nouvelle page"
            colorClass="blue"
          >
            <ModalContent
              text="Vous êtes sur le point d'ajouter une nouvelle feuille de route. :::Attention : Vérifier si une feuille de route existe déjà, avant d'en créer une nouvelle "
              moreText="Sélectionner la date et la période de la feuille de route : "
            >
              <div className="duoLign">
                <div className="duoLign__left">
                  <DatePicker changeStartDate={changeDate} />
                </div>
                <div className="duoLign__right">
                  <MultiBtnOnOff
                    name="  "
                    values={["Journée", "Matin", "Après-midi"]}
                    getSelected={getSelectedPeriod}
                  />
                </div>
              </div>
              <div className="dashform__form pad--1">
                <label htmlFor="infoSup" className="pad--1">
                  Différencier les feuilles de routes :
                </label>
                <input
                  id="infoSup"
                  className="font__simpleText"
                  placeholder="Nom du chauffeur, Secteur, etc ..."
                  onChange={(e) => changeInfoSup(e)}
                ></input>
              </div>
              <div className="centerLign">
                <p className="pad--1">
                  Vous souhaitez créer une feuille de route pour le :
                </p>
                <p className="font__mainInfo">
                  {dayjs(date).format("DD/MM/YYYY") +
                    " - " +
                    moment +
                    " - " +
                    infoSup}
                </p>
              </div>

              <div className="buttonsContainer">
                <div className="buttonContainer--small">
                  {date && moment && infoSup && validInfoSup ? (
                    <DashButton
                      //disabled={disabled}
                      action="action"
                      text="Valider"
                      onClick={(e) => handleNewRoadMap(e)}
                    />
                  ) : (
                    <DashButton
                      disabled={true}
                      action="inactive"
                      text="Veuillez confirmer"
                    />
                  )}
                </div>
                <div className="buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="cancel"
                    text="Annuler"
                    onClick={(e) => toggleModal(e)}
                  />
                </div>
              </div>
            </ModalContent>
          </ModalLayout>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default ListeWidgetRoadMap;
