import { useState, useEffect } from "react";
import { phoneNumber } from "../../utils/phoneNumber";
import { useSelector } from "react-redux";

const MySpydeContact = () => {
  const userData = useSelector((state) => state.user.user);

  const Lastname = userData.spydeContact.lastname;
  const Firstname = userData.spydeContact.firstname;
  const tel = userData.spydeContact.phone;
  const email = userData.spydeContact["Login.email"];

  const [imageUrl, setImageUrl] = useState(userData.spydeContact.addressURL);

  useEffect(() => {
    if (userData.spydeContact.addressURL) {
      setImageUrl(userData.spydeContact.addressURL);
    } else {
      setImageUrl("/img/spyde_avatar.png");
    }
  }, []);

  return (
    <>
      <div className="dashform dashform--row">
        <form className="dashform__form">
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Nom :</label>

            <p className="responseContainer__result">{Firstname}</p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Prénom :</label>

            <p className="responseContainer__result">{Lastname}</p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>Téléphone :</label>
            <p className="responseContainer__result">{phoneNumber(tel)}</p>
          </div>
          <div className="dashform__form__block dashform__form__block--padding responseContainer__split">
            <label>E-mail :</label>
            <p className="responseContainer__result">{email}</p>
          </div>
        </form>

        <div className="imageContainer">
          <label>
            <div className="imageBlock">
              <img
                className="imageBlock__img"
                src={imageUrl}
                alt="votre photo de profil"
              />
            </div>
          </label>
        </div>
      </div>
      <div className="buttonsContainer"></div>
    </>
  );
};

export default MySpydeContact;
