import React, { useState, useLayoutEffect } from "react";
import ModalLayout from "../layouts/ModalLayout";
import { useSelector, useDispatch } from "react-redux";
import dateFormat from "../../utils/dateFormat";

import DashButton from "../elements/DashButton";
import { addNewArticle, getPagesBlog } from "../../feature/pageBlog.slice";
import { BlogEditArticle } from "./BlogEditArticle";

export const BlogSelectArticle = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const pagesBlogData = useSelector((state) => state.pagesBlog.pagesBlog);
  const [modalNewArticle, setModalNewArticle] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedPage, setSelectedPage] = useState("");

  useLayoutEffect(() => {
    dispatch(getPagesBlog());
  }, [dispatch]);

  const toggleModalNewArticle = (e) => {
    setModalNewArticle(e.target.value);
    if (!e.target.value) {
      document.body.classList.remove("active-modal");
    }
  };

  const closeModalNewArticle = () => {
    setModalNewArticle(false);

    document.body.classList.remove("active-modal");
  };

  const changeTitle = (e) => {
    setTitle(e.target.value);
  };

  const generateNewArticle = (e) => {
    e.preventDefault();

    try {
      let data = {
        spyder_id: userData.id,
        isPublished: false,
        pageName: title,
      };

      dispatch(addNewArticle(data));
      setModalNewArticle(false);
      document.body.classList.remove("active-modal");
    } catch (err) {
      console.log("Error generateNewArticle");
    }
  };

  const toggleSelectedPage = (e, page) => {
    setSelectedPage(page);
  };

  const clearSelectedPage = () => {
    setSelectedPage("");
  };

  return (
    <>
      <div className="blockMessage">
        <div className="recycle">
          <div className="buttonContainer--small">
            <DashButton
              //disabled={disabled}
              action="search"
              iconA="add"
              text="Ajouter un nouvel article"
              onClick={() => setModalNewArticle(true)}
            />
          </div>
          <div className="recycle__container">
            <div className="recycle__container__table">
              <table className="tableRecycle">
                <thead className="tableRecycle__lines tableRecycle__line--header">
                  <tr className="tableRecycle__columns tableRecycle__columns--header">
                    <th className="tableRecycle__columns--text widgetFont__table--header">
                      N°
                    </th>
                    <th className="tableRecycle__columns--text widgetFont__table--header">
                      Titre
                    </th>
                    <th className="tableRecycle__columns--text widgetFont__table--header">
                      Date
                    </th>
                    <th className="tableRecycle__columns--text widgetFont__table--header">
                      Statut
                    </th>
                  </tr>
                </thead>
                <tbody className="tableRecycle__lines">
                  {pagesBlogData &&
                    pagesBlogData.data.map((page, index) => {
                      let testId = page.id;
                      let selectedId = selectedPage.id;

                      return (
                        <tr
                          key={index}
                          className={
                            testId == selectedId
                              ? "tableRecycle__columns tableRecycle__columns--selectable tableRecycle__columns--selected"
                              : "tableRecycle__columns tableRecycle__columns--selectable"
                          }
                          onClick={(e) => toggleSelectedPage(e, page)}
                        >
                          <th className="minSizeAuto tableRecycle__columns--text widgetFont__table--body">
                            {page.id}
                          </th>
                          <th className="cutTextLine tableRecycle__columns--text widgetFont__table--body">
                            {page.pageName}
                          </th>
                          <th className="minSizeAuto tableRecycle__columns--text widgetFont__table--body">
                            {dateFormat(page.createdAt)}
                          </th>
                          <th className="minSizeAuto tableRecycle__columns--text widgetFont__table--body">
                            {page.isPublished ? (
                              <span className="inLine">En Ligne</span>
                            ) : (
                              <span className="offLine">Hors Ligne</span>
                            )}
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {modalNewArticle && (
          <ModalLayout
            toggle={toggleModalNewArticle}
            modal={modalNewArticle}
            label="Ajouter un article au blog"
            colorClass="yellow"
          >
            <div className="modalContent editBlog__modal">
              <label className="modalContent__text">
                Quel est le titre de l'article à créer ?
              </label>

              <input
                type="text"
                className="editBlog__modal--input"
                placeholder="Choisissez le titre de votre article"
                onChange={changeTitle}
              />
              <p className="font__italic pad--1">
                Nota : Vous pourrez le modifier avant sa publication.
              </p>
              <div className="buttonsContainer">
                <div className="buttonContainer buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="action"
                    text="Créer l'article"
                    onClick={(e) => generateNewArticle(e)}
                  />
                </div>

                <div className="buttonContainer buttonContainer--small">
                  <DashButton
                    //disabled={disabled}
                    action="cancel"
                    text="Annuler"
                    onClick={() => closeModalNewArticle()}
                  />
                </div>
              </div>
            </div>
          </ModalLayout>
        )}
      </div>
      {selectedPage ? (
        <BlogEditArticle
          pageData={selectedPage}
          clearSelectedPage={clearSelectedPage}
        />
      ) : (
        <div className="blockMessage blockMessage__message">
          <p className="font__italic">
            Sélectionner un article pour le modifier
          </p>
        </div>
      )}
    </>
  );
};
