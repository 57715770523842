import React, { useLayoutEffect, useState } from "react";
import { RecycleBox } from "../blocks/RecycleBox";
import plasticImg from "../../img/Plastic.svg";
import inkImg from "../../img/Ink.svg";
import aluImg from "../../img/Alu.svg";
import paperImg from "../../img/Paper.svg";
import electronicImg from "../../img/DEE.svg";
import { useSelector } from "react-redux";

export const MyRecycles = () => {
  const recyclesData = useSelector((state) => state.recycles.recycles);

  const [totalPlastic, setTotalPlastic] = useState(0);
  const [totalPaper, setTotalPaper] = useState(0);
  const [totalInk, setTotalInk] = useState(0);
  const [totalElectronic, setTotalElectronic] = useState(0);
  const [totalAluminium, setTotalAluminium] = useState(0);

  const [dataOk, setDataOk] = useState(false);

  //separate weight
  useLayoutEffect(() => {
    let allWeights = [];
    recyclesData?.data.map((recycle) => {
      const { plastic, paper, electronic, aluminium, ink } = recycle;
      return allWeights.push([plastic, paper, electronic, aluminium, ink]);
    });

    let plasticWeight = 0;
    let paperWeight = 0;
    let electronicWeight = 0;
    let inkWeight = 0;
    let aluminiumWeight = 0;

    allWeights.map((weightsByRecycle) => {
      plasticWeight = plasticWeight + weightsByRecycle[0];
      paperWeight = paperWeight + weightsByRecycle[1];
      electronicWeight = electronicWeight + weightsByRecycle[2];
      aluminiumWeight = aluminiumWeight + weightsByRecycle[3];
      inkWeight = inkWeight + weightsByRecycle[4];
    });
    setTotalPlastic(plasticWeight);
    setTotalPaper(paperWeight);
    setTotalElectronic(electronicWeight);
    setTotalInk(inkWeight);
    setTotalAluminium(aluminiumWeight);
    setDataOk(true);
  }, [recyclesData]);

  return (
    <div className="myrecycle">
      {dataOk ? (
        <>
          <RecycleBox
            title="PLASTIQUE"
            weight={totalPlastic}
            logoUrl={plasticImg}
            color="plastic"
          />
          <RecycleBox
            title="PAPIER"
            weight={totalPaper}
            logoUrl={paperImg}
            color="paper"
          />
          <RecycleBox
            title="ALUMINIUM"
            weight={totalAluminium}
            logoUrl={aluImg}
            color="aluminium"
          />
          <RecycleBox
            title="CARTOUCHE"
            weight={totalInk}
            logoUrl={inkImg}
            color="ink"
          />
          <RecycleBox
            title="ELECTRONIQUE"
            weight={totalElectronic}
            logoUrl={electronicImg}
            color="electronic"
          />
        </>
      ) : (
        "Chargement des données ..."
      )}
    </div>
  );
};
