import { useEffect } from "react";
import SectHomeA from "../components/layouts/SectHomeA";
import SectHomeB from "../components/layouts/SectHomeB";
import SectHomeC from "../components/layouts/SectHomeC";
import SectHomeFAQ from "../components/layouts/SectHomeFAQ";
import SectRdv from "../components/layouts/SectRdv";
import SectHomeBlog from "../components/layouts/SectHomeBlog";
import { useDispatch } from "react-redux";
import { getPublishPagesBlog } from "../feature/publishPageBlog.slice";
import { getPublishImagesBlog } from "../feature/publishImagesBlog.slice";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublishPagesBlog());
    dispatch(getPublishImagesBlog());
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="home">
      <SectHomeA />
      <SectHomeB />
      <SectHomeC />
      <SectHomeBlog />
      <SectRdv />
      <SectHomeFAQ />
    </div>
  );
};

export default Home;
