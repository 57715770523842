import { useState, useEffect } from "react";
import formatLog from "../../utils/formatLog";

const MultiBtnOnOff = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    props.getSelected(selectedItem);
    formatLog("selectedItem", selectedItem);
  }, [selectedItem]);

  let valuesArray = props.values;

  return (
    <div className="containerBtnOnOff">
      <p className="containerBtnOnOff__title">{props.name}</p>
      <div className="containerBtnOnOff containerBtnOnOff__buttons">
        {valuesArray.map((value, index) => {
          return isSelected && selectedItem === value ? (
            <button
              key={index}
              className={"btnOnOff btnOnOff--selected"}
              onClick={() => {
                setIsSelected(false);
                setSelectedItem("");
              }}
            >
              {value}
            </button>
          ) : (
            <button
              key={index}
              className={"btnOnOff"}
              onClick={() => {
                setSelectedItem(value);
                setIsSelected(true);
              }}
            >
              {value}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MultiBtnOnOff;
