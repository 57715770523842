import React, { useState, useEffect } from "react";
import DashButton from "../elements/DashButton";
import { useDispatch, useSelector } from "react-redux";
import { deleteRdvFromPublic, getRdvFromPublic } from "../../feature/rdv.slice";
import dateFormat from "../../utils/dateFormat";

export const PublicMessages = () => {
  const dispatch = useDispatch();
  const rdvsData = useSelector((state) => state.rdvs.rdvs);

  const [selectedRdv, setSelectedRdv] = useState([]);

  useEffect(() => {
    dispatch(getRdvFromPublic());
  }, [dispatch]);

  const toggleSelectedRdv = (e, rdv) => {
    e.preventDefault();
    setSelectedRdv(rdv);
  };

  const handleDeleteRdv = async (id) => {
    await dispatch(deleteRdvFromPublic(id));
    await setSelectedRdv([]);
  };

  return (
    <div className="blockMessage">
      <div className="blockMessage__halfContainer recycle">
        <div className="recycle__container">
          <div className="recycle__container__table">
            <table className="tableRecycle">
              <thead className="tableRecycle__lines tableRecycle__line--header">
                <tr className="tableRecycle__columns tableRecycle__columns--header">
                  <th className="tableRecycle__columns--text widgetFont__table--header">
                    N°
                  </th>
                  <th className="tableRecycle__columns--text widgetFont__table--header">
                    Date
                  </th>
                  <th className="tableRecycle__columns--text widgetFont__table--header">
                    Entreprise
                  </th>
                  <th className="tableRecycle__columns--text widgetFont__table--header">
                    Contact
                  </th>
                </tr>
              </thead>
              <tbody className="tableRecycle__lines">
                {rdvsData &&
                  rdvsData.data.map((rdv, index) => {
                    let testId = rdv.id;
                    let selectedId = selectedRdv.id;

                    return (
                      <tr
                        key={index}
                        className={
                          testId == selectedId
                            ? "tableRecycle__columns tableRecycle__columns--selectable tableRecycle__columns--selected"
                            : "tableRecycle__columns tableRecycle__columns--selectable"
                        }
                        onClick={(e) => toggleSelectedRdv(e, rdv)}
                      >
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {rdv.id}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {dateFormat(rdv.createdAt)}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {rdv.companyName}
                        </th>
                        <th className="tableRecycle__columns--text widgetFont__table--body">
                          {rdv.name}
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="blockMessage__halfContainer blockMessage__message">
        <div className="rdvGrid">
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Demande n° :</span>
            <p>{selectedRdv.id ? selectedRdv.id : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Date d'envoi :</span>
            <p>
              {selectedRdv.createdAt
                ? dateFormat(selectedRdv.createdAt)
                : "..."}
            </p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Entreprise :</span>
            <p>{selectedRdv.companyName ? selectedRdv.companyName : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Contact :</span>
            <p>{selectedRdv.name ? selectedRdv.name : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Code postal :</span>
            <p>{selectedRdv.zipCode ? selectedRdv.zipCode : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">E-mail :</span>
            <p>{selectedRdv.email ? selectedRdv.email : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Nbre employés :</span>
            <p>{selectedRdv.companySize ? selectedRdv.companySize : "..."}</p>
          </div>
          <div className="rdvGrid__element">
            <span className="font__sublineInfo">Téléphone :</span>
            <p>{selectedRdv.phone ? selectedRdv.phone : "..."}</p>
          </div>
        </div>

        <div className="blockMessage__message">
          <span className="font__sublineInfo">Message du contact :</span>
          <p className="font__paragraphe">
            {selectedRdv.message
              ? selectedRdv.message
              : "Sélectionnez une demande pour l'afficher ..."}
          </p>
        </div>
        <div className="buttonsContainer">
          <div className="buttonContainer--small">
            <DashButton
              //disabled={disabled}
              action="delete"
              text="Supprimer"
              onClick={() => handleDeleteRdv(selectedRdv.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
