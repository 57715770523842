import { useEffect } from "react";
import WidgetContainer from "../../components/widgets/WidgetContainer";
import { MyRecycles } from "../../components/widgets/MyRecycles";
import { TotalRecycles } from "../../components/widgets/TotalRecycles";
import { useDispatch, useSelector } from "react-redux";
import { getClientRecycles } from "../../feature/recycle.slice";
import { FollowRecycles } from "../../components/widgets/FollowRecycles";
import { HelpForForest } from "../../components/widgets/HelpForForest";
import { Bonus } from "../../components/widgets/Bonus";
import { getMyContract } from "../../feature/contract.slice";

const ClientIndicators = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(getClientRecycles(userData.contact["Client.id"]));
    dispatch(getMyContract(userData.contact["Client.id"]));
  }, []);

  return (
    <div className="configGrid">
      <section className="recycleGrid">
        <div className="recycleGrid__element recycleGrid__element--span2">
          <WidgetContainer
            icon="sync-outline"
            title="Mes recyclages"
            order={1}
            open={true}
          >
            <MyRecycles />
          </WidgetContainer>
          <div className="recycleGrid__element--gap"></div>
          <WidgetContainer
            icon="leaf-outline"
            title="Aide à la reforestation"
            order={4}
          >
            <HelpForForest />
          </WidgetContainer>
          <div className="recycleGrid__element--gap"></div>
          <WidgetContainer
            icon="analytics-outline"
            title="Evolution de mes recyclages"
            order={3}
          >
            <FollowRecycles />
          </WidgetContainer>
        </div>
        <div className="recycleGrid__element">
          <WidgetContainer
            icon="pie-chart-outline"
            title="Total et répartition"
            order={2}
            open={true}
          >
            <TotalRecycles />
          </WidgetContainer>
          <div className="recycleGrid__element--gap"></div>
          <WidgetContainer icon="star-outline" title="Mon Bonus" order={5}>
            <Bonus />
          </WidgetContainer>
        </div>
      </section>
    </div>
  );
};

export default ClientIndicators;
