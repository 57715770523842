import React, { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import { useDispatch } from "react-redux";
import { selectWish, unselectWish } from "../../feature/wish.slice";
import { useParams } from "react-router-dom";
import DashButton from "../elements/DashButton";

const MapPopupSelected = ({
  result,
  removeToSelectedData,
  isPublished,
  listOfSelected,
}) => {
  const [selectedLocation, setSelectedLocation] = useState([{}]);
  const dispatch = useDispatch();
  let { uid } = useParams();
  const roadmapId = uid;

  const removeOfSelected = async (result, e) => {
    e.preventDefault();

    const changePositionOfSelectedWish = async (position) => {
      if (position < listOfSelected.length) {
        await listOfSelected
          .filter((wish) => wish.position > position)
          .forEach((wish) => {
            let data = {
              roadmap_id: roadmapId,
              position: wish.position - 1,
            };
            let dataAndId = {
              data: data,
              id: wish.id,
            };

            dispatch(selectWish(dataAndId));
          });
      } else {
        //console.log("LAST ONE");
      }
    };

    try {
      let data = {
        roadmap_id: roadmapId,
        //position: null,
      };
      let dataAndId = {
        data: data,
        id: result.id,
      };
      await dispatch(unselectWish(dataAndId)).then((res) => {
        changePositionOfSelectedWish(result.position);
      });
      removeToSelectedData(result);
      setSelectedLocation([{}]);
    } catch (err) {
      console.log("err : MapPopupSelected");
    }
  };

  return (
    <div key={"dataMap" + result.id}>
      <Marker longitude={result.long} latitude={result.lat}>
        <div
          className="mapPoints mapPoints--selected"
          onClick={() => setSelectedLocation(result)}
        >
          <ion-icon
            className={"mapPopup__status--" + result.is_in_time}
            icon={"location-sharp"}
          ></ion-icon>
        </div>
      </Marker>
      {selectedLocation.long === result.long ? (
        <Popup
          onClose={() => setSelectedLocation([{}])}
          closeOnClick={false}
          latitude={result.lat}
          longitude={result.long}
        >
          <div className="mapPopup">
            <p className="mapPopup__title">Demande</p>
            <p className="mapPopup__demande mapPopup__demande--selected">
              {result.demande}
            </p>
            <div className="mapPopup__status mapPopup__status--selected">
              <ion-icon icon="shield-checkmark-sharp"></ion-icon>
              <p>Sélectionnée</p>
            </div>
            <p className="mapPopup__name">
              {"Etape n° " + result.position + " :"}
            </p>
            <p className="mapPopup__name">{result.name}</p>
            <p className="mapPopup__adress">{result.site}</p>
          </div>
          {!isPublished && (
            <div className="mapPopup__button">
              <DashButton
                action="cancel"
                text="Retirer"
                onClick={(e) => removeOfSelected(result, e)}
              />
            </div>
          )}
        </Popup>
      ) : (
        ""
      )}
    </div>
  );
};

export default MapPopupSelected;
